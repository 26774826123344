import { useState, Fragment, useEffect } from 'react';
import { Dialog, Transition } from "@headlessui/react";
import { useDarkMode } from 'frontastic';
import { FiPhoneCall, FiX } from "react-icons/fi";
import Markdown from 'components/commercetools-ui/content/markdown';


interface Props {
  onSubmit: (boolean) => void,
  onClose: (boolean) => void,
  isAnonymousUser?: boolean,
  phoneNumber: string
}

const CustomerCarePopup = ({ onSubmit, onClose, isAnonymousUser = true, phoneNumber }: Props) => {
  const { mode, theme } = useDarkMode();
  const [userInput, setUserInput] = useState('')

  const handleInputChange = (e) => {
    const { target: { value } } = e;
    console.log(value, !isNaN(parseInt(value)))
    if (!isNaN(parseInt(value))) {
      setUserInput(value)
    }
  }

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog className={`${mode} ${theme} default fixed inset-0 z-10 overflow-y-auto dialog-background remove-dialog-background`} onClose={() => { }}>
        <div className="remove-dialog-content">
          <div>
            <FiPhoneCall size={24} />
            <div className='remove-dialog-text primary-remove-dialog-text !-mt-8 !-ml-8'>
              <p className={`text-lg -ml-${isAnonymousUser ? '16' : '20'} mt-1`}>Customer care</p>
              <FiX className="float-right text-primary-theme-color !-mt-8 !-ml-8 cursor-pointer" onClick={onClose} />
            </div>
          </div>
          {
            isAnonymousUser
              ? <>
                <div className='remove-dialog-text secondary-remove-dialog-text text-base'>Register with us to receive a call back</div>
                <div className='remove-dialog-button-container'>
                  <button className='remove-dialog-secondary-button !bg-primary-theme-color !text-white' onClick={() => onSubmit(false)}>Register</button>
                </div>
              </>
              : <div className='w-full'>
                <div className='remove-dialog-text secondary-remove-dialog-text text-base'>For Support contact us on 
                <Markdown isMarkdownClassApply={false} className='inline-flex text-primary-theme-color underline' text={phoneNumber}></Markdown></div>
                <hr className='border-t-2' />
                <div className='remove-dialog-text secondary-remove-dialog-text text-base -ml-20 mb-2'>For Call back enter your number</div>
                <input type="text" className='w-full' value={userInput} onChange={handleInputChange} placeholder='Enter your number here' />
                <div className='remove-dialog-button-container'>
                  <button className='remove-dialog-secondary-button !bg-primary-theme-color !text-white' onClick={() => onSubmit(userInput)}>Submit</button>
                </div>
              </div>
          }
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default CustomerCarePopup;