import React, { useEffect, useState } from 'react';
import NextLink from 'next/link';
import QuoteDetails from 'components/commercetools-ui/products/product-details/request-quote-details';
import QuoteItem from 'components/commercetools-ui/request-for-quote/quote-item';
import RequestQuoteProductDetails from 'components/commercetools-ui/request-for-quote/quote-product-details';
import SellersDetail from 'components/commercetools-ui/request-for-quote/sellers-detail';
import { useAccount } from 'frontastic';
import { useRouter } from 'next/router';
import Stepper from '../../../components/commercetools-ui/stepper';
import { notifyQuoteActions } from 'components/commercetools-ui/cart/notification';
import { backendAPIClient } from 'helpers/utils/axiosRequestsUtil';
import {
  STATIC_CREATE_QUOTE_CART,
  STATIC_CREATE_QUOTE_REQUEST,
  STATIC_GET_SELLER_DETAILS,
  STATIC_QUOTE_DOCUMENTS_UPLOAD,
  STATIC_STAGEQUOTE_COMMENT,
} from 'helpers/constants/statisURLs';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import Modal from './Modal';
import Loader from 'components/commercetools-ui/loader';
import { FileUpload } from 'components/commercetools-ui/form';
import { useFormat } from 'helpers/hooks/useFormat';
import TalkToSeller from './talkToSeller';
import toast from 'react-hot-toast';

function RequestForQuote() {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [fullProducts, setFullProducts] = useState([]);
  const [addedProducts, setAddedProducts] = useState([]);
  const [productSellers, setProductSellers] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [packageState, setPackage] = useState('1Kg');
  const [description, setDescription] = useState('');
  const [showPreview, setShowPreview] = useState(false);
  const [disableAddProductBtn, setDisableAddProductBtn] = useState(true);
  const { account } = useAccount();
  const [addProductResponse, setAddProductResponse] = useState();
  const [step1Values, setStep1Values] = useState({ RFQTitle: '', quoteNumber: '' });
  const [quoteDocuments, setQuoteDocuments] = useState<any>();
  const [hideSteps, setHideSteps] = useState([1, 2]);
  const [isValid, setIsValid] = useState(true);
  const [selectedMobileStep, setSelectedMobileStep] = useState(1);
  const [selectedTab, setSelectedTab] = useState('Request Quote');
  const [clearData, setClearData] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isMultiplevendorFound, setIsMultiplevendorFound] = useState(false);
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [talkToSellerMessage, setTalkToSellerMessage] = useState('');
  const [talkToSellerDocument, setTalkToSellerDocument] = useState();
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [file, setFile] = useState<File>();
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const accept = '.doc, .docx, .xls, .xlsx, .pdf';
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage } = useFormat({ name: 'common' });
  const [selectedSellers, setSelectedSellers] = useState([])
  const [selectedAttributes, setSelectedAttributes] = useState({})

  const clearUpload = () => {
    setTalkToSellerDocument(null);
    setIsFileUploaded(false);
  };

  const onFileChange = (file: any) => {
    setIsFileUploaded(false);
    setTalkToSellerDocument(file[0]);
  };

  // const onUploadFile = async (file: any) => {
  //   setTalkToSellerDocument(file[0])
  // };

  const handleTalkToSellerSave = async () => {
    try {
      const formData = new FormData();
      formData.append('stagedQuoteId', '97f6b707-df16-4b6e-898a-9b0fb8945c06');
      formData.append('name', 'Gouthami');
      formData.append('postDate', `${new Date()}`);
      formData.append('companyName', 'Royal Cyber Company');
      formData.append('message', talkToSellerMessage);
      formData.append('quoteDocument', talkToSellerDocument);
      formData.append('source', 'SYSTEM');

      await backendAPIClient.put(STATIC_STAGEQUOTE_COMMENT, formData).then((res) => {
        fileUploadCallback(res);
        setIsFileUploaded(true);
      });
    } catch (err) { }
  }

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const openModal = () => {
    setShowModal(true);
  };

  const getSelectedProduct = (product) => {
    setFullProducts([...fullProducts, product]);
    setSelectedProduct(product);
    setDisableAddProductBtn(false);
    setClearData(false);

    const foundVendor = product?.masterVariant?.attributes?.filter((attr) => attr.name === 'vendor-name');

    if (foundVendor && foundVendor.length > 0) {
      setProductSellers([{ name: foundVendor[0].value['en-IN'] }]);
    }
    quantityCallBack(1);
    if (product) {
      setHideSteps([]);
    } else {
      setHideSteps([1, 2]);
    }
  };

  const checkIfValuePresent = (attributes) => {
    return attributes.find(ele => ele.name === 'packaging-size').value === selectedAttributes['packSize'] &&
      (attributes.find(ele => ele.name === 'supplier-part-number') ? attributes.find(ele => ele.name === 'supplier-part-number').value === selectedAttributes['supplierPartNumber'] : true)
  }
  const getResponseBodyForAddProduct = (products = addedProducts) => {
    const lineItems = [];
    products.forEach((p) => {
      let variant = p.product?.variants.find(e => checkIfValuePresent(e.attributes));

      lineItems.push({
        productName: p?.product?.name?.['en-IN'],
        variantSKU: variant.sku,
        quantity: p.quantity,
        // vendorName: p?.product?.variants[0]?.attributes.find((element) => element.name == 'vendor-name').value['en-IN'],
        vendorName: (variant.attributes.find(ele => ele.name === 'vendor-name')).value['en-IN']
      });
    });
    const reqPayload = {
      lineItems,
      description: description,
      shippingAddress: {
        country: 'IN',
      },
      customFields: {
        quoteNumber: step1Values?.quoteNumber,
        RFQTitle: step1Values?.RFQTitle,
        isQuote: true,
        quoteDocuments: quoteDocuments?.data.join(','),
      },
      customerId: account?.accountId,
      email: account?.['work_email'],
    };
    return reqPayload;
  };

  const addProductToQuote = async () => {
    if (step1Values?.RFQTitle) {
      setIsValid(false);
    }
    if (step1Values?.RFQTitle && selectedProduct) {
      const products = [
        ...addedProducts,
        { id: selectedProduct?.id, product: selectedProduct, quantity, productPackage: packageState },
      ];
      console.log('products - ', products);
      setAddedProducts(products);

      setLoading(true);
      // This is temporary arrangement, should be called onClick of "Reques for quote" 
      quoteDetailsHandler(products)
    } else {
      setIsValid(false);
    }
  };

  const clearExistingData = () => {
    setSelectedProduct(null);
    setHideSteps([1, 2]);
    setClearData(true);
    setShowPreview(true);
    setIsValid(true);
  }

  const quantityCallBack = (quantityValue) => {
    if (quantityValue) {
      setQuantity(quantityValue);
    }
  };

  const packageSizeCallback = (value) => {
    if (value) {
      setPackage(value.packSize);
      setSelectedAttributes(value)
    }

  };

  const descriptionCallBack = (descriptionValue) => {
    if (descriptionValue) {
      setDescription(descriptionValue);
    }
  };

  const stepOneValuesUpdated = (vaulesUpdated) => {
    setStep1Values({ ...step1Values, ...vaulesUpdated });
    setIsValid(!!vaulesUpdated?.RFQTitle);
  };

  const fileUploadCallback = (fileName) => {
    setQuoteDocuments(fileName);
  };

  const handleStep = (stepNumber) => {
    setSelectedMobileStep(stepNumber);
  };

  const getSelectedSellers = (sellers) => setSelectedSellers(sellers);

  const stepperTitles = [
    {
      label: 'Step - 1',
      component: (
        <QuoteDetails
          selectedProduct={getSelectedProduct}
          valuesUpdated={stepOneValuesUpdated}
          isValid={isValid}
          isMobile={isMobile}
          clearData={clearData}
        />
      ),
    },
    {
      label: 'Step - 2',
      component: (
        <div className="sellersList">
          <SellersDetail sellers={productSellers} isMobile={isMobile} getSelectedSellers={getSelectedSellers} />
        </div>
      ),
    },
    {
      label: 'Step - 3',
      component: (
        <RequestQuoteProductDetails
          quantityCallBack={quantityCallBack}
          packageSizeCallback={packageSizeCallback}
          selectedProduct={selectedProduct}
          descriptionCallBack={descriptionCallBack}
          fileUploadCallback={fileUploadCallback}
          isMobile={isMobile}
        />
      ),
    },
  ];

  const createQuoteRequest = (cartResponse) => {
    console.log('cartResponse', cartResponse);

    return {
      cartId: cartResponse?.id,
      cartVersion: cartResponse?.version,
      description: description,
      customFields: {
        quoteNumber: step1Values?.quoteNumber,
        RFQTitle: step1Values?.RFQTitle,
        isQuote: true,
        quoteDocuments: quoteDocuments?.data.join(','),
      },
    };
  };

  const quoteDetailsHandler = async (products = addedProducts) => {
    if (products.length === 0) {
      return;
    }
    try {
      const reqPayload = getResponseBodyForAddProduct(products)
      let isMultipleVendorName = false;
      const { vendorName } = reqPayload?.lineItems[0]
      reqPayload.lineItems.forEach((element) => {
        if (vendorName !== element.vendorName) isMultipleVendorName = true;
      });
      if (isMultipleVendorName) {
        setIsMultiplevendorFound(true);
        return;
      }
      setLoading(true);
      setIsMultiplevendorFound(false);
      const res = await backendAPIClient.post(STATIC_CREATE_QUOTE_CART, reqPayload);
      setAddProductResponse(res?.data);
      const quoteResponse = await backendAPIClient.post(STATIC_CREATE_QUOTE_REQUEST, createQuoteRequest(res?.data));
      //Notify to Buyer
      if (account?.['email'])
        notifyQuoteActions({
          toName: account?.firstName + ' ' + account?.lastName,
          toEmail: account?.['email'],
          subject: 'Quote(s) has been created!',
          body: 'Quote(s) has been created. Please wait for the seller to respond.',
        });
      //Notify to Seller
      const sellerCodeList = [];
      products.forEach(async (addedProduct) => {
        const sellerName = addedProduct?.product?.masterVariant?.attributes.find((el) => el.name == 'vendor-name')
          ?.value?.['en-IN'];
        const sellerCode = addedProduct?.product?.masterVariant?.attributes.find((el) => el.name == 'vendor-code')
          ?.value?.['en-IN'];
        if (sellerCodeList.includes(sellerCode)) return; //skip sending email to seller if email sent to them already.
        const sellerContactEmail = await getSellerDetails(sellerCode);
        if (sellerContactEmail) {
          notifyQuoteActions({
            toName: sellerName,
            toEmail: sellerContactEmail,
            subject: 'Quote(s) has been created!',
            body: 'Received Quote(s) from an buyer. Please view and approve/reject the quote.',
          });
          sellerCodeList.push(sellerCode);
        }
      });
      if (quoteResponse.status === 201) {
        localStorage.setItem('quote-left-menu-list', 'true');
        router.push('/quotes/quotes-list');
      } else {
        // TODO: Display dialog modal box to show the error
      }
      setTimeout(() => {
        toast.success('Quote has been created!');
        clearExistingData();
        setLoading(false);
      }, 1000)
    } catch (error) { setLoading(false); }
  };

  const getSellerDetails = async (sellerCode: string) => {
    try {
      const sellerDetailRes = await backendAPIClient.get(`${STATIC_GET_SELLER_DETAILS}/?vendorCode=${sellerCode}`);
      return sellerDetailRes?.data?.value?.email;
    } catch (ex) {
      console.error('Error on fetch seller details');
    }
  };

  const handlePreview = () => {
    setShowPreview(!showPreview);
    if (isMobile) {
      setSelectedTab('Request Quote');
    }
  };

  const getSelectedModalOption = (selectedOption) => {
    setShowModal(false);
    if (selectedOption) {
      setAddedProducts([]);
      setSelectedProduct(null);
      setHideSteps([1, 2]);
      setClearData(true);
      setProductSellers([]);
    }
  };

  const isItemDeleted = (itemId) => {
    const remainingItems = addedProducts.filter((p) => p.id !== itemId);
    setAddedProducts(remainingItems);
  };

  const isQuantityChanged = (itemId, qty) => {
    const foundItem = addedProducts.find((p) => p.id === itemId);
    if (foundItem) {
      foundItem.quantity = qty;
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="rfqWrapper container mx-auto pb-24 lg:mr-[100px]" style={{ backgroundColor: 'white' }}>
        <div className="quote-container-main flex">
          <div className="quote-container">
            <div className="quote-part-two">
              <h1 className="cart-breadcrumb">
                <NextLink href="/">
                  <a className="pdpBreadcrumbItem text-[#0280C7] hover:text-gray-500">
                    <span className="mr-3 cursor-pointer font-medium capitalize hover:text-gray-600">Home Page</span>
                  </a>
                </NextLink>
                <span className="quote-breadcrumb-seperator">/</span>
                <span className="quote-breadcrumb-part">Request for Quote</span>
              </h1>
              <div className="request-for-quote-top-banner">
                <div>
                  <div className="quote-title">Request for Quote</div>
                  <p>Fill in your details for the requirements to get you perfect deals</p>
                </div>
                <div className="rfq-top-button">
                  <button disabled={!step1Values?.RFQTitle || selectedSellers.length < 1} className="btn" onClick={addProductToQuote}>
                    Request for Quote
                  </button>
                </div>
              </div>
              <div className="rfq-container">
                {!isMobile && (
                  <Stepper
                    stepperData={stepperTitles}
                    showCurrentStepOnly={false}
                    isHorizontal={false}
                    hideSteps={hideSteps}
                  />
                )}
                {isMobile && (
                  <>
                    <div className="rfq-tabs">
                      <div
                        className={`rfq-tab ${selectedTab !== 'Request Quote' ? 'rfq-tab-selected' : ''}`}
                        onClick={() => setSelectedTab('Request Quote')}
                      >
                        Request Quote
                      </div>
                      <div
                        className={`rfq-tab ${selectedTab !== 'View Product' ? 'rfq-tab-selected' : ''}`}
                        onClick={() => setSelectedTab('View Product')}
                      >
                        View Product
                      </div>
                    </div>
                    {selectedTab === 'Request Quote' && (
                      <div style={{ width: '100% !important' }}>
                        <div className="mobile-step">
                          <div>Step 1</div>
                          <div className="rfq-step-heading" onClick={() => handleStep(1)}>
                            <div className="quote-title-inner">Request for Quote Details</div>
                            <div className="dashboard-icon h-5 w-5 text-neutral-600">
                              {selectedMobileStep === 1 ? <ChevronUpIcon /> : <ChevronDownIcon />}
                            </div>
                          </div>
                        </div>
                        {selectedMobileStep === 1 && (
                          <QuoteDetails
                            selectedProduct={getSelectedProduct}
                            valuesUpdated={stepOneValuesUpdated}
                            isValid={isValid}
                            isMobile={isMobile}
                          />
                        )}
                        <div className="mobile-step mobile-step-next">
                          <div>Step 2</div>
                          <div className="rfq-step-heading" onClick={() => handleStep(2)}>
                            <div className="quote-title-inner">Product Details</div>
                            <div className="dashboard-icon h-5 w-5 text-neutral-600">
                              {selectedMobileStep === 2 ? <ChevronUpIcon /> : <ChevronDownIcon />}
                            </div>
                          </div>
                        </div>
                        {selectedMobileStep === 2 && (
                          <RequestQuoteProductDetails
                            quantityCallBack={quantityCallBack}
                            selectedProduct={selectedProduct}
                            descriptionCallBack={descriptionCallBack}
                            fileUploadCallback={fileUploadCallback}
                            isMobile={isMobile}
                            packageSizeCallback={packageSizeCallback}
                          />
                        )}
                        <div className="mobile-step mobile-step-next">
                          <div>Step 3</div>
                          <div className="rfq-step-heading" onClick={() => handleStep(3)}>
                            <div className="quote-title-inner">Select Sellers</div>
                            <div className="dashboard-icon h-5 w-5 text-neutral-600">
                              {selectedMobileStep === 3 ? <ChevronUpIcon /> : <ChevronDownIcon />}
                            </div>
                          </div>
                        </div>
                        <div className="sellersList">
                          {selectedMobileStep === 3 && <SellersDetail sellers={productSellers} isMobile={isMobile} getSelectedSellers={getSelectedSellers} />}
                        </div>
                      </div>
                    )}
                    {showPreview && addedProducts?.length > 0 && (
                      <div className="quote-preview">
                        <div className="quote-part-two quote-preview-part-two">
                          {addedProducts?.map((product, index) => (
                            <QuoteItem
                              key={index}
                              item={product.product}
                              quantity={product.quantity}
                              sellers={productSellers}
                              isItemDeleted={isItemDeleted}
                              productPackage={product.productPackage}
                              isQuantityChanged={isQuantityChanged}
                            />
                          ))}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
              {/* <TalkToSeller fileUploadCallback={fileUploadCallback} existingComments={[]} quoteRequestId=''/> */}
            </div>
          </div>
        </div>

        <div className="quote-bottom-bar">
          <div className="quote-bottom-bar-one">
            <button className="quote-bottom-btn btn" onClick={openModal}>
              Cancel
            </button>
            <button disabled={!step1Values?.RFQTitle || selectedSellers.length < 1} className="quote-bottom-btn btn" onClick={addProductToQuote}>
              Request for Quote
            </button>
          </div>
          {/* <div className="quote-bottom-bar-two">
            <button
              className={`quote-bottom-btn`}
              style={{ color: addedProducts.length === 0 ? 'gray' : '#0280c7' }}
              onClick={() => handlePreview()}
              disabled={addedProducts.length === 0}
            >
              Preview
            </button>
            <button className="quote-bottom-btn btn" onClick={addProductToQuote} disabled={disableAddProductBtn}>
              Add Product
            </button>
          </div> */}
        </div>
        {isMultiplevendorFound && (
          <div className="request-quote-error">
            Quote Request cant be created with products added from multiple vendors
          </div>
        )}
        {/* {showPreview && !isMobile && addedProducts?.length > 0 && (
          <div className="quote-preview">
            <div className="quote-part-two quote-preview-part-two">
              {addedProducts?.map((product, index) => (
                <QuoteItem
                  key={index}
                  item={product.product}
                  quantity={product.quantity}
                  productPackage={product.productPackage}
                  sellers={[
                    {
                      name: product?.product?.variants[0].attributes.find((attr) => attr.name === 'vendor-name').value[
                        'en-IN'
                      ],
                    },
                  ]}
                  isItemDeleted={isItemDeleted}
                  isQuantityChanged={isQuantityChanged}
                />
              ))}
            </div>
          </div>
        )} */}
        {showModal ? <Modal setShowModal={setShowModal} selectedOption={getSelectedModalOption} /> : null}
      </div>
    </>
  );
}
export default RequestForQuote;
