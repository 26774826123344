import React, { useEffect, useState } from 'react';
import { useAccount } from 'frontastic';
// import axios from 'axios';
// import { BACKEND_BASE_URL, COMMERCETOOLS_BASE_URL } from 'helpers/constants/envVariables';
// import { loginWithOTP } from 'frontastic/actions/account';
// import { useRouter } from 'next/router';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
 import { InteractionStatus } from '@azure/msal-browser';


const AutoLogin = () => {
  
    const url_string = window.location.href;
    const url = new URL(url_string);
    const punchUrl = url.href;
    const validUrl = punchUrl.includes("punchout");
    const token = url.searchParams.get("session_id")
    const hookUrl = url.searchParams.get("HOOK_URL")
    const sapClient = url.searchParams.get("sap-client")
    // const router = useRouter();
    let { loggedIn } = useAccount();
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [isUserValid, setIsUserValid] = useState(false);
    // const [customerData, setCustomerData] = useState(null);
    
    // const customerAccessToken = url.searchParams.get("session_id"); 
    

  const fetchCustomerData = async () => {
    // try {
    //      const response = await axios.get(`${COMMERCETOOLS_BASE_URL}`+'/me', {
    //      headers: {
    //                 Authorization: `Bearer ${customerAccessToken}`,
    //         },
    //     });
    //        const customer_id = response?.data?.id;
    //        const cutomerGroupId = response?.data?.customerGroup.id
    //        if(cutomerGroupId){
    //              localStorage.setItem("customerGroup",cutomerGroupId)
    //        }
           
    //        const isEmailVerified = true;
    //             if(response?.data?.id){
    //                         try {
    //                           const res = await axios.get(`${COMMERCETOOLS_BASE_URL}`+'/customers/'+`${customer_id}`, {
    //                           headers: {
    //                                     Authorization: `Bearer ${customerAccessToken}`,
    //                             },
    //                         });
    //                         setCustomerData(res.data)
    //                         loginWithOTP(response.data, isEmailVerified)
    //                         router.push('/');                     
    //                       }catch (error) {
    //                         console.error('Error fetching customer data:', error);
    //                       }
    //                 }
      
    // } catch (error) {
    //   console.error('Error fetching customer data:', error);
    // }

     if (inProgress === InteractionStatus.None && !isAuthenticated) {
         setIsUserValid(false);
         await instance.loginRedirect()
           .catch(error => {
             console.error('Error during login redirect:', error);
           });
       }
  };

  useEffect(() => {
    isUserValid && fetchCustomerData();
  }, [inProgress]);
 
  useEffect(() => {
    if(validUrl){
        localStorage.setItem('punchoutUrl','yes');
        localStorage.setItem('hookUrl',hookUrl);
        localStorage.setItem('sapClient',sapClient);
        localStorage.setItem("BearerToken",token)
        localStorage.setItem("token",token)
        // fetchCustomerData();
        setIsUserValid(true);
        loggedIn = true;
        
     }
  }, [validUrl]);
 return(
    <>
    </>
 )
};
export default AutoLogin;
