import React, { useEffect, useState } from 'react';
import { MailIcon } from '@heroicons/react/outline';
import { STATIC_GET_OTP_URL } from 'helpers/constants/statisURLs';
import { useFormat } from 'helpers/hooks/useFormat';
import { backendAPIClient } from 'helpers/utils/axiosRequestsUtil';
import OTPVerfiy from './OTP-verify';

function OTPEmail({ setShowOTPScreen, handleResetPasswordScreen, email ='' }) {
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage } = useFormat({ name: 'common' });
  const [userEmail, setUserEmail] = useState(email);
  const [OTP, setOTP] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showOTPVerify, setShowOTPVerify] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const getOTPCode = async (e) => {
    e.preventDefault();
    setLoading(true);
    const reqBody = {
      email: userEmail,
      action: 'login',
    };

    try {
      await backendAPIClient.post(STATIC_GET_OTP_URL, reqBody);
      setLoading(false);
      setShowOTPVerify(true);
      setShowSuccessMessage(true);
    } catch (error) {
      if (error?.response?.data) {
        setErrorMessage(error?.response?.data?.Details);
      } else {
        setErrorMessage('There was a problem, please try again!');
      }
    }
  };

  const backToLogin = () => {
    setShowOTPScreen(false);
    handleResetPasswordScreen(false);
  };

  const handleTermsClick = () => {
    window.open('/terms-of-use', '_blank');
  };
  const handlePrivacyPolicyClick = () => {
    window.open('/privacy-policy', '_blank');
  };

  return (
    <div className="">
      <div className="flex">
        <p onClick={backToLogin}>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14.8627 3.225L13.3794 1.75L5.1377 10L13.3877 18.25L14.8627 16.775L8.0877 10L14.8627 3.225Z"
              fill="#0068A3"
            />
          </svg>
        </p>
        <button className="return_sign_in" onClick={backToLogin}>
          Return to Sign In
        </button>
      </div>

      <div className="otp_signin text-center">
        <h2 className="signin_heading">Sign-in Using OTP</h2>
        {showSuccessMessage ? (
          <p className="signin_tooltip">
            We have sent an 4 digit OTP to the {userEmail}. Please enter it below to complete verification.
          </p>
        ) : (
          <p className="signin_tooltip">
            To receive one time password, please enter the email address associated with your account
          </p>
        )}
      </div>
      {!showOTPVerify ? (
        <form onSubmit={getOTPCode} className="space-y-7">
          <div>
            {/* <label htmlFor="email" className="block text-sm font-medium text-neutral-700"> */}
            <div className="flex">
              <label htmlFor="email" className="email_label">
                {formatMessage({ id: 'email', defaultMessage: 'Email' })}
              </label>
              <span className="ml-[3px] text-[#EB4747]">*</span>
            </div>
            <div className="relative mt-0">
              <input
                id="email2"
                name="email"
                type="email"
                autoComplete="email"
                value={userEmail}
                placeholder={formatAccountMessage({ id: 'email.enter', defaultMessage: 'Enter your email' })}
                required
                className="email_input block w-full appearance-none rounded-sm border border-gray-300 px-3 py-2 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                onChange={(e) => {
                  setUserEmail(e.target.value);
                  setLoading(false);
                  setErrorMessage('');
                }}
              />
              <span className="absolute left-3 top-1/2 block h-4 w-4 -translate-y-1/2 text-neutral-600">
                <MailIcon className="mail_icon" />
              </span>
            </div>
            <div className="mt-2">
              {errorMessage && <span className="email_label text-[#EB4747]">{errorMessage}</span>}
            </div>
          </div>
          <div>
            <button
              type="submit"
              // className="flex w-full justify-center rounded-sm border border-transparent bg-accent-400 py-2 px-4 text-sm font-medium text-white shadow-sm transition-colors duration-200 ease-out hover:bg-accent-500 focus:outline-none focus:ring-2 focus:ring-accent-400 focus:ring-offset-2 disabled:bg-gray-200"
              className="flex w-full justify-center rounded-sm border border-transparent bg-accent-400 py-2 px-4 text-[14px] font-medium tracking-[1px] text-white shadow-sm transition-colors duration-200 ease-out disabled:bg-gray-200"
              disabled={loading}
              //   onClick={}
            >
              Send OTP
            </button>
            <div className="terms_and_policy">
              By continuing, you agree to our{' '}
              <span className="underline" onClick={handleTermsClick}>
                Terms of Service
              </span>{' '}
              &{' '}
              <span className="underline" onClick={handlePrivacyPolicyClick}>
                Privacy Policy
              </span>
            </div>
          </div>
        </form>
      ) : (
        <>
          <OTPVerfiy OTP={OTP} setOTP={setOTP} userEmail={userEmail} getOTPCode={getOTPCode} />
        </>
      )}
    </div>
  );
}

export default OTPEmail;
