import React from 'react';
import Image from 'frontastic/lib/image';
import Link from 'next/link';

export const ErrorInProductLoading = () => {
  return (
    <div className="md:grid lg:grid-cols-12 m-4">
      <div className="col-span-4">
        <Image
          src="/images/varient-not-found.png"
          alt='variant not found'
          className="ordered-item-image"
        />
      </div>
      <div className="col-span-4 mt-[25%]">
        <h1 className='font-semibold text-lg'>Error</h1>
        <p className='text-sm'>There was an error loading the data please wait a bit, if it doesn’t load automatically <span className='text-primary-theme-color underline'><Link href="/">click here</Link></span></p>
      </div>
    </div >
  )
}