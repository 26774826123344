import React from 'react';
import AccordionBtn from 'components/commercetools-ui/accordion';
import RefundVendorPolicy from './refund-vendor-policy';

const VendorReturnPolicy = ({ data }) => {
  const returnPolicyAttributes = data?.data?.dataSource?.attributes.component.content.filter((item, index) => item?.data?.target)
    const policyAttributes = [
      {
        id: 1,
        component: (
          <AccordionBtn
            openSectionTitle="Refund Policy"
            closedSectionTitle="Refund Policy"
            className="collapse-title vendor-policy-accordion"
          >
            <RefundVendorPolicy data={returnPolicyAttributes [0]?.data?.target?.fields} />
          </AccordionBtn>
        ),
      },
   ]
return (
   <div className='vendor-refud-policy'>
       {policyAttributes?.map((item, index) => (
              <div key={item?.id}>{item?.component}</div>
            ))}
     </div>
  );
};

export default VendorReturnPolicy;