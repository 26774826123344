import { Cart } from '@Types/cart/Cart';
import { useFormat } from 'helpers/hooks/useFormat';
import Item from './item';
import TermsConditions from './termsConditions';
import VendorwiseTotal from './vendorwiseTotal';
import { useEffect, useState } from 'react';
import RemoveItemPopup from './removeItemPopup';
import Loader from 'components/commercetools-ui/loader';

interface Props {
  readonly cart: Cart;
  readonly editItemQuantity: (lineItemId: string, newQuantity: number) => void;
  readonly goToProductPage: (_url: string) => void;
  readonly removeItem: (lineItemId: string[]) => void;
}

const ItemList = ({ cart, editItemQuantity, goToProductPage, removeItem }: Props) => {
  //i18n messages
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });

  const [selectedItems, setSelectedItems] = useState([]);
  const [deletePopUpResponse, setDeletePopUpResponse] = useState(null);
  const [isDeletePopupVisible, setIsDeletePopupVisible] = useState(false);
  const [isAllItemsSelected, setIsAllItemsSelected] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const vendorWiseItems = cart?.lineItems?.reduce((group, item) => {
    const vendorName = item.variant?.attributes['vendor-name'];
    group[vendorName] = group[vendorName] || [];
    group[vendorName].push(item);
    return group;
  }, {});

  useEffect(() => {
    setIsDeletePopupVisible(false);
    setDeletePopUpResponse(null);
    setSelectedItems([]);
    setLoading(false);
  }, [cart]);

  useEffect(() => {
    if (deletePopUpResponse) {
      if (selectedItems.length > 0) removeItem(selectedItems);
    }
  }, [deletePopUpResponse]);

  useEffect(() => {
    if (selectedItems.length === cart.lineItems?.length) {
      setIsAllItemsSelected(true);
    }
  }, [selectedItems.length]);

  const vendorTotal = (vendorName: string) => {
    const items = vendorWiseItems[vendorName];
    return (
      <div>
        <>
          <VendorwiseTotal items={items} />{' '}
        </>
      </div>
    );
  };

  const handleProductSelect = (itemId: string) => {
    const temp = [...selectedItems];
    if (temp.indexOf(itemId) === -1) {
      temp.push(itemId);
    } else {
      temp.splice(temp.indexOf(itemId), 1);
    }
    setSelectedItems(temp);
    setIsAllItemsSelected(temp.length === cart.lineItems.length);
  };

  const handleAllItemSelect = (event) => {
    const allItemsInCart = [];
    if (event.target.checked) {
      cart?.lineItems?.forEach((item) => {
        allItemsInCart.push(item.lineItemId);
      });
    }
    setSelectedItems(allItemsInCart);
    setIsAllItemsSelected(allItemsInCart.length === cart.lineItems.length);
  };

  const handleDeleteAllItems = () => {
    setIsDeletePopupVisible(true);
  };

  const handleRemoveSingleItem = (itemIdArray: string[]) => {
    setIsDeletePopupVisible(true);
    setSelectedItems(itemIdArray);
  };

  const handleDeletePopupResponse = (response: boolean) => {
    setLoading(true);
    if (response == false) {
      if (selectedItems.length == 1) setSelectedItems([]);
      setIsDeletePopupVisible(false);
      setLoading(false);
    } else {
      setDeletePopUpResponse(response);
    }
 
  };

  return (
    loading ? <Loader /> :
    <section aria-labelledby="cart-heading" className="cart-items-main-section">
      <h2 id="cart-heading" className="sr-only">
        {formatCartMessage({ id: 'cart.shopping.items', defaultMessage: 'Items in your shopping cart' })}
      </h2>
      {isDeletePopupVisible && <RemoveItemPopup response={handleDeletePopupResponse} />}
      <div className="cart-items-section">
        <h1 className="cart-product-details">Product Details</h1>
        {vendorWiseItems &&
          Object.keys(vendorWiseItems).map((vendor, index) => (
            <>
              <div key={vendor} className="cart-vendor-header-main">
                <div className="cart-vendor-header">
                  <span className="cart-vendor-sold">Sold and Fulfilled by : </span>
                  <span style={{ fontSize: '16px', fontWeight: '700', borderBottom: '1px solid' }}>{vendor}</span>
                </div>
                <ul className="" role="list">
                  {vendorWiseItems[vendor].map((lineItem, i) => (
                    <li key={i} className="cart-vendor-li">
                      {i < cart?.lineItems.length && <hr className="md:hidden" />}
                      <input
                        type="checkbox"
                        checked={selectedItems.indexOf(lineItem.lineItemId) != -1}
                        className="cart-item-checkbox"
                        style={{ marginRight: '10px', marginTop: '10px' }}
                        onClick={() => handleProductSelect(lineItem.lineItemId)}
                      />
                      <Item
                        lineItem={lineItem}
                        editItemQuantity={editItemQuantity}
                        goToProductPage={goToProductPage}
                        removeItem={handleRemoveSingleItem}
                      />
                    </li>
                  ))}
                </ul>
                <div className="cart-list-bottom" style={{ marginLeft: '25px' }}>
                  <div className="cart-list-bottom-terms">
                    <TermsConditions key={vendor} vendor={vendor} />
                  </div>
                  <div className="shippingBlock" style={{ flex: '3' }}>
                    {vendorTotal(vendor)}
                  </div>
                </div>
              </div>
              {vendorWiseItems && index !== Object.keys(vendorWiseItems).length - 1 && (
                <div className="iem-separator"></div>
              )}
            </>
          ))}
        <div className="cart-product-selcted-details">
          <input
            type="checkbox"
            checked={isAllItemsSelected}
            className="cart-item-checkbox"
            onClick={handleAllItemSelect}
          />
          <div style={{ fontSize: '18px', fontWeight: '700', margin: '0px 10px' }}>
            {' '}
            {selectedItems.length} / {cart?.lineItems?.length} Items Selected
          </div>
          <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0px' }}>
            {selectedItems.length > 0 && (
              <span style={{ color: '#0280C7', fontSize: '14px', cursor: 'pointer' }} onClick={handleDeleteAllItems}>
                Remove
              </span>
            )}
            {selectedItems.length === 0 && <span style={{ fontSize: '14px' }}>Remove</span>}
            {/* <span style={{ margin: '0px 5px' }}> | </span> */}
            {/* <span style={{ color: '#0280C7', fontSize: '14px' }}>Move to Project</span> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ItemList;
