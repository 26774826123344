import ProductCertifications from './product-certifications';
import React, { useState, useEffect, useMemo } from 'react';
import { commercetoolsAPIClient } from 'helpers/utils/axiosRequestsUtil';
import { StarIcon } from '@heroicons/react/outline';
import { useRouter } from 'next/router';

const SellerDetails = ({ vendorInfo }) => {
  const router = useRouter();
  const [review, setReview] = useState([]);
  const [avgRating, setAvgRating] = useState(0);
  const [rating, setRating] = useState(0);
  const vendorCode = router?.query?.['vendor-code']
  const setReviewHandler = async () => {
    try {
      const response = await commercetoolsAPIClient.get(
        `/reviews?where=custom%28fields%28vendor_code%3D%22${vendorCode}%22%29%29`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
          },
        },
      );
      setReview(response.data.results);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    setReviewHandler();
  }, []);
  useEffect(() => {
    const totalRating = review?.reduce(function (acc, obj) {
      return acc + obj.rating / review?.length;
    }, 0);
    setAvgRating(Math.round(totalRating));
  }, [review]);
  const count = 5;
  const color = {
    filled: '#FEB343',
    unfilled: '#F9F2E0',
  };
  const getColor = (index) => {
    if (avgRating >= index) {
      return color.filled;
    }
    return color.unfilled;
  };

  const starRating = useMemo(() => {
    return Array(count)
      .fill(0)
      .map((_, i) => i + 1)
      .map((idx) => (
        <StarIcon
          key={idx}
          className="pdp-rating-average cursor-pointer"
          style={{ color: getColor(idx), fill: getColor(idx) }}
        />
      ));
  }, []);
  { }
  return (
    <div className='mt-[20px] lg:pl-[32px]'>
      <div className='pdp_product_description vendor-descr flex flex-col'>
        {/* <span>{vendorInfo?.value?.vendorName} is a leading multinational pharmaceutical company based in India and overseas, committed to providing access to affordable and innovative medicines, driven by our purpose of ‘Good Health Can’t Wait’.</span> */}
        <span>{vendorInfo?.value?.aboutVendor}</span>
      </div>
      <div>
        <ProductCertifications starRating={starRating} />
      </div>
      {/* <div className="pdp_tags mt-[20px] flex justify-start">
        <p className="pdp_product_Tag ml-1">FDA</p>
        <p className="pdp_product_Tag ml-1">GMP</p>
        <p className="pdp_product_Tag ml-1">MSDS</p>
      </div>
      <div className='mt-[20px] flex justify-start'>
        <p className='pdpCategoryItem capitalize mr-[9px]'>API</p>
        <p className='pdpCategoryItem capitalize mr-[9px]'>EXCIPIENTS</p>
      </div> */}
    </div>
  );
};

export default SellerDetails;