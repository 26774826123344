import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { StarIcon } from '@heroicons/react/outline';


function ReviewsAndRatings({ productVariants }) {
  useEffect(() => {}, [productVariants.rating]);
  const count = 5;
  const color = {
    filled: '#FEB343',
    unfilled: '#F9F2E0',
  };
  const getColor = (index) => {
    if (productVariants.rating >= index) {
      return color.filled;
    }

    return color.unfilled;
  };

  const starRating = useMemo(() => {
    return Array(count)
      .fill(0)
      .map((_, i) => i + 1)
      .map((idx) => (
        <StarIcon
          key={idx}
          className="pdp-rating-average cursor-pointer"
          style={{ color: getColor(idx), fill: getColor(idx) }}
        />
      ));
  }, [productVariants.rating]);
  return (
    <div className="review_item bg-white">
      <div className="mt-[20px] flex flex-col  lg:flex-row">
        <div className="reviews_pdp  flex-col">
          <div className="title_rating flex flex-col justify-between lg:flex-row">
            <div className="review_title_carousel flex lg:ml-[20px]">
              <div className="lg:relative">
                <svg
                  width="38"
                  height="38"
                  viewBox="0 0 38 38"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="profile_icon_review lg:relative"
                >
                  <circle cx="19" cy="19" r="18" fill="#CCE6F4" stroke="#9ACCE9" />
                </svg>
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="profile_icon_top_review"
                >
                  <path
                    d="M4 7.25C5.65685 7.25 7 5.90685 7 4.25C7 2.59315 5.65685 1.25 4 1.25C2.34315 1.25 1 2.59315 1 4.25C1 5.90685 2.34315 7.25 4 7.25Z"
                    stroke="#003769"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <svg
                  width="14"
                  height="7"
                  viewBox="0 0 14 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="profile_icon_bottom_review"
                >
                  <path
                    d="M13 5.75V4.25C13 3.45435 12.6839 2.69129 12.1213 2.12868C11.5587 1.56607 10.7956 1.25 10 1.25H4C3.20435 1.25 2.44129 1.56607 1.87868 2.12868C1.31607 2.69129 1 3.45435 1 4.25V5.75"
                    stroke="#003769"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              <div className="ml-[10px]">
                <p className="text-[16px] font-normal text-[#003769]">Anonymous</p>
                <p className="text-[12px] font-normal text-[#003769]">
                  {new Date(productVariants?.createdAt).getDate()}-{new Date(productVariants?.createdAt).getMonth()+1}-
                  {new Date(productVariants?.createdAt).getFullYear()}
                </p>
              </div>
            </div>
            <div className="ratingsWrapMain review_star_carousel flex items-baseline lg:mr-[32px] xl:mr-[45px]">{starRating}</div>
          </div>
          <div>
            <p className="reviwes_content mt-[23px] text-left text-[16px]  font-normal text-[#676767] lg:mt-[11px] 2xl:mt-[20px]">
              {productVariants?.text}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReviewsAndRatings;
