import MostRecentAddressContainer from './mostRecentAddressContainer';
import AddAddressForm from './addAddressForm';
import { useAccount, useCart } from 'frontastic';
import MostRecentAddressCard from './mostRecentAddressCard';
import AddressHeaderText from './addressHeaderText';
import { useEffect, useState } from 'react';
import Loader from 'components/commercetools-ui/loader';

const ShippingAddress = () => {
  const { addAddress, account, updateAddress } = useAccount();
  const { data: cartList, updateCart } = useCart();

  const [isShippingCollapsed, setIsShippingCollapsed] = useState(true);
  const [isBillingCollapsed, setIsBillingCollapsed] = useState(true);
  const [isBillingSameAsDeliveryAddressSelected, setIsBillingSameAsDeliveryAddressSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const addBillingAddress = async (addressPayload) => {
    setLoading(true);
    const res = await addAddress({
      country: 'IN',
      isDefaultBillingAddress: true,
      isDefaultShippingAddress: false,
      firstName: addressPayload.addressName,
      lastName: addressPayload.addressName,
      streetNumber: addressPayload.unit,
      streetName: addressPayload.street,
      phone: '',
      state: addressPayload.addressState,
      postalCode: addressPayload.pincode,
      city: addressPayload.city,
    });
    if (res) {
      updateCart({ billing: res.addresses.slice(-1)[0], shipping: cartList?.shippingAddress }).finally(() => {
        setLoading(false);
      });
    }
  };

  const addShippingAddress = async (addressPayload) => {
    setLoading(true);

    const res = await addAddress({
      country: 'IN',
      isDefaultBillingAddress: false,
      isDefaultShippingAddress: true,
      firstName: addressPayload.addressName,
      lastName: addressPayload.addressName,
      streetNumber: addressPayload.unit,
      streetName: addressPayload.street,
      phone: '',
      state: addressPayload.addressState,
      postalCode: addressPayload.pincode,
      city: addressPayload.city,
    });
    if (res) {
      updateCart({ shipping: res.addresses.slice(-1)[0], billing: cartList?.billingAddress }).finally(() => {
        setLoading(false);
      });
    }
  };
  const makeAddressDefault = (addressId) => {
    setLoading(true);

    const selectedAddress = account?.addresses.find((address) => address.addressId === addressId);
    updateCart({ billing: selectedAddress, shipping: cartList?.shippingAddress }).finally(() => {
      setLoading(false);
    });
  };

  const handleSameAsDeliveyAddressClick = () => {
    if (!isBillingSameAsDeliveryAddressSelected) {
      const selectedAddress = account?.addresses.find((address) => address.isDefaultShippingAddress);
      updateCart({ shipping: selectedAddress, billing: selectedAddress }).finally(() => {
        setLoading(false);
      });
      updateAddress({ ...selectedAddress, isDefaultBillingAddress: true, isDefaultShippingAddress: true });
    } else {
      const selectedAddress = account?.addresses.find((address) => address.isDefaultShippingAddress);
      updateCart({ shipping: selectedAddress, billing: {} }).finally(() => {
        setLoading(false);
      });
      updateAddress({ ...selectedAddress, isDefaultBillingAddress: false, isDefaultShippingAddress: true });
    }
    setIsBillingSameAsDeliveryAddressSelected(!isBillingSameAsDeliveryAddressSelected);
  };

  return (
    <>
      {loading && <Loader />}
      <div>
        <div className="mt-5 text-2xl font-bold">Delivery Address</div>
        <div className="mt-8">
          <MostRecentAddressContainer isBillingSameAsDeliveryAddressSelected={isBillingSameAsDeliveryAddressSelected} />
        </div>
        <div className="mt-8">
          <AddressHeaderText
            text={'Add New  Delivery Address'}
            onCollapseClick={setIsShippingCollapsed}
            isCollapsed={isShippingCollapsed}
          />
          {isShippingCollapsed && <AddAddressForm addAddress={addShippingAddress} />}
        </div>
        <div className="mt-8">
          <div className="text-2xl font-bold">Billing Address</div>
          <div className="mb-5">
            {account?.addresses
              .filter((data) => data.isDefaultBillingAddress)
              .map((address, index) => (
                <MostRecentAddressCard
                  address={address}
                  key={index}
                  onClick={makeAddressDefault}
                  isBillingAddress={true}
                />
              ))}
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '5px', marginTop: '20px', marginBottom: '20px' }}>
            <input
              type="checkbox"
              checked={isBillingSameAsDeliveryAddressSelected}
              className="cart-item-checkbox"
              onClick={handleSameAsDeliveyAddressClick}
            />
            <div style={{ fontSize: '14px', fontWeight: 500 }}>Same as Delivery Address</div>
          </div>
          {!isBillingSameAsDeliveryAddressSelected && (
            <>
              <AddressHeaderText
                text={'Add New  Billing Address'}
                onCollapseClick={setIsBillingCollapsed}
                isCollapsed={isBillingCollapsed}
              />
              {isBillingCollapsed && <AddAddressForm addAddress={addBillingAddress} />}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ShippingAddress;
