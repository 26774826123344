import React from 'react';
import NextLink from 'next/link';

export type BreadcrumbProps = {
  Separator?: React.ReactNode;
  categorySlugs?: string[];
};

const Breadcrumb: React.FC<BreadcrumbProps> = ({ children, Separator, categorySlugs }) => {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center">
        <li>
          <div>
            <NextLink href="/">
              <a className="pdpBreadcrumbItem text-[#0280C7] hover:text-gray-500">
                {/* <HomeIcon className="h-5 w-5 shrink-0" aria-hidden="true" /> */}
                {/* <span className="mr-3 cursor-pointer font-medium capitalize hover:text-gray-600">Home Page</span> */}
              </a>
            </NextLink>
          </div>
        </li>
        {React.Children.map(children, (Child, index) => {
          if (index < React.Children.count(children) - 1) {
            return (
              <NextLink href={`/${categorySlugs[index]}`}>
                <li>
                  {/* <span className="text-gray-400">{Separator}</span> */}
                  <span className="text-14px pdpBreadcrumbItem mx-3 cursor-pointer font-medium capitalize text-gray-400 hover:text-gray-600">
                    {Child.toString().replaceAll('-', ' ')}
                  </span>
                </li>
              </NextLink>
            );
          }
          return (
            <NextLink href={`/${categorySlugs[index]}`}>
              <li>
                <span className="text-gray-400">{Separator}</span>

                <span className="pdpCategoryItem capitalize">{Child.toString().replaceAll('-', ' ')}</span>
              </li>
            </NextLink>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
