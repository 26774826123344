import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import 'react-datepicker/dist/react-datepicker.css';
import AccordionBtn from 'components/commercetools-ui/accordion';
import { compareAsc, format, subDays } from 'date-fns';


function DeliveryDate({cartList,validDate}) {
  const [startDate, setStartDate] = useState(null);
  const [isValidDate, setIsValidDate] = useState(false); // New state variable
  const { formatMessage } = useFormat({ name: 'common' });

  const handleDateChange = (date) => {
    setStartDate(date);
    setIsValidDate(true); // Reset validity when the date changes
  };

  const validateDate = () => {
    if (!startDate) {
      setIsValidDate(false);
    }
  };
  {validDate(isValidDate)}

  return (
    <AccordionBtn
      openSectionTitle="2. Requested Delivery Date"
      closedSectionTitle="2. Requested Delivery Date"
      className="collapse-title"
    >
      <div className="">
        <label htmlFor="date" className="email_label">
          {formatMessage({ id: 'Date', defaultMessage: 'Date' })}
        </label>
        <span className="ml-[3px] text-[#EB4747]">*</span>
        <DatePicker
          showIcon
          selected={startDate}
          onChange={handleDateChange}
          onBlur={validateDate} // Validate when the date picker loses focus
          minDate={subDays(new Date(), -1)}
          placeholderText="(DD/MM/YYYY)"
          dateFormat="dd/MM/yyyy"
          className={!isValidDate ? 'datepicker-invalid' : ''}
        />
        {!isValidDate && <div className="error-message mb-2 text-sm text-red-600">Please select a delivery date.</div>}
      </div>
    </AccordionBtn>
  );
}

export default DeliveryDate;