import React, { useEffect, useState } from 'react';
import { useAccount } from 'frontastic';

function OrderDetails({ oderDetails }) {
  const { account } = useAccount();
  const [accountState, setAccountState] = useState(null);

  useEffect(() => {
    if (account) {
      setAccountState(account);
    }
  }, [account]);

  return (
    <div className="cart-mob" style={{ width: '40%' }}>
      <div className="orderDetailsConatinerLeft cart-form">
        {' '}
        {/*  To be removed */}
        <div className="orderDetailsWrapperLeft">
          <div className="orderMainList" style={{ marginTop: '20px' }}>
            <div className="order-list-item">
              <p className="orderListItemHead">Order Date</p>
              <p className="orderListItemtext">{new Date(oderDetails?.createdAt).toLocaleDateString()}</p>
            </div>
            <div className="order-list-item">
              <p className="orderListItemHead">Requested Date</p>
              <p className="orderListItemtext"></p>
            </div>
            <div className="order-list-item">
              <p className="orderListItemHead">Purchase Order Date</p>
              <p className="orderListItemtext"></p>
            </div>
            <div className="order-list-item">
              <p className="orderListItemHead">Payment Method</p>
              <p className="orderListItemtext"></p>
            </div>
            <div className="order-list-item">
              <p className="orderListItemHead">Contact Details</p>
              <p className="orderListItemtext">
                <span className="oderDetailsIcon">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M19.9376 19.9999V18.111C19.9376 17.1091 19.6887 15.7501 18.9415 15.0416C18.1943 14.3331 17.01 14.3333 15.9532 14.3333H7.9845C6.92778 14.3333 5.49441 14.3331 4.74719 15.0416C3.99998 15.7501 4.00012 17.1091 4.00012 18.111V19.9999M15.9532 6.77772C15.9532 8.86413 14.1694 10.5555 11.9689 10.5555C9.76836 10.5555 7.9845 8.86413 7.9845 6.77772C7.9845 4.69131 9.76836 2.99994 11.9689 2.99994C14.1694 2.99994 15.9532 4.69131 15.9532 6.77772Z"
                      stroke="#676767"
                      strokeWidth="1.5"
                      strokeLinecap="square"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>{' '}
                {accountState?.firstName} {accountState?.lastName}
              </p>
              <p className="orderListItemtext">
                <span className="oderDetailsIcon">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="email">
                      <path
                        id="Vector"
                        d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6ZM20 6L12 11L4 6H20ZM20 18H4V8L12 13L20 8V18Z"
                        fill="#676767"
                      />
                    </g>
                  </svg>
                </span>{' '}
                {accountState?.email}
              </p>
              <p className="orderListItemtext">
                <span className="oderDetailsIcon">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="phone_android">
                      <path
                        id="Vector"
                        d="M16 1H8C6.34 1 5 2.34 5 4V20C5 21.66 6.34 23 8 23H16C17.66 23 19 21.66 19 20V4C19 2.34 17.66 1 16 1ZM17 18H7V4H17V18ZM14 21H10V20H14V21Z"
                        fill="#676767"
                      />
                    </g>
                  </svg>
                </span>
                {accountState?.phone_number}
              </p>
            </div>
          </div>
          <div className="orderAddressMain">
            <div className="order-list-item orderAddressFirst">
              <p className="orderListItemHead">Billing Address</p>
              <p className="orderListItemtext">
                {oderDetails?.billingAddress && !(Object.keys(oderDetails?.billingAddress).length === 0) ? (
                  <>
                    {oderDetails?.billingAddress?.streetNumber +
                      ' ' +
                      oderDetails?.billingAddress?.streetName +
                      ', ' +
                      oderDetails?.billingAddress?.city +
                      ' - ' +
                      oderDetails?.billingAddress?.state +
                      ' - ' +
                      oderDetails?.billingAddress?.postalCode +
                      ', ' +
                      (oderDetails?.billingAddress?.country === 'IN' ? 'India' : oderDetails?.billingAddress?.country)}
                  </>
                ) : (
                  <p className="order-preview-contact-details-text">N/A</p>
                )}
              </p>
            </div>
            <div className="order-list-item">
              <p className="orderListItemHead">Shipping Address</p>
              <p className="orderListItemtext">
                {oderDetails?.shippingAddress && !(Object.keys(oderDetails?.shippingAddress).length === 0) ? (
                  <>
                    {oderDetails?.shippingAddress?.streetNumber +
                      ' ' +
                      oderDetails?.shippingAddress?.streetName +
                      ', ' +
                      oderDetails?.shippingAddress?.city +
                      ' - ' +
                      oderDetails?.billingAddress?.state +
                      ' - ' +
                      oderDetails?.shippingAddress?.postalCode +
                      ', ' +
                      (oderDetails?.shippingAddress?.country === 'IN'
                        ? 'India'
                        : oderDetails?.shippingAddress?.country)}
                  </>
                ) : (
                  <p className="order-preview-contact-details-text">N/A</p>
                )}
              </p>
            </div>
          </div>
          {/* <div className="orderCommentsMain">
            <div className="order-list-item">
              <p className="orderListItemHead">Comments</p>
              <p className="orderListItemtext">
                Lorem Ipsum dummy content. Lorem Ipsum dummy content.Lorem Ipsum dummy content.Lorem Ipsum dummy
                content.Lorem Ipsum dummy content.Lorem Ipsum dummy content.Lorem Ipsum dummy content.Lorem Ipsum dummy
                content.Lorem Ipsum dummy content.
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default OrderDetails;
