import React from 'react';
import { LOADER_ICON } from './Loader-icon';

function Loader() {
  return (
    <div className="loader-wrapper">
      <span className="loader-icon">{LOADER_ICON}</span>
      <p className="loader-font">Loading...</p>
    </div>
  );
}

export default Loader;
