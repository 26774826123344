import { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { useRouter } from 'next/router';
import { setContext } from '@apollo/client/link/context';
import { StarIcon } from '@heroicons/react/outline';
import { Money } from '@Types/product/Money';
import { Variant } from '@Types/product/Variant';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { commercetoolsAPIClient } from 'helpers/utils/axiosRequestsUtil';
import { useAccount } from 'frontastic';
import { UserContext } from 'frontastic/lib/renderer';
import { ErrorInProductLoading } from './ErrorProductLoading';
import useTrack from './hooks/useTrack';
import PDPBreadcrumb from './pdp-breadcrumb';
import ProductDescription from './product-details-components/product-description.tsx/product-description';
import ProductDetailsSection from './product-details-components/product-details-section';
import ProductImageCarousel from './product-details-components/product-image-carousel';
import ProductReviews from './product-details-components/product-Reviews';
import ProductSellers from './product-details-components/product-sellers';
export interface Props {
  product: UIProduct;
  onAddToCart: (variant: Variant, quantity: number) => Promise<void>;
  onAddToWishlist: () => void;
  variant: Variant;
  onChangeVariantIdx: (idx: number) => void;
  quickBuyEnabled?: boolean;
  searching;
}

export type UIProduct = {
  productId: string;
  name: string;
  variants: Variant[];
  prices?: Money;
  price?: Money;
  images?: UIImage[];
  colors?: UIColor[];
  sizes?: UISize[];
  description: string;
  details?: UIDetail[];
  isOnWishlist?: boolean;
  _url: string;
  reviewRatingStatistics?: any;
  id: string;
  Rdd?: string;
  Edd?: string;
  notes?: string;
  categoryKey?: string;
  mercQuantity?: number;
};
interface UIImage {
  id?: string;
  src?: string;
  alt?: string;
}
export interface UIColor {
  name?: string;
  key?: string;
  bgColor?: string;
  selectedColor?: string;
}
export interface UISize {
  label: string;
  key: string;
}
interface UIDetail {
  name: string;
  items: string[];
}

export default function ProductDetail({
  product,
  onAddToCart,
  onAddToWishlist,
  variant,
  onChangeVariantIdx,
  quickBuyEnabled,
  searching,
}: Props) {
  const { contextValue, setContextValue } = useContext(UserContext);
  const [productVariant, setProductVariant] = useState(
    product?.variants?.find((e) => e.sku === window.location.href.split('/').pop()),
  );
  const attributes = productVariant?.attributes;
  const dataToShow = attributes && Object.entries(attributes).slice(0, 15);
  const productGroup = productVariant?.attributes['product-group-name'];
  const [vendorList, setVendorList] = useState([]);
  const [total, setTotal] = useState(0);
  const [review, setReview] = useState([]);
  const [avgRating, setAvgRating] = useState(0);
  const [rating, setRating] = useState(0);
  const [vendorRating, setVendorRating] = useState({});
  const [productQuantity, setProductQuantity] = useState(
    contextValue?.quantity,
    // Math.round(
    //   parseInt(variant?.attributes['MOQ'].split(' ')[0], 10) /
    //     parseInt(variant?.attributes['packaging-size'].split(' ')[0], 10),
    // ),
  );
  const [productQuantityPdp, setProductQuantityPdp] = useState(0);
  const [productWeight, setProductWeight] = useState(contextValue?.variant);
  const [reviewAdded, setReviewAdded] = useState<boolean>(false);
  const [mercQuantityfromDetails, setMercQuantityfromDetails] = useState(null);
  const [userNotes, setUserNotes] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [showErrorPage, setShowErrorPage] = useState(false);
  //next/router
  const router = useRouter();
  const { account } = useAccount();
  //Variant attributes
  const selectedColor = useMemo<UIColor | undefined>(
    () => product?.colors?.find((c) => c.key === variant?.attributes?.color?.key) ?? product?.colors?.[0],
    [product, variant],
  );

  const selectedSize = useMemo<UISize>(
    () => product?.sizes?.find((s) => s?.key === variant?.attributes?.commonSize?.key) ?? product?.sizes?.[0],
    [product, variant],
  );

  useEffect(() => {
    const variantData: any = product?.variants?.find((e) => e.sku === window.location.href.split('/').pop());
    setProductVariant(variantData);
  }, [product]);
  //Component states
  const [loading, setLoading] = useState<boolean>(false);
  const [added, setAdded] = useState<boolean>(false);

  const reviewCallBack = (reviewAdded) => {
    if (reviewAdded) {
      setReviewAdded(true);
      setTimeout(() => {
        setReviewAdded(false);
      }, 1500);
    }
  };

  const receivedMercQuantity = (data) => {
    setMercQuantityfromDetails(data);
  };

  const receivedNotes = (data) => {
    setUserNotes(data);
  };

  const { trackView } = useTrack({ product });
  useEffect(() => {
    trackView();
  }, [trackView]);

  const productQuantityCallBack = (quantityValue) => {
    if (quantityValue) {
      setProductQuantity(quantityValue);
    }
  };
  const productWeightCallBack = (prodWeight) => {
    if (prodWeight) {
      setProductWeight(prodWeight);
    }
  };
  // const handleAddToCart = (variant: Variant): Promise<void> => {
  //   return addItem(variant, productQuantity);
  // };

  // const handleAddToCart = async () => {
  //   console.log('addtocart', variant);
  //   await addItem(variant, 1);
  // };
  // const handleAddToCart = async () => {
  //   if (!account) {
  //     router.push('/login');
  //   } else {

  //     //   if (loading || !variant.isOnStock) return;
  //     setLoading(true);
  //     console.log('handleAddToCart', variant);
  //     await onAddToCart(variant, 1);
  //     setLoading(false);
  //     setAdded(true);
  //   }
  // };
  useEffect(() => {
    if (product) {
      const duplicateRating = {};

      if (product?.reviewRatingStatistics) {
        setRating(Math.round(product?.reviewRatingStatistics?.averageRating));
      }
    }
  }, [product, review]);

  const handleQuickBuy = async () => {
    await onAddToCart(variant, 1);
    router.push('/checkout');
  };

  const logResult = useCallback(() => {
    return 2 + 2;
  }, []);
  const setReviewHandler = async () => {
    try {
      const response = await commercetoolsAPIClient.get(
        `/reviews?where=target(typeId%3D%22product%22%20and%20id%3D%22${product?.id}%22)`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
          },
        },
      );
      setReview(response.data.results);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    setReviewHandler();
  }, [logResult, reviewAdded]);

  useEffect(() => {
    const totalRating = review?.reduce(function (acc, obj) {
      return acc + obj.rating / review?.length;
    }, 0);
    setAvgRating(Math.round(totalRating));
  }, [review, avgRating]);
  const count = 5;
  const color = {
    filled: '#FEB343',
    unfilled: '#F9F2E0',
  };
  const getColor = (index) => {
    if (avgRating >= index) {
      return color.filled;
    }
    return color.unfilled;
  };

  const starRating = useMemo(() => {
    return Array(count)
      .fill(0)
      .map((_, i) => i + 1)
      .map((idx) => (
        <StarIcon
          key={idx}
          className="pdp-rating-average cursor-pointer"
          style={{ color: getColor(idx), fill: getColor(idx) }}
        />
      ));
  }, [rating, review, avgRating]);
  const getVendorListHandler = async () => {
    try {
      const response = await commercetoolsAPIClient.get(
        `/products?where=masterData%28current%28variants%28attributes%28name%3D%22product-group-name%22%20and%20value%28en-IN%3D%22${productGroup}%22%29%29%29%29%29`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
          },
        },
      );
      const data = response.data.results;
      const total = response.data.total;
      setVendorList(data);
      setTotal(total);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getVendorListHandler();
  }, [logResult, review]);
  const handleStarRating = (name) => {
    if (vendorRating[name]) {
      const stars = [];
      for (let i = 0; i < 5; i++) {
        stars.push(<StarIcon className={`ratings ${i < vendorRating[name] ? '' : 'grey-rating'}`} />);
      }
      return stars;
    } else {
      return Array(5).fill(<StarIcon className="ratings  grey-rating" />);
    }
  };
  useEffect(() => {
    if (vendorList?.length > 0) {
      const duplicateRating = {};

      vendorList.forEach((el) => {
        if (el?.reviewRatingStatistics) {
          duplicateRating[el?.key] = Math.round(el?.reviewRatingStatistics?.averageRating);
        }
      });
      setVendorRating({ ...duplicateRating });
    }
  }, [vendorList]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const allEntries = JSON.parse(localStorage.getItem('recently-viewed-product')) || [];
      if (allEntries.length > 20) {
        allEntries.splice(-1);
      }
      if (!JSON.stringify(allEntries).includes(JSON.stringify(product))) {
        allEntries.unshift(product);
        localStorage.setItem('recently-viewed-product', JSON.stringify(allEntries));
      } else {
        const currentIndex = allEntries.findIndex((x) => x.productId === product.productId);
        allEntries.unshift(allEntries[currentIndex]);
        allEntries.splice(currentIndex + 1, 1);
        localStorage.setItem('recently-viewed-product', JSON.stringify(allEntries));
      }
    }
    setProductQuantity(
      // Math.round(
      //   parseInt(variant?.attributes['MOQ'].split(' ')[0], 10) /
      //     parseInt(variant?.attributes['packaging-size'].split(' ')[0], 10),
      // ),
      1,
    );
  }, [router.asPath]);

  return (
    <div className="pdpMainContainer">
      <div className="pdp_container mx-auto flex flex-col px-5 md:px-0 lg:mx-[100px]">
        <div className="mb-4 hidden pl-6 text-sm lg:mt-[50px] lg:block">
          <PDPBreadcrumb productSKU={variant.sku} productName={product.name} />
        </div>
        {showErrorPage ? (
          <ErrorInProductLoading />
        ) : (
          <div>
            <div className="md:grid lg:grid-cols-12 2xl:grid-cols-12 2xl:gap-[20px]">
              <div className="col-span-12">
                <div className="md:grid lg:grid-cols-12 2xl:grid-cols-12 2xl:gap-[20px]">
                  {/* product Image*/}
                  <div className="col-span-4 lg:mt-[20px]">
                    <ProductImageCarousel images={[product?.images[0]]} />
                  </div>
                  {/* product Details*/}
                  <div className="col-span-8 ml-2 lg:mt-[20px]">
                    <ProductDetailsSection
                      product={product}
                      review={review}
                      variant={productVariant}
                      starRating={starRating}
                      handleReviewCallBack={reviewCallBack}
                      handleProductQuantityCallBack={productQuantityCallBack}
                      handleProductWeightCallBack={productWeightCallBack}
                      productQuantityPLP={productQuantity}
                      sendMercQuantity={receivedMercQuantity}
                      sendUserNotes={receivedNotes}
                      setSelectedProduct={setSelectedProduct}
                      productQuantity={productQuantityPdp}
                      setProductQuantity={setProductQuantityPdp}
                      setShowErrorPage={setShowErrorPage}
                    />
                    {/* product Sellers*/}

                    <ProductSellers
                      product={product}
                      onAddToCart={onAddToCart}
                      variant={productVariant}
                      total={total}
                      handleStarRating={handleStarRating}
                      vendorList={vendorList}
                      searching={searching}
                      productQuantity={productQuantity}
                      productQuantityPdp={productQuantityPdp}
                      starRating={starRating}
                      review={review}
                      pdpWeight={productWeight}
                      mercQuantity={mercQuantityfromDetails}
                      userNotes={userNotes}
                      selectedProduct={selectedProduct}
                    />
                  </div>
                </div>
              </div>
              <div>
                {/* product specifications and documents*/}
                <ProductDescription dataToShow={dataToShow} product={product} attributes={attributes} />
              </div>
            </div>
            {/* product reviews */}
            <ProductReviews review={review} product={product} handleReviewCallBack={reviewCallBack} />
          </div>
        )}
      </div>
    </div>
  );
}
