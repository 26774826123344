import ReviewCarousel from './ReviewCarousel';
import ReviewsAndRatings from './ReviewsandRatings';
import AddReview from './addRatingModal';
import { useAccount } from 'frontastic';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { commercetoolsAPIClient } from 'helpers/utils/axiosRequestsUtil';
import { backendAPIClient } from 'helpers/utils/axiosRequestsUtil';
import { STATIC_GET_VENDOR_DETAILS } from 'helpers/constants/statisURLs';
import { useRouter } from 'next/router';

const VendorReviews = () => {
  const router = useRouter();
  const [vendorInfo, setVendorInfo] = useState(null);
  const vendorCode = router?.query?.['vendor-code'];
  const handleVendorInfo = async () => {
    try {
      const response = await backendAPIClient.get(`${STATIC_GET_VENDOR_DETAILS}/?vendorCode=${vendorCode}`);
      setVendorInfo(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleVendorInfo();
  }, []);
  const [review, setReview] = useState([]);
  const setReviewHandler = async () => {
    try {
      const response = await commercetoolsAPIClient.get(
        `/reviews?where=custom%28fields%28vendor_code%3D%22${vendorCode}%22%29%29`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
          },
        },
      );
      setReview(response.data.results.reverse());
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    setReviewHandler();
  }, []);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [seeMorecounter, setSeeMoreCounter] = useState(4);
  const [showSeeLessButton, setShowLessButton] = useState(false);
  const [showSeeMoreButton, setShowMoreButton] = useState(true);
  const [reviewAdded, setReviewAdded] = useState<boolean>(false);
  const { account } = useAccount();
  const handleAddReview = () => {
    if (!account) {
      router.push({ pathname: '/login', query: router.asPath });
    } else {
      setShowReviewModal(true);
    }
  };
  const viewMoreReviews = () => {
    setSeeMoreCounter((current) => current + 4);
    setShowLessButton(true);
    setShowMoreButton(false);
  };
  const viewLessReviews = () => {
    setSeeMoreCounter((current) => current - 4);
    setShowMoreButton(true);
  };
  if (review?.length == 0) {
    return null;
  }
  return (
    <div>
      <div className="pdp_review_rating bg-[#F3F5FD]  lg:mt-[60px]">
        <div className="lg:grid lg:grid-cols-12 lg:items-start lg:items-start">
          {review?.length > 0 && (
            <p className="vendor-info-name vendor-review-heading lg:col-span-5 lg:col-start-2 lg:mt-[28px]">
              Reviews and Ratings
            </p>
          )}
          {/* {review?.length > 2 && (
            <button className="see_all_button vendor-review-see-all-btn lg:col-span-2 lg:col-start-10 lg:mt-[28px] 2xl:col-start-11">
              See All
            </button>
          )} */}
        </div>
        <div className="flex flex-col lg:mx-[20px] lg:mt-[22px] lg:flex-row">
          <div className="pdp_reviews vendor_reviews">
            <ReviewCarousel review={review} />
          </div>

          <div className="pdp_reviews_mobile lg:hidden">
            {review?.slice(0,8).map((item) => (
              <ReviewsAndRatings productVariants={item} key={item.id} />
            ))}
          </div>
          <div className="flex justify-evenly">
            {showSeeMoreButton && review?.length > 8 && (
              <button className="see_more_reviews lg:hidden">
                See More
              </button>
            )}
            {/* {showSeeLessButton && seeMorecounter > 5 && review?.length > 0 && (
              <button className="see_more_reviews lg:hidden" onClick={viewLessReviews}>
                See Less
              </button>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorReviews;
