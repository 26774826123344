import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { connectSearchBox } from 'react-instantsearch-dom';
import { useAccount } from 'frontastic';
import { DR_VENDOR_NAME } from 'helpers/constants/constant';

export let searchProducts = [];
export let searchQueryText = '';

function SearchBox({
  refine,
  style,
  placeHolder,
  searchOutput,
  searchQuery,
  showbutton,
  setHideSearchDropdown,
  triggerSearch,
  isFromQuote,
}) {
  const router = useRouter();
const { isSSOLoggedIn} = useAccount();
  const addRecentSearches = () => {
    const query = searchQuery.length > 0 ? searchQuery : '';
    if (typeof window !== 'undefined') {
      const recentSearches = JSON.parse(localStorage.getItem('recentSearches') || '[]');
      if (recentSearches.length > 5) {
        recentSearches.splice(-1);
      }

      recentSearches.unshift(query);
      localStorage.setItem('recentSearches', JSON.stringify(recentSearches));
    }
  };
  const handleSubmit = (e) => {
    e !== 'Trigger Search' && e.preventDefault();
    searchProducts = searchOutput?.hits;
    searchQueryText = searchQuery;
    if (!isFromQuote && searchQueryText?.trim().length > 2) {
      addRecentSearches();
      router.push(`/search?q=${searchQueryText}${!isSSOLoggedIn ? `&facetFilters=productProjections.variants.filterableAttributes.vendor-name:${DR_VENDOR_NAME}` : ''}` );
    }
  };
  const handleInputChange = (e) => {
    // if (e.currentTarget.value.length >= 3) {
    // }
    refine(e.currentTarget.value);
  };

  useEffect(() => {
    if (!!triggerSearch) {
      handleSubmit('Trigger Search');
    }
  }, [triggerSearch]);

  return (
    <form onSubmit={handleSubmit}>
      <input
        className={style}
        type="search"
        placeholder={placeHolder}
        onChange={(e) => handleInputChange(e)}
        // onKeyDown={(event) => event.key === 'Enter' && event.preventDefault()}
        onFocus={() => setHideSearchDropdown(false)}
      />
      {showbutton && (
        <button type="submit" className="btn-search">
          Search
        </button>
      )}
    </form>
  );
}
export default connectSearchBox(SearchBox);
