import React from 'react';

export default function Navbar({products, onRemoveProductBtnClick, handleRemoveAll, handleNavigateToProductCompare}) {

  const productTitle = (product, index) => {
    return (
        <div className='flex compare-api-tile h-[7rem] w-[17rem] mr-5 mt-[1rem]'> 
            <div className="api-tile-img-container-compare w-[7rem]">
                <img src={product?.image ? product.image : '/icons/drlIcons/NoImagePLP.png'} 
                className='h-[6rem]'
                alt={product?.productName['en-IN']} />
            </div>
            <div className="line-navbar"></div>
            <div className='flex'>
                <div className="compare-api-tile-container-navbar">
                    <div className="api-tile-title-navbar cursor-pointer">
                        <span className='navbar-tile-title tooltip'>
                            {`${(product?.productName).substring(0, 15)}...`}
                            <span className="tooltiptext">{product?.productName}</span>
                        </span>
                    </div>
                    <div className="compare-variant-no mt-2">
                        <p className="compare-category-title">Pack Size:</p>
                        <span className='compare-seller-name tooltip'>
                            {(product?.packSize)?.length > 15 ? ` ${(product?.packSize).substring(0, 15)}...` : ` ${product?.packSize}`}
                            {/* <span className="pack-size-tooltiptext">{product?.packSize}</span> */}
                        </span>

                    </div>
                    <div className="compare-seller-no">
                        <p className="compare-category-title">Seller:</p>
                        <span className='mt-2 compare-seller-name tooltip'> {` ${(product?.seller).substring(0, 30)}...`}
                        <span className="seller-tooltiptext">{product?.seller}</span>
                         </span>
                    </div>
                </div>
                <div className="plpMobileFilterTextWrap p-[5px] ml-[10rem] z-50">
                    <svg
                        className="ml-auto cursor-pointer"
                        onClick={() => onRemoveProductBtnClick(index)}
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                        fill="#0068A3"
                        />
                    </svg>
                    </div>
            </div>
            
        </div>
    )
    
  }

  return (
    <div className={`navbar align-top`}>
        <div className='flex ml-5'>
            {
                products.map((product, index) => 
                    productTitle(product, index)
                )
            }     
            { products.length > 1 &&
                <button className="first_review ask-price-btn flex" onClick={() => handleRemoveAll()}>
                    Remove All
                </button>
            }

            <button 
                disabled={products.length < 2} 
                className={`compare-know-more float-right w-40 ${products.length < 2 ? 'bg-gray-400' : ''} mt-2`} 
                onClick={() => handleNavigateToProductCompare()}>
                Compare Product
            </button>
        </div>
        </div>
  );
}