import React, { useRef } from 'react';

const Modal = ({ setShowModal, selectedOption }) => {
  // close the modal when clicking outside the modal.
  const modalRef = useRef();
  const closeModal = (e) => {
    if (e.target === modalRef.current) {
      setShowModal(false);
    }
  };

  const handelModalButton = (option) => {
    selectedOption(option);
  };

  return (
    <div className="rfq-modal-container" ref={modalRef} onClick={closeModal}>
      <div className="rfq-modal">
        <h2 className="cancel-rfq">Do you want to cancel the quote?</h2>
        <div className="rfq-modal-buttons">
          <button className="rfq-modal-button btn" onClick={() => handelModalButton(true)}>
            Yes
          </button>
          <button className="rfq-modal-button btn" onClick={() => handelModalButton(false)}>
            No
          </button>
        </div>
        <button className="rfq-modal-close" onClick={() => setShowModal(false)}>
          X
        </button>
      </div>
    </div>
  );
};

export default Modal;
