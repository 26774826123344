/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { MinusIcon, PlusIcon, TrashIcon } from '@heroicons/react/outline';
import { LineItem } from '@Types/cart/LineItem';
import Dropdown, { DropdownProps } from 'components/commercetools-ui/dropdown';
import { useFormat } from 'helpers/hooks/useFormat';
import Image from 'frontastic/lib/image';
import Price from '../price';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import Typography from '../typography';
import { IoHeartOutline } from 'react-icons/io5';
import { isArray } from '@apollo/client/utilities';
import Loader from '../loader';
import axios from 'axios';
import { BACKEND_AMP_BASE_URL, COMMERCETOOLS_BASE_URL } from 'helpers/constants/envVariables';
import { COUNTRY_CODE, CURRENCY_SYMBOL } from 'helpers/constants/constant';

interface Props {
  lineItem: any;
  goToProductPage: (_url: string) => void;
  editItemQuantity: (lineItemId: string, newQuantity: number) => void;
  removeItem: (lineItemId: string[]) => void;
  isFromOrderDetails?: boolean;
}

const Item = ({ lineItem, goToProductPage, editItemQuantity, removeItem, isFromOrderDetails = false }: Props) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const [itemQuantity, setItemQuantity] = useState(lineItem?.count);
  const [loading, setLoading] = useState(false);
  const [mercVendor, setMercVendor] = useState(false);
  const [latestPrice, setLatestPrice] = useState(0);
  const [oldPrice, setOldPrice] = useState(0);
  const [percentageChange, setPercentageChange] = useState<any>(0);
  const [mercQuantity, setMercQuantity] = useState(0);
  const [expectedDateOfDelivery, setExpectedDateOfDelivery] = useState(undefined);
  const [mercLoader, setMercLoader] = useState(false);
  const [mercUpdatedPrice, setMercUpdatedPrice] = useState(0);
  const [mercUpdatedOldprice, setMercUpdatedOldprice] = useState(0);
  const amountLimit = 10;
  // price modification for Merc code starts here
  const tokenForPunchout = localStorage.getItem('token');
  const existingLineItemsJSON = localStorage.getItem('lineItems');
  const punchurl = localStorage.getItem('punchoutUrl');
  const existingLineItems = JSON.parse(existingLineItemsJSON);
  const priceObjIfUSD = (lineItem?.variant?.allPrices?.find(e => e.value.currencyCode === COUNTRY_CODE.USD))?.value || (lineItem?.variant?.prices.length > 0 && lineItem?.variant?.prices?.find(e => e.value.currencyCode === COUNTRY_CODE.USD))?.value;
  const [lineItemDetails, setLineItemDetails] = useState({
    expectedDateOfDelivery: '',
    notes: '',
    requestedDateOfDelivery: '',
    location: ''
  })

  useEffect(() => {
    existingLineItems?.hasOwnProperty(lineItem?.lineItemId) && setLineItemDetails(existingLineItems[lineItem?.lineItemId]);
  }, []);

  useEffect(() => {
    getCategoryData();
  })

  const tokenCategory = localStorage.getItem('token');
  const getCategoryData = () => {
    const productID = lineItem?.productId;

    const categoryUrl = `${COMMERCETOOLS_BASE_URL}/products/${productID}`;
    axios
      .get(categoryUrl, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${tokenCategory}`,
        },
      })
      .then((response) => {
        const catID = response?.data?.masterData?.current?.categories[0]?.id;
        if (catID) {
          const categorygetUrl = `${COMMERCETOOLS_BASE_URL}/categories/${catID}`;
          axios
            .get(categorygetUrl, {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${tokenCategory}`,
              },
            })
            .then((response) => {
              lineItem.categoryKey = response?.data?.key;
            })
            .catch((error) => {
              console.error('inner category call error', error);
            });
        }
      })
      .catch((error) => {
        console.error('Error from transfer cart API:', error);
      });
    return;
  };

  const d = new Date(lineItem?.lastModifiedAt);
  d.setDate(d.getDate() + 15);

  useEffect(() => {
    setLoading(false);
  }, [lineItem?.count]);
  const handleQuantityChange = (event) => {
    setItemQuantity(event.target.value);
  };

  const handleQuantityChangeBlur = () => {
    editItemQuantity(lineItem?.lineItemId, itemQuantity);
  };

  const handleMercpriceEdit = (lineItemId, itemQuantity) => {
    const resultPrice = itemQuantity * latestPrice;
    const resultOldPrice = itemQuantity * oldPrice;
    setMercUpdatedPrice(resultPrice);
    setMercUpdatedOldprice(resultOldPrice);
  };
  const handleMercpriceEditMinus = (lineItemId, itemQuantity) => {
    const resultPrice = itemQuantity * latestPrice;
    const resultOldPrice = itemQuantity * oldPrice;
    setMercUpdatedPrice(resultPrice);
    setMercUpdatedOldprice(resultOldPrice);
  };
const casNo = !isFromOrderDetails
? lineItem?.variant?.attributes['cas-no']
: lineItem?.variant?.attributes.find((element) => element.name === 'cas-no').value['en-IN'];
  return (
    <>
      {loading && <Loader />}
      <div className="cart-item">
        <div className="cart-item-main-section">
          <div className="cart-item-image ">
            {/* <Image
            src={!isFromOrderDetails ? lineItem?.variant?.images[0] : lineItem?.variant?.images[0]?.url}
            alt={lineItem?.name}
            className="cart_image"
            onClick={() => goToProductPage(lineItem?._url)}
          /> */}
          </div>

          <div className="cart-item-data">
            {/* <div> */}
            <div className="cart-item-data-one">
              <div className="flex-container">
                <div className="cas-num-items">
                { casNo &&
                    <div className="cas-no mt-2">
                      <span className="category-title">CAS No. :</span>
                      {` ${casNo}`}
                    </div>
                }
                {
                  lineItem?.variant?.attributes?.['supplier-part-number'] &&
                  <div className="cas-no mt-1 mb-2">
                    <span className="category-title">Supplier Part No. :</span>
                    {` ${lineItem?.variant?.attributes?.['supplier-part-number']}`}
                  </div>
                }

                  {/* <p className="item-cas-no">
                    CAS No. :
                    {` ${!isFromOrderDetails
                      ? lineItem?.variant?.attributes['cas-no']
                      : lineItem?.variant?.attributes.find((element) => element.name === 'cas-no').value['en-IN']}`}
                  </p> */}
                </div>
              </div>
              <p className="item-name" onClick={() => goToProductPage(lineItem?._url)}>
                {!isFromOrderDetails ? lineItem?.name : lineItem?.name['en-IN']}
              </p>

              {(lineItem?.variant.attributes?.dosage ||
                (isArray(lineItem?.variant?.attributes) &&
                  lineItem?.variant?.attributes?.find((element) => element.name === 'dosage'))) && (
                  <div className="item-dosage">
                    <p className="">
                      <span>{formatProductMessage({ id: 'dosage', defaultMessage: 'Dosage' })}: </span>
                      <span className="capitalize">
                        {!isFromOrderDetails
                          ? lineItem?.variant?.attributes?.dosage
                          : lineItem?.variant.attributes.find((element) => element.name === 'dosage').value['en-IN']}
                      </span>
                    </p>
                  </div>
                )}
              {(lineItem?.variant?.attributes['packaging-size'] ||
                lineItem?.variant.attributes.find((element) => element.name === 'packaging-size')) && (
                  <div className="item-dosage">
                    <p className="">
                      <span>{formatProductMessage({ id: 'pack-size', defaultMessage: 'Pack Size' })}: </span>
                      <span className={typeof lineItem?.variant?.attributes?.size == 'string' && 'capitalize'}>
                        {!isFromOrderDetails
                          ? lineItem?.variant?.attributes['packaging-size']
                          : lineItem?.variant.attributes.find((element) => element.name === 'packaging-size').value}
                      </span>
                    </p>
                  </div>
                )}
              {lineItemDetails.expectedDateOfDelivery && (
                <p className="headingLeft mt-2 tracking-[1px]">
                  EDD: <span className="ml-2 font-semibold">{lineItemDetails.expectedDateOfDelivery}</span>
                </p>
              )}
              {lineItemDetails.requestedDateOfDelivery && (
                <p className="headingLeft mt-2  tracking-[1px]">
                  RDD: <span className="ml-2 font-semibold"> {lineItemDetails.requestedDateOfDelivery}</span>
                </p>
              )}
              {lineItemDetails.location && (
                <p className="headingLeft mt-2  tracking-[1px]">
                  Location: <span className="ml-2 font-semibold">{lineItemDetails.location}</span>
                </p>
              )}
              {lineItemDetails.notes && (
                <p className="headingLeft mt-2  tracking-[1px]">
                  Notes: <span className="ml-2 font-semibold">{lineItemDetails.notes}</span>
                </p>
              )}
              <div className="cart-item-price-mobile">
                <div className="cart-item-price">
                  <Typography>{CurrencyHelpers.formatForCurrency(lineItem?.totalPrice)}</Typography>
                  {lineItem?.discountedPrice && <Price price={lineItem?.discountedPrice} className="" />}
                </div>
              </div>

              <div className="absolute top-0 right-0">
                <button
                  type="button"
                  onClick={() => removeItem([lineItem?.lineItemId])}
                  className="inline-flex text-gray-400 hover:text-gray-500 md:p-5"
                >
                  <span className="sr-only">{formatMessage({ id: 'remove', defaultMessage: 'Remove' })}</span>
                  <TrashIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>

            <div className="cart-item-data-two">
              {!isFromOrderDetails &&
                (mercVendor ? (
                  mercQuantity >= 1 ? (
                    <div className="cart-item-qty">
                      <span className="cart-item-qty-label">Quantity</span>
                      <div className="cart-item-qty-buttons">
                        <button
                          aria-label="Reduce"
                          type="button"
                          onClick={() => {
                            setLoading(true);
                            editItemQuantity(lineItem?.lineItemId, lineItem?.count - 1);
                            handleMercpriceEditMinus(lineItem?.lineItemId, lineItem?.count - 1);
                          }}
                          className={`cart-item-qty-btn ${lineItem?.count <= 1 ? 'cursor-not-allowed' : 'cursor-pointer'
                            }`}
                        >
                          <MinusIcon width={17} />
                        </button>
                        <span className="">{lineItem?.count}</span>
                        <button
                          type="button"
                          aria-label="Increment"
                          onClick={() => {
                            setLoading(true);
                            editItemQuantity(lineItem?.lineItemId, lineItem?.count + 1);
                            handleMercpriceEdit(lineItem?.lineItemId, lineItem?.count + 1);
                          }}
                          className={`cart-item-qty-btn cursor-pointer`}
                        >
                          <PlusIcon width={17} />
                        </button>
                      </div>
                    </div>
                  ) : null
                ) : (
                  <div className="cart-item-qty">
                    <span className="cart-item-qty-label">Quantity</span>
                    <div className="cart-item-qty-buttons">
                      <button
                        aria-label="Reduce"
                        type="button"
                        onClick={() => {
                          setLoading(true);
                          editItemQuantity(lineItem?.lineItemId, lineItem?.count - 1);
                          handleMercpriceEditMinus(lineItem?.lineItemId, lineItem?.count - 1);
                        }}
                        className={`cart-item-qty-btn ${lineItem?.count <= 1 ? 'cursor-not-allowed' : 'cursor-pointer'
                          }`}
                      >
                        <MinusIcon width={17} />
                      </button>
                      <span className="">{lineItem?.count}</span>
                      <button
                        type="button"
                        aria-label="Increment"
                        onClick={() => {
                          setLoading(true);
                          editItemQuantity(lineItem?.lineItemId, lineItem?.count + 1);
                          handleMercpriceEdit(lineItem?.lineItemId, lineItem?.count + 1);
                        }}
                        className={`cart-item-qty-btn cursor-pointer`}
                      >
                        <PlusIcon width={17} />
                      </button>
                    </div>
                  </div>
                ))}

              <div className="hidden md:flex">
                <div className="cart-item-price">
                  <Typography>{
                    (punchurl && priceObjIfUSD ) ?
                    `${CURRENCY_SYMBOL[priceObjIfUSD.currencyCode]} ${(((priceObjIfUSD?.centAmount)/100)*lineItem.count).toFixed(2)}` :
                    CurrencyHelpers.formatForCurrency(lineItem?.totalPrice)
                  }</Typography>
                  {lineItem?.discountedPrice && <Price price={lineItem?.discountedPrice} className="" />}
                </div>
              </div>
              { 
                priceObjIfUSD && 
                <Typography>{
                  punchurl ?
                  CurrencyHelpers.formatForCurrency(lineItem?.totalPrice) :
                  `${CURRENCY_SYMBOL[priceObjIfUSD.currencyCode]} ${((priceObjIfUSD?.centAmount)/100)*(lineItem.count || lineItem.quantity)}`
                }</Typography>
              }
              <div className="mt-5 flex justify-end">
                {isFromOrderDetails ? (
                  <div className="text-right">
                    <div className="font-bold">Delivery Date</div>
                    <div>{d.toLocaleDateString()}</div>
                  </div>
                ) : (
                  <span className="cursor-pointer" onClick={() => removeItem([lineItem?.lineItemId])}>
                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M3 6H5H21"
                        stroke="#0280C7"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z"
                        stroke="#0280C7"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10 11V17"
                        stroke="#0280C7"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14 11V17"
                        stroke="#0280C7"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                )}
              </div>
            </div>
            <div>{lineItem?.discountedPrice && <Price price={lineItem?.discountedPrice} className="" />}</div>
          </div>
        </div>

        {!isFromOrderDetails ? (
          mercVendor ? (
            mercQuantity >= 1 ? (
              <div className="cart-item-secondary-section">
                <div className="cart-item-qty">
                  <span className="cart-item-qty-label">Quantity</span>
                  <div className="cart-item-qty-buttons">
                    <button
                      aria-label="Reduce"
                      type="button"
                      onClick={() => {
                        setLoading(true);

                        editItemQuantity(lineItem?.lineItemId, lineItem?.count - 1);
                        handleMercpriceEditMinus(lineItem?.lineItemId, lineItem?.count - 1);
                      }}
                      className={`cart-item-qty-btn ${lineItem?.count <= 1 ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                    >
                      <MinusIcon width={17} />
                    </button>
                    <span className="">{lineItem?.count}</span>
                    <button
                      type="button"
                      aria-label="Increment"
                      onClick={() => {
                        setLoading(true);
                        handleMercpriceEdit(lineItem?.lineItemId, lineItem?.count + 1);
                        editItemQuantity(lineItem?.lineItemId, lineItem?.count + 1);
                      }}
                      className={`cart-item-qty-btn cursor-pointer`}
                    >
                      <PlusIcon width={17} />
                    </button>
                  </div>
                </div>

                <div className="cart-mobile-icons flex">
                  <span className="cursor-pointer" onClick={() => removeItem([lineItem?.lineItemId])}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M3 6H5H21"
                        stroke="#0280C7"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z"
                        stroke="#0280C7"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10 11V17"
                        stroke="#0280C7"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14 11V17"
                        stroke="#0280C7"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  <span style={{ color: '#ABABAB' }}>|</span>
                  <span>
                    <IoHeartOutline color="#0280C7" strokeWidth="2" size="24" />
                  </span>
                </div>
              </div>
            ) : null
          ) : (
            <div className="cart-item-secondary-section">
              <div className="cart-item-qty">
                <span className="cart-item-qty-label">Quantity</span>
                <div className="cart-item-qty-buttons">
                  <button
                    aria-label="Reduce"
                    type="button"
                    onClick={() => {
                      setLoading(true);

                      editItemQuantity(lineItem?.lineItemId, lineItem?.count - 1);
                      handleMercpriceEditMinus(lineItem?.lineItemId, lineItem?.count - 1);
                    }}
                    className={`cart-item-qty-btn ${lineItem?.count <= 1 ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                  >
                    <MinusIcon width={17} />
                  </button>
                  <span className="">{lineItem?.count}</span>
                  <button
                    type="button"
                    aria-label="Increment"
                    onClick={() => {
                      setLoading(true);
                      handleMercpriceEdit(lineItem?.lineItemId, lineItem?.count + 1);
                      editItemQuantity(lineItem?.lineItemId, lineItem?.count + 1);
                    }}
                    className={`cart-item-qty-btn cursor-pointer`}
                  >
                    <PlusIcon width={17} />
                  </button>
                </div>
              </div>

              <div className="cart-mobile-icons flex">
                <span className="cursor-pointer" onClick={() => removeItem([lineItem?.lineItemId])}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 6H5H21" stroke="#0280C7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path
                      d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z"
                      stroke="#0280C7"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path d="M10 11V17" stroke="#0280C7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M14 11V17" stroke="#0280C7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </span>
                <span style={{ color: '#ABABAB' }}>|</span>
                <span>
                  <IoHeartOutline color="#0280C7" strokeWidth="2" size="24" />
                </span>
              </div>
            </div>
          )
        ) : (
          window.innerWidth < 400 && (
            <div className="">
              <div className="font-bold">Delivery ddd Date</div>
              <div>{d.toLocaleDateString()}</div>
            </div>
          )
        )}
      </div>
    </>
  );
};

export default Item;
