import React from 'react'

function CheckoutInstructions() {
    return (
        <div>
            <p className="instructionsTitle">
                Order related comments
            </p>
            <p className="instructionsTextarea">
            <textarea  placeholder="Add details"></textarea>
            </p>
        </div>
    )
}

export default CheckoutInstructions;
