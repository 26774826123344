import React, { useEffect, useState } from 'react';
import { MenuIcon } from '@heroicons/react/outline';
import { Account } from '@Types/account/Account';
import Typography from 'components/commercetools-ui/typography';
import { headerNavigation } from 'helpers/mocks/mockData';
import { Reference, ReferenceLink } from 'helpers/reference';
import Image, { MediaItemWithMods } from 'frontastic/lib/image';
import AccountButton from './account-button';
import CartButton from './cart-button';
import HeaderMenu from './header-menu';
import SearchButton from './search-button';
import WishListButton from './wishlist-button';
import Link from 'next/link';
import MegaMenu from './mega-menu/mega-menu';
import { LOAD_MEGAMENU_DATA } from './graphql-query/mega-menu-graphql';
import { apolloClient } from 'pages/_app';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export interface Link {
  name: string;
  reference: Reference;
}

export interface HeaderProps {
  tagline?: string;
  links: Link[];
  cartItemCount: number;
  wishlistItemCount?: number;
  logo: MediaItemWithMods;
  logoLink: Reference;
  account: Account;
  accountLink: Reference;
  wishlistLink?: Reference;
  cartLink: Reference;
  previewId: string;
}

const Header: React.FC<HeaderProps> = ({
  tagline,
  links,
  cartItemCount,
  wishlistItemCount,
  logo,
  logoLink,
  account,
  accountLink,
  wishlistLink,
  cartLink,
  previewId,
}) => {
  const [open, setOpen] = useState(false);
  const [megaMenuData, setMegaMenuData] = useState(null);
  const [isVisible, setIsVisible] = useState(true);
  const requestForQuoteUrl = account ? 'quotes/request-for-quote' : 'login';

  const detectCategoriesClick = () => {
    const checkMenu = window.localStorage.getItem('showSideMenu');
    if (checkMenu === 'true') {
      window.localStorage.removeItem('showSideMenu');
      setOpen(true);
    }
  };
  const getData = async () => {
    apolloClient
      .query({
        query: LOAD_MEGAMENU_DATA,
      })
      .then((response) => {
        sessionStorage.setItem('megamenu-data', JSON.stringify(response.data));
        setMegaMenuData(response.data);
      });
  };
  //   const { loading, error, data } = useQuery(LOAD_MEGAMENU_DATA);
  useEffect(() => {
    const isData = JSON.parse(window.sessionStorage.getItem('megamenu-data'));
    if (isData) {
      setMegaMenuData(isData);
    } else {
      getData();
    }
    window.addEventListener('storage', detectCategoriesClick);

    return () => {
      window.removeEventListener('storage', detectCategoriesClick);
    };
  }, []);

  let lastScrollTop = 0;
  const heightToHideFrom = 100;
  useEffect(() => {
    window.addEventListener(
      'scroll',
      function () {
        if (window.location.href.includes('/vendor-list')) {
          setIsVisible(true);
          return;
        }
        const st = window.pageYOffset || document.documentElement.scrollTop;
        if (st > lastScrollTop && st > heightToHideFrom) {
          setIsVisible(false);
        } else if (st < lastScrollTop) {
          setIsVisible(true);
        }
        lastScrollTop = st <= 0 ? 0 : st;
      },
      false,
    );
  }, []);

  return (
    <div>
      {isVisible && (
        <div className="fixed-screen-width lg:relative-width transition-all">
          {/* Mobile menu */}
          <HeaderMenu
            open={open}
            setOpen={setOpen}
            links={links}
            navigation={headerNavigation}
            previewId={previewId}
            account={account}
            accountLink={accountLink}
            wishlistLink={wishlistLink}
            wishlistItemCount={wishlistItemCount}
            megaMenuData={megaMenuData}
          />

          <header className="headerNavWrap relative">
            {tagline && (
              <p className="flex items-center justify-center bg-primary-400 px-4 text-sm font-medium text-white sm:px-6 lg:px-8">
                <Typography>{tagline}</Typography>
              </p>
            )}

            <nav aria-label="Top" className="flex w-full lg:mx-[100px]">
              {/* Secondary navigation */}
              <div className="flex h-full w-full justify-center">
                <div className="headerNavMain flex items-center justify-between px-5 lg:px-0">
                  <div className="flex items-center md:flex-1 lg:hidden">
                    <button
                      type="button"
                      className="-ml-2 rounded-md bg-none p-2 text-primary-400 dark:text-light-100"
                      onClick={() => setOpen(!open)}
                    >
                      <span className="sr-only">Open menu</span>
                      <MenuIcon className="h-7 w-7" aria-hidden="true" />
                    </button>
                  </div>

                  {/* Logo */}
                  <ReferenceLink target={logoLink} className="headerLogoWrap flex h-full items-center pr-2 md:py-3">
                    <span className="sr-only">Catwalk</span>
                    <div className="header-logo-main relative h-8 px-4 pr-3 sm:pr-7">
                      <Image media={logo} className="dark:invert" layout="fill" objectFit="contain" alt="Logo" />
                    </div>
                  </ReferenceLink>

                  <div className="flex flex-1 items-center justify-end">
                    <div className="headerBtnGroup flex w-fit items-center">
                      {!localStorage.getItem('punchoutUrl') && <Link href={requestForQuoteUrl}>
                        <button className="headerEnquirybtn hidden lg:block">Product Enquiry</button>
                      </Link>}
                      {/* <Link href="/contact-us">
                        <button className="headerSellbtn hidden lg:block">Sell on Allverz</button>
                      </Link> */}
                      <span className="hidden lg:block">
                        {!account && <AccountButton showIcon={false} account={account} accountLink={accountLink} />}
                      </span>
                      <SearchButton />
                      <span className="hidden lg:block">
                        <AccountButton showIcon={true} account={account} accountLink={accountLink} />
                      </span>
                      {/* <span className="hidden lg:block">
                        <WishListButton wishlistItemCount={wishlistItemCount} wishlistLink={wishlistLink} />
                      </span> */}
                      <CartButton cartItemCount={cartItemCount} cartLink={cartLink} />
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </header>
          { !window.location.href.endsWith('/cart') &&
            !window.location.href.endsWith('/checkout') &&
            !window.location.href.includes('/preview-order') &&
            !window.location.href.endsWith('/payment') && <MegaMenu/>}
        </div>
      )}
    </div>
  );
};

export default Header;
