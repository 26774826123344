import Addresses from "../../../components/commercetools-ui/account/details/sections/addresses";

const Address = () =>{
 return(<div className="aboutUsTopContainer container mx-auto mt-[20px]  md:px-0 lg:px-0">
 <div className="flex">
   <div className="quote-details-component mr-10">
     <Addresses />
   </div>
 </div>
</div>
)
}

export default Address;