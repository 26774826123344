import React, { useEffect, useState } from 'react';

type TextBoxValidations = 'onlyAlphabetsAndSpace' | 'onlyAlphabetsAndDigits' | 'all';
export interface TextBoxProps {
  type?: string;
  iconName?: React.ReactNode;
  label?: string;
  id?: string;
  placeholder?: string;
  isValid?: boolean;
  value?: string;
  validation?: TextBoxValidations;
  onChane?: (string) => void;
  onBlur?: (string) => void;
}

const TextBox: React.FC<TextBoxProps> = ({
  type = 'text',
  value = '',
  id,
  label,
  iconName,
  placeholder,
  isValid = true,
  validation = 'all',
  onChane,
  onBlur,
}) => {
  const [isTextBoxValid, setIsTextBoxValid] = useState(isValid);
  const [textBoxValue, setTextBoxValue] = useState(value);

  const validationFunctions = {
    onlyAlphabetsAndSpace: (value: string): boolean => {
      const regex = /^(?!\s)[a-z A-Z]*$/;
      return regex.test(value);
    },
    onlyAlphabetsAndDigits: (value: string): boolean => {
      const regex = /^[a-zA-Z0-9]*$/;
      return regex.test(value);
    },
    all: (value: string): boolean => {
      return true;
    },
  };

  const handleOnChange = (e) => {
    const validationFn = e.target.dataset.validation;
    const value = e.target.value;
    if (validationFunctions[validationFn](value)) {
      setTextBoxValue(value);
      onChane({ [id]: value });
    }
  };

  const handleOnBlur = (e) => {
    setIsTextBoxValid(!!e.target.value);
    onChane({ [id]: e.target.value });
  };

  useEffect(() => {
    setIsTextBoxValid(isValid);
  }, [isValid]);

  const inputClassNames = isTextBoxValid ? 'acc-input' : 'acc-input acc-input-invalid';

  return (
    <label className="account-editable-fields acc-new relative">
      <span>
        {label} <span className="ml-1 text-[#EB4747]">*</span>
      </span>
      <span className="absolute">{iconName}</span>
      <input
        className={inputClassNames}
        id="id"
        type={type}
        onChange={handleOnChange}
        placeholder={placeholder}
        onBlur={handleOnBlur}
        onKeyDown={(evt) => type === 'number' && evt.key === 'e' && evt.preventDefault()}
        value={textBoxValue}
        data-validation={validation}
      />
    </label>
  );
};

export default TextBox;
