import React from 'react';
import { useMsal } from '@azure/msal-react';
import NextLink from 'next/link';

function AzureAuth() {
  const { instance, accounts, inProgress } = useMsal();

  const getSSOLogin = (disabled) => {
    return <p className={'new_account new_account_mobile mt-[30px] flex justify-center  lg:mt-[40px]'}>
      {'For SSO Login \u00A0\u00A0'}
        <button
        className='link-like underline font-bold'
        disabled={disabled}
        onClick={() => instance.loginRedirect()}
        >
        SSO Login
        </button>
  </p>
  }

  if (inProgress === 'login') {
    return (getSSOLogin(true));
  } else {
    return (getSSOLogin(false));
  }
}

export default AzureAuth;