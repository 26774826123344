import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import Breadcrumb from 'components/commercetools-ui/breadcrumb';
import { LOAD_PDP_BREADCRUMB_DATA } from 'components/commercetools-ui/header/graphql-query/mega-menu-graphql';

function PDPBreadcrumb({ productSKU, productName }) {
  const { loading, error, data } = useQuery(LOAD_PDP_BREADCRUMB_DATA(productSKU));
  const [PDPBreadcrumbSlugArray, setPDPBreadcrumbSlugArray] = useState([]);
  const [PDPBreadcrumbNameArray, setPDPBreadcrumbNameArray] = useState([]);

  const getBreadcrumbString = () => {
    const slugArr = [];
    const nameArr = [];
    data.product?.masterData.current.categories[0].ancestors.map((item) => {
      slugArr.push(item.slug);
      nameArr.push(item.name);
    });
    slugArr.shift();
    nameArr.shift();
    slugArr.push(data?.product?.masterData?.current?.categories[0]?.slug);
    nameArr.push(data?.product?.masterData?.current?.categories[0]?.name);
    nameArr.push(productName);
    setPDPBreadcrumbSlugArray(slugArr);
    setPDPBreadcrumbNameArray(nameArr);
  };

  useEffect(() => {
    if (!loading) {
      getBreadcrumbString();
    }
  }, [data]);
  return (
    <Breadcrumb Separator="/" categorySlugs={PDPBreadcrumbSlugArray}>
      {PDPBreadcrumbNameArray}
    </Breadcrumb>
  );
}

export default PDPBreadcrumb;
