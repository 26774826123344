import Price from '../price';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from 'frontastic/provider';
import { useEffect, useState } from 'react';

export type vendorwiseTotalProps = {
  items: any;
};

const VendorwiseTotal: React.FC<vendorwiseTotalProps> = ({ items }) => {
  const [shippingAmount, setShppingAmount] = useState<number>(0)
  const [currencyCode, setCurrencyCode] = useState<string>('INR')

  const { getShippingMethods } = useCart();

  useEffect(()=>{
    getShippingMethods().then((data: any) => {
      setShppingAmount(data[0]?.rates[0]?.price?.centAmount)
      setCurrencyCode(data[0]?.rates[0]?.price?.currencyCode)
    });
  },[])
  
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const cart = items;
  //i18n messages
  return (
    <div style={{ fontSize: '14px' }}>
      <div className="flex items-center justify-between">
        <dt className="text-md text-gray-600 dark:text-light-100">
          {formatCartMessage({ id: 'subtotal', defaultMessage: 'Subtotal' })}
        </dt>
        <dd>
          <Price
            price={{
              centAmount: items.reduce((x, y) => {
                return x + y.totalPrice.centAmount;
              }, 0),
              currencyCode: cart?.sum?.currencyCode,
            }}
            className="font-medium text-gray-900 dark:text-light-100"
          />
        </dd>
      </div>
      {/* <div className="flex items-center justify-between  border-gray-200 pt-2">
        <dt className="text-md flex items-center text-gray-600 dark:text-light-100">
          <span>{formatCartMessage({ id: 'shipping.estimate', defaultMessage: 'Shipping estimate' })}</span>
        </dt>
        <dd>
          <Price price={{
              centAmount: shippingAmount,
              currencyCode: currencyCode,
            }} className="font-medium text-gray-900 dark:text-light-100" />
        </dd>
      </div> */}
      <div className="flex items-center justify-between  border-gray-200 pt-2">
        <dt className="text-md flex text-gray-600 dark:text-light-100">
          <span>{formatCartMessage({ id: 'discounts', defaultMessage: 'Discount' })}</span>
        </dt>
        <dd>
          <Price price={cart?.discounts || {}} className="font-medium text-gray-900 dark:text-light-100" />
        </dd>
      </div>
      <div className="flex items-center justify-between  border-gray-200 pt-2">
        <dt className="text-base font-medium text-gray-900 dark:text-light-100">
          {formatCartMessage({ id: 'vendorOrderTotal', defaultMessage: 'Total' })}
        </dt>
        <dd>
          <Price
            price={{
              centAmount: items.reduce((x, y) => {
                return x + y.totalPrice.centAmount;
              }, 0),
              currencyCode: cart?.sum?.currencyCode,
            }}
            className="text-base font-medium text-gray-900 dark:text-light-100"
          />
        </dd>
      </div>
    </div>
  );
};

export default VendorwiseTotal;
