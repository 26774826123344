import React from 'react';

export default function Modal({ setOpenModal, goTOPreview, children }) {
  return (
    <>
      <div className="fixed right-0 top-0 z-50 h-full w-full bg-gray-900 bg-opacity-75">
        {/* <div className="bg-black fixed inset-0 h-full w-full opacity-100" onClick={() => setOpenModal(false)}></div> */}
        <div className="plpMobileWrap flex min-h-screen items-center px-4 py-8">
          <div className="plpMobileMain relative mx-auto h-[200px] w-full max-w-md rounded-md bg-white p-4 shadow-lg">
            <div className="plpMobileFilterTextWrap">
              <svg
                className="ml-auto cursor-pointer"
                onClick={() => setOpenModal(false)}
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                  fill="#0068A3"
                />
              </svg>
            </div>
            <div className="flex h-full flex-col items-center justify-center gap-2">
              {children}
              <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="4" y="4" width="48" height="48" rx="24" fill="#E0F9E2" />
                <path
                  d="M38 27.0799V27.9999C37.9988 30.1563 37.3005 32.2545 36.0093 33.9817C34.7182 35.7088 32.9033 36.9723 30.8354 37.5838C28.7674 38.1952 26.5573 38.1218 24.5345 37.3744C22.5117 36.6271 20.7847 35.246 19.611 33.4369C18.4373 31.6279 17.8798 29.4879 18.0217 27.3362C18.1636 25.1844 18.9972 23.1362 20.3983 21.4969C21.7994 19.8577 23.6928 18.7152 25.7962 18.24C27.8996 17.7648 30.1003 17.9822 32.07 18.8599M38 19.9999L28 30.0099L25 27.0099"
                  stroke="#30AB30"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <rect x="4" y="4" width="48" height="48" rx="24" stroke="#F7FCF5" strokeWidth="8" />
              </svg>
              <button
                  className="text-[16px] font-semibold text-[#0280C7] underline"
                  style={{ color: '#0280c7' }}
                  onClick={goTOPreview}
                >
                  Preview Order
                </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
