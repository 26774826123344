import { Carousel } from 'react-responsive-carousel';

export default function ProductImageCarousel({ images }) {
  return (
    <div>
      <div className="pdp_left_details">
        <Carousel>
          {images?.map((image) => (
            <div className='pdpImageWrapper' key={(image?.id) ? image.id : '1'}>
              <img className="pdp_image" src={(image?.src) ? image.src : '/icons/drlIcons/NoImagePLP.png'} />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  )
}