import React from 'react';
import { LOADER_ICON } from 'components/commercetools-ui/loader/Loader-icon';
import Link from 'next/link';

const CompanyInfo = () => {
  return (
    <div className='coming-soon-products mt-[150px]'>
         <span className="loader-icon margin-auto  lg:mt-[30px]">{LOADER_ICON}</span>
       <p className="mt-[20px] text-center text-[22px] font-semibold text-[#90ee90] lg:mt-[30px]">
        coming soon ...
      </p>
      <Link href={'/'}>
        <button className="headerEnquirybtn mt-[20px]  lg:my-[30px]">Back to Home</button>
      </Link>
    </div>
  );
};

export default CompanyInfo;