import { ABOUT_US_PAGE_VIEWED } from 'helpers/constants/events';
import React, { useCallback, useEffect } from 'react';

const ContentAbout = ({ data }) => {
  const handleAboutUsAnalytics = useCallback(async () => {
    gtag('event', ABOUT_US_PAGE_VIEWED, {});
  }, []);
  useEffect(() => {
    handleAboutUsAnalytics();
  }, [handleAboutUsAnalytics]);
  const aboutAttributes = data.data.dataSource.attributes;

  return (
    <>
      <div className="aboutUsTopContainer container mx-auto mt-[160px] px-5 md:px-0 lg:px-0">
        <div className="aboutUsBreadcrumbContainer">{/* Breadcrumb will come here */}</div>
        <div className="aboutUsOurMissionContainer">
          <div className="card-breadcrumbs mt-[12rem]">
            <a href="/" style={{ color: '#0280C7' }}>
              Home Page
            </a>
            <a>/</a>
            <a>About Us</a>
          </div>
          <div className="sm:grid md:grid-cols-2 md:flex-col-reverse md:gap-[50px] 2xl:grid-cols-2 2xl:gap-[90px]">
            <div className="aboutUsOurMissionLeft col-span-1 md:block">
              <img
                src={
                  aboutAttributes?.cardContentBox1?.content?.fields?.bannerImage?.fields?.file?.url || 'Image not Found'
                }
                alt=""
              />
            </div>
            <div className="aboutUsOurMissionRight col-span-1">
              <div>
                <p className="ourMissionTitle">
                  {aboutAttributes?.cardContentBox1?.content?.fields?.bannerTitle || 'Title not found'}
                </p>
                <p className="ourMissionSubHead">
                  {aboutAttributes?.cardContentBox1?.content?.fields?.bannerSubTitle || 'Text not found'}
                </p>
                <p className="ourMissionPara">
                  {aboutAttributes?.cardContentBox1?.content?.fields?.description || 'Text not found'}
                </p>
              </div>
            </div>
            <div className="aboutUsOurMissionRight ourMissionMobile col-span-1 block md:block">
              <div className="pt-6 sm:block md:hidden">
                <img
                  src={
                    aboutAttributes?.cardContentBox2?.content?.fields?.cardImage?.fields?.file?.url || 'Image not Found'
                  }
                  className="w-auto"
                  alt=""
                />
              </div>
              <div>
                <p className="ourMissionTitle">
                  {aboutAttributes?.cardContentBox2?.content?.fields?.cardTitle || 'Title not found'}
                </p>
                <p className="ourMissionSubHead">
                  {aboutAttributes?.cardContentBox2?.content?.fields?.cardDescriptionBox || 'Text not found'}
                </p>
                <p className="ourMissionPara">
                  {aboutAttributes?.cardContentBox3?.content?.fields?.titleDescription || 'Text not found'}
                </p>
              </div>
            </div>
            <div className="aboutUsOurMissionLeft col-span-1 hidden md:block">
              <img
                src={
                  aboutAttributes?.cardContentBox2?.content?.fields?.cardImage?.fields?.file?.url || 'Image not Found'
                }
                alt=""
              />
            </div>
            <div className="aboutUsOurMissionLeft col-span-1 hidden md:block">
              <img
                src={
                  aboutAttributes?.cardContentBox4?.content?.fields?.bannerImage?.fields?.file?.url || 'Image not Found'
                }
                alt=""
              />
            </div>
            <div className="aboutUsOurMissionRight ourMissionMobile col-span-1 md:block">
              <div className="pt-6 sm:block md:hidden">
                <img
                  src={
                    aboutAttributes?.cardContentBox4?.content?.fields?.bannerImage?.fields?.file?.url ||
                    'Image not Found'
                  }
                  className="w-auto"
                  alt=""
                />
              </div>
              <div>
                <p className="ourMissionTitle">
                  {aboutAttributes?.cardContentBox4?.content?.fields?.bannerTitle || 'Title not found'}
                </p>
                <p className="ourMissionSubHead">
                  {aboutAttributes?.cardContentBox4?.content?.fields?.bannerSubTitle || 'Text not found'}
                </p>
                <p className="ourMissionPara">
                  {aboutAttributes?.cardContentBox4?.content?.fields?.description || 'Text not found'}
                </p>
                <span>
                  <p className="ourMissionParaHeading">
                    {aboutAttributes?.cardContentBox5?.content?.fields?.title || 'Title not found'}
                  </p>
                  <p className="ourMissionPara">
                    {aboutAttributes?.cardContentBox5?.content?.fields?.description || 'Text not found'}
                  </p>
                </span>
                <span>
                  <p className="ourMissionParaHeading">
                    {aboutAttributes?.cardContentBox6?.content?.fields?.title || 'Title not found'}
                  </p>
                  <p className="ourMissionPara">
                    {aboutAttributes?.cardContentBox6?.content?.fields?.description || 'Text not found'}
                  </p>
                </span>
                <span>
                  <p className="ourMissionParaHeading">
                    {aboutAttributes?.descriptionBlockWithButton?.content?.fields?.title || 'Title not found'}
                  </p>
                  <p className="ourMissionPara">
                    {aboutAttributes?.descriptionBlockWithButton?.content?.fields?.description || 'Text not found'}
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="aboutUsOurOfferingContainer">
        <div className="container mx-auto px-5 md:px-0 lg:px-0">
          <p className="aboutUsOurOfferingTitle">
            {aboutAttributes?.contentBox2?.content?.fields?.title || 'Title not found'}
          </p>
          <p className="aboutUsOurOfferingSubHeads">
            {aboutAttributes?.contentBox2?.content?.fields?.titleDescription || 'Text not found'}
          </p>
          <div className="aboutUsOurOfferingGridWrap">
            <ul>
              <li className="aboutUsOurOfferingGridItem">
                <div className="card bg-white">
                  <figure className="cart-figure">
                    <img
                      src={aboutAttributes?.contentBox3?.content?.fields?.cardImage?.fields?.file?.url}
                      alt="Image"
                      className="aboutUsOurOfferingGridItemImg"
                    />
                  </figure>
                  <div className="card-body">
                    <h2 className="card-title">
                      {aboutAttributes?.contentBox3?.content?.fields?.cardTitle || 'Title not found'}
                    </h2>
                    <p className="cardDescription">
                      {aboutAttributes?.contentBox3?.content?.fields?.cardDescriptionBox || 'Description not found'}
                    </p>
                  </div>
                </div>
              </li>
              <li className="aboutUsOurOfferingGridItem">
                <div className="card bg-white">
                  <figure className="cart-figure">
                    <img
                      src={aboutAttributes?.contentBox4?.content?.fields?.cardImage?.fields?.file?.url}
                      alt="Image"
                      className="aboutUsOurOfferingGridItemImg"
                    />
                  </figure>
                  <div className="card-body">
                    <h2 className="card-title">
                      {aboutAttributes?.contentBox4?.content?.fields?.cardTitle || 'Title not found'}
                    </h2>
                    <p className="cardDescription">
                      {aboutAttributes?.contentBox4?.content?.fields?.cardDescriptionBox || 'Description not found'}
                    </p>
                  </div>
                </div>
              </li>
              <li className="aboutUsOurOfferingGridItem">
                <div className="card bg-white">
                  <figure className="cart-figure">
                    <img
                      src={aboutAttributes?.contentBox5?.content?.fields?.cardImage?.fields?.file?.url}
                      alt="Image"
                      className="aboutUsOurOfferingGridItemImg"
                    />
                  </figure>
                  <div className="card-body">
                    <h2 className="card-title">
                      {aboutAttributes?.contentBox5?.content?.fields?.cardTitle || 'Title not found'}
                    </h2>
                    <p className="cardDescription">
                      {aboutAttributes?.contentBox5?.content?.fields?.cardDescriptionBox || 'Description not found'}
                    </p>
                  </div>
                </div>
              </li>
              <li className="aboutUsOurOfferingGridItem">
                <div className="card bg-white">
                  <figure className="cart-figure">
                    <img
                      src={aboutAttributes?.contentBox6?.content?.fields?.cardImage?.fields?.file?.url}
                      alt="Image"
                      className="aboutUsOurOfferingGridItemImg"
                    />
                  </figure>
                  <div className="card-body">
                    <h2 className="card-title">
                      {aboutAttributes?.contentBox6?.content?.fields?.cardTitle || 'Title not found'}
                    </h2>
                    <p className="cardDescription">
                      {aboutAttributes?.contentBox6?.content?.fields?.cardDescriptionBox || 'Description not found'}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container mx-auto px-5 md:px-0 lg:px-0">
        <div className="aboutUsSupplierContainer">
          <p className="aboutUsSupplierContainerText text-center">
            {aboutAttributes?.contentBox7?.content?.fields?.bannerTitle || 'Text Not Found'}
          </p>
          <div className="flex-column supplierContainer flex h-[auto] gap-20 overflow-hidden">
            <div className="supplierCard">
              <div className="alignCardImage">
                <figure>
                  <img
                    src={aboutAttributes?.contentBox7?.content?.fields?.bannerImage?.fields?.file?.url}
                    alt="Image"
                    className="aboutUsOurOfferingGridItemImg"
                  />
                </figure>
              </div>
              <div>
                <h2 className="supplierCardTitle">
                  {aboutAttributes?.contentBox8?.content?.fields?.title || 'Title not found'}
                </h2>
                <p className="supplierCardDescription">
                  {aboutAttributes?.contentBox8?.content?.fields?.description || 'Description not found'}
                </p>
              </div>
              <div className="alignCardImage">
                <figure>
                  <img
                    src={aboutAttributes?.contentBox9?.content?.fields?.icon?.fields?.file?.url}
                    alt="Image"
                    className="aboutUsOurOfferingGridItemImg"
                  />
                </figure>
              </div>
            </div>
            <div className="suppliersStep">
              <ul className="greenProgressBar steps steps-vertical">
                <li data-content="" className="green greenStep step"></li>
                <li className="greenStep step step-primary"></li>
                <li className="greenStep step"></li>
              </ul>
            </div>
            <div className="supplierCard">
              <div>
                <h2 className="supplierCardTitle">
                  {aboutAttributes?.contentBox7?.content?.fields?.bannerSubTitle || 'Title not found'}
                </h2>
                <p className="supplierCardDescription">
                  {aboutAttributes?.contentBox7?.content?.fields?.description || 'Description not found'}
                </p>
              </div>
              <div className="">
                <figure>
                  <img
                    src={aboutAttributes?.contentBox8?.content?.fields?.icon?.fields?.file?.url}
                    alt="Image"
                    className="aboutUsOurOfferingGridItemImg"
                  />
                </figure>
              </div>
              <div>
                <h2 className="supplierCardTitle">
                  {aboutAttributes?.contentBox9?.content?.fields?.title || 'Title not found'}
                </h2>
                <p className="supplierCardDescription">
                  {aboutAttributes?.contentBox9?.content?.fields?.description || 'Description not found'}
                </p>
              </div>
            </div>
          </div>
          <div className="supplierCardMobile">
            <ul>
              <li>
                <div className="supplierCardMobileContainer bg-white">
                  <figure>
                    <img
                      src={aboutAttributes?.contentBox7?.content?.fields?.bannerImage?.fields?.file?.url}
                      alt="Image"
                      className=""
                    />
                  </figure>
                  <div className="supplierCardText">
                    <h2 className="supplierCardTitle">
                      {aboutAttributes?.contentBox7?.content?.fields?.bannerSubTitle || 'Title not found'}
                    </h2>
                    <p className="supplierCardDescription ">
                      {aboutAttributes?.contentBox7?.content?.fields?.description || 'Description not found'}
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="supplierCardMobileContainer bg-white">
                  <figure>
                    <img
                      src={aboutAttributes?.contentBox8?.content?.fields?.icon?.fields?.file?.url}
                      alt="Image"
                      className=""
                    />
                  </figure>
                  <div className="supplierCardText">
                    <h2 className="supplierCardTitle">
                      {aboutAttributes?.contentBox8?.content?.fields?.title || 'Title not found'}
                    </h2>
                    <p className="supplierCardDescription ">
                      {aboutAttributes?.contentBox8?.content?.fields?.description || 'Description not found'}
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="supplierCardMobileContainer bg-white">
                  <figure>
                    <img
                      src={aboutAttributes?.contentBox9?.content?.fields?.icon?.fields?.file?.url}
                      alt="Image"
                      className=""
                    />
                  </figure>
                  <div className="supplierCardText">
                    <h2 className="supplierCardTitle">
                      {aboutAttributes?.contentBox9?.content?.fields?.title || 'Title not found'}
                    </h2>
                    <p className="supplierCardDescription ">
                      {aboutAttributes?.contentBox9?.content?.fields?.description || 'Description not found'}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContentAbout;
