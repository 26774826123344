import Link from 'next/link';
import React, { useState } from 'react';
import AlgoliaSearch from './search-algolia';

export default function MobileSearchModal({ setOpenModal }) {
  const [triggerSearch, setTriggerSearch] = useState(false);
  return (
    <>
      <div className="fixed right-0 top-0 z-[8000] w-full searchModalHeight">
        <div className="flex h-full items-center">
          <div className="relative mx-auto h-full w-full bg-white">
            <div className="plpMobileFilterText mx-auto">
              <div className="plp-search-bar flex items-center">
                <p className="mr-2" onClick={() => setOpenModal(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M17.885 3.77L16.115 2L6.11499 12L16.115 22L17.885 20.23L9.65499 12L17.885 3.77Z"
                      fill="#0068A3"
                    />
                  </svg>
                </p>
                <svg
                  className="absolute left-14 z-50"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M13.1292 11.8791H12.4709L12.2375 11.6541C13.0542 10.7041 13.5459 9.47074 13.5459 8.12907C13.5459 5.1374 11.1209 2.7124 8.12919 2.7124C5.13752 2.7124 2.71252 5.1374 2.71252 8.12907C2.71252 11.1207 5.13752 13.5457 8.12919 13.5457C9.47086 13.5457 10.7042 13.0541 11.6542 12.2374L11.8792 12.4707V13.1291L16.0459 17.2874L17.2875 16.0457L13.1292 11.8791ZM8.12919 11.8791C6.05419 11.8791 4.37919 10.2041 4.37919 8.12907C4.37919 6.05407 6.05419 4.37907 8.12919 4.37907C10.2042 4.37907 11.8792 6.05407 11.8792 8.12907C11.8792 10.2041 10.2042 11.8791 8.12919 11.8791Z"
                    fill="#0068A3"
                  />
                </svg>
                <AlgoliaSearch
                  searchInputStyle={'search_Input-mob-plp search_Input'}
                  placeHolder={'Search Chemical name & CAS No.'}
                  triggerSearch={triggerSearch}
                  isMobileModal={true}
                />
                <button onClick={() => setTriggerSearch(true)} className="btn-search-mob-plp">
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
