import React, { useEffect, useState, useContext } from 'react';
import router, { useRouter } from 'next/router';
import { StarIcon } from '@heroicons/react/solid';
import { Product } from '@Types/product/Product';
import Loader from 'components/commercetools-ui/loader';
import { HOME, LOCAL_STORAGE_KEYS, PLP } from 'helpers/constants/constant';
import { PLP_PRODUCT_CLICKED } from 'helpers/constants/events';
import { UserContext } from 'frontastic/lib/renderer';
import { useAccount } from '../../../../frontastic';
import { CurrencyHelpers } from '../../../../helpers/currencyHelpers';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import Certificates from './certificates';
import Navbar from './navbar';

export interface Props {
  // items: any;
  products: Product[];
  category: string;
  totalProducts: number;
  disableTopMargin?: boolean;
}
export default function ProductTiles({ products, totalProducts, disableTopMargin }: Props) {
  const { contextValue, setContextValue } = useContext(UserContext);
  const [ratings, setRatings] = useState({});

  const [quantities, setQuantities] = useState(Array(totalProducts).fill(1));
  const [selectedVariant, setSelectedVariant] = useState('');
  const [weightChanged, setWeightChanged] = useState(false);

  const [rows, setRows] = useState([{ value: null, error: '' }]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedVariants, setSelectedVariants] = useState(products.map((p) => 0));

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [compareProductList, setCompareProductList] = useState([]);

  const urlParams = new URLSearchParams(window.location.search);
  const query = urlParams.get('q');
  const sortBy = urlParams.get('sortby');
  const faceFacets = urlParams.get('facetFilters');
  
  useEffect(() => {
    const selectProductVariants = [];
    products?.forEach((product) => {
      const matchedVariantIndex = product?.variants.findIndex(
        (variant) => variant?.attributes?.['supplier-part-number'] === query,
      );
      if (matchedVariantIndex > -1) {
        setSelectedVariant(product?.variants?.[matchedVariantIndex].sku);
        setWeightChanged(true)
      }
      selectProductVariants.push(matchedVariantIndex > -1 ? matchedVariantIndex : 0);
    });
    setSelectedVariants(selectProductVariants);
  }, [query, sortBy, faceFacets, products]);

  function decreaseValue(index) {
    const newQuantities = [...quantities];
    if (newQuantities[index] > 1) {
      newQuantities[index] = newQuantities[index] - 1;
      contextValue.quantity = newQuantities[index];
      setQuantities(newQuantities);
      setContextValue({ ...contextValue });
    }
  }
  function increaseValue(index) {
    const newQuantities = [...quantities];
    newQuantities[index] = newQuantities[index] + 1;
    contextValue.quantity = newQuantities[index];
    setQuantities(newQuantities);
    setQuantities(newQuantities);
  }
  const handleQuantitychange = (e, index) => {
    const newQuantities = [...quantities];
    newQuantities[index] = e.target.value;
    setQuantities(newQuantities);
    let copy = [...rows];
    if (isNaN(e.target.value) || e.target.value == 0 || e.target.value == '') {
      copy = [...copy, { value: index, error: 'Minimum Quantity should be 1' }];
      setRows(copy);
    } else {
      copy.splice(
        copy.findIndex((f) => f.value === index),
        1,
      );
      setRows(copy);
    }
    setRows(copy);
  };
  useEffect(() => {
    setQuantities(Array(totalProducts).fill(1));
  }, [products[0]?.slug]);
  const [isFiltering, setIsFiltering] = useState<boolean>(false);

  const { asPath } = useRouter();
  const { account } = useAccount();

  const handleAddToProject = () => {
    if (!account) {
      router.push({ pathname: '/login', query: router.asPath });
    } else {
      // handle add to project here
    }
  };

  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });

  const toggleFiltering = () => {
    setIsFiltering(!isFiltering);
  };
  const handleNavigateToPDP = (product: Product, variant = selectedVariant, hasWeightChanged = weightChanged) => {
    setIsLoading(true);
    localStorage.setItem(LOCAL_STORAGE_KEYS.redirectTo, PLP);
    if (hasWeightChanged) {
      gtag('event', PLP_PRODUCT_CLICKED, product);
      router.push({ pathname: `/${product?.slug}/p/${variant}` }, undefined, {
        scroll: false,
      });
    } else {
      gtag('event', PLP_PRODUCT_CLICKED, product);
      router.push({ pathname: `/${product?.slug}/p/${product.variants[0].sku}` }, undefined, {
        scroll: false,
      });
      setIsLoading(true);
    }
  };

  const handleNavigateToProductCompare = () => {
    setIsLoading(true);
    const encodedArray = encodeURIComponent(JSON.stringify(compareProductList));

    router.push(`/compare-product?encodedArray=${encodedArray}`);
    setIsLoading(false);
  };

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.redirectTo, HOME);
    const { pathname, search } = window.location;
    const redirectRoute = `${pathname}${search}`;
    localStorage.setItem(LOCAL_STORAGE_KEYS.redirectRoute, redirectRoute);
    return () => setIsLoading(false);
  }, []);

  useEffect(() => {
    if (products?.length > 0) {
      const duplicateRating = {};

      products.forEach((el) => {
        if (el?.reviewRatingStatistics) {
          duplicateRating[el?.slug] = Math.round(el?.reviewRatingStatistics?.averageRating);
        }
      });
      setRatings({ ...duplicateRating });
    }
  }, [products]);
  const handleStarRating = (id) => {
    if (ratings[id]) {
      const stars = [];
      for (let i = 0; i < 5; i++) {
        stars.push(<StarIcon key={i} className={`ratings ${i < ratings[id] ? 'ratings' : 'grey-rating'}`} />);
      }
      return stars;
    } else {
      return Array.from(Array(5), (_, i) => <StarIcon key={i} className="ratings grey-rating" />);
    }
  };
  const handleEmptyStarRating = (name) => {
    return Array.from(Array(5), (_, i) => <StarIcon key={i} className="ratings grey-rating" />);
  };
  const handleProductWeightChange = (e, product, productInd, isWeightChange) => {
    contextValue[`${isWeightChange ? 'variant' : 'supplierPartNo'}`] = e.target.value;
    setContextValue({ ...contextValue });
    if (e.target.value) {
      setWeightChanged(true);
    }
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const option = el.getAttribute('id');
    setSelectedVariant(option);
    const tempSelectedVariants = [...selectedVariants];
    tempSelectedVariants[productInd] =
      product.variants.findIndex(
        (i) => i?.attributes[`${isWeightChange ? 'packaging-size' : 'supplier-part-number'}`] === e.target.value,
      ) || 0;
    setSelectedVariants([...tempSelectedVariants]);
  };

  useEffect(() => {
    contextValue.plpWeightChanged = weightChanged;
    setContextValue({ ...contextValue });
  }, []);

  useEffect(() => {
    contextValue.plpWeightChanged = weightChanged;
    setContextValue({ ...contextValue });
  }, [weightChanged]);

  const requestForQuote = () => {
    if (!account) {
      router.push({ pathname: '/login', query: router.asPath });
    } else {
      router.push('quotes/request-for-quote');
    }
  };

  const handleDetailPageRoute = (vendorCode) => {
    if (vendorCode) {
      router.push(`/vendor-details?vendor-code=${vendorCode}`);
    } else {
      return false;
    }
  };

  const handleAddToCompare = (product, productInd) => {
    if (
      compareProductList.find(
        (e) => e.productId === product.id && e.skuId === product.variants[selectedVariants[productInd]].sku,
      )
    ) {
      setShowErrorMessage(true);
      setErrorMessage('Product already added to compare list!');
    } else {
      if (compareProductList.length < 4) {
        const compareProduct = {
          productId: product.id,
          skuId: product.variants[selectedVariants[productInd]]?.sku,
          vendorId: product.variants[selectedVariants[productInd]]?.attributes['vendor-id'],
          productName: product.name,
          image: product.variants[0]?.images.length && product.variants[0]?.images[0],
          seller: product.variants[0]?.attributes['vendor-name'],
          packSize: product.variants[selectedVariants[productInd]]?.attributes['packaging-size'],
        };
        setCompareProductList([...compareProductList, compareProduct]);
      } else {
        setErrorMessage('Only 4 products can be selected!');
        setShowErrorMessage(true);
      }
    }
  };

  const onRemoveProductBtnClick = (index) => {
    compareProductList.splice(index, 1);
    setCompareProductList([...compareProductList]);
  };

  const displayInfoModal = () => {
    return (
      <div className="fixed right-0 top-0 z-50 h-full w-full bg-gray-900 bg-opacity-75">
        {/* <div className="bg-black fixed inset-0 h-full w-full opacity-100" onClick={() => setOpenModal(false)}></div> */}
        <div className="plpMobileWrap flex min-h-screen items-center px-4 py-8">
          <div className="plpMobileMain relative mx-auto h-[150px] w-full max-w-md rounded-md bg-white p-4 shadow-lg">
            <div className="plpMobileFilterTextWrap">
              <svg
                className="ml-auto cursor-pointer"
                onClick={() => setShowErrorMessage(false)}
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                  fill="#0068A3"
                />
              </svg>
            </div>
            <div className="mt-8 flex h-full flex-col">
              <p className="text-center text-[20px] font-semibold text-[#0280c7]">{errorMessage}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getUniqVariants = (variants) => {
    const uniqueData = variants.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.attributes['packaging-size'] === item.attributes['packaging-size']),
    );
    return uniqueData;
  };
  return (
    <div>
      {isLoading && <Loader />}
      {showErrorMessage && displayInfoModal()}
      {compareProductList.length > 0 && !showErrorMessage && (
        <Navbar
          products={compareProductList}
          onRemoveProductBtnClick={(e) => onRemoveProductBtnClick(e)}
          handleRemoveAll={() => setCompareProductList([])}
          handleNavigateToProductCompare={() => handleNavigateToProductCompare()}
        />
      )}
      <div className={`${disableTopMargin ? '' : 'plpProductTilesWrap'}`}>
        <div className="plpProductTilesMain">
          {products?.map((product, id) => (
            <div className="group my-4" key={id}>
              <div className="product-card">
                {/* {product.variants?.slice(0, 1).map((variant) => ( */}
                {[product?.variants[selectedVariants?.[id]]].map((variant) => (
                  <div className="product-details" key={variant?.id}>
                    <div className="plpTilesLeft">
                      <img
                        src={variant?.images[0] ? variant?.images[0] : '/icons/drlIcons/NoImagePLP.png'}
                        alt={product.name}
                        className="product-img h-full object-cover md:block md:w-48 lg:w-96 lg:max-w-md"
                        onClick={() => handleNavigateToPDP(product)}
                      />
                      <div className="plp-certif flex">
                        <div className="plp_certifications flex">
                          <div className="plp-verified ml-1">
                            <svg
                              width="16"
                              height="21"
                              viewBox="0 0 16 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.9 14.1L12.6 8.39998L11.55 7.37498L6.975 11.95L4.45 9.42498L3.35 10.525L6.9 14.1ZM8 20.975C5.66667 20.3916 3.75 19.0375 2.25 16.9125C0.75 14.7875 0 12.4583 0 9.92498V3.97498L8 0.974976L16 3.97498V9.92498C16 12.4583 15.25 14.7875 13.75 16.9125C12.25 19.0375 10.3333 20.3916 8 20.975Z"
                                fill="#32C902"
                              />
                            </svg>
                            <p className="ml-1 mt-0.5 text-xs font-medium text-[#000000]">Verified</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="line"></div>
                    <div className="attributes lg:mr-auto">
                      {product.reviewRatingStatistics && (
                        <div className="rating-mobile">{handleStarRating(product?.slug)}</div>
                      )}
                      <div className="cas-star mt-2">
                        {variant?.attributes?.['cas-no'] && (
                          <div className="cas-no">
                            <span className="category-title">CAS No. :</span>
                            {` ${variant?.attributes?.['cas-no']}`}
                          </div>
                        )}
                        {product.reviewRatingStatistics && (
                          <div className="rating hidden">{handleStarRating(product?.slug)}</div>
                        )}
                      </div>
                      {variant?.attributes?.['supplier-part-number'] && (
                        <div className="cas-no mt-2">
                          <span className="category-title">Supplier Part No. :</span>
                          {` ${variant?.attributes?.['supplier-part-number']}`}
                        </div>
                      )}
                      <h3 className="product-name" onClick={() => handleNavigateToPDP(product)}>
                        {product?.name}
                      </h3>
                      <span className="category">
                        <span className="category-title">Category:</span>
                        {product &&
                          product.categories &&
                          product.categories.map(
                            (item, index) =>
                              item.name !== 'All' && (
                                <h4 className="category-value capitalize" onClick={() => handleNavigateToPDP(product)}>
                                  {item.name}
                                </h4>
                              ),
                          )}
                      </span>
                      {variant?.attributes?.grade ? (
                        <h4 className="grade">Grade: {variant?.attributes.grade}</h4>
                      ) : null}
                      {variant?.attributes?.purity ? (
                        <div className="pure">
                          <h4 className="purity">Purity: {variant?.attributes.purity}</h4>
                        </div>
                      ) : null}
                      {variant?.attributes['country-of-origin']?.label ? (
                        <div className="origin">
                          <h4 className="produced">Produced In: {variant?.attributes['country-of-origin']?.label}</h4>
                        </div>
                      ) : null}
                      {variant?.attributes['lengthxinner-diameter'] ? (
                        <div className="origin">
                          <h4 className="produced">
                            Length-Inner-Diameter: {variant?.attributes['lengthxinner-diameter']}
                          </h4>
                        </div>
                      ) : null}
                      {variant?.attributes['capacity'] ? (
                        <div className="origin">
                          <h4 className="produced">Capacity: {variant?.attributes['capacity']}</h4>
                        </div>
                      ) : null}
                      {variant?.attributes['material'] ? (
                        <div className="origin">
                          <h4 className="produced">Material: {variant?.attributes['material']}</h4>
                        </div>
                      ) : null}
                      {variant?.attributes['pore-size'] ? (
                        <div className="origin">
                          <h4 className="produced">Pore Size: {variant?.attributes['pore-size']}</h4>
                        </div>
                      ) : null}
                      {variant?.attributes['particle-size'] ? (
                        <div className="origin">
                          <h4 className="produced">Particle Size: {variant?.attributes['particle-size']}</h4>
                        </div>
                      ) : null}
                      {variant?.attributes['length-outer-diameter'] ? (
                        <div className="origin">
                          <h4 className="produced">
                            Length Outer Diameter: {variant?.attributes['length-outer-diameter']}
                          </h4>
                        </div>
                      ) : null}

                      <div className="sold">
                        <p className="sold-by">Sold and Fulfilled by: </p>
                        <span
                          className="cursor-pointer"
                          onClick={() => handleDetailPageRoute(product.variants[0]?.attributes['vendor-code'])}
                        >
                          <p className="sold-value"> {product.variants[0]?.attributes['vendor-name']}</p>
                        </span>
                      </div>
                      {/* <button className="first_review ask-price-btn flex" onClick={() => handleAddToCompare(product, id)}>
                        {formatMessage({ id: 'Add To Compare', defaultMessage: 'Add To Compare' })}
                      </button> */}
                    </div>
                    <div className="attributes-rht lg:ml-auto lg:mr-[10px] lg:flex">
                      <div className="plp-rit-attributes">
                        <div className="plp-whislist">
                          <div className="certificate">
                            {variant?.attributes['product-certificate'] ? (
                              <Certificates certificates={variant?.attributes['product-certificate']} />
                            ) : null}
                          </div>
                        </div>
                        <div
                          className={product?.variants[0]?.attributes['product-certificate'] ? 'prices' : 'plp-prices'}
                        >
                          {/* {variant?.prices?.centAmount != 0 ? ( */}
                          {product.variants[selectedVariants[id]]?.prices?.centAmount != 0 ? (
                            <p className="price">
                              {CurrencyHelpers.formatForCurrency(product.variants[selectedVariants[id]]?.prices)}
                            </p>
                          ) : (
                            // <p className="price">{CurrencyHelpers.formatForCurrency(variant?.prices)} -- {product.variants[selectedVariants[id]]?.prices?.centAmount}</p>
                            <button className="first_review ask-price-btn flex" onClick={requestForQuote}>
                              Ask for price
                            </button>
                          )}
                        </div>
                        <div className="weight">
                          <select
                            id="selected-tab"
                            name="selected-tab"
                            className="mt-1 block w-full rounded-sm border-gray-300 stroke-accent-400 py-2 pr-10 pl-3 text-base text-accent-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                          >
                            {product.variants
                              .filter((_, index) => index !== 0)
                              .map((item, index) => (
                                <option
                                  className="values"
                                  key={index}
                                  defaultValue={item?.attributes['packaging-size']}
                                >
                                  {item?.attributes['packaging-size']}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="quantityMobile ">
                          <p className="qtyText plpQuantMob ml-[14px] mt-[10px] text-[#303030]">Quantity:</p>

                          <div className="gms flex gap-[10px]">
                            <div className="qty plpQTY">
                              <div className="grms-qty bg-[white]">
                                <button className="qtyminus bg-[white]" onClick={() => decreaseValue(id)}>
                                  <svg
                                    width="14"
                                    height="2"
                                    viewBox="0 0 14 2"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M13.2079 2H0.992188V0H13.2079V2Z" fill="#0068A3" />
                                  </svg>
                                </button>
                                <input
                                  className="plp-weight plp_qty_digit"
                                  type="number"
                                  value={quantities[id]}
                                  //  onChange={() => {}}
                                  onChange={(e) => handleQuantitychange(e, id)}
                                />
                                <button className="qtyplus bg-[white]" onClick={() => increaseValue(id)}>
                                  <svg
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M13.1649 8H7.92961V14H6.18451V8H0.949219V6H6.18451V0H7.92961V6H13.1649V8Z"
                                      fill="#0068A3"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <p className="request-quote-error plp-qty-error lg:hidden">
                          {rows?.find((item) => item.value == id)?.error}
                        </p>
                        <div className="quantity">
                          <div className="plp-tile-weight flex gap-[10px]">
                            <select
                              id="selected-tab"
                              name="selected-tab"
                              className="mt-1 block w-full rounded-sm border-gray-300 stroke-accent-400 py-2 pr-10 pl-3 text-base text-accent-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                              onChange={(e) => handleProductWeightChange(e, product, id, true)}
                              value={product.variants[selectedVariants[id]]?.attributes['packaging-size']}
                            >
                              {getUniqVariants(product.variants).map((item, index) => (
                                <option
                                  className="values"
                                  key={item?.sku}
                                  id={item?.sku}
                                  defaultValue={item?.attributes['packaging-size']}
                                >
                                  {item?.attributes['packaging-size']}
                                </option>
                              ))}
                            </select>
                            {product.variants.filter(
                              (item) =>
                                item?.attributes?.['packaging-size'] ===
                                  product.variants[selectedVariants[id]]?.attributes?.['packaging-size'] &&
                                item?.attributes['supplier-part-number'],
                            ).length ? (
                              <select
                                id="selected-tab"
                                name="selected-tab"
                                className="mt-1 block w-full rounded-sm border-gray-300 stroke-accent-400 py-2 pr-10 pl-3 text-base text-accent-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                                onChange={(e) => handleProductWeightChange(e, product, id, false)}
                                value={product.variants[selectedVariants[id]]?.attributes['supplier-part-number']}
                              >
                                {product.variants
                                  .filter((_, index) => index !== 0)
                                  .map((item, index) => {
                                    return (
                                      item?.attributes?.['packaging-size'] ===
                                        product.variants[selectedVariants[id]]?.attributes?.['packaging-size'] && (
                                        <option
                                          className="values"
                                          key={item?.sku}
                                          id={item?.sku}
                                          defaultValue={item?.attributes['supplier-part-number']}
                                        >
                                          {item?.attributes['supplier-part-number']}
                                        </option>
                                      )
                                    );
                                  })}
                              </select>
                            ) : (
                              <></>
                            )}

                            {/* <div className="qty plpQTY">
                              <div className="grms-qty bg-[white]">
                                <p>Quantity </p>
                                <button className="qtyminus bg-[white]" onClick={() => decreaseValue(id)}>
                                  <svg
                                    width="12"
                                    height="2"
                                    viewBox="0 0 14 2"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M13.2079 2H0.992188V0H13.2079V2Z" fill="#0068A3" />
                                  </svg>
                                </button>
                                <input
                                  className="plp-weight plp_qty_digit"
                                  type="text"
                                  value={quantities[id]}
                                  //  onChange={() => {}}
                                  onChange={(e) => handleQuantitychange(e, id)}
                                />
                                <button className="qtyplus bg-[white]" onClick={() => increaseValue(id)}>
                                  <svg
                                    width="12"
                                    height="12"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M13.1649 8H7.92961V14H6.18451V8H0.949219V6H6.18451V0H7.92961V6H13.1649V8Z"
                                      fill="#0068A3"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div> */}
                          </div>
                        </div>
                        <p className="request-quote-error plp-lg-qty-err lg:pt-[10px] lg:pb-[0px]">
                          {rows?.find((item) => item.value == id)?.error}
                        </p>
                        <div className="product-right ml-[-1rem] lg:mt-[10px]">
                          {/* <button className="project-list" onClick={handleAddToProject}>
                          Add To Project
                        </button> */}

                          <button className="add-to-compare mr-6 w-40" onClick={() => handleAddToCompare(product, id)}>
                            Add To Compare
                          </button>
                          <button className="add-to-cart w-40" onClick={() => handleNavigateToPDP(product)}>
                            Know More{' '}
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M8 0L6.59 1.41L12.17 7H0V9H12.17L6.59 14.59L8 16L16 8L8 0Z" fill="white" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
