import React from 'react';
import Markdown from 'components/commercetools-ui/content/markdown';
const RefundVendorPolicy = ({data}) => {
   return (
      <Markdown
      className="privacyPolicyDesciption" 
       text={data?.description}
       />
  );
};
export default RefundVendorPolicy;