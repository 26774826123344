import React from 'react';

export default function SortByModal({ setOpenModal, children }) {
  return (
    <>
      <div className="fixed right-0 top-0 z-50 h-full w-full bg-gray-900 bg-opacity-75">
        <div className="bg-black fixed inset-0 h-full w-full opacity-100" onClick={() => setOpenModal(false)}></div>
        <div className="plpMobileWrap flex min-h-screen items-center px-4 py-8">
          <div className="plpMobileMain relative mx-auto h-[400px] w-full max-w-lg rounded-md bg-white shadow-lg">
            <div className="plpMobileFilterTextWrap">
              <div className="plpMobileFilterText">
                <p className="plpMobileFilterHead">Sort By</p>
                <p className="plpMobileCloseBtn" onClick={() => setOpenModal(false)}>
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                      fill="#0068A3"
                    />
                  </svg>
                </p>
              </div>
            </div>
            <div className="py-4 px-6">{children}</div>
            <div className="plpMobileFilterBtnWrap">
              <span className="bg-white">
                <button className="plpMobileApplyBtn" onClick={() => setOpenModal(false)}>
                  Apply
                </button>
                <button className="plpMobileCancelBtn" onClick={() => setOpenModal(false)}>
                  Clear All
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
