import { gql } from '@apollo/client';

export const LOAD_MEGAMENU_DATA = gql`
  query GetMegaMenuData {
    categories(where: "parent is defined", sort: "orderHint desc", limit: 4) {
      results {
        id
        key
        externalId
        name(locale: "en-IN")
        description(locale: "en-IN")
        slug(locale: "en-IN")
        orderHint
        parent {
          id
          key
        }
        children {
          id
          key
          name(locale: "en-IN")
          description(locale: "en-IN")
          slug(locale: "en-IN")
          orderHint
          parent {
            id
            key
          }
          children {
            id
            key
            externalId
            name(locale: "en-IN")
            description(locale: "en-IN")
            slug(locale: "en-IN")
            orderHint
            parent {
              id
              key
            }
            children {
              id
              key
              externalId
              name(locale: "en-IN")
              description(locale: "en-IN")
              slug(locale: "en-IN")
              orderHint
              parent {
                id
                key
              }
            }
          }
        }
      }
    }
  }
`;

export const LOAD_PDP_BREADCRUMB_DATA = (product) => gql`
  query GetPDPBreadcrumData {
    product(sku: "${product}") {
      masterData {
        current {
          name(locale: "en-IN")
          slug(locale: "en-IN")
          categories {
            id
            key
            name(locale: "en-IN")
            slug(locale: "en-IN")
            ancestors {
              id
              key
              name(locale: "en-IN")
              slug(locale: "en-IN")
            }
          }
        }
      }
    }
  }
`;
