import { useState, useEffect, FormEvent } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { Cart as CartType } from '@Types/cart/Cart';
import { useFormat } from 'helpers/hooks/useFormat';
import { Reference } from 'helpers/reference';
import { NextFrontasticImage } from 'frontastic/lib/image';
import Spinner from '../spinner';
import Stepper from '../stepper';
import EmptyCart from './emptyCart';
import ItemList from './itemList';
import OrderSummary from './orderSummary';
import ImportProject from './project/importProject';
import { useAccount } from 'frontastic';
import CompanyRegistrationModal from 'components/commercetools-ui/company-registration-popup/company-registration-popup';
import { FiArrowLeftCircle } from 'react-icons/fi';

export interface Props {
  pageTitle?: string;
  emptyStateImage?: NextFrontasticImage;
  emptyStateTitle?: string;
  emptyStateSubtitle?: string;
  emptyStateCTALabel?: string;
  emptyStateCTALink?: Reference;
  cart: CartType;
  editItemQuantity: (lineItemId: string, newQuantity: number) => Promise<void>;
  removeItem: (lineItemId: string[]) => void;
}

const Cart = ({
  cart,
  editItemQuantity,
  removeItem,
  pageTitle,
  emptyStateImage,
  emptyStateTitle,
  emptyStateSubtitle,
  emptyStateCTALabel,
  emptyStateCTALink,
}: Props) => {
  const { account, isSSOLoggedIn } = useAccount();
  const [loading, setLoading] = useState<boolean>(true);
  const [showImportProjectModal, setShowImportProjectModal] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  //i18n messages
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });

  const router = useRouter();

  const onCheckout = (e: FormEvent) => {
    e.preventDefault();
    if (!isSSOLoggedIn && (account.customerGroup == null || account.companyApprovalStatus !== 'Approved')) {
      setShowResetPasswordModal(true);
      return;
    }

    router.push('/checkout');
  };

  const goToProductPage = (_url: string) => router.push(_url);
  const punchurl = localStorage.getItem("punchoutUrl");

  useEffect(() => {
    if (cart?.lineItems) {
      setLoading(false);
    }
  }, [cart]);

  if (loading) {
    return (
      <div className="flex items-stretch justify-center py-20 px-12">
        <Spinner />
      </div>
    );
    setLoading(false);
  }

  if (!loading && cart?.lineItems && cart?.lineItems.length === 0) {
    return (
      <EmptyCart
        pageTitle={pageTitle}
        image={emptyStateImage}
        title={emptyStateTitle}
        subtitle={emptyStateSubtitle}
        ctaLabel={emptyStateCTALabel}
        ctaLink={emptyStateCTALink}
      />
    );
  }
  const stepperData = [{ label: 'Product Summary' }, { label: 'Checkout' }, { label: 'Payment' }];

  return (
    <main className="cartWrapper pt-32 pb-24 lg:mx-[100px]">
      {showResetPasswordModal && <CompanyRegistrationModal setOpenModal={setShowResetPasswordModal} />}
      <div className='text-primary-theme-color-2 cursor-pointer w-20 mb-2 mt-1'>
        <FiArrowLeftCircle />
        <p onClick={() => history.back()} className='-mt-5 ml-5'>
          Back
        </p>
      </div>
      <h1 className="cart-breadcrumb">
        <NextLink href="/">
          <a className="pdpBreadcrumbItem text-[#0280C7] hover:text-gray-500">
            <span className="mr-3 cursor-pointer font-medium capitalize hover:text-gray-600">Home Page</span>
          </a>
        </NextLink>
        /{' '}
        <span className="pdpBreadcrumbItem" style={{ marginLeft: '6px' }}>
          Cart
        </span>
      </h1>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {(!punchurl) ?
          <Stepper stepperData={stepperData} isHorizontal={true} isClickable={false} />
          : null}
      </div>

      <div className="cart-heading-count flex justify-between">
        <div>
          <h1 className="my-cart-text text-2xl font-bold">My Cart</h1>
          <h1 style={{ marginTop: '5px' }}>{cart?.lineItems?.length || 0} item(s) in your Cart</h1>
        </div>
        <button
          className="btn-sm-mobile btn btn-sm"
          onClick={() => {
            setShowImportProjectModal(true);
          }}
        >
          {formatCartMessage({ id: 'importProj.heading', defaultMessage: 'Import Project' })}
        </button>
        {showImportProjectModal && <ImportProject setOpenModal={setShowImportProjectModal} />}
      </div>

      {loading ? (
        <div className="flex items-stretch justify-center py-10 px-12">
          <Spinner />
        </div>
      ) : (
        <>
          <form className="cart-form">
            <ItemList
              cart={cart}
              editItemQuantity={editItemQuantity}
              goToProductPage={goToProductPage}
              removeItem={(lineItemId: string[]) => removeItem(lineItemId)}
            />
            <OrderSummary cart={cart} onSubmit={onCheckout} showDiscountsForm={false} />
          </form>
        </>
      )}
    </main>
  );
};

export default Cart;
