import React, { useState } from 'react';

const MonthData = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const YearData = ['2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030', '2031', '2032', '2033'];

function CreditCard() {
  const [isCreditCard, setIsCreditCard] = useState(false);
  const [cardNumber, setCardNumber] = useState('');
  const [cardName, setCardName] = useState('');
  const [cardValidity, setCardValidity] = useState('');
  const [cardCVV, setCardCVV] = useState('');
  return (
    <form className="payment-cheque-wrapper">
      <div className="mb-5 flex gap-9">
        <label className="flex items-center gap-2" htmlFor="">
          <input className="payment-radio-button" type="radio" name="cardType" onChange={() => setIsCreditCard(true)} />
          <span className="payment-cheque-label !mb-0 flex">Credit Card</span>
        </label>
        <label className="flex items-center gap-2" htmlFor="">
          <input className="payment-radio-button" type="radio" name="cardType" onChange={() => setIsCreditCard(true)} />
          <span className="payment-cheque-label !mb-0 flex">Debit Card</span>
        </label>
      </div>
      <div>
        <label htmlFor="">
          <span className="payment-cheque-label flex">
            Card Number
            <span className="text-[#EB4747]">*</span>
          </span>
          <input
            placeholder="Enter Card Number"
            className="cheque-input block w-full appearance-none rounded border border-[#d9d9d9] focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
            type="number"
            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
            onChange={(e) => setCardNumber(e.target.value)}
          />
        </label>
      </div>
      <div className="mt-4">
        <label htmlFor="">
          <span className="payment-cheque-label flex">
            Name on Card
            <span className="text-[#EB4747]">*</span>
          </span>
          <input
            placeholder="Enter Name"
            className="cheque-input block w-full appearance-none rounded border border-[#d9d9d9] focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
            type="text"
            required={true}
            onChange={(e) => setCardName(e.target.value)}
          />
        </label>
      </div>
      <div className="mt-4 flex items-center gap-2 md:gap-10">
        <div>
          <span className="payment-cheque-label flex">
            Validity
            <span className="text-[#EB4747]">*</span>
          </span>
          <div className="flex gap-2">
            <select
              className="cheque-input block w-20 appearance-none rounded border border-[#d9d9d9] focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm md:w-28"
              placeholder="MM"
            >
              <option className="hidden" value="">
                MM
              </option>
              {MonthData.map((month, index) => (
                <option key={index} value={month}>
                  {month}
                </option>
              ))}
            </select>

            <select
              className="cheque-input block w-20 appearance-none rounded border border-[#d9d9d9] focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm md:w-28"
              placeholder="YYYY"
            >
              <option className="hidden" value="">
                YYYY
              </option>
              {YearData.map((year, index) => (
                <option key={index} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <label htmlFor="">
            <span className="payment-cheque-label flex">
              CVV
              <span className="text-[#EB4747]">*</span>
            </span>
            <input
              placeholder="CVV"
              className="cheque-input block w-full appearance-none rounded border border-[#d9d9d9] focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
              type="number"
              onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
              onChange={(e) => setCardCVV(e.target.value)}
            />
          </label>
        </div>
      </div>
    </form>
  );
}

export default CreditCard;
