import { useState, useEffect, useRef } from 'react';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid';

function CustomAccordion({ openSectionTitle, closedSectionTitle, children, className }) {
  const [isOpen, setIsOpen] = useState(false);
  const accordionRef = useRef(null);

  useEffect(() => {
    const handleClickOutsideAccordion = (e) => {
      if (!accordionRef?.current?.contains(e.target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener('click', handleClickOutsideAccordion);

    return () => {
      window.removeEventListener('click', handleClickOutsideAccordion);
    };
  }, []);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`custom_accordion hover:cursor-pointer ${className}`} ref={accordionRef}>
      <div className="custom_accordion_header capitalize" onClick={toggleAccordion}>
        <span>{isOpen ? openSectionTitle : closedSectionTitle}</span>
        {isOpen ? (
          <ChevronUpIcon
            className="custom_accordion_icon"
            style={{
              transition: 'transform 0.3s ease-in-out',
              height: '25px',
              width: '16px',
              color: '#0068A3',
              pointerEvents: 'none',
            }}
          />
        ) : (
          <ChevronDownIcon
            className="custom_accordion_icon"
            style={{
              transition: 'transform 0.3s ease-in-out',
              height: '25px',
              width: '16px',
              color: '#0068A3',
              pointerEvents: 'none',
            }}
          />
        )}
      </div>
      {isOpen && <div className="custom_accordion_content">{children}</div>}
    </div>
  );
}
export default CustomAccordion;
