import React, { useCallback, useEffect, useState } from 'react';
import OrderSummaryItems from './order-summary-items';
import CartSum from './cart-sum';
import ContactDetails from './contact-details';
import { useAccount, useCart } from 'frontastic';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { commercetoolsAPIClient } from 'helpers/utils/axiosRequestsUtil';
import { Order } from '@Types/cart/Order';

const PreviewOrder = () => {
  const { getOrderDetails } = useCart();
  const { account } = useAccount();
  const router = useRouter();
  const [orderData, setOrderData] = useState<Order>();
  const [customOrderData, setCustomOrderData] = useState<any>();
  const reqHeaders = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.localStorage.getItem('token')}`,
    },
  };
  const fetchDetails = useCallback(async () => {
    try {
      const res = await getOrderDetails(router.query.orderID.toString());
      const res2 = await commercetoolsAPIClient.get('/orders/' + router.query.orderID, reqHeaders);
      setOrderData(res);
      setCustomOrderData(res2.data);
    } catch (error) {
      console.log(error);
    }
  }, [router.query.orderID]);

  useEffect(() => {
    if (router.query.orderID) {
      fetchDetails();
    }
  }, []);

  return (
    <>
      {orderData && customOrderData && (
        <div className="checkoutContainer cartWrapper mb-4 ">
          <h1 className="cart-breadcrumb mb-10">
            <NextLink href="/">
              <a className="pdpBreadcrumbItem text-[#0280C7] hover:text-gray-500">
                <span className="mr-3 cursor-pointer font-medium capitalize hover:text-gray-600">Home Page</span>
              </a>
            </NextLink>
            /{' '}
            <NextLink href="/checkout">
              <span className="pdpBreadcrumbItem ml-1 mr-3 cursor-pointer capitalize hover:text-gray-900">
                Checkout
              </span>
            </NextLink>
            /{' '}
            <span className="pdpBreadcrumbItem" style={{ marginLeft: '6px' }}>
              Order Preview
            </span>
          </h1>
          <div>
            <h1 className="order-preview-order-no">Order Num {customOrderData?.orderNumber || 'N/A'}</h1>
            <h1 className="order-preview-ref-no">Reference Number #{customOrderData?.purchaseOrderNumber || 'N/A'}</h1>
          </div>
          <ContactDetails accountData={account} orderData={orderData} />
          <div className="lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-3 2xl:gap-x-7">
            <div className="lg:col-span-8">
              <OrderSummaryItems cartData={orderData} />
            </div>
            <div className="lg:col-span-4">
              <CartSum cartData={orderData} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PreviewOrder;
