import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

function TestimonialSlider({ data }) {
  return (
    <div className="testimonialWrap">
      <Carousel
        interval={4000}
        infiniteLoop
        showStatus={false}
        stopOnHover={true}
        swipeable={true}
        showArrows={true}
        autoPlay={true}
        showThumbs={false}
      >
        <div className="testimonialOuter">
          <div className="testimonialBlock">
            <p className="testimonialPara">{data?.cardContentBox2?.content?.fields?.description}</p>
            <div className="testimonialBottom">
              <div className="testimonalBottomLeft">
                <p className="testimonialBottomText">{data?.cardContentBox2?.content?.fields?.title}</p>
                <img src={data?.cardContentBox2?.content?.fields?.icon1?.fields?.file?.url} alt="" width="110px" />
              </div>
              <div className="testimonalBottomRight">
                <img src={data?.cardContentBox2?.content?.fields?.icon2?.fields?.file?.url} alt="" />
                <p>{data?.cardContentBox2?.content?.fields?.reviewText}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="testimonialOuter">
          <div className="testimonialBlock">
            <p className="testimonialPara">{data?.cardContentBox3?.content?.fields?.description}</p>
            <div className="testimonialBottom">
              <div className="testimonalBottomLeft">
                <p className="testimonialBottomText">{data?.cardContentBox3?.content?.fields?.title}</p>
                <img src={data?.cardContentBox3?.content?.fields?.icon1?.fields?.file?.url} alt="" width="110px" />
              </div>
              <div className="testimonalBottomRight">
                <img src={data?.cardContentBox3?.content?.fields?.icon2?.fields?.file?.url} alt="" />
                <p>{data?.cardContentBox3?.content?.fields?.reviewText}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="testimonialOuter">
          <div className="testimonialBlock">
            <p className="testimonialPara">{data?.cardContentBox4?.content?.fields?.description}</p>
            <div className="testimonialBottom">
              <div className="testimonalBottomLeft">
                <p className="testimonialBottomText">{data?.cardContentBox4?.content?.fields?.title}</p>
                <img src={data?.cardContentBox4?.content?.fields?.icon1?.fields?.file?.url} alt="" width="110px" />
              </div>
              <div className="testimonalBottomRight">
                <img src={data?.cardContentBox4?.content?.fields?.icon2?.fields?.file?.url} alt="" />
                <p>{data?.cardContentBox4?.content?.fields?.reviewText}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="testimonialOuter">
          <div className="testimonialBlock">
            <p className="testimonialPara">{data?.cardContentBox6?.content?.fields?.description}</p>
            <div className="testimonialBottom">
              <div className="testimonalBottomLeft">
                <p className="testimonialBottomText">{data?.cardContentBox6?.content?.fields?.title}</p>
                <img src={data?.cardContentBox6?.content?.fields?.icon1?.fields?.file?.url} alt="" width="110px" />
              </div>
              <div className="testimonalBottomRight">
                <img src={data?.cardContentBox6?.content?.fields?.icon2?.fields?.file?.url} alt="" />
                <p>{data?.cardContentBox6?.content?.fields?.reviewText}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="testimonialOuter">
          <div className="testimonialBlock">
            <p className="testimonialPara">{data?.cardContentBox5?.content?.fields?.description}</p>
            <div className="testimonialBottom">
              <div className="testimonalBottomLeft">
                <p className="testimonialBottomText">{data?.cardContentBox5?.content?.fields?.title}</p>
                <img src={data?.cardContentBox5?.content?.fields?.icon1?.fields?.file?.url} alt="" width="110px" />
              </div>
              <div className="testimonalBottomRight">
                <img src={data?.cardContentBox5?.content?.fields?.icon2?.fields?.file?.url} alt="" />
                <p>{data?.cardContentBox5?.content?.fields?.reviewText}</p>
              </div>
            </div>
          </div>
        </div>
      </Carousel>
    </div>
  );
}

export default TestimonialSlider;
