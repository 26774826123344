import React, { useState, useEffect } from 'react';
import { backendAPIClient } from 'helpers/utils/axiosRequestsUtil';
import { STATIC_GET_VENDOR_DETAILS } from 'helpers/constants/statisURLs';
import { useRouter } from 'next/router';

const VendorDetailsBanner = ({ data }) => {
  const desktopVendorBanner = data?.data?.dataSource?.attributes?.bannerImage?.content?.url
  const mobileVendorBanner = data?.data?.dataSource?.attributes?.bannerBackgroundMobile?.content?.url
  const [vendorInfo, setVendorInfo] = useState(null)
  const router = useRouter();
  const vendorCode = router?.query?.['vendor-code']
  const handleVendorInfo = async () => {
    try {
      const response = await backendAPIClient.get(`${STATIC_GET_VENDOR_DETAILS}/?vendorCode=${vendorCode}`);
      setVendorInfo(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleVendorInfo();
  }, [])
  return (
    <div>
      <div className='lg:grid lg:grid-cols-12 lg:items-start mt-[140px]'>
        <div className="lg:col-start-2 lg:col-span-10 lg:col-end-12 card-breadcrumbs  vendor-bc mt-8">
          <a href="/" style={{ color: '#0280C7' }}>
            Home Page
          </a>
          <a>/</a>
          <a href="/vendor-list" style={{ color: '#0280C7' }}>
            Vendor Listing
          </a>
          <a>/</a>
          <a>Vendor Details</a>
        </div>
      </div>
      <div className='lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-2 2xl:gap-x-3 mx-[20px] lg:mx-[0px] banner-vendor'>
        <p className='lg:col-start-2 lg:col-span-5 vendor-info-name'>{vendorInfo?.value?.vendorName}</p>
      </div>
      <div className='lg:grid lg:grid-cols-12 lg:items-start lg:items-start'>
        <div className="lg:col-start-2 lg:col-span-10 lg:col-end-12">
          <img className='vendor-banner-image' src={desktopVendorBanner} />
          <img className='lg:hidden vendor-banner-mobile' src={mobileVendorBanner} />
        </div>
      </div>
    </div>
  );
};

export default VendorDetailsBanner;