import ProductListInOrderDetails from './product-list';
import OrderDetails from './orderDetails';
import { useState } from 'react';

import NextLink from 'next/link';

const OrderDetailsContainer = ({id}) => {
  const [oderDetails, setOrderDetails] = useState();

  const handleGetOrderDetails = (orderDetails) =>{
    setOrderDetails(orderDetails);
  }
  return (
    <div style={{marginTop:'200px'}}>
      <h1 className="cart-breadcrumb">
       <NextLink href="/">
          <a className="pdpBreadcrumbItem text-[#0280C7] hover:text-gray-500">
            <span className="mr-3 cursor-pointer font-medium capitalize hover:text-gray-600">Home Page</span>
          </a>
        </NextLink>
        /{' '}
        <span className="pdpBreadcrumbItem" style={{ marginLeft: '6px' }}>
          Order History
        </span>
        </h1>
        
    <div className="cart-form mt-5">
      <ProductListInOrderDetails id={id} getOrderDetails={handleGetOrderDetails}/>
      <OrderDetails oderDetails={oderDetails}/>
    </div>
    </div>
  );
};

export default OrderDetailsContainer;
