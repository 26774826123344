import Link from 'next/link';
import { useRouter } from 'next/router';
import { Product } from '@Types/product/Product';
import Typography from 'components/commercetools-ui/typography';
import { SLIDER_PRODUCT_CLICKED } from 'helpers/constants/events';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { Reference, ReferenceLink } from 'helpers/reference';
import { useAccount } from 'frontastic';
import Image, { MediaItemWithMods } from 'frontastic/lib/image';
import { useState } from 'react';
export interface TileProps {
  image?: string;
  title?: string;
  subtitle?: string;
  grade?: string;
  price?: any;
  _url?: string;
  product?: Product;
}

const APITile: React.FC<TileProps> = ({ image, title, subtitle, grade, price, _url, product }) => {
  const handleProductTileAnalytics = (product: Product) => {
    gtag('event', SLIDER_PRODUCT_CLICKED, product);
  };
  const { account } = useAccount();
  const router = useRouter();
  const prepareTitle = (title: string): string => {
    if (title.split(' ').length === 2 && title.length <= 26) {
      return title;
    } else if (title.length >= 42) {
      return `${title.slice(0, 35)}...`;
    }
    return title;
  };

  const mTitle = prepareTitle(title);
  const requestForQuote = () => {
    if (!account) {
      router.push({ pathname: '/login', query: router.asPath });
    } else {
      router.push('quotes/request-for-quote');
    }
  };
  const variantsArray = [...product?.masterData?.current?.variants, product?.masterData?.current?.masterVariant];
  const valuess = variantsArray.map((item) =>
    item?.attributes?.find((itemm) => itemm.name === 'packaging-size'),
  );
  const dosages: Array<any> = [...new Set(product.masterData?.current?.variants?.map((v) => v.attributes['dosage']))];
  const [recentlyproductDosage, setRecentlyProductDosage] = useState<any>(dosages[0]);
  const dosageVariants = product.masterData?.current?.variants?.filter((v) => v.attributes['dosage'] === recentlyproductDosage);
  return (
    <div className="api-tile">
      <div className="api-tile-img-container">
        <img 
        src={image ? image : 'https://drl-allverz-qa.s3.ap-south-1.amazonaws.com/product-images/Custom/NoImagePLP.png'} 
        className="api-tile-img" 
        alt={title} />
      </div>
      <div className="api-tile-divider"></div>
      <div className="api-tile-container">
      { subtitle &&
        <div className="cas-no">
          <p className="category-title">CAS No. :</p>
          {` ${subtitle}`}
        </div>
      }
      {
          product?.masterData?.current?.masterVariant?.attributes['supplier-part-number'] &&
          <div className="cas-no mt-0">
            <p className="category-title">Supplier Part No. :</p>
            {` ${(product?.masterData?.current?.masterVariant?.attributes.find(e => e.name === 'supplier-part-number'))?.value}`}
          </div>
        }
        <Link href={`${_url}`}>
          <h2 className="api-tile-title cursor-pointer">
            {mTitle.endsWith('...') ? (
              <span className="api-title tooltip tooltip-bottom" data-tip={title} style={{ textAlign: 'start' }}>
                {mTitle}
              </span>
            ) : (
              <Typography>{mTitle}</Typography>
            )}
          </h2>
        </Link>

        {grade && <p className={`api-tile-grade ${mTitle.length < 30 && 'mt-[-25px]'}`}>Grade: {grade}</p>}
        {price?.centAmount != 0 ? (
          <p className="api-tile-price">{CurrencyHelpers.formatForCurrency(price)}</p>
        ) : (
          <button className="first_review ask-price-btn flex" onClick={requestForQuote}>
            Ask for price
          </button>
        )}
        <div className="api-tile-weight">
          {/* <select defaultValue="grm">
            <option defaultValue="">100 gm</option>
            <option defaultValue="">200 gm</option>
            <option defaultValue="">300 gm</option>
          </select> */}
          <select id="selected-tab" name="selected-tab" className="">
            {valuess?.map((item, index) => (
              <option key={index} value={item?.value} onChange={() => { }}>
                {item?.value}
              </option>
            ))}
            {dosageVariants?.map((item, index) => (
              <option key={index} value={item?.attributes['packaging-size']} onChange={() => { }}>
                {item.attributes['packaging-size']}
              </option>
            ))}
          </select>
          <Link href={`${_url}`}>
            <button className="api-know-more-btn" onClick={() => handleProductTileAnalytics(product)}>
              Know More
              <img src="/icons/drlIcons/test-next-arrow-new.svg" alt="" />
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default APITile;
