import React, { useState, useRef, useEffect } from 'react';
import { StarIcon } from '@heroicons/react/outline';
import HomeRate from './HomeRate';
import axios from 'axios';
import HomeReviewSuccess from './HomeReviewSuccessModal';
import { commercetoolsAPIClient } from 'helpers/utils/axiosRequestsUtil';
import { STATIC_REVIEWS_URL } from 'helpers/constants/statisURLs';
const imageMimeType = /image\/(png|jpg|jpeg)/i;
import { REVIEW_ID } from 'helpers/constants/envVariables';
import { useAccount } from 'frontastic';
import { FileUpload } from 'components/commercetools-ui/form';
import { useFormat } from 'helpers/hooks/useFormat';
import toast from 'react-hot-toast';

export default function AddHomeReview({ setOpenModal }) {
  const [rating, setRating] = useState(0);
  const [title, setTiltle] = useState('');
  const [description, setDescription] = useState('');
  const [addReview, setAddReview] = useState(null);
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [filename, setFileName] = useState('');
  const [file, setFile] = useState<File>();
  console.log('fileNameeeeeeee', file);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [fileDataURL, setFileDataURL] = useState(null);
  const [showReviewSuccessModal, setShowReviewSuccessModal] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [error, setError] = useState('');
  const [ratingError, setRatingError] = useState('');
  const [sellerName, setSellerName] = useState('');
  const { account } = useAccount();
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });

  const onFileChange = (file: File) => {
    // setIsFileUploaded(false);
    if (!file.type.match(imageMimeType)) {
      alert('Image mime type is not valid');
      return;
    }
    setFile(file);
  };
  const clearUpload = () => {
    setFile(null);
    setIsFileUploaded(false);
  };
  const showAlert = (type: string, id: string, defaultMessage: string) => {
    if (type === 'success')
      toast.success(
        formatCartMessage({ id: 'importProj.fileupload.done', defaultMessage: 'File Uploaded successfully.' }),
      );
    else
      toast.error(
        formatCartMessage({
          id: 'importProj.fileupload.error',
          defaultMessage: 'Something went wrong. Please try again.',
        }),
      );
  };

  useEffect(() => {
    let fileReader,
      isCancel = false;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
        }
      };
      fileReader.readAsDataURL(file);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [file]);

  const handleUploadClick = () => {
    inputRef.current?.click();
    showAlert('success', 'importProj.fileupload.done', 'File Uploaded successfully.');
    setIsFileUploaded(true);
  };

  const handleTitleChange = (e) => {
    setTiltle(e.target.value);
  };
  const handleSellerChange = (e) => {
    setSellerName(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
    if (e.target.value) {
      setError('');
    }
  };
  const reqBody = {
    authorName: 'Anonymous',
    title: title,
    text: description,
    rating: rating,

    customer: {
      typeId: 'customer',
      id: account?.accountId,
    },
    custom: {
      typeId: REVIEW_ID,
      fields: {
        review_images: fileDataURL ? fileDataURL : '',
        vendor_name: sellerName ? sellerName : '',
        //   "review_images": "https://drl-allverz-qa.s3.ap-south-1.amazonaws.com/product-images/API/atoravastion.png"
      },
    },
  };

  const createReview = async () => {
    if (rating > 0 && description) {
      try {
        const response = await commercetoolsAPIClient.post(STATIC_REVIEWS_URL, reqBody, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
          },
        });
        setAddReview(response.data);
        setShowReviewSuccessModal(true);
        setTimeout(() => {
          setOpenModal(false);
        }, 3000);
      } catch (error) {
        console.log(error);
      }
    } else if (rating < 1) {
      setRatingError('Please add the rating');
      return false;
    } else if (!description) {
      setError('Please provide the review');
      return false;
    }
  };

  function CallBack(closeModalValue) {
    if (closeModalValue) {
      setOpenModal(false);
    }

    return (
      <div>
        <p>Message feom child - {closeModalValue}</p>
      </div>
    );
  }
  function ReviewCallBack(ratingValue) {
    if (ratingValue > 0) {
      setRatingError('');
    }
    return (
      <div>
        <p>Message feom child - {ratingValue}</p>
      </div>
    );
  }

  useEffect(() => {
    const body = document.querySelector('body');
    if (body && document.querySelector('.blockBodyScroll')) {
      body.style.overflow = 'hidden';
    }
    return () => {
      if (body) {
        body.style.overflow = 'visible';
      }
    };
  }, []);

  return (
    <>
      <div className="rating_modal blockBodyScroll fixed right-0 top-0  z-[5001] h-full w-full overflow-scroll bg-gray-900 bg-opacity-75">
        <div className="bg-black fixed inset-0 h-full w-full opacity-100" onClick={() => setOpenModal(false)}></div>
        <div className=" add_Review_Popup flex min-h-screen items-center px-4 py-8">
          <div className=" rating_modal_height relative mx-auto  max-h-fit w-full max-w-fit max-w-lg rounded-md bg-white  shadow-lg">
            <div className="plpMobileFilterTextWrap">
              <div className="plpMobileFilterText ml-auto mr-auto lg:mt-[20px]">
                <p className="review_modal_closebtn absolute" onClick={() => setOpenModal(false)}>
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                      fill="#0068A3"
                    />
                  </svg>
                </p>
              </div>
            </div>
            <div className="review_modal_content ml-[30px] mr-[30px] lg:mt-[26px] lg:ml-[47px] lg:mr-[47px]">
              <p className="text-[28px] font-bold text-[#1E1E1E] lg:text-left lg:text-[32px]">Add Review</p>
              <p className="para mt-[27px] text-[14px] font-normal  text-[#1E1E1E] lg:mt-[27px] lg:text-[14px]">
                Add your valuable feedback based on your experience with us
              </p>
              <div className="score text-left">
                <p className="  text-[12px] text-[#1E1E1E]">
                  Score :<span className="ml-1 text-[#EB4747]">* </span> {rating}
                </p>
                <div className="mt-[8px]">
                  <HomeRate rating={rating} onRating={(rate) => setRating(rate)} reviewCallBack={ReviewCallBack} />
                  {ratingError ? <p className="error_login">{ratingError}</p> : ''}
                </div>
              </div>
              <div className="sellerbox">
                <p className="sellerspan text-left">Seller</p>
                <input
                  required
                  type="text"
                  name="seller"
                  id="seller"
                  placeholder="Add Seller Name"
                  autoComplete="given-seller"
                  className="seller review_modal_title bg-[#FFFFFF] font-normal   text-[#1E1E1E] lg:text-[12px]"
                  onChange={handleSellerChange}
                />
              </div>

              <div className="titlespan">
                <p className="title text-left">Title</p>
                <input
                  type="text"
                  name="title"
                  id="title"
                  placeholder="Add Title"
                  autoComplete="given-title"
                  className="review_modal_title bg-[#FFFFFF] font-normal   text-[#1E1E1E] lg:text-[12px]"
                  onChange={handleTitleChange}
                />
              </div>

              <div className="text-left">
                <FileUpload
                  file={file}
                  name="file_upload"
                  label={formatCartMessage({ id: 'importProj.addDocument', defaultMessage: 'Add Document' })}
                  accept=".png, .jpg, .jpeg"
                  isFileUploaded={isFileUploaded}
                  onFileChange={onFileChange}
                  onUpload={handleUploadClick}
                  clearUpload={clearUpload}
                />
              </div>
              <div className="des mt-[2px] text-left">
                <span className="description text-left">Description</span>
                <textarea
                  required
                  name="Description"
                  id="Description"
                  placeholder="Enter a description"
                  autoComplete="given-Description"
                  className="review_description_box"
                  onChange={handleDescriptionChange}
                  rows={3}
                />
              </div>
              {error ? <p className="error_login">{error}</p> : ''}
              <button className="review_adding_button h-[40px] lg:mt-[10px]" onClick={createReview}>
                Add Review
              </button>
              {showReviewSuccessModal && localStorage.getItem('userName') && (
                <HomeReviewSuccess setOpenModal={setShowReviewSuccessModal} handleCallBack={CallBack} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
