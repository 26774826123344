import React, { useEffect, useState } from 'react';
import { STATE_CANCELLED, STATE_DELIVERED, STATE_INITIAL, STATE_TRANSIT } from 'helpers/constants/envVariables';
import { STATIC_CANCEL_ORDER, STATIC_QUOTE_NOTIFICATIONS } from 'helpers/constants/statisURLs';
import { useFormat } from 'helpers/hooks/useFormat';
import { backendAPIClient, commercetoolsAPIClient } from 'helpers/utils/axiosRequestsUtil';
import CustomAccordion from '../accordion/customAccordion';
import AppModal from '../modal/app-modal';

const CancelOrder = ({ setOpenModal, apiResponse, onStatusChange, onStatus }) => {
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const [cancelReasons, setCancelReasons] = useState(null);
  const [status, setStatus] = useState(null);
  const [isCheckedList, setIsCheckedList] = useState(Array(apiResponse.lineItems.length).fill(false));
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const isConfirmButtonDisabled = isChecked && selectedReasons.length > 0;

  const handleCheckboxChange = (index) => {
    const updatedList = [...isCheckedList];
    updatedList[index] = !updatedList[index];
    setIsCheckedList(updatedList);

    const anyCheckboxChecked = updatedList.some((isChecked) => isChecked);
    setIsChecked(anyCheckboxChecked);

    const selectedProduct = apiResponse.lineItems[index];
    if (updatedList[index]) {
      setSelectedProducts((prevSelectedProducts) => [...prevSelectedProducts, selectedProduct]);
    } else {
      setSelectedProducts((prevSelectedProducts) =>
        prevSelectedProducts.filter((product) => product.id !== selectedProduct.id),
      );
    }
  };

  const handleChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedReasons((prevSelectedReasons) => [...prevSelectedReasons, value]);
    } else {
      setSelectedReasons((prevSelectedReasons) => prevSelectedReasons.filter((reason) => reason !== value));
    }
  };

  const getApi = async () => {
    try {
      const response = await commercetoolsAPIClient.get(`custom-objects?where=container%3D%22order-cancel-reason%22`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
      });

      if (response) {
        const cancelReasonsData = response.data;

        const cancelReasons = cancelReasonsData.results[0].value;
        setCancelReasons(cancelReasons);
      } else {
        console.error('Failed to fetch cancel reasons:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  useEffect(() => {
    getApi();
    setStatus(apiResponse?.orderState);
  }, [apiResponse]);

  const reqBody = {
    orderId: apiResponse.id,
    orderVersion: apiResponse.version,
    orderNumber: '123',
    orderStatus: apiResponse.orderState,
    orderCancelReasons: selectedReasons.join(', '),
    orderCancelComments: 'comments for Order',
    lineItems: [],
  };
  selectedProducts.forEach((product) => {
    const lineItem = {
      lineItemId: product.id,
      state: product.state[0]?.state?.id,
      quantity: product.quantity,
    };
    reqBody.lineItems.push(lineItem);
  });
  const confirmCancel = async () => {
    const cartId = apiResponse.id;
    try {
      const response = await backendAPIClient.post(`${STATIC_CANCEL_ORDER}`, reqBody, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
      });
      const statusCode = response.status;
      const orderstatus = response.data.orderState;
      setStatus(orderstatus);
      onStatusChange(orderstatus);
      if (statusCode === 200 || statusCode === 201) {
        setOpenModal(false);
        onStatus(false);
        confirmCancelEmail();
        // setShowQuoteCreateSuccess(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const confirmCancelEmail = async () => {
    try {
      const reqBody = {
        toEmail: 'drlAdmin@yopmail.com',
        subject: 'Order Has Been Cancelled',
        body: `Order Has Been Cancelled Order No: ${apiResponse.orderNumber}`,
        toName: 'DRL Admin',
        fromName: 'DRL',
      };
      const response = await backendAPIClient.post(STATIC_QUOTE_NOTIFICATIONS, reqBody);
    } catch (err) {
      console.error('Send notification failed:', err.message);
    }
  };
  useEffect(() => {
    const body = document.querySelector('body') as HTMLElement;
    const header = document.getElementsByClassName('wrapperMain')[0] as HTMLElement;
    if (body && document.querySelector('.blockBodyScroll')) {
      body.style.overflow = 'hidden';
      header.style.zIndex = '10';
    }
    return () => {
      if (body) {
        body.style.overflow = 'visible';
        header.style.zIndex = '5000';
      }
    };
  }, []);

  const initialStatus = STATE_INITIAL;
  const transitStatus = STATE_TRANSIT;
  const deliveredStatus = STATE_DELIVERED;
  const cancelledStatus = STATE_CANCELLED;

  const LineItemStatus = (statusId) => {
    let statusMessage = '';
    if (statusId === initialStatus) {
      statusMessage = 'Under Process';
    } else if (statusId === transitStatus) {
      statusMessage = 'In Transit';
    } else if (statusId === deliveredStatus) {
      statusMessage = 'Delivered';
    } else if (statusId === cancelledStatus) {
      statusMessage = 'Cancelled';
    }
    return statusMessage;
  };

  return (
    <AppModal setOpenModal={() => setOpenModal(false)}>
      <div className="blockBodyScroll order-cancel rating_modal mx-[10px] mb-[30px] md:mx-[30px] md:mb-[62px] lg:mt-[39px] lg:ml-[63px] lg:mr-[62px]">
        <p className="text-[32px] font-bold text-[#1E1E1E] lg:text-[32px]">
          {formatCartMessage({ id: 'cancellation.heading', defaultMessage: 'Cancel Order' })}
        </p>
        <p className="hidden text-[14px] font-normal leading-5 md:block">
          {formatCartMessage({
            id: 'cancellation.headNote',
            defaultMessage: 'Select the products for cancellation.',
          })}
        </p>
        <div>
          {apiResponse?.lineItems?.map((product, i) => (
            <div key={i} className="cancel-product">
              <div className="flex">
                <input
                  type="checkbox"
                  checked={isCheckedList[i]}
                  onChange={() => handleCheckboxChange(i)}
                  className={
                    product.state[0].state.id === transitStatus ||
                    product.state[0].state.id === deliveredStatus ||
                    product.state[0].state.id === cancelledStatus
                      ? 'disabled-checkbox'
                      : ''
                  }
                  disabled={
                    product.state[0].state.id === transitStatus ||
                    product.state[0].state.id === deliveredStatus ||
                    product.state[0].state.id === cancelledStatus
                  }
                />
                <div className="selected-product">
                  <div className="cancel-product-name flex">
                    <span className="desktop ml-[10px]">{product.name['en-IN']}</span>
                    <span className="mobile">
                      {product.name['en-IN'].length > 18
                        ? `${product.name['en-IN'].substring(0, 12)}...`
                        : product.name['en-IN']}
                    </span>
                    {/*  */}
                  </div>
                </div>
              </div>
              <div className="status-cas flex">
                <p className="cancel-status md:ml-[30px]">{LineItemStatus(product.state[0].state.id)}</p>
                <p className="cancel-cas">{product?.variant?.attributes[0]?.value['en-IN']}</p>
              </div>
            </div>
          ))}
        </div>
        <p className="cancel-reasons text-[14px] font-normal leading-5 md:mt-[25px] md:block">
          {formatCartMessage({ id: 'cancellation.heading', defaultMessage: 'Select the reasons for cancellation :' })}
        </p>
        <div>
          <CustomAccordion
            openSectionTitle="Reasons"
            closedSectionTitle="Reasons"
            className="secondary_search_filter_weight accordion_cancel_item mt-2 capitalize"
          >
            <div className="plpLeftFilterItem plp_country_filter flex items-center justify-between">
              {cancelReasons &&
                Object.keys(cancelReasons).map((key) => (
                  <div key={key}>
                    <label className="plp_country_filter_options">
                      <input
                        type="checkbox"
                        onChange={handleChange}
                        checked={selectedReasons.includes(cancelReasons[key])}
                        value={cancelReasons[key]}
                      />
                      <p className="plp_country_filter_label">{cancelReasons[key]}</p>
                    </label>
                  </div>
                ))}
            </div>
          </CustomAccordion>
        </div>
        <div className="my-2 flex flex-wrap">
          {selectedReasons.map((reason, index) => (
            <span className="selected-filter mt-1 capitalize" key={index}>
              {reason}
            </span>
          ))}
        </div>
        <div className="des mt-[2px] text-left">
          <span className="description text-left">Description</span>
          <textarea
            required
            name="Description"
            id="Description"
            placeholder="Enter a description"
            autoComplete="given-Description"
            className="review_description_box"
            // onChange={handleDescriptionChange}
            rows={3}
          />
        </div>

        <div className="grid">
          <button
            className="btn-stretch btn-blue btn mt-[25px] flex-1"
            onClick={() => confirmCancel()}
            disabled={!isConfirmButtonDisabled}
          >
            {formatCartMessage({ id: 'cancellation.confirm', defaultMessage: 'Confirm' })}
          </button>
          {/* <button
            className="btn-stretch btn-blue btn mt-[25px] flex-1"
            onClick={() => confirmCancel()}
            disabled={!isChecked && selectedReasons.length === 0}
          >
            {formatCartMessage({ id: 'cancellation.confirm', defaultMessage: 'Confirm' })}
          </button> */}

          <button className="btn btn-outline mt-[25px] flex-1" onClick={() => setOpenModal(false)}>
            {formatCartMessage({ id: 'importProj.button.cancel', defaultMessage: 'Cancel' })}
          </button>
        </div>
      </div>
    </AppModal>
  );
};

export default CancelOrder;
