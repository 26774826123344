import React from 'react';
import PreviewOrder from 'components/commercetools-ui/checkout/preview-order';

const PreviewOrderTastic = () => {
  return (
    <div className='mt-[150px]'>
      <PreviewOrder />
    </div>
  );
};

export default PreviewOrderTastic;