import React, { useEffect, useState } from 'react';
import APISlider from '../mostPopularSlider/api-slider';
import { useRouter } from 'next/router';

function RecentlyViewedSlider() {
  const [recentlyViewedProducts, setRecentlyViewedProducts] = useState([]);
  const router = useRouter()
  const path = (router.query.slug && ['cart', 'checkout'].includes(router.query.slug[0])) || null;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setRecentlyViewedProducts(JSON.parse(localStorage.getItem('recently-viewed-product')));
    }
  }, []);
  return (
    <>
      {recentlyViewedProducts && recentlyViewedProducts.length >= 3 ? (
        <div className="mostPopularContainer recentlyViewedContainer bg-[#F3F5FD] ">
          <div className="container mx-auto relative">
            <div className="flex flex-wrap lg:mb-[24px]">
              <div className="w-full">
                <h1 className="most-popular-title">Recently Viewed</h1>
                {recentlyViewedProducts ? (
                  <APISlider recentlyViewedProducts={recentlyViewedProducts} mostPopularTile={false} />
                ) : (
                  <h1>No Products viewed recently</h1>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (<></>)}
    </>
  );
}

export default RecentlyViewedSlider;
