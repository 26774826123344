import { useState } from 'react';
import MostRecentAddressCard from './mostRecentAddressCard';
import SeeAllAddressModal from './seeAllAddressModal';
import AddressHeaderText from './addressHeaderText';
import { useAccount, useCart } from 'frontastic';
import Loader from 'components/commercetools-ui/loader';

const MostRecentAddressContainer = ({ isBillingSameAsDeliveryAddressSelected }) => {
  const { account, updateAddress } = useAccount();
  const { data: cartList, updateCart } = useCart();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isSeeAllAddressModalVisible, setIsSeeAllAddressModalVisible] = useState(false);

  const makeAddressDefault = (addressId) => {
    setLoading(true);
    const selectedAddress = account?.addresses.find((address) => address.addressId === addressId);
    updateCart({ shipping: selectedAddress, billing: cartList?.billingAddress }).finally(() => {
      setLoading(false);
    });
    if (isBillingSameAsDeliveryAddressSelected) {
      updateAddress({ ...selectedAddress, isDefaultShippingAddress: true, isDefaultBillingAddress: true });
    } else {
      updateAddress({ ...selectedAddress, isDefaultShippingAddress: true, isDefaultBillingAddress: false });
    }
  };

  const handleSeeMoreClick = (e) => {
    setIsSeeAllAddressModalVisible(true);
    e.stopPropagation();
  };

  return (
    <>
      {loading && <Loader />}
      <div>
        {isSeeAllAddressModalVisible && (
          <SeeAllAddressModal setOpenModal={setIsSeeAllAddressModalVisible}>
            {[...account?.addresses]
              ?.reverse()
              .sort((a: any, b: any) => {
                return b.isDefaultShippingAddress - a.isDefaultShippingAddress;
              })
              .filter((element) => !element?.isDefaultBillingAddress)
              .map((address, index) => (
                <MostRecentAddressCard address={address} key={index} onClick={makeAddressDefault} isFromModal={true} />
              ))}
          </SeeAllAddressModal>
        )}
        <AddressHeaderText text={'Most Recent Addresses'} onCollapseClick={setIsCollapsed} isCollapsed={isCollapsed} />
        {isCollapsed && (
          <div className="mt-4">
            {!isSeeAllAddressModalVisible &&
              [...account?.addresses]
                ?.reverse()
                .sort((a: any, b: any) => {
                  return b.isDefaultShippingAddress - a.isDefaultShippingAddress;
                })
                .filter((element) => !(!element?.isDefaultShippingAddress && element.isDefaultBillingAddress))
                .slice(0, 5)
                ?.map((address, index) => (
                  <MostRecentAddressCard address={address} onClick={makeAddressDefault} key={index} />
                ))}
            {account?.addresses?.length > 5 && (
              <button
                className="font-bold underline-offset-1"
                style={{ color: '#0280C7' }}
                onClick={handleSeeMoreClick}
              >
                See More Address
              </button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default MostRecentAddressContainer;
