import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { CogIcon } from '@heroicons/react/outline';
import axios from 'axios';

export default function CompanyRegistrationModal({ setOpenModal }) {
  const router = useRouter();

  const onDialogClose = (e) => {
    setOpenModal(false);
  };

  const routeToRegistration = (e) => {
    router.push({ pathname: '/buyer-registration', query: router.asPath });
  };

  return (
    <>
      <div className="fixed right-0 top-0 z-50 h-full w-full bg-gray-900 bg-opacity-75">
        <div className="bg-black fixed inset-0 h-full w-full opacity-100" onClick={() => setOpenModal(false)}></div>
        <div className="plpMobileWrap flex min-h-screen items-center px-4 py-8">
          <div className="company_registration_popup relative  mx-auto max-h-fit w-full max-w-fit max-w-lg rounded-md  bg-white shadow-lg lg:mt-[60px]">
            {/* <div className="relative w-full lg:min-w-[756px] max-w-lg mx-auto bg-white rounded-md shadow-lg h-[400px] lg:h-[644px] plpMobileMain"> */}
            <div className="cogicon plpMobileFilterTextWrap">
              <div style={{ marginTop: '15px', marginLeft: '30px' }}>
                <div style={{ backgroundColor: '#E0F9E2', width: '35px', padding: '4px', borderRadius: '50%' }}>
                  <CogIcon width="28px" />
                </div>
              </div>
            </div>
            <div className=" mx-[30px] lg:mt-[0px]">
              <p className="text-[20px] font-semibold tracking-[1px] text-[#303030] lg:text-[22px]">
                Please complete company registration to complete
              </p>
              {/* <p className="text-[14px] lg:text-[14px] text-[#1E1E1E]  font-normal mt-[27px] lg:mt-[27px]">Add your valuable feedback based on your experience with us</p>
                           <div className="flex text-[12px] text-[#1E1E1E]   font-normal mt-[15px]">Seller : <p className="font-bold">Cipla Pharmaceuticals</p></div> */}

              <div className="mt-[8px] flex">
                <p className="text-center text-[12px] font-medium tracking-[1px] text-[#ABABAB] lg:text-[14px]">
                  Would you like to proceed further?{' '}
                </p>
              </div>
              <div style={{ display: 'flex', marginTop: '25px' }}>
                <button
                  className="btn btn-outline"
                  style={{ flex: 6, marginRight: '6px' }}
                  onClick={() => onDialogClose(false)}
                >
                  No
                </button>
                <button className="btn" style={{ flex: 6, marginLeft: '6px' }} onClick={routeToRegistration}>
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
