import React, { useState } from 'react';

function AccountDropdown({ section, children }) {
  const [currentStep, setCurrentStep] = useState(false);
  return (
    <div className="account-section-wrapper ">
      <ul>
        {/* Render the steps navigation */}

        <li className="payment-dropdown-list ">
          <div>
            <div
              className="flex cursor-pointer flex-row-reverse items-center my-account-accordion"
              onClick={() => setCurrentStep(!currentStep)}
            >
              <span>
                {currentStep ? (
                  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="12" viewBox="0 0 21 12" fill="none">
                    <path
                      d="M18.8687 11.8852L20.6387 10.1152L10.6387 0.115234L0.638672 10.1152L2.40867 11.8852L10.6387 3.65523L18.8687 11.8852Z"
                      fill="#676767"
                    />
                  </svg>
                ) : (
                  <svg
                    className="rotate-180"
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="12"
                    viewBox="0 0 21 12"
                    fill="none"
                  >
                    <path
                      d="M18.8687 11.8852L20.6387 10.1152L10.6387 0.115234L0.638672 10.1152L2.40867 11.8852L10.6387 3.65523L18.8687 11.8852Z"
                      fill="#676767"
                    />
                  </svg>
                )}
              </span>
              <h2 className="account-heading w-full">{section.headline}</h2>
            </div>
            <div>{currentStep && children}</div>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default AccountDropdown;
