import React, { useEffect, useState } from 'react';
import router from 'next/router';
import Loader from 'components/commercetools-ui/loader';
import AccordionBtn from '../../../../../components/commercetools-ui/accordion';
import { updateURLParams, URLParam } from '../../../../../helpers/utils/updateURLParams';
import { useAccount } from 'frontastic';
import { DR_VENDOR_NAME } from 'helpers/constants/constant';
import { checkIfUserIsSSOVerified } from 'helpers/helper';

function FilterDropdown({ facets, facetKey }) {
  const [selected, setSelected] = useState<Record<string, boolean>>({});
  const [loading, setLoading] = useState(false);
  const [selectedVendorFilters, setSeletedVendorFilters]  = useState([]);
  const urlParams = new URLSearchParams(window.location.search);
  const { account, isSSOLoggedIn } = useAccount()
  const query = urlParams.get('q');

  const handleClearFilters = () => {
    setLoading(true);
    const params = [
      {
        key: 'cursor',
        value: 'offset:0',
      }
    ];

    const urlParams = new URLSearchParams(window.location.search);
    const facetFilters = urlParams.get('facetFilters');
    const query = urlParams.get('q');
    const preservedFacets = [
      'productProjections.variants.filterableAttributes.purity',
      'productProjections.variants.filterableAttributes.MOQ',
      'productProjections.variants.filterableAttributes.country-of-origin',
    ];

    if (facetFilters) {
      facetFilters.split(',').forEach((facet) => {
        const facetKey = facet.split(':')[0];
        if (preservedFacets.includes(facetKey)) {
          params.push({ key: 'facetFilters', value: facet });
        }
      });
    }
    
    if((account && !checkIfUserIsSSOVerified(account.email)) || !isSSOLoggedIn) {
      params.push({
        key: 'facetFilters',
        value: `productProjections.variants.filterableAttributes.vendor-name:${DR_VENDOR_NAME}`
      })
    }

    if (query !== null && query !== undefined) {
      params.push({ key: 'q', value: query });
    }

    const currentURL = updateURLParams(params);
    router.push(currentURL);
    setSelected({});
    setSeletedVendorFilters([]);
  };
  useEffect(() => {
    if(!urlParams.get('facetFilters')) {
      handleClearFilters()
    }
  }, [urlParams.get('facetFilters')])
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const facetFilters = urlParams.get('facetFilters');
    if (facetFilters != null && facetFilters.indexOf(',') > -1) {
      facetFilters.split(',').forEach((facet) => {
        setSelected((prevState) => ({ ...prevState, [facet]: true }));
      });
    } else {
      setSelected((prevState) => ({ ...prevState, [facetFilters]: true }));
    }
  }, [facetKey]);

  useEffect(() => {
    setLoading(false);
    const existingFacetFilters = urlParams.get('facetFilters');
    if (!existingFacetFilters) {
      const objectKeys = Object.keys(selected);
      if (objectKeys.length > 0 && objectKeys[0] !== 'null') {
        handleClearFilters();
      }
    }
  }, [router.query]);

  const handleMultiSelect = (value) => {
    const selectedVendorFiltersList = selectedVendorFilters;
    const selectedValue = selected;
    if(selectedVendorFiltersList.includes(value)) {
      const index = selectedVendorFiltersList.indexOf(value);
          if (index !== -1) {
            selectedVendorFiltersList.splice(index, 1);
            delete selectedValue[value];
            setSelected({ ...selectedValue });
          }
          setSeletedVendorFilters([...selectedVendorFiltersList]);
    } else {
    setSeletedVendorFilters([...selectedVendorFilters, `${value}`]);
    setSelected((prevState) => ({ ...prevState, [value]: true }));
    }
  }

  const handleCheckboxChange = (facetValue) => {
    setLoading(true);
    const urlParams = new URLSearchParams(window.location.search);
    const existingFacetFilters = urlParams.get('facetFilters');
    const query = urlParams.get('q');
    const existingSort = urlParams.get('sortby');
    const isFacetSelected = existingFacetFilters?.split(',').includes(facetValue);

    if (isFacetSelected) {
      const updatedFacetFilters = existingFacetFilters
        .split(',')
        .filter((value) => value !== facetValue)
        .join(',');

      const params = [
        { key: 'cursor', value: 'offset:0' },
        { key: 'facetFilters', value: updatedFacetFilters },
      ];
      if (query !== null && query !== undefined) {
        params.push({ key: 'q', value: query });
      }
      if (existingSort) {
        params.push({ key: 'sortby', value: existingSort });
      }

      const currentURL = updateURLParams(params);
      router.push(currentURL);
      setSelected((prevState) => ({ ...prevState, [facetValue]: false }));

      const newSelected = { ...selected };
      delete newSelected[facetValue];
      if (Object.keys(newSelected).length < 2) {
        setSelected(newSelected);
      }
    } else {
      const newFacetFilters = facetValue ? existingFacetFilters + ',' + facetValue : facetValue;

      const params = [
        { key: 'cursor', value: 'offset:0' },
        { key: 'facetFilters', value: facetValue },
      ];
      if (query !== null && query !== undefined) {
        params.push({ key: 'q', value: query });
      }
      if (existingSort) {
        params.push({ key: 'sortby', value: existingSort });
      }
      const currentURL = updateURLParams(params);
      router.push(currentURL);
      setSelected((prevState) => ({ ...prevState, [facetValue]: true }));
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="plpLeftFiltersWrap col-span-1">
        <div className="plpTitleDeck">
          <p className="plpFilterPrimaryText">More Filters</p>
          <p className="plpFilterPrimaryClear" onClick={handleClearFilters}>
            Clear All
          </p>
        </div>
        <div className="plpTitleDeck">
          <button
          className="add-to-cart w-40"
          disabled={selectedVendorFilters.length === 0}
          onClick={() => handleCheckboxChange(selectedVendorFilters)}>
            Apply Filter{' '}
          </button>
        </div>
        {facets.map((accordionData, i) =>
          accordionData.label !== 'productProjections.categorySlugs' &&
            accordionData.label !== 'productProjections.variants.filterableAttributes.purity' &&
            accordionData.label !== 'productProjections.variants.filterableAttributes.MOQ' &&
            accordionData.label !== 'productProjections.variants.filterableAttributes.country-of-origin' &&
            accordionData.label !== 'productProjections.categories.id' ? (
            <AccordionBtn
              openSectionTitle={accordionData.label.split('.').pop().replace('-', ' ')}
              closedSectionTitle={accordionData.label.split('.').pop().replace('-', ' ')}
              className={`plpFilterAccordion capitalize`}
            >
              <div className="plpLeftFilterItem plp_country_filter flex items-center justify-between">
                {accordionData?.terms?.map((filter, i) => {
                  return (
                    <div key={i}>
                      <label className="plp_country_filter_options">
                        <input
                          type="checkbox"
                          value={accordionData.key + ':' +filter.key}
                          checked={selected[accordionData.key + ':' + filter.key] ? true : false}
                          // onChange={(e) => handleCheckboxChange(accordionData.key + ':' + filter.key)}
                          onChange={(e) => handleMultiSelect(e.target.value)}
                        />

                        <p className="plp_country_filter_label">{filter.key}</p>
                      </label>
                    </div>
                  );
                })}
              </div>
            </AccordionBtn>
          ) : null,
        )}
      </div>
    </>
  );
}

export default FilterDropdown;
