export const PLP_PRODUCT_CLICKED = 'PLP: Product Clicked';
export const PLP_PRODUCT_ADDED_TO_CART = 'PLP: Product Added To Cart';
export const SLIDER_PRODUCT_CLICKED = 'Slider: Product Clicked';
export const PDP_PRODUCT_ADDED_TO_CART_AFTER_SEARCH = 'PDP: Product Added To Cart After Search';
export const PDP_PRODUCT_ADDED_TO_CART = 'PDP: Product Added To Cart';
export const PRODUCT_VIEWED = 'Product Viewed';
export const PDP_VIEWED = 'Product Details Page Viewed';
export const PDP_VIEWED_AFTER_SEARCH = 'Product Details Page Viewed After Search';
export const QUICK_VIEW_PRODUCT_ADDED_TO_CART = 'Quickview: Product Added To Cart';
export const QUICK_VIEW_PRODUCT_ADDED_TO_CART_AFTER_SEARCH = 'Quickview: Product Added To Cart After Search';
export const AUTOCOMPLETE_PRODUCT_CLICKED = 'Autocomplete: Product Clicked';
export const FILTER_APPLIED = 'Filter Applied';
export const ABOUT_US_PAGE_VIEWED = 'About Us: Page Viewed';
export const HOW_ALLVERZ_WORKS_PAGE_VIEWED = 'How Allverz Works: Page Viewed';
export const FAQ_PAGE_VIEWED = 'FAQs: Page Viewed';
export const PRIVACY_POLICY_PAGE_VIEWED = 'Privacy Policy: Page Viewed';
export const TERMS_AND_CONDITIONS_PAGE_VIEWED = 'Terms and Conditions: Page Viewed';
export const BLOGS_PAGE_VIEWED = 'Blogs: Page Viewed';
export const SINGLE_BLOG_VIEWED = 'Blogs: Single Blog Viewed';
