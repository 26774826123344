import React, { useState } from 'react';
import Link from 'next/link';
import axios from 'axios';
import { BACKEND_BASE_URL } from 'helpers/constants/envVariables';
import BuyerRegistrationSuccess from './success-buyer-registration';
import CompanyAlreadyRegistered from './company-already-registered';
import { useAccount } from 'frontastic';
import { useRouter } from 'next/router';

const RegistrationBuyer = () => {
  const router = useRouter();
  const [showRegistrationSuccess, setShowRegistrationSuccess] = useState(false);
  const [showCompanyregistered, setShowCompanyregistered] = useState(false);
  const [orgType, SetOrgType] = useState('');
  const [gstNumber, SetGstumber] = useState('');
  const [compName, SetCompName] = useState('');
  const [yourRole, SetYourRole] = useState('');
  const [compAddress, SetCompAddress] = useState<any>({
    country: 'IN',
  });
  const [panNumber, SetPanNumber] = useState('');
  const [tanNumber, SetTanNumber] = useState('');
  const [cinNumber, SetCinNumber] = useState('');
  const [eximNumber, SetEximNumber] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [karzaResponse, setKarzaResponse] = useState<any>();
  const { account } = useAccount();

  const verifyGSTNumber = async () => {
    const karzaPayload = {
      gstin: gstNumber,
      consent: 'Y',
    };
    const urlRegister = `${BACKEND_BASE_URL}/customer/karzaApi`;

    try {
      const response = await axios.post(`${urlRegister}`, karzaPayload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setKarzaResponse(response);
      SetCompName(response?.data?.companyName);
      SetPanNumber(response?.data?.panNumber);
      if (!response?.data?.panNumber) {
        setShowCompanyregistered(true);
      }
      //SetCompAddress(response.data.address);
    } catch (e) {}
  };

  const handleSubmit = async () => {
    if (
      !compName.toString().trim() ||
      !gstNumber.toString().trim() ||
      !yourRole ||
      !panNumber.toString().trim() ||
      !orgType.toString().trim() ||
      !compAddress.toString().trim()
    ) {
      setIsError(true);
      return;
    }
    setIsError(false);
    const formJson = {
      companyName: compName,
      gstinNumber: gstNumber.toString().trim(),
      roleInCompany: yourRole,
      panNumber: panNumber,
      cinLlpin: cinNumber,
      organizationalType: orgType,
      companyAddress: compAddress,
      tanNumber: tanNumber,
      importExportCode: eximNumber,
    };

    const urlRegister = `${BACKEND_BASE_URL}/customer/registration/company?customerId=${account?.accountId}`;

    try {
      const response = await axios.post(`${urlRegister}`, formJson, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setResponseMessage(response.data.message);
      setShowRegistrationSuccess(true);
    } catch (error) {
      console.error('Error making POST request:', error);
    }
  };
  return (
    <div className="sign_in_container sign_up_container flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sign-in sign-up-parent widthAdjustment flex  lg:mr-auto">
        <div className="sign-in-content registration-content w-full bg-white px-3 pb-32 pt-16 2xl:px-6">
          <Link href="/login">
            <span
              className="sign_in_return_btn ml-5 flex cursor-pointer items-center text-accent-400"
              onClick={() => {
                router.push('/checkout');
              }}
            >
              X<p className="pl-2 text-accent-400">Close</p>
            </span>
          </Link>
          <div className="space-y-0 sm:space-y-7">
            <div className="py-6 text-center">
              <h2 className="text-3xl font-extrabold text-neutral-700">Register your Company</h2>
              <h3 className="text-md mt-6 text-neutral-600">
                Enter the details of your Company details for registration
              </h3>
            </div>
            <div className="col-md-12">
              <div className="col-md-6 mt-4 sm:mt-0">
                <label htmlFor="companydetail" className="flex text-sm font-medium text-neutral-700">
                  GSTIN Number
                  <span className="d-block pl-[5px] text-[21px] text-[#dc2626]">*</span>
                </label>
                <div className="relative mt-1">
                  <input
                    name="companyname"
                    type="text"
                    autoComplete="companyname"
                    placeholder="Enter GSTIN Number"
                    required
                    className="gstinImg block w-full appearance-none rounded-sm border border-gray-300 py-2 px-3 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                    onChange={(event) => {
                      SetGstumber(event.target.value);
                    }}
                  />
                  <button
                    disabled={gstNumber === ''}
                    style={{ color: gstNumber === '' ? 'gray' : '' }}
                    className="verifyEmail absolute top-0 right-0 mr-1 mt-3 bg-inherit pl-1 hover:underline"
                    onClick={verifyGSTNumber}
                  >
                    Verify
                  </button>
                </div>
              </div>
              <div className="col-md-6 mt-4 sm:mt-0">
                <label
                  htmlFor="knowAllverz"
                  className=" flex  h-auto items-center text-sm font-medium text-neutral-700"
                >
                  Organizational Type
                  <span className="d-block pl-[5px] text-[21px] text-[#dc2626]">*</span>
                </label>
                <div className="relative mt-1">
                  <select
                    required
                    name="howAllverz"
                    style={{ height: '44px' }}
                    className="howAllverzImg block w-full appearance-none rounded-sm border border-gray-300 py-2 px-3 pl-10 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                    onChange={(event) => {
                      SetOrgType(event.target.value);
                    }}
                  >
                    <option value="Select Source">Select Type</option>
                    <option value="Trader">Trader</option>
                    <option value="Manufacturer">Manufacturer</option>
                    <option value="Distributor">Distributor</option>
                    <option value="Others">Others</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="col-md-6 mt-4 flex flex-col sm:mt-0">
                <div>
                  <label htmlFor="companydetail" className="flex text-sm font-medium text-neutral-700">
                    Company Name
                    <span className="d-block pl-[5px] text-[21px] text-[#dc2626]">*</span>
                  </label>
                  <div className="relative mt-1">
                    <input
                      name="companydetail"
                      type="text"
                      autoComplete="companydetail"
                      placeholder="Enter Company Name"
                      required
                      value={compName}
                      className="companyDetailImg block w-full appearance-none rounded-sm border border-gray-300 py-2 px-3 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                      onChange={(event) => {
                        SetCompName(event.target.value);
                      }}
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="knowAllverz"
                    className=" flex h-auto items-center text-sm font-medium text-neutral-700"
                  >
                    Your role in your company
                    <span className="d-block pl-[5px] text-[21px] text-[#dc2626]">*</span>
                  </label>
                  <div className="relative mt-1">
                    <select
                      required
                      name="howAllverz"
                      style={{ height: '44px' }}
                      className="firstNameImg block w-full appearance-none rounded-sm border border-gray-300 py-2 px-3 pl-10 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                      onChange={(event) => {
                        SetYourRole(event.target.value);
                      }}
                    >
                      <option value="Select Source">Select Role</option>
                      <option value="Owner/Proprietor">Owner/Proprietor</option>
                      <option value="Head - Strategic Sourcing">Head - Strategic Sourcing</option>
                      <option value="Head - Procurement">Head - Procurement</option>
                      <option value="Lead - Strategic Sourcing">Lead - Strategic Sourcing</option>
                      <option value="Lead - Procurement">Lead - Procurement</option>
                      <option value="Member - Strategic Sourcing">Member - Strategic Sourcing</option>
                      <option value="Member - Procurement">Member - Procurement</option>
                      <option value="Others">Others</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="col-md-6 mt-4 sm:mt-0">
                <label htmlFor="firstName" className="flex text-sm font-medium text-neutral-700">
                  Company Address
                  <span className="d-block pl-[5px] text-[21px] text-[#dc2626]">*</span>
                </label>
                <div className="buyer-company-addr mt-1">
                  <textarea
                    required
                    name="Description"
                    id="Description"
                    placeholder="Enter Address"
                    autoComplete="given-Description"
                    className="locationImg buyer-reg-company-addrs block w-full appearance-none rounded-sm border border-gray-300 px-3 py-2 pl-[15px] shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm lg:pl-[40px]"
                    value={karzaResponse?.data?.address}
                    // onChange={(event) => {
                    //   SetCompAddress(event.target.value);
                    // }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="col-md-6 mt-4 sm:mt-0">
                <label htmlFor="companydetail" className="flex text-sm font-medium text-neutral-700">
                  PAN Number
                  <span className="d-block pl-[5px] text-[21px] text-[#dc2626]">*</span>
                </label>
                <div className="mt-1">
                  <input
                    name="companyname"
                    type="text"
                    autoComplete="companyname"
                    placeholder="Enter PAN Number"
                    required
                    className="exportCodeImg block w-full appearance-none rounded-sm border border-gray-300 py-2 px-3 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                    value={panNumber}
                    onChange={(event) => {
                      SetPanNumber(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4 sm:mt-0">
                <label htmlFor="companydetail" className="flex text-sm font-medium text-neutral-700">
                  TAN Number
                </label>
                <div className="mt-1">
                  <input
                    name="companyname"
                    type="text"
                    autoComplete="companyname"
                    placeholder="Enter TAN Number"
                    required
                    className="exportCodeImg block w-full appearance-none rounded-sm border border-gray-300 py-2 px-3 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                    // onChange={handleChange}
                    onChange={(event) => {
                      SetTanNumber(event.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="col-md-6 mt-4 sm:mt-0">
                <label htmlFor="companydetail" className="flex text-sm font-medium text-neutral-700">
                  CIN/LLPIN
                </label>
                <div className="mt-1">
                  <input
                    name="companyname"
                    type="text"
                    autoComplete="companyname"
                    placeholder="Enter CIN/LLPIN "
                    required
                    className="exportCodeImg block w-full appearance-none rounded-sm border border-gray-300 py-2 px-3 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                    onChange={(event) => {
                      SetCinNumber(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4 sm:mt-0">
                <label htmlFor="companydetail" className="flex text-sm font-medium text-neutral-700">
                  Enter Import and Export Code
                </label>
                <div className="mt-1">
                  <input
                    name="companyname"
                    type="text"
                    autoComplete="companyname"
                    placeholder="Enter Import & Export Code"
                    required
                    className="exportCodeImg block w-full appearance-none rounded-sm border border-gray-300 py-2 px-3 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                    onChange={(event) => {
                      SetEximNumber(event.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              {/* <div className="col-md-6  mt-4 sm:mt-0">
                <button
                  type="button"
                  className="flex w-full justify-center rounded-sm border border-accent-400 bg-white py-2 px-4 text-sm font-medium text-accent-400 shadow-sm transition-colors duration-200 ease-out hover:bg-accent-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-accent-400 focus:ring-offset-2 disabled:bg-gray-200"
                  onClick={() => {
                    window.history.back();
                  }}
                >
                  Back
                </button>
              </div> */}
              <div className="col-md-12  mt-4 sm:mt-0">
                <button
                  type="submit"
                  className="register flex w-full justify-center rounded-sm border border-transparent bg-accent-400 py-2 px-4 text-sm font-medium text-white shadow-sm transition-colors duration-200 ease-out hover:bg-accent-500 focus:outline-none focus:ring-2 focus:ring-accent-400 focus:ring-offset-2 disabled:bg-gray-200"
                  onClick={handleSubmit}
                >
                  {/* {formatAccountMessage({ id: 'signup', defaultMessage: 'Sign-up' })} */}
                  {/* {formatAccountMessage({ id: 'register', defaultMessage: 'Register' })} */}
                  Register
                </button>
              </div>
              {showRegistrationSuccess && <BuyerRegistrationSuccess setOpenModal={setShowRegistrationSuccess} />}
              {showCompanyregistered && (
                <CompanyAlreadyRegistered setOpenModal={setShowCompanyregistered} gstNo={karzaResponse?.data?.key} />
              )}
              <div className="col-md-6  mt-4 sm:mt-0">
                {isError && <span style={{ color: 'red', fontSize: '12px' }}>Fields marked with * are mandatory</span>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RegistrationBuyer;
