import React, { useState } from 'react';

export default function QuoteDescription({ descriptionCallBack }) {
  const [description, setDescription] = useState<string>('');

  const handleDescriptionChange = (e) => {
    if (e.target.value) {
      setDescription(e.target.value);
      descriptionCallBack(e.target.value);
    }
  };
  return (
    <div>
      <textarea
        required
        name="Description"
        id="Description"
        placeholder="Enter a Description"
        autoComplete="given-Description"
        className="review_description_box quote_description_box mt-[6px]  text-[12px] 2xl:mt-[20px]"
        onChange={handleDescriptionChange}
      />
    </div>
  );
}
