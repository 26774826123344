import Link from 'next/link';
import React, { useState } from 'react';
import NextLink from 'next/link';
import Loader from 'components/commercetools-ui/loader';
import { useRouter } from 'next/router';

export default function SubCategoryModal({ setOpenModal, subCategories }) {
  const [showL3Category, setShowL3Category] = useState(0);
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const handleL3Category = (item) => {
    router.push(`/${item.slug}`)
  }
  return (
    <>
      <div className="fixed right-0 top-0 z-50 h-full w-full bg-gray-900 bg-opacity-75">
        <div className="bg-black fixed inset-0 h-full w-full opacity-100" onClick={() => setOpenModal(false)}></div>
        <div className="plpMobileWrap flex min-h-screen items-center px-4 py-8">
          <div className="plpMobileMain relative mx-auto h-full w-full max-w-lg rounded-md bg-white shadow-lg" style={{ height: 'auto', paddingBottom: '30px' }}>
            <div className="plpMobileFilterTextWrap">
              <div className="plpMobileFilterText ml-auto mr-auto lg:mt-[20px]">
                <div className="flex">
                  <button onClick={() => setOpenModal(false)} className="text-[22px] font-bold text-[#0068A3]">
                    <svg
                      width="14"
                      height="15"
                      viewBox="0 0 14 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mt-[5px] h-[20px] w-[22px]"
                    >
                      <path
                        d="M13.6667 6.66683L3.52504 6.66683L8.18337 2.0085L7.00004 0.833496L0.333374 7.50016L7.00004 14.1668L8.17504 12.9918L3.52504 8.3335H13.6667V6.66683Z"
                        fill="#0068A3"
                      />
                    </svg>
                  </button>
                  <p className="mr-[15px] ml-[15px] mt-[5px] text-[16px] font-bold text-[#101828]">All Categories</p>
                </div>
                <p className="category_modal_closebtn absolute" onClick={() => setOpenModal(false)}>
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                      fill="#0068A3"
                    />
                  </svg>
                </p>
              </div>
            </div>
            <div className="review_modal_content lg:mt-[26px] lg:ml-[47px]">
              <div className="mt-10 bg-[#EBF5FB]">
                <p className="ml-[20px] pt-[25px] text-[14px] font-semibold leading-[20px] tracking-[1px] text-[#003769]">
                  L2 Heading
                </p>
                <div className="mt-[20px] ml-[20px] mr-[20px] grid grid-cols-2 gap-6 pb-[35px] md:grid-cols-3">
                  {subCategories &&
                    subCategories.map((item, index) => (
                      <p
                        className={
                          showL3Category === index
                            ? 'l2_category_mobile font-mediium selected_l2 text-center text-[12px] tracking-[1px] text-[#182F43]'
                            : 'l2_category_mobile font-mediium text-center text-[12px] tracking-[1px] text-[#182F43]'
                        }
                        key={item.id}
                        onClick={() => setShowL3Category(index)}
                      >
                        {item.name}
                      </p>
                    ))}
                </div>
              </div>
              <div className="mt-[43px]">
                <p className="ml-[20px] text-[14px] font-semibold leading-[20px] tracking-[1px]  text-[#003769]">
                  L3 Heading
                </p>
                <ul role="list" className="mt-[20px] ml-[20px] mr-[20px] flex flex-col items-start gap-6 ">
                  {subCategories &&
                    subCategories.length > 0 &&
                    subCategories[showL3Category].children.map((item, index) => (

                      <li className="sub_category_mobile flex" key={item.id}>
                        <span className="l3_category_mobile font-mediium text-center text-[12px] tracking-[1px] text-[#182F43]">
                          <p onClick={() => {
                            setLoading(true)
                            handleL3Category(item)
                          }}>{item.name}</p>
                        </span>

                        <span className="l3_arrow_mobile" onClick={() => {
                          setLoading(true)
                          handleL3Category(item)
                        }}>❯</span>
                      </li>

                    ))}
                </ul>

                {/* <div className="flex flex-column gap-6 mt-[20px] ml-[20px] mr-[20px] ">
                {subCategories && subCategories[showL3Category].children.map((item, index) => (
                  <div className="flex flex-col justify-evenly">


                      <span className="l3_category_mobile text-[#182F43] text-[12px] font-mediium tracking-[1px] text-center" key={item.id}>
                        <Link href={`/${item.slug}`}>{item.name}</Link>
                      </span>


                     <span className="l3_arrow_mobile">❯</span>
                     </div>
                      ))}
                </div> */}
              </div>
            </div>
            {loading && <Loader />}
          </div>
        </div>
      </div>
    </>
  );
}
