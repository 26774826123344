// components/search.js
import { useContext, useEffect, useState } from 'react';
import algoliasearch from 'algoliasearch/lite';
import RfqHits from 'components/commercetools-ui/request-for-quote/rfqHits';
import { ALGOLIA_APPLICATION_ID, ALGOLIA_APPLICATION_KEY, ALGOLIA_INDEX_NAME } from 'helpers/constants/envVariables';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
import { UserContext } from 'frontastic';
import MobileSearchHits from './mobileSearchHits';
import SearchBox from './searchbox';
import SearchHits from './searchhits';

interface SearchProps {
  searchInputStyle?: string;
  placeHolder?: string;
  endSearch?: any;
  showbutton?: boolean;
  triggerSearch?: boolean;
  isMobileModal?: boolean;
  isRfq?: boolean;
  handleCasCallback?: any;
  isFromQuote?: boolean;
}
const searchClient = algoliasearch(ALGOLIA_APPLICATION_ID, ALGOLIA_APPLICATION_KEY);
export default function AlgoliaSearch({
  searchInputStyle,
  placeHolder,
  showbutton,
  triggerSearch,
  isMobileModal,
  isRfq,
  handleCasCallback,
  isFromQuote = false,
}: SearchProps) {
  const [searchOutput, setSearchOutput] = useState([]);
  const [searchQuery, setSearchQuery] = useState([]);
  const [recentSearchIndex, setRecentSearchIndex] = useState(-1);
  const [hideSearchDropdown, setHideSearchDropdown] = useState(false);
  const { contextValue, setContextValue } = useContext(UserContext);
  const [configureFilters, setConfigureFilters] = useState('');
  useEffect(() => {
    let filterStr = '';
    contextValue?.categoriesIdList?.map((categoryId, index) => {
      filterStr = filterStr.concat(
        `productProjections.categories.id: ${categoryId} ${
          index !== contextValue.categoriesIdList.length - 1 ? 'OR ' : ''
        }`,
      );
    });
    setConfigureFilters(filterStr);
  }, [contextValue]);
  useEffect;
  return (
    <div className="plp-search-bar-input-container relative">
      <InstantSearch searchClient={searchClient} indexName={ALGOLIA_INDEX_NAME}>
        <Configure filters={configureFilters} />
        <SearchBox
          style={searchInputStyle}
          placeHolder={placeHolder}
          searchOutput={searchOutput}
          searchQuery={searchQuery}
          showbutton={false}
          setHideSearchDropdown={setHideSearchDropdown}
          triggerSearch={triggerSearch}
          handleCasCallback={handleCasCallback}
          isFromQuote={isFromQuote}
        />
        {isRfq ? (
          <RfqHits
            setSearchOutput={setSearchOutput}
            setSearchQuery={setSearchQuery}
            setRecentSearchIndex={setRecentSearchIndex}
            hideSearchDropdown={hideSearchDropdown}
            setHideSearchDropdown={setHideSearchDropdown}
            handleCasCallback={handleCasCallback}
          />
        ) : !isMobileModal ? (
          <SearchHits
            setSearchOutput={setSearchOutput}
            setSearchQuery={setSearchQuery}
            setRecentSearchIndex={setRecentSearchIndex}
            hideSearchDropdown={hideSearchDropdown}
            setHideSearchDropdown={setHideSearchDropdown}
          />
        ) : (
          <MobileSearchHits
            setSearchOutput={setSearchOutput}
            setSearchQuery={setSearchQuery}
            setRecentSearchIndex={setRecentSearchIndex}
            hideSearchDropdown={hideSearchDropdown}
            setHideSearchDropdown={setHideSearchDropdown}
          />
        )}
      </InstantSearch>
    </div>
  );
}
