import { useEffect, useMemo, useState, useContext, useRef } from 'react';
import { useRouter } from 'next/router';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useAccount } from 'frontastic';
import { UserContext } from 'frontastic/lib/renderer';
import AddReview from '../addRatingModal';
import PDPCategory from '../pdp-category';
import ProductCertifications from './product-certifications';
import { BACKEND_AMP_BASE_URL, COMMERCETOOLS_BASE_URL } from 'helpers/constants/envVariables';
import axios from 'axios';
import Loader from 'components/commercetools-ui/loader';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { subDays } from 'date-fns';
import { Dialog, RadioGroup, Transition } from '@headlessui/react';
import { useQuery } from '@apollo/client';
import { LOAD_PDP_BREADCRUMB_DATA } from 'components/commercetools-ui/header/graphql-query/mega-menu-graphql';
import { ArrowCircleUpIcon, ArrowCircleDownIcon } from '@heroicons/react/solid'
import { COUNTRY_CODE, CURRENCY_SYMBOL, DEFAULT_PRODUCT_MAX_QTY } from 'helpers/constants/constant';
import EnquireMoreProduct from './enquire-more-product';
import { FiAlertCircle } from 'react-icons/fi';

export default function ProductDetailsSection({
  product,
  variant,
  review,
  starRating,
  handleReviewCallBack,
  handleProductQuantityCallBack,
  handleProductWeightCallBack,
  productQuantityPLP,
  sendMercQuantity,
  sendUserNotes,
  setSelectedProduct,
  productQuantity,
  setProductQuantity,
  setShowErrorPage
}) {
  const { contextValue, setContextValue } = useContext(UserContext);
  const selectedVariantFronHref = product?.variants.find(e => e.sku === window.location.href.split("/").pop());
  const [selectedVariant, setSelectedVariant] = useState(contextValue.selectedVariant ? contextValue.selectedVariant : selectedVariantFronHref);
  const [specifications, setSpecifications] = useState(selectedVariant?.attributes);
  // const specifications = selectedVariant?.attributes;
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [qtyError, setQtyError] = useState('');
  const { account } = useAccount();
  const [plpWeight, setPlpWeight] = useState(contextValue?.variant);
  const [chemicalProduct, setChemicalProduct] = useState(false);
  const [packageValue, setPackageValue] = useState(specifications?.['packaging-size']);
  {
    handleProductWeightCallBack(packageValue);
  }
  const [minimumQuantity, setMinimumQuantity] = useState(1);
  // setProductQuantity(minimumQuantity);
  // const [productQuantity, setProductQuantity] = useState(minimumQuantity);
  const [weightChanged, setWeightChanged] = useState(1);
  const dosages: Array<any> = [...new Set(product.variants.map((v) => v.attributes['dosage']))];
  const [productDosage, setProductDosage] = useState<any>(dosages[0]);
  const dosageVariants = product.variants.filter((v) => v.attributes['dosage'] === productDosage);
  // const [selectedIndexSku, setSelectedIndexSku] = useState(product?.variants[0]?.sku);
  const [selectedIndexSku, setSelectedIndexSku] = useState(window.location.href.split("/").pop());
  const [loading, setLoading] = useState(false);
  const [mercVendor, setMercVendor] = useState(false);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [currency, setCurrency] = useState('');
  const [oldPrice, setOldPrice] = useState(0);
  const [percentageChange, setPercentageChange] = useState<any>(0);
  const [mercQuantity, setMercQuantity] = useState(0);
  const [mercLoader, setMercLoader] = useState(false);
  const [mercUpdatedPrice, setMercUpdatedPrice] = useState(0);
  const [mercUpdatedOldprice, setMercUpdatedOldprice] = useState(0);
  const [expectedDateOfDelivery, setExpectedDateOfDelivery] = useState(undefined);
  const [startDate, setStartDate] = useState(null);
  const [isValidDate, setIsValidDate] = useState(false);
  const [rdd, setRdd] = useState<any>();
  const [selectedPartNumber, setSelectedPartNumber] = useState('');
  const [selectedSize, setSelectedSize] = useState(variant.attributes['packaging-size']);
  const [partNumbers, setPartNumbers] = useState([]);
  const [packagingSizeArray, setPackagingSizeArray] = useState([]);
  const [innerDiameterArray, setinnerDiameterArray] = useState([]);
  const [selectedInnerDiameterSize, setSelectedInnerDiameterSize] = useState([]);
  const [particleSizeArray, setParticleSizeArray] = useState([]);
  const [selectedParticleSize, setSelectedParticleSize] = useState([]);
  const [mroProduct, setMroProduct] = useState(false);
  const [enquireMoreProductPopupVisible, setEnquireMoreProductPopupVisible] = useState(false);

  const [productList, setProductList] = useState([]);
  const [error, setError] = useState('');
  const [callingprodListAPI, setCallingprodListAPI] = useState(false);
  const elementsRef = useRef<HTMLInputElement>(null);

  // code added for merc Api changes
  const punchurl = localStorage.getItem('punchoutUrl');
  const hookUrl = localStorage.getItem('hookUrl');
  const tokenForPunchout = localStorage.getItem('token');
  const tokenCategory = localStorage.getItem('token');

  useEffect(() => {
    setSpecifications(selectedVariant.attributes);
  }, [selectedVariant])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const openEnquireMoreProductPopup = () => setEnquireMoreProductPopupVisible(true);

  const closeEnquireMoreProductPopup = () => setEnquireMoreProductPopupVisible(false);

  const validateDate = () => {
    if (!startDate) {
      setIsValidDate(false);
    }
  };
  const handleDateChange = (date) => {
    date = date?.toLocaleDateString('en-GB') || '';
    setRdd(date);
    setIsValidDate(date ? true : false);
  };

  const handleNotes = (event) => {
    const data = event.target.value;
    setTimeout(() => {
      sendUserNotes(data);
    }, 1000);
  };
  const calculatePercentageChange = (discountedPrice, listedPrice) => {
    const change = discountedPrice - listedPrice;
    const percentage = (Math.abs(change) / listedPrice) * 100;
    const percetageResult = percentage.toFixed(2);
    setPercentageChange(percetageResult);
  };

  const getCategoryData = () => {
    const productID = product?.id;
    const categoryUrl = `${COMMERCETOOLS_BASE_URL}/products/${productID}`;
    axios
      .get(categoryUrl, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${tokenCategory}`,
        },
      })
      .then((response) => {
        const catID = response?.data?.masterData?.current?.categories[0]?.id;
        if (catID) {
          const categorygetUrl = `${COMMERCETOOLS_BASE_URL}/categories/${catID}`;
          axios
            .get(categorygetUrl, {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${tokenCategory}`,
              },
            })
            .then((response) => {
              //  lineItem.categoryKey = response?.data?.key
              product.categoryKey = response?.data?.key;
            })
            .catch((error) => {
              console.error('inner category call error', error);
            });
        }
      })
      .catch((error) => {
        console.error('Error from transfer cart API:', error);
      });
    return;
  };

  useEffect(() => {
    setMercLoader(false);
  }, [oldPrice])

  const fetchData = async () => {
    setLoading(true);
    setMercLoader(true);
    setCallingprodListAPI(true);
    // const timeout = 20000; // 20 seconds in milliseconds
    const { id, variants } = product
    // const vendorId = variants[0]?.attributes['vendor-id'] || variants[0]?.attributes['vendor-code'];
    const vendorId = specifications?.['vendor-id'] || specifications?.['vendor-code'];
    const url = `${BACKEND_AMP_BASE_URL}/api/products/${id}/variants/${selectedIndexSku}?vendorId=${vendorId}`;

    // const timeoutPromise = new Promise<never>((_, reject) =>
    //   setTimeout(() => reject(new Error('Request timed out')), timeout)
    // );

    try {
      const response: any = await
        fetch(url, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${tokenCategory}`,
          },
        });

      // const response: any = await Promise.race([
      //   fetch(url, {
      //     headers: {
      //       'Content-Type': 'application/json',
      //       Authorization: `Bearer ${tokenCategory}`,
      //     },
      //   }),
      //   timeoutPromise
      // ]);

      setCallingprodListAPI(false);
      // Check if the response was successful
      if (response instanceof Response) {
        if (response?.status === 200) {
          // Process the data
          const data = await response.json()
          const prodList = data?.data
          setError(data?.error);
          // const prodList = response?.data?.data
          if (prodList?.length > 0) {
            prodList[0].isEnable = true;
            const product = prodList?.find(p => p.currencyCode === COUNTRY_CODE.INR) || data?.data[0]
            setDiscountedPrice(product?.discountedPrice * 1)
            setCurrency(product?.currencyCode)
            setOldPrice(product?.listedPrice * 1)
            prodList.forEach(el => {
              if (data.cbpCatalog === "Yes" || data.cbpCatalog === null) el.quantityAvailable = DEFAULT_PRODUCT_MAX_QTY;
              el.selectedQty = (el.quantityAvailable ? 1 : 0);
            })
            setProductQuantity(product?.quantityAvailable > 0 ? 1 : 0)
            setProductList(prodList)
          }
          setMercLoader(false);

        } else {
          setMercLoader(false);
          console.error(`HTTP error! Status: ${response.status}`);
          setShowErrorPage(st => true)
        }
      } else {
        setMercLoader(false);
      }
    } catch (error) {
      setShowErrorPage(st => true)
      // Handle other errors, including timeouts
      console.error(error.message);
      setLoading(false);
      setMercLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
    if (specifications?.['vendor-id'] == '507078') {
      const productId = product?.id;
      const sku = selectedVariant?.sku;
      setMercLoader(true);
      setMercVendor(true);
      handleMerckData(productId, sku);
      getCategoryData();
    } else if (specifications?.['vendor-id'] == '535594') {
      const productId = product?.id;
      const sku = selectedVariant?.sku;
      setMercLoader(true);
      setMercVendor(true);
      handleAngeneData(productId, sku);
      getCategoryData();
    }
  }, [selectedIndexSku]);

  const handleMerckData = (productId, sku) => {
    setMercLoader(true);
    // const vendorId = product?.variants[0]?.attributes['vendor-id'];
    const vendorId = specifications['vendor-id'];
    const mercUrl = `${BACKEND_AMP_BASE_URL}/api/products/${productId}/variants/${selectedIndexSku}?vendorId=${vendorId}`;
    axios
      .get(mercUrl, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${tokenForPunchout}`,
        },
      })
      .then((response) => {
        setMercQuantity(response?.data?.quantityAvailable);
        setExpectedDateOfDelivery(response?.data?.expectedDateOfDelivery);
        calculatePercentageChange(response?.data?.discountedPrice, response?.data?.listedPrice);
        product.Edd = response?.data?.expectedDateOfDelivery;
        product.mercQuantity = response?.data?.quantityAvailable;
        sendMercQuantity(response?.data?.quantityAvailable);
        setMercLoader(false);
      })
      .catch((error) => {
        setMercLoader(false);
        console.error('Error from transfer cart API:', error);
      });
    return;
  };
  const handleAngeneData = (productId, sku) => {
    // const vendorId = product?.variants[0]?.attributes['vendor-id'];
    setMercLoader(true);
    const vendorId = specifications['vendor-id'];
    const mercUrl = `${BACKEND_AMP_BASE_URL}/api/products/${productId}/variants/${selectedIndexSku}?vendorId=${vendorId}`;
    axios
      .get(mercUrl, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${tokenForPunchout}`,
        },
      })
      .then((response) => {
        // setDiscountedPrice(response?.data?.discountedPrice);
        // setOldPrice(response?.data?.listedPrice);
        setMercQuantity(response?.data?.quantityAvailable);
        setExpectedDateOfDelivery(response?.data?.expectedDateOfDelivery);
        calculatePercentageChange(response?.data?.discountedPrice, response?.data?.listedPrice);
        product.Edd = response?.data?.expectedDateOfDelivery;
        product.mercQuantity = response?.data?.quantityAvailable;
        product.price.centAmount = response?.data?.discountedPrice;
        sendMercQuantity(response?.data?.quantityAvailable);
        setMercLoader(false);
      })
      .catch((error) => {
        setMercLoader(false);
        console.error('Error from transfer cart API:', error);
      });
    return;
  };

  const handleQuantityChange = (e) => {
    if (isNaN(e) || e == 0 || e == '' || e < minimumQuantity) {
      setQtyError(`Minimum Quantity should be ${minimumQuantity == 0 ? 1 : minimumQuantity}`);
      // setQtyError(`Minimum Quantity should be ${minimumQuantity}`);
      return 0;
    } else {
      setQtyError('');
      setProductQuantity(e);
    }
  };
  const handleProductQuantityChange = (data) => {
    const { val, index, key, maxVal = 0, qty, minus = false } = data;
    const productListData = [...productList];
    const anyOneVarientSelected = productListData.find(el => el.isEnable)
    if (!anyOneVarientSelected) {
      productListData[index].isEnable = true;
      setSelectedProduct(productListData[index])
    }
    if (key === 'selectedQty') {
      if ((val * 1) > maxVal && !minus) {
        return;
      }
      if (productListData[index].isEnable) {
        setProductQuantity(val);
      }
    } else if (key === 'requestedDateOfDelivery') {
      handleDateChange(val);
    } else if (key === 'isEnable') {
      // For Quantity
      productListData.forEach((el, i) => el.isEnable = i === index)
      setProductQuantity(qty);
      setSelectedProduct(productListData[index])
      // for requestedDateOfDelivery
      handleDateChange(productListData[index]['requestedDateOfDelivery']);
    }
    productListData[index][key] = val;
    setProductList(productListData);
    setSelectedProduct(productListData[0]);
  };

  const calPercen = (lessVal, moreVal) => (((lessVal - moreVal) / lessVal) * 100).toFixed(2)

  useMemo(() => {
    if (weightChanged) {
      setTimeout(() => {
        if (specifications) {
          const { MOQ, 'packaging-size': packageSize } = specifications;
          const moqValue = parseInt(MOQ?.split(' ')[0], 10);
          // const moqValue = 1;
          const packageSizeValue = parseInt(packageValue?.split(' ')[0], 10);
          const minQty1 = Math.round(moqValue / (packageSizeValue * 1000));
          const minQty2 = Math.round(moqValue / packageSizeValue);
          const minQty3 = Math.round((moqValue * 1000) / packageSizeValue);
          const MOQINLit = MOQ?.includes('L');
          const MOQINML = MOQ?.includes('ml');
          const MOQINKG = MOQ?.includes('kg');
          const MOQINGMS = MOQ?.includes('gms');
          const PACKINLit = packageValue?.includes('L');
          const PACKINML = packageValue?.includes('ml');
          const PACKINKG = packageValue?.includes('Kg');
          const PACKINGMS = packageValue?.includes('gms');
          if ((MOQINLit && PACKINLit) || (MOQINKG && PACKINKG)) {
            setProductQuantity(minQty2);
            setMinimumQuantity(minQty2);
          } else if ((MOQINML && PACKINGMS) || (MOQINGMS && PACKINGMS)) {
            setProductQuantity(minQty2);
            setMinimumQuantity(minQty2);
          } else if ((MOQINML && PACKINLit) || (MOQINGMS && PACKINKG)) {
            setProductQuantity(minQty1);
            setMinimumQuantity(minQty1);
          } else if ((MOQINLit && PACKINML) || (MOQINKG && PACKINGMS)) {
            setProductQuantity(minQty3);
            setMinimumQuantity(minQty3);
          }
        }
      }, 1000);
      if (productQuantity < minimumQuantity) {
        setQtyError(`Minimum Quantity should be ${minimumQuantity == 0 ? 1 : minimumQuantity}`);
        // setQtyError(`Minimum Quantity should be ${minimumQuantity}`);
      } else {
        setQtyError('');
      }
    }
  }, [weightChanged]);

  useEffect(() => {
    setQtyError((productQuantity < minimumQuantity)
      ? `Minimum Quantity should be ${minimumQuantity == 0 ? 1 : minimumQuantity}`
      : '');
  }, [productQuantity]);

  const router = useRouter();
  const handleProductWeightChange = (e) => {
    router.push({ pathname: `/${product.productId}/p/${dosageVariants[e.target.selectedIndex].sku}` }, undefined, {
      scroll: false,
    });
    setSelectedIndexSku(dosageVariants[e.target.selectedIndex].sku);
    setPackageValue(e.target.value);
    setWeightChanged((current) => current + 1);
    setPlpWeight(e.target.value);
  };
  const handleAddReview = () => {
    if (!account) {
      router.push({ pathname: '/login', query: router.asPath });
    } else {
      setShowReviewModal(true);
    }
  };
  {
    handleProductQuantityCallBack(productQuantity);
  }
  const requestForQuote = () => {
    if (!account) {
      router.push({ pathname: '/login', query: router.asPath });
    } else {
      router.push('quotes/request-for-quote');
    }
  };

  useEffect(() => {
    if (product && product.variants) {
      const partNumbers = product.variants.map((variant) => variant.attributes['supplier-part-number']);
      setPartNumbers(partNumbers);
    }
  }, [product]);

  const getUniqVariants = (variants) => {
    const uniqueData = variants.filter((item, index, self) =>
      index === self.findIndex((t) => (
        t.attributes['packaging-size'] === item.attributes['packaging-size']
      ))
    );
    return uniqueData;
  }

  const handlePartNumber = (e) => {
    setSelectedPartNumber(e.target.value);

    // Find the packaging sizes for the selected part number
    const selectedPartNumberVariants = product.variants.filter(
      (variant) => variant.attributes['supplier-part-number'] === e.target.value,
    );
    const sizes = selectedPartNumberVariants.map((variant) => variant.attributes['packaging-size']);
    if (sizes !== null) {
      setPackagingSizeArray(sizes);
    } else {
      // setPackagingSizeArray(product?.variants[0]?.attributes['packaging-size']);
      setPackagingSizeArray(specifications['packaging-size']);
    }
  };
  const handlePartNumberForMro = (e) => {
    setSelectedPartNumber(e.target.value);

    const selectedPartNumberVariants = product.variants.filter(
      (variant) => variant.attributes['supplier-part-number'] === e.target.value,
    );
    const lengthxinnerDiameter = selectedPartNumberVariants.map(
      (variant) => variant.attributes['lengthxinner-diameter'],
    );
    if (lengthxinnerDiameter !== null) {
      setinnerDiameterArray(lengthxinnerDiameter);
    } else {
      // setinnerDiameterArray(product?.variants[0]?.attributes['lengthxinnerDiameter']);
      setinnerDiameterArray(specifications['lengthxinnerDiameter']);
    }
  };
  const handleInnerDiameterChange = (e) => {
    setSelectedInnerDiameterSize(e.target.value);
    const selectedInnerDiameterVariants = product.variants.filter(
      (variant) =>
        variant.attributes['supplier-part-number'] === selectedPartNumber &&
        variant.attributes['lengthxinner-diameter'] === e.target.value,
    );
    const particleSizes = selectedInnerDiameterVariants.map((variant) => variant.attributes['particle-size']);
    if (particleSizes !== null) {
      setParticleSizeArray(particleSizes);
    } else {
      // setParticleSizeArray(product?.variants[0]?.attributes['particle-size']);
      setParticleSizeArray(specifications['particle-size']);
    }
  };
  const handleParticleSizeChange = (e) => {
    setSelectedParticleSize(e.target.value);
    const selectedPartNumberVariants = product.variants.filter(
      (variant) =>
        variant.attributes['supplier-part-number'] === selectedPartNumber &&
        variant.attributes['lengthxinner-diameter'] === selectedInnerDiameterSize &&
        variant.attributes['particle-size'] === e.target.value,
    );

    if (selectedPartNumberVariants.length > 0) {
      const selectedSKU = selectedPartNumberVariants[0].sku;
      router.push({ pathname: `/${product.productId}/p/${selectedSKU}` }, undefined, {
        scroll: false,
      });
    }
  };
  const handleSelectChange = (e, isPackageSizeChange) => {
    isPackageSizeChange && setSelectedSize(e.target.value);
    const selectedPartNumberVariants = product.variants.filter(
      (variant) =>
        variant.attributes[`${isPackageSizeChange ? 'packaging-size' : 'supplier-part-number'}`] === e.target.value
    );

    if (selectedPartNumberVariants.length > 0) {
      const selectedSKU = selectedPartNumberVariants[0].sku;
      router.push({ pathname: `/${product.productId}/p/${selectedSKU}` }, undefined, {
        scroll: false,
      });

      setSelectedIndexSku(selectedSKU);
    }
    setPackageValue(e.target.value);
    setWeightChanged((current) => current + 1);
    setPlpWeight(e.target.value);
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const option = el.getAttribute('id');
    setSelectedVariant(option);
    setSpecifications(selectedPartNumberVariants[0].attributes);
  };
  // const { data } = useQuery(LOAD_PDP_BREADCRUMB_DATA(product?.variants[0]?.sku));
  const { data } = useQuery(LOAD_PDP_BREADCRUMB_DATA(window.location.href.split("/").pop()));
  useEffect(() => {
    const allowedCategories = ['Biology', 'Chemicals'];
    const categoryLabcolum = 'LabColumns';
    const ancestors = data?.product?.masterData?.current?.categories.map((category) => category.ancestors);
    const flattenedAncestors = ancestors?.flat();
    const ancestorSlugs = flattenedAncestors?.map((ancestor) => ancestor.slug);
    console.log(data);
    const hasMatchingAncestors =
      ancestorSlugs !== null && ancestorSlugs?.some((slug) => allowedCategories.includes(slug));
    const hasMatchingMroAncestors = data?.product?.masterData?.current?.categories[0].slug;
    if (hasMatchingAncestors) {
      setChemicalProduct(true);
    }
    if (hasMatchingMroAncestors === categoryLabcolum) {
      setMroProduct(true);
    }
  }, [chemicalProduct, data]);

  const isOldPriceDiffThanLatest = oldPrice > 0 && oldPrice !== discountedPrice

  return (
    <div>
      {mercLoader && <Loader />}
      {enquireMoreProductPopupVisible && <EnquireMoreProduct handleClose={closeEnquireMoreProductPopup} />}
      <div className="pdp_details">
        <div>

          {/* <div className="pdp_casNo">
            <p className="category-title">CAS No. :</p>
            {variant?.attributes?.['cas-no']}
          </div> */}
          {variant?.attributes?.['cas-no'] &&
            <div className="cas-no">
              <span className="category-title">CAS No. :</span>
              {` ${variant?.attributes?.['cas-no']}`}
            </div>
          }
          {variant?.attributes?.['supplier-part-number'] &&
            <div className="cas-no mt-1 text-wrap">
              <span className="category-title">Supplier Part No. :</span>
              {` ${variant?.attributes?.['supplier-part-number']}`}
            </div>
          }
        </div>
        <p className="pdp_Name tracking-[0.01em] lg:mt-1.5">{product?.name}</p>
        {/* <div className="pdp_Category text-[16px] tracking-[1px] lg:mt-2">
          <PDPCategory productSKU={variant?.sku} productName={product.name} />
        </div> */}
        <div className="md:grid lg:grid-cols-12">
          <div className="col-span-8">
            {variant?.attributes?.['description'] &&
              <div className="product-description mt-5">
                <span className="category-title w-[9.2rem] mt-[-5px]">Description :</span>
                {` ${variant?.attributes?.['description']}`}
              </div>
            }
            {/* <p className="pdp_product_description mt-[10px] lg:mt-3">{product.description}</p> */}

            {variant?.attributes?.['product-certificate'] && (
              <div className="pdp_tags mt-[20px] flex justify-start">
                <p className="pdp_product_Tag ml-1">{variant?.attributes?.['product-certificate']}</p>
              </div>
            )}
            <div className="show-for-big">
              <div className="mt-[30px]">
                <div className="pdp_brand">
                  {variant?.attributes?.grade ? (
                    <>
                      <p className="headingLeft tracking-[1px]">Grade</p>
                      <p className="name tracking-[1px]">{variant?.attributes?.grade}</p>
                    </>
                  ) : null}
                </div>
                <div className="pdp_brand">
                  {variant?.attributes?.purity ? (
                    <>
                      <p className="headingLeft tracking-[1px]">Purity</p>
                      <p className="name tracking-[1px]">{variant?.attributes?.purity}</p>
                    </>
                  ) : null}
                </div>
                <div className="pdp_brand">
                  {variant?.attributes?.['molecular-formula'] ? (
                    <>
                      <p className="headingLeft tracking-[1px]">Molecular Formula</p>
                      <p className="name tracking-[1px]">{variant?.attributes?.['molecular-formula']}</p>
                    </>
                  ) : null}
                </div>
                <div className="pdp_brand">
                  {variant?.attributes?.['chemical-name'] ? (
                    <>
                      <p className="headingLeft tracking-[1px]">Chemical Formula</p>
                      <p className="name tracking-[1px]">{variant?.attributes?.['chemical-name']}</p>
                    </>
                  ) : null}
                </div>
                <div className="pdp_brand">
                  {variant?.attributes?.['shelf-life'] && variant?.attributes?.['shelf-life'] !== 'NA' ? (
                    <>
                      <p className="headingLeft tracking-[1px]">Shelf Life</p>
                      <p className="name tracking-[1px]">{variant?.attributes?.['shelf-life']}</p>
                    </>
                  ) : null}
                </div>
                <div className="pdp_brand">
                  {/* {variant?.attributes?.['lengthxinner-diameter'] ? (
                <>
                  <p className="headingLeft tracking-[1px]">Inner diameter</p>
                  <p className="name tracking-[1px]">{variant?.attributes?.['lengthxinner-diameter']}</p>
                </>
              ) : null} */}
                </div>
                <div className="pdp_brand">
                  {/* {variant?.attributes?.['particle-size'] ? (
                <>
                  <p className="headingLeft tracking-[1px]">Particle Size</p>
                  <p className="name tracking-[1px]">{variant?.attributes?.['particle-size']}</p>
                </>
              ) : null} */}
                </div>
                <div className="pdp_brand">
                  {variant?.attributes?.['pore-size'] ? (
                    <>
                      <p className="headingLeft tracking-[1px]">Pore Size</p>
                      <p className="name tracking-[1px]">{variant?.attributes?.['pore-size']}</p>
                    </>
                  ) : null}
                </div>
                <div className="pdp_brand">
                  {variant?.attributes?.['country-of-origin'] ? (
                    <>
                      <p className="headingLeft tracking-[1px]">Country of Origin</p>
                      <p className="name tracking-[1px]">
                        {/* {variant?.attributes?.['country-of-origin']?.label} */}
                        {variant?.attributes?.['country-of-origin']?.label
                          ? variant?.attributes?.['country-of-origin'].label
                          : 'INDIA'}
                      </p>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="show-for-mobile mt-[30px]">
              {variant?.attributes?.purity ? (
                <p>
                  Purity: <span>{variant?.attributes?.purity}</span>
                </p>
              ) : null}

              <p>
                MoleCular Formula: <span>{variant?.attributes?.['molecular-formula']}</span>
              </p>
              {variant?.attributes?.['chemical-name'] ? (
                <p>
                  Chemical Formula: <span>{variant?.attributes?.['chemical-name']}</span>
                </p>
              ) : null}
              {variant?.attributes?.['shelf-life'] || variant?.attributes?.['shelf-life'] === 'NA' ? (
                <p>
                  Shelf Life: <span>{variant?.attributes?.['shelf-life']}</span>
                </p>
              ) : null}

              <p>
                Country of Origin:{' '}
                <span>
                  {variant?.attributes?.['country-of-origin']?.label
                    ? variant?.attributes?.['country-of-origin'].label
                    : 'INDIA'}
                </span>
              </p>
            </div>

            <div className="pdp_filters pdp-chev-change pdp_filter_des flex-col  sm:mt-[5px] lg:-ml-[5px] lg:mt-[5px] lg:w-auto 2xl:flex-row">
              {variant?.attributes?.dosage && (
                <div>
                  <select
                    id="selected-tab"
                    name="selected-tab"
                    className="pdp_select-tab pdp_search_filter w-[50%] 2xl:w-auto"
                    onChange={(e) => {
                      setProductDosage(e.target.value);
                    }}
                  >
                    {variant?.attributes?.dosage &&
                      dosages.map((item, index) => (
                        <option className="values" key={index} value={item} onClick={() => setProductDosage(item)}>
                          {item}
                        </option>
                      ))}
                  </select>
                </div>
              )}
              {/* {/* new code for mro three drop Dropdown */}
              {mroProduct ? (
                <>
                  <div className="pdp-tile-weight flex" id="scroll-to-product-description">
                    <select
                      id="selected-tab"
                      name="selected-partnumber"
                      className="pdp_search_filter  cursor-pointer 2xl:w-auto"
                      onChange={(e) => handlePartNumberForMro(e)}
                    >
                      <option value="">Select part no</option>

                      {partNumbers
                        ? partNumbers.map((partNumber, index) => (
                          <option key={index} value={partNumber}>
                            {partNumber}
                          </option>
                        ))
                        : null}
                    </select>
                  </div>
                  <div className="pdp-tile-weight flex" id="scroll-to-product-description">
                    <select
                      id="selected-tab"
                      name="selected-packaingSie"
                      className="pdp_search_filter  cursor-pointer 2xl:w-auto"
                      value={selectedInnerDiameterSize}
                      onChange={(e) => handleInnerDiameterChange(e)}
                    >
                      <option value="">Select inner diam</option>

                      {innerDiameterArray
                        ? innerDiameterArray.map((size, index) => (
                          <option key={index} value={size}>
                            {size}
                          </option>
                        ))
                        : null}
                    </select>
                  </div>
                  <div className="pdp-tile-weight flex" id="scroll-to-product-description">
                    <select
                      id="selected-tab"
                      name="selected-packaingSie"
                      className="pdp_search_filter  cursor-pointer 2xl:w-auto"
                      value={selectedParticleSize}
                      onChange={(e) => handleParticleSizeChange(e)}
                    >
                      <option value="">Select particle size</option>

                      {particleSizeArray
                        ? particleSizeArray.map((size, index) => (
                          <option key={index} value={size}>
                            {size}
                          </option>
                        ))
                        : null}
                    </select>
                  </div>
                </>
              ) : chemicalProduct ? (
                <>
                  <div className="pdp-tile-weight flex" id="scroll-to-product-description">
                    <select
                      id="selected-tab"
                      name="selected-packaingSie"
                      className="pdp_search_filter  cursor-pointer 2xl:w-auto"
                      value={selectedSize}
                      onChange={(e) => handleSelectChange(e, true)}
                    >
                      <option value="">Select pack size</option>
                      {/* {packagingSizeArray
                        ? packagingSizeArray.map((size, index) => (
                          <option key={index} value={size}>
                            {size}
                          </option>
                        ))
                        : null} */}
                      {getUniqVariants(product.variants)
                        .map((item, index) => (
                          <option
                            className="values"
                            key={item?.sku}
                            id={item?.sku}
                            defaultValue={item?.attributes['packaging-size']}
                          >
                            {item?.attributes['packaging-size']}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="pdp-tile-weight flex" id="scroll-to-product-description">
                    {/* <select
                      id="selected-tab"
                      name="selected-partnumber"
                      className="pdp_search_filter  cursor-pointer 2xl:w-auto"
                      onChange={(e) => handlePartNumber(e)}
                      value={partNo}
                    >
                      <option value="">Select part no</option>
                      {partNumbers
                        ? partNumbers.map((partNumber, index) => (
                          <option key={index} value={partNumber}>
                            {partNumber}
                          </option>
                        ))
                        : null}
                    </select> */}
                    {product.variants
                      .filter(item => item?.attributes?.['packaging-size'] === selectedSize && item?.attributes['supplier-part-number']).length ?
                      <select
                        id="selected-tab"
                        name="selected-tab"
                        className="pdp_search_filter  cursor-pointer 2xl:w-auto"
                        onChange={(e) => handleSelectChange(e, false)}
                        // value={product.variants[selectedVariants]?.attributes['supplier-part-number']}
                        value={variant?.attributes?.['supplier-part-number']}
                      >
                        {product.variants
                          .map((item, index) => {
                            return item?.attributes?.['packaging-size'] === selectedSize && (
                              <option
                                className="values"
                                key={item?.sku}
                                id={item?.sku}
                                defaultValue={item?.attributes['supplier-part-number']}
                              >
                                {item?.attributes['supplier-part-number']}
                              </option>
                            )
                          }
                          )}
                      </select> : <></>
                    }
                  </div>

                </>
              ) : (
                <div className="pdp-tile-weight flex" id="scroll-to-product-description">
                  <select
                    id="selected-tab"
                    name="selected-tab"
                    className="pdp_search_filter  cursor-pointer 2xl:w-auto"
                    onChange={(e) => handleProductWeightChange(e)}
                    //value={plpWeight}
                    value={packageValue}
                  >
                    {dosageVariants.map((item, index) => (
                      <option
                        className="values text-left"
                        key={index}
                        value={item?.attributes['packaging-size']}
                        onChange={() => { }}
                      >
                        {item?.attributes['packaging-size']}
                      </option>
                    ))}
                  </select>
                </div>
              )
              }

              {/* <div className="qty qty-width">
                <div className="grms-qty grms-qty-pdp mt-[14px] cursor-pointer bg-[white]">
                  <p className="qty-p text-center">Quantity:</p>
                  <button
                    className="qtyminus bg-[white] "
                    aria-hidden="true"
                    onClick={() => productQuantity > minimumQuantity && setProductQuantity(productQuantity - 1)}
                  >
                    &minus;
                  </button>
                  <input
                    className="plp-weight pdp-weight"
                    type="text"
                    name="qty"
                    id="qty"
                    step="1"
                    value={productQuantity || 0}
                    onChange={(e) => handleQuantityChange(e.target.value)}
                  // onChange={() => {}}
                  />
                  <button
                    className="qtyplus bg-[white]"
                    aria-hidden="true"
                    onClick={() => setProductQuantity(productQuantity + 1)}
                  >
                    +
                  </button>
                </div>
              </div> */}
            </div >
          </div >
          <div className="product_details mt-[12px] flex lg:mt-[0px] col-span-4 text-right flex-col">
            <div>
              <div className="price-container flex">
                {<div className={`ml-[${isOldPriceDiffThanLatest ? 0 : 7}rem] text-[28px] text-gray-500 font-bold line-through mr-2`}>
                  {/* {isOldPriceDiffThanLatest && CurrencyHelpers.formatForCurrency(oldPrice * 100)} */}
                  {isOldPriceDiffThanLatest && `${CURRENCY_SYMBOL[currency]}${oldPrice}`}
                </div>}
                <div className="text-[28px] text-[#303030] font-bold">
                  {
                    isOldPriceDiffThanLatest && discountedPrice != 0
                      // ? `${CurrencyHelpers.formatForCurrency(discountedPrice * 100)}/unit`
                      ? (discountedPrice && `${CURRENCY_SYMBOL[currency]}${discountedPrice}/unit`)
                      : (discountedPrice != 0 || product?.price?.centAmount != 0) ?
                        (<div className={` ${!isOldPriceDiffThanLatest ? 'ml-24' : 'ml-[10px]'} text-[28px] text-[#303030] sm:font-bold lg:font-bold`}>
                          {(discountedPrice !== 0 || !product?.price) && `${CURRENCY_SYMBOL[currency]}${discountedPrice || product?.price / 100}/unit`}
                          {/* {CurrencyHelpers.formatForCurrency(discountedPrice && discountedPrice * 100 || product?.price)}/unit */}
                        </div>) :
                        (<button className="first_review flex lg:mt-[8px] mb-4 ml-2" onClick={requestForQuote}>
                          Ask for price
                        </button>)
                  }
                </div>
              </div>
              {oldPrice && oldPrice !== discountedPrice ? (
                <div className="ml-[6rem] mb-2">
                  {
                    // oldPrice > discountedPrice ?
                    //   <p className="price-decrease w-[16rem]">
                    //     <ArrowCircleDownIcon className="h-4 w-5 stroke-[1.5px] mr-2" />
                    //     {'Price Discounted'} By {calPercen(oldPrice, discountedPrice)} %
                    //   </p> :
                    //   <p className="price-increase w-[16rem]">
                    //     <ArrowCircleUpIcon className="h-4 w-4 stroke-[1.5px] mr-2" />
                    //     {'Price Increase'} By {calPercen(discountedPrice, oldPrice)} %
                    //   </p>
                    oldPrice > discountedPrice &&
                    <p className="price-decrease w-[16rem]">
                      <span className='ml-2'>{'Discount - '} {calPercen(oldPrice, discountedPrice)} %</span>
                    </p>
                  }
                </div>
              ) :
                // (discountedPrice != 0 || product?.price?.centAmount != 0) ?
                //   <p className="price-increase w-[16rem] pl-8 ml-24">
                //     Not Discounted Price
                //   </p> :
                null}
            </div>
            <div className={`${review?.length > 0 && 'flex'} ml-[6.3rem] mt-2`}>
              <ProductCertifications starRating={starRating} />
              <div className="product_rating flex w-[16rem]">
                {review?.length > 0 ? (
                  starRating
                ) : (
                  <p className="first_review flex" onClick={handleAddReview}>
                    Be the first one to review
                  </p>
                )}
                {showReviewModal && (
                  <AddReview
                    setOpenModal={setShowReviewModal}
                    sellerName={specifications?.['vendor-name']}
                    productId={product?.id}
                    handleReviewCallBack={handleReviewCallBack}
                    vendorCode={specifications?.['vendor-code']}
                  />
                )}
              </div>
            </div>
          </div>
        </div >


        {/* <div className="flex">
          <div className="mt-2 flex-1 pr-2">
            <p className="headingLeft datepicker-container mt-1 mb-2 tracking-[1px]">
              RDD
              <span className="ml-8 font-semibold">{rdd}</span>
              <DatePicker
                showIcon
                selected={startDate}
                onChange={handleDateChange}
                onBlur={validateDate}
                minDate={subDays(new Date(), -1)}
                placeholderText="(DD.MM.YYYY)"
                dateFormat="dd.MM.yyyy"
                inline={false}
                className={`datepicker border-2 border-blue-200 ${!isValidDate ? 'datepicker-invalid' : ''}`}
                style={{ border: '2px solid blue' }}
              />
            </p>
          </div>
          {expectedDateOfDelivery ? (
            <div className="w-1/2 flex-1">
              <p className="headingLeft mt-2 tracking-[1px]">
                EDD
                <span className="font-semibold">
                  <input type="text" value={expectedDateOfDelivery} className="border-2 border-blue-200" />
                </span>
              </p>
            </div>
          ) : null}
        </div> */}

        {/* Product details table */}
        <div className="flex overflow-x-auto">
          <div className="mt-12 flex-1 pr-2">
            {error && <p>{error}</p>}
            {(callingprodListAPI || productList.length > 0) &&
              <table className="min-w-full border border-gray-300 mt-4">
                <thead>
                  <tr className="bg-gray-100 text-left" style={{ fontSize: '14px', marginLeft: '15px' }}>
                    <th className="border-b py-2 px-4"></th>
                    <th className="border-b py-2 px-4">Location</th>
                    <th className="border-b py-2 px-4">Qty</th>
                    <th className="border-b py-2 px-4">Price/Unit</th>
                    <th className="border-b py-2 px-4">Stock</th>
                    <th className="border-b py-2 px-4">EDD</th>
                    <th className="border-b py-2 px-4">RDD</th>
                  </tr>
                </thead>
                <tbody>
                  {productList.length > 0 &&
                    productList.map((row, index) => (
                      <>
                        <tr key={index} style={{ fontSize: '14px', marginLeft: '15px' }}>
                          <td className="border-b py-2 px-4 text-center">
                            <input
                              type="radio"
                              id="html"
                              name="fav_language"
                              value={row?.isEnable}
                              checked={row?.isEnable || false}
                              onClick={() => handleProductQuantityChange({ val: !row?.isEnable, index, key: 'isEnable', qty: row?.selectedQty })} />

                          </td>
                          <td className="border-b py-2 px-4">{row?.location}</td>
                          <td className="border-b ">
                            <div className="pdp_filters">
                              <div className="qty w-36">
                                <div className="cursor-pointer pl-2">
                                  <button
                                    className={`qtyplus ${(row?.selectedQty <= 1) && '!bg-gray-400'} `}
                                    aria-hidden="true"
                                    onClick={() => row?.selectedQty > 1 && handleProductQuantityChange({ val: row?.selectedQty - 1, index, key: 'selectedQty', maxVal: row?.quantityAvailable, minus: true })}
                                  >
                                    <span className={!row?.selectedQty && '!text-gray-100'}>&minus;</span>
                                  </button>
                                  <span className="pl-5 pr-5"> {row?.selectedQty || 0} </span>

                                  {
                                    row?.selectedQty < row?.quantityAvailable
                                      ? <button
                                        className="qtyplus"
                                        aria-hidden="true"
                                        onClick={() => handleProductQuantityChange({ val: row?.selectedQty + 1 || 1, index, key: 'selectedQty', maxVal: row?.quantityAvailable })}
                                      >
                                        <span>+</span>
                                      </button>
                                      : <button
                                        className="qtyplus"
                                        aria-hidden="true"
                                        onClick={openEnquireMoreProductPopup}
                                      >
                                        <span >
                                          <FiAlertCircle size={13} />
                                        </span>
                                      </button>}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="border-b py-2 px-4">{row?.discountedPrice > 0 ? `${CURRENCY_SYMBOL[row?.currencyCode]} ${row?.discountedPrice}` : 'NA'}</td>
                          <td className={`border-b py-2 px-4 text-${row?.quantityAvailable > 0 ? 'green-500' : 'red-500'}`}>{row?.quantityAvailable > 0 ? `In Stock (${row?.quantityAvailable})` : "Out Of Stock"}</td>
                          <td className="border-b py-2 px-4">{(row?.quantityAvailable > 0 && row?.expectedDateOfDelivery) ? row?.expectedDateOfDelivery : 'NA'}</td>
                          <td className="border-b py-2 px-4">
                            {
                              <DatePicker
                                showIcon
                                ref={elementsRef}
                                selected={row?.requestedDateOfDelivery}
                                value
                                onChange={(e) => handleProductQuantityChange({ val: e, index, key: 'requestedDateOfDelivery' })}
                                dateFormat="dd-MM-yyyy"
                                disabled={row?.quantityAvailable == 0 || !row?.isEnable}
                              />
                            }
                          </td>
                        </tr>
                      </>
                    ))
                    || <p className="pl-4">{callingprodListAPI ? 'Loading' : 'No data found!'}</p>
                  }
                </tbody>
              </table>
            }
          </div>
        </div>

        {/* new custom field added */}
        <div
          className={
            expectedDateOfDelivery ? 'tile changecat col-span-full mt-4' : 'tile changecat col-span-full mt-12'
          }
        >
          <p className="headingLeft tracking-[1px]">Notes:</p>
          <div className="relative mt-1">
            <textarea
              required
              name="description"
              id="Description"
              placeholder="Enter a Description"
              autoComplete="given-Description"
              className="review_description_box quote_description_box mt-[6px]  border-2 border-blue-200 text-[12px]"
              onChange={handleNotes}
            />
          </div>
        </div>
        {/* product Price */}
        {qtyError && <p className="request-quote-error">{qtyError}</p>}
        {/* <div className="mt-7 flex flex-col 2xl:flex-row">
          <div className="flex items-center">
            {mercVendor ? (
              mercQuantity > 0 ? (
                <>
                  <svg
                    width="18"
                    height="14"
                    viewBox="0 0 18 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mt-[4px]"
                  >
                    <path
                      d="M5.80188 10.905L2.30188 7.40499C1.91188 7.01499 1.29188 7.01499 0.901875 7.40499C0.511875 7.79499 0.511875 8.41499 0.901875 8.80499L5.09188 12.995C5.48187 13.385 6.11188 13.385 6.50187 12.995L17.1019 2.40499C17.4919 2.01499 17.4919 1.39499 17.1019 1.00499C16.7119 0.614994 16.0919 0.614994 15.7019 1.00499L5.80188 10.905Z"
                      fill="#30AB30"
                    />
                  </svg>
                  <p className="ml-[6px] text-[16px] font-bold text-[#30AB30]">In Stock</p>
                </>
              ) : (
                <>
                  <svg
                    width="18"
                    height="14"
                    viewBox="0 0 18 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mt-[4px]"
                  >
                    <path
                      d="M5.80188 10.905L2.30188 7.40499C1.91188 7.01499 1.29188 7.01499 0.901875 7.40499C0.511875 7.79499 0.511875 8.41499 0.901875 8.80499L5.09188 12.995C5.48187 13.385 6.11188 13.385 6.50187 12.995L17.1019 2.40499C17.4919 2.01499 17.4919 1.39499 17.1019 1.00499C16.7119 0.614994 16.0919 0.614994 15.7019 1.00499L5.80188 10.905Z"
                      fill="#C70039"
                    />
                  </svg>
                  <p className="ml-[6px] text-[16px] font-bold text-[#C70039]">Out of Stock {mercQuantity}</p>
                </>
              )
            ) : null}
          </div>
        </div> */}
      </div >
    </div >
  );
}
