import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Product } from '@Types/product/Product';
import axios from 'axios';
import Loader from 'components/commercetools-ui/loader';
import Typography from 'components/commercetools-ui/typography';
import { CURRENCY_SYMBOL, DEFAULT_PRODUCT_MAX_QTY } from 'helpers/constants/constant';
import { BACKEND_BASE_URL } from 'helpers/constants/envVariables';
import { useAccount } from 'frontastic';
import ProductCertifications from '../product-details/product-details-components/product-certifications';
import Modal from './addToCartModal';

const CompareProduct: React.FC = () => {
  const [showOnlyDifference, setShowOnlyDifference] = useState(false);
  const [showOnlyDifferenceList, setShowOnlyDifferenceList] = useState([]);
  const [productToBuy, setProductToBuy] = useState({});
  const [addToCartModalOpen, setAddToCartModalOpen] = useState(false);
  const [productList, setProductList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const { account } = useAccount();

  const router = useRouter();
  const encodedArray = typeof router.query.encodedArray === 'string' ? router.query.encodedArray : '';
  const compareProductList = JSON.parse(encodedArray);

  const rowToBeDisplay = {
    Location: 'location',
    'Pack Size': 'packaging-size',
    Purity: 'purity',
    'Supplier Part Number': 'supplier-part-number',
    Form: 'form',
    'Molecular Weight': 'molecular-weight',
    Grade: 'grade',
    Colour: 'color',
    'Melting Point': 'melting-point',
    'Lead Time': 'expectedDateOfDelivery',
  };
  const fetchProductsData = async () => {
    try {
      setLoader(true);
      const productPayload = {
        products: compareProductList,
      };
      const res = await axios.post(`${BACKEND_BASE_URL}/product/compare`, productPayload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setProductList(res.data.products);
      setSelectedLocation(res.data.products.map((r) => 0));
      const keyList = [];
      res.data.products[0].productAttributes[0].map((e) => e.valuesDifferent && keyList.push(e.name));
      setShowOnlyDifferenceList(keyList);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error('Error fetching product data:', error);
    }
  };

  const handleNavigateToPDP = (product) => {
    setLoader(true);
    router.push(
      { pathname: `/${product?.slug}/p/${product?.productAttributes[0].find((e) => e.name === 'skuId')?.value}` },
      undefined,
      {
        scroll: false,
      },
    );
  };

  const handleLocationChanged = (productInd, location, data) => {
    const tempSelectedLocation = [...selectedLocation];
    tempSelectedLocation[productInd] = data.findIndex((i) => i?.location === location) || 0;
    setSelectedLocation([...tempSelectedLocation]);
  };

  const requestForQuote = () => {
    if (!account) {
      router.push({ pathname: '/login', query: router.asPath });
    } else {
      router.push('quotes/request-for-quote');
    }
  };

  useEffect(() => {
    fetchProductsData();
  }, []);
  return (
    <>
      {loader && <Loader />}
      <div className="col-span-6 mt-[12rem] px-5 pb-10">
        {/* {customerCarePopup && <CustomerCarePopup isAnonymousUser={!loggedIn} phoneNumber={countactUsPhoneNumber} onClose={() => setCustomerCarePopup(false)} onSubmit={handleSubmitClick} />} */}
        <div className="card-breadcrumbs">
          <a href="/" style={{ color: '#0280C7' }}>
            Home Page
          </a>
          <a>/</a>
          <a>Compare Product</a>
        </div>
        <h1 className="contactUsHeading">Compare Product</h1>
        {/* <p className="contactUsContent">Comparing Bensezarid Hydrocloride (4)</p> */}
        <div className="grid grid-cols-12">
          <div className="col-span-2">
            <div className="mt-[14rem]">
              <label>
                <input
                  className="radioBtn"
                  type="checkbox"
                  name="userJourney"
                  id="flexRadioDefault1"
                  value="Buyer"
                  onChange={() => setShowOnlyDifference(!showOnlyDifference)}
                  checked={showOnlyDifference}
                />
                {` Show only difference`}
              </label>
            </div>
          </div>
          {productList?.map((product: any, index) => (
            <div className="col-span-2" key={index}>
              <div className="compare-api-tile">
                {
                  <div className="api-tile-img-container">
                    <img
                      src={
                        product?.productAttributes[0]?.find((e) => e.name === 'images')?.value[0]?.url
                          ? product?.productAttributes[0]?.find((e) => e.name === 'images')?.value[0]?.url
                          : '/icons/drlIcons/NoImagePLP.png'
                      }
                      className="compare-api-tile-img"
                      alt={product?.productName['en-IN']}
                    />
                  </div>
                }
                <div className="compare-api-tile-divider"></div>
                <div className="compare-api-tile-container">
                  <div className="api-tile-title cursor-pointer">
                    <Typography>{product?.productName['en-IN']}</Typography>
                  </div>
                  {product.productAttributes.length && (
                    <div className="compare-seller-no">
                      <p className="compare-category-title">Seller:</p>
                      {` ${(product?.productAttributes[0]?.find((e) => e.name === 'vendor-name')).value['en-IN']}`}
                    </div>
                  )}
                  <ProductCertifications starRating={product.rating} />
                </div>
              </div>
            </div>
          ))}
        </div>
        <table className="mt-4 min-w-full border-none">
          <thead>
            <tr className="grid grid-cols-12 text-left">
              <th className="col-span-2 border bg-gray-100 py-1 px-4">CAS No.</th>
              {productList?.map((product: any, index) => (
                <th className="col-span-2 border py-1 px-4" key={index}>
                  {product.productAttributes[0].find((e) => e.name === 'cas-no').value['en-IN']}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.keys(rowToBeDisplay).map((rowKey) => {
              return (
                (!showOnlyDifference ||
                  (showOnlyDifference && showOnlyDifferenceList.includes(rowToBeDisplay[rowKey]))) &&
                ((
                  <tr className="grid grid-cols-12 text-left">
                    <th className="col-span-2 border bg-gray-100 py-1 px-4">{rowKey}</th>
                    {productList?.map((product: any, ind: number) => (
                      <th className="col-span-2 border py-1 px-4 font-light text-gray-400" key={ind}>
                        {rowKey === 'Location' ? (
                          <select
                            id="selected-tab"
                            key={ind}
                            name="selected-tab"
                            className="mt-[4px] h-[35px] w-[200px]"
                            onChange={(e) => {
                              handleLocationChanged(ind, e.target.value, product.data);
                            }}
                          >
                            {product?.data?.map((item, index) => (
                              <option key={index} value={item.location}>
                                {item.location}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <>
                            {rowKey === 'expectedDateOfDelivery' ? (
                              product.data[selectedLocation[ind]]?.expectedDateOfDelivery
                            ) : (
                              <>
                                {typeof product.productAttributes[0].find((e) => e.name === rowToBeDisplay[rowKey])
                                  ?.value === 'object'
                                  ? product.productAttributes[0].find((e) => e.name === rowToBeDisplay[rowKey])?.value[
                                      'en-IN'
                                    ] || '-'
                                  : product.productAttributes[0].find((e) => e.name === rowToBeDisplay[rowKey])
                                      ?.value || '-'}
                              </>
                            )}
                          </>
                        )}
                      </th>
                    ))}
                  </tr>
                ) || <></>)
              );
            })}
            <tr className="grid grid-cols-12 text-left">
              <th className="col-span-2 border bg-gray-100 py-2 px-4">Stock</th>
              {productList?.map((product: any, ind: number) => {
                const quantityAvailable = product?.cbpCatalogue
                  ? DEFAULT_PRODUCT_MAX_QTY
                  : product.data[selectedLocation[ind]]?.quantityAvailable;
                return (
                  <th
                    className={`col-span-2 border py-1 px-4 font-light text-${
                      quantityAvailable > 0 ? 'green-500' : 'red-500'
                    }`}
                    key={ind}
                  >
                    {quantityAvailable > 0 ? `In Stock (${quantityAvailable})` : 'Out Of Stock'}
                  </th>
                );
              })}
            </tr>
            <tr className="grid grid-cols-12 text-left">
              <th className="col-span-2 border bg-gray-100 py-2 px-4">Price</th>
              {productList?.map((product: any, ind: number) => (
                <th className="col-span-2 border py-2 px-4" key={ind}>
                  {product.data[selectedLocation[ind]]?.discountedPrice !== '0.0' ? (
                    `${CURRENCY_SYMBOL[product.data[selectedLocation[ind]]?.currencyCode]} ${
                      product.data[selectedLocation[ind]]?.discountedPrice
                    }/unit`
                  ) : (
                    <button className="first_review ask-price-btn flex" onClick={requestForQuote}>
                      Ask for price
                    </button>
                  )}
                </th>
              ))}
            </tr>
            <tr className="grid grid-cols-12 text-left">
              <th className="col-span-2 border bg-gray-100 py-2 px-4">Action</th>
              {productList?.map((product: any, ind: number) => {
                const quantityAvailable = product?.cbpCatalogue
                  ? DEFAULT_PRODUCT_MAX_QTY
                  : product.data[selectedLocation[ind]]?.quantityAvailable;
                return (
                  <th className="col-span-2 border py-2" key={ind}>
                    <button
                      disabled={quantityAvailable <= 0}
                      className={`compare-add-cart ${
                        quantityAvailable <= 0 && 'cursor-not-allowed border-gray-300 bg-gray-300 text-white'
                      }`}
                      onClick={() => {
                        if (quantityAvailable > 0) {
                          setProductToBuy({ ...product, selectedLoc: selectedLocation[ind] });
                          setAddToCartModalOpen(true);
                        }
                      }}
                    >
                      Add To Cart
                    </button>
                    <button className={`compare-know-more`} onClick={() => handleNavigateToPDP(product)}>
                      Know More
                    </button>
                  </th>
                );
              })}
            </tr>
          </tbody>
        </table>

        {addToCartModalOpen && <Modal setOpenModal={() => setAddToCartModalOpen(false)} product={productToBuy} />}
      </div>
    </>
  );
};

export default CompareProduct;
