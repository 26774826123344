import Image from 'frontastic/lib/image';
import { useFormat } from 'helpers/hooks/useFormat';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import Typography from 'components/commercetools-ui/typography';

const OrderSummaryItem = ({ item }) => {
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  return (
    <div className="order-summary-checkout flex">
      <div className="summary-item-image">
        <Image
          src={item?.variant?.images[0]}
          alt={item?.name}
          className="ordered-item-image"
          //onClick={() => goToProductPage(lineItem._url)}
        />
      </div>
      <div className="ordered-item-details pl-[11px] lg:pl-[20px]">
        <p className="ordered-item-casNo">{item?.variant?.attributes?.['cas-no']}</p>
        <p className="ordered-item-name">{item?.name}</p>
        {item?.variant?.attributes?.dosage && (
          <p className="ordered-item-dosage">
            <span>{formatProductMessage({ id: 'dosage', defaultMessage: 'Dosage' })}: </span>
            <span className="font-semibold">{item?.variant?.attributes?.dosage}</span>
          </p>
        )}
        <div className="flex">
          <div>
            <p className="ordered-item-dosage max-w-[200px]">
              <span>{formatProductMessage({ id: 'vendor-name', defaultMessage: 'Vendor' })}: </span>
              <span className="font-semibold">{item?.variant?.attributes?.['vendor-name']}</span>
            </p>
            <p className="ordered-item-dosage">
              <span>{formatProductMessage({ id: 'pack-size', defaultMessage: 'Pack Size' })}: </span>
              <span className="font-semibold">{item?.variant?.attributes?.['packaging-size']}</span>
            </p>
            <p className="ordered-item-dosage ordered-item-quantity">
              <span>Quantity: </span>
              <span className="font-semibold">{item?.count}</span>
            </p>
          </div>
          <p className="ordered-item-price item-price-mobile">
            <Typography>{CurrencyHelpers.formatForCurrency(item?.count * item?.prices?.centAmount)}</Typography>
          </p>
        </div>
        <p className="ordered-item-price lg:hidden">
          <Typography>{CurrencyHelpers.formatForCurrency(item?.prices?.centAmount)}</Typography>
        </p>
      </div>
    </div>
  );
};
export default OrderSummaryItem;
