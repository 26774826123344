import { useState, useEffect, useCallback } from 'react';
// import ReadCard from './readCard';
import Link from 'next/link';
import Markdown from '../../content/markdown';
import { SINGLE_BLOG_VIEWED } from 'helpers/constants/events';

function BlogDetail({ blog }) {
  const handleSingleBlogAnalytics = useCallback(async () => {
    gtag('event', SINGLE_BLOG_VIEWED, blog);
  }, [blog]);
  useEffect(() => {
    handleSingleBlogAnalytics();
  }, [handleSingleBlogAnalytics]);
  return (
    <div className="container mx-auto px-5 md:px-0 lg:px-0 ">
      <div className="blog-article-body">
        <div className="card-breadcrumbs">
          <a href="/" style={{ color: '#0280C7' }}>
            Home Page
          </a>
          <p>/</p>
          <Link href="/blogs">Blogs</Link>
          <p>/</p>
          <Link href={'#'}>
            <p>{blog?.title?.content}</p>
          </Link>
        </div>
        <div className="blog-article-header">
          <h2 className="article-heading">{blog?.title?.content}</h2>
        </div>
        <div className="article-img">
          <img
            loading="lazy"
            src={blog?.featuredImage?.content?.url}
            className="card-image blogs-detail-image"
            alt="pic"
          />
        </div>
        <div className="article-description">
          <Markdown text={blog?.description.content} />
        </div>

        <br />
        <br />
      </div>
    </div>
  );
}

export default BlogDetail;
