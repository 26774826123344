import React ,{useState} from 'react';
import PaymentDropdown from './payment-dropdown';
import Cheque from './payment-methods/cheque';
import CreditCard from './payment-methods/credit-card';
import NetBanking from './payment-methods/net-banking';
import UPI from './payment-methods/upi';
import Link from 'next/link';
import { useCart } from 'frontastic';
import POGeneratedSuccess from './po-generated-success';

function Payment() {
  const { data: cartList, removeItem } = useCart();
  const [showPoGenerateSuccess, setShowPoGenerateSuccess] = useState<boolean>(false);
  const dropdownData = [
    {
      label: 'Cheque',
      component: <Cheque />,
    },
    {
      label: 'UPI',
      component: <UPI />,
    },
    {
      label: 'Credit/Debit Card',
      component: <CreditCard />,
    },
    {
      label: 'Net Banking',
      component: <NetBanking />,
    },
  ];
  const handleGoToPayment = async () => {
    const cartItems = [];
    cartList?.lineItems?.forEach((element) => {
      cartItems.push(element.lineItemId);
    });
    setShowPoGenerateSuccess(true)
    await removeItem(cartItems);
  };
  return (
    <>
      <div className="payment-container">
        <h1 className="payment-heading">Payment Details</h1>
        <p className="payment-subheading">
          Payment Mode<span className="text-[#EB4747]">*</span>
        </p>
        <div>
          {dropdownData.map((item, index) => (
            <PaymentDropdown key={index} label={item.label} component={item.component} />
          ))}
        </div>
      </div>
      <div className="payment-button-wrapper">
        <button className="headerEnquirybtn !m-0 !p-0">
          <Link href={'/checkout'}>Back to Checkout</Link>
        </button>
        <button className="headerAccBtn !m-0 !p-0" onClick={() => handleGoToPayment()}>
          Continue to Pay
        </button>
        {showPoGenerateSuccess && (
                <POGeneratedSuccess setOpenModal={setShowPoGenerateSuccess} />
              )}
      </div>
    </>
  );
}

export default Payment;
