import React from 'react';
import { useAccount } from 'frontastic';
import { useRouter } from 'next/router';

const VendorRequestQuoteBanner = ({ data }) => {
  const bannerAttributes = data?.data?.dataSource?.attributes;
  const bannerTitle = bannerAttributes?.bannerTitle?.content;
  const bannerButton = bannerAttributes?.bannerCta1Text?.content;
  const { account } = useAccount();
  const router = useRouter();
  const requestForQuoteUrl = () => {
   if (!account) {
      router.push({pathname:'/login',query: router.asPath});
    } else {
      router.push('quotes/request-for-quote')
    }
  };
  return (
    <div className="plp_bottom_banner vendor-quote-banner" style={{backgroundImage : `url(${bannerAttributes?.bannerImage?.content?.url})`}}>
    <div className="banner_title vendor_banner_title">{bannerTitle}</div>
    <button className="banner_button vendor_banner_button" onClick={requestForQuoteUrl}>
      {bannerButton}
    </button>
  </div>
  );
};

export default VendorRequestQuoteBanner;