import React, { useEffect, useState } from 'react';
import { useAccount } from 'frontastic';
import { useRouter } from 'next/router';

const ContentBottomBanner = ({ data }) => {
  const bannerAttributes = data?.data?.dataSource?.attributes;
  const bannerTitle = bannerAttributes?.bannerTitle?.content;
  const bannerButton = bannerAttributes?.bannerCta1Text?.content;
  const account = useAccount();
  const { loggedIn } = useAccount();
  const router = useRouter();
  const requestForQuoteUrl = () => {
    if (!loggedIn) {
      router.push({ pathname: '/login', query: router.asPath });
    } else {
      router.push('quotes/request-for-quote');
    }
  };

  return (
    <>
      <div className="plp_bottom_banner">
        <div className="banner_title">{bannerTitle}</div>
        <button className="banner_button" onClick={requestForQuoteUrl}>
          {bannerButton}
        </button>
      </div>
    </>
  );
};

export default ContentBottomBanner;
