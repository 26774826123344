import { useState, Fragment, useEffect, useRef } from 'react';
import { CogIcon } from '@heroicons/react/outline';
import { TextBox, UploadFile } from 'components/commercetools-ui/common';
import { STATIC_BANK_CHEQUE_UPLOAD } from 'helpers/constants/statisURLs';
import { backendAPIClient } from 'helpers/utils/axiosRequestsUtil';
import toast from 'react-hot-toast';
import { useAccount } from 'frontastic';
import { AC_NUMBER_ICON, COMPANY_ROLE_ICON, BANK_NAME_ICON, COMPANY_DETAIL_NUMBER_ICON } from '../../icons';

export interface AddAccountProps {
  open?: boolean;
  onClose?: ({}) => void;
}

const AddAccount: React.FC<AddAccountProps> = ({ open, onClose }) => {
  const modalRef = useRef();
  const [file, setFile] = useState<File>();
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [fields, setFields] = useState({});
  const [isAcHolderNameValid, setIsAcHolderNameValid] = useState(true);
  const [isBankAccountNumberValid, setIsBankAccountNumberValid] = useState(true);
  const [isBankNameValid, setIsBankNameValid] = useState(true);
  const [isIFSCValid, setIsIFSCValid] = useState(true);
  const [isVatValid, setIsVatValid] = useState(true);

  const { account } = useAccount();

  const updateValues = (receivedValues) => {
    setFields({ ...fields, ...receivedValues });
  };

  const onFileChange = (file: File) => {
    setIsFileUploaded(false);
    setFile(file);
  };

  const onUploadFile = async (file: File) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('customerId', account?.accountId);

      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      await backendAPIClient.post(STATIC_BANK_CHEQUE_UPLOAD, formData, { headers }).then((res) => {
        const x = { ...fields };
        x['canceledCheque'] = res?.data;
        setFields({ ...x });
        setIsFileUploaded(true);
      });
    } catch (err) {}
  };

  const clearUpload = () => {
    setFile(null);
    setIsFileUploaded(false);
  };

  const handleAddAccount = () => {
    setIsAcHolderNameValid(
      fields['accountHolderName'] === undefined ? false : fields['accountHolderName'].trim() === '' ? false : true,
    );
    setIsBankAccountNumberValid(!!fields['accountNumber']);
    setIsBankNameValid(fields['bankName']?.trim() !== '');
    setIsIFSCValid(!!fields['ifscCode']);
    setIsVatValid(!!fields['vatNumber']);
    if (!file) {
      toast.error('Please attach the file');
    } else if (!isFileUploaded) {
      toast.error('Please upload the file');
    }

    if (
      fields['accountHolderName']?.trim() !== '' &&
      isBankAccountNumberValid &&
      fields['bankName']?.trim() !== '' &&
      isIFSCValid &&
      isVatValid &&
      isFileUploaded
    ) {
      onClose(fields);
    }
  };
  useEffect(() => {
    const body = document.querySelector('body') as HTMLElement;
    const header = document.getElementsByClassName('wrapperMain')[0] as HTMLElement;
    if (body && document.querySelector('.blockBodyScroll')) {
      body.style.overflow = 'hidden';
      header.style.zIndex = '10';
    }
    return () => {
      if (body) {
        body.style.overflow = 'visible';
        header.style.zIndex = '5000';
      }
    };
  }, []);

  return (
    <div className="blockBodyScroll add-account-modal-container" ref={modalRef}>
      <div className="add-account-modal">
        <div style={{ marginTop: '24px' }}>
          <div style={{ backgroundColor: '#E0F9E2', width: '35px', padding: '4px', borderRadius: '50%' }}>
            <CogIcon width="28px" />
          </div>
        </div>
        <div style={{ marginTop: '10px' }}>
          <h1 className="add-acc-h1">Add Bank Account</h1>
          <p className="add-acc-h2">Please add in your bank details to proceed</p>
        </div>
        <div
          className="add-bank-account"
          style={{ marginTop: '10px', maxHeight: '30vh', overflowY: 'auto', paddingRight: 5 }}
        >
          <TextBox
            id="accountHolderName"
            label="A/C Holder Name"
            placeholder="A/C Holder Name"
            onChane={updateValues}
            iconName={COMPANY_ROLE_ICON}
            isValid={isAcHolderNameValid}
            validation="onlyAlphabetsAndSpace"
          />
          <TextBox
            id="accountNumber"
            label="Bank AC Number"
            type="number"
            placeholder="Bank AC Number"
            onChane={updateValues}
            iconName={AC_NUMBER_ICON}
            isValid={isBankAccountNumberValid}
          />
          <TextBox
            id="bankName"
            label="Bank Name"
            placeholder="Bank Name"
            onChane={updateValues}
            iconName={BANK_NAME_ICON}
            isValid={isBankNameValid}
            validation="onlyAlphabetsAndSpace"
          />
          <TextBox
            id="ifscCode"
            label="IFSC Code"
            placeholder="IFSC Code"
            onChane={updateValues}
            iconName={COMPANY_DETAIL_NUMBER_ICON}
            isValid={isIFSCValid}
            validation="onlyAlphabetsAndDigits"
          />
          <TextBox
            id="vatNumber"
            label="VAT Number"
            type="number"
            placeholder="VAT Number"
            onChane={updateValues}
            iconName={COMPANY_DETAIL_NUMBER_ICON}
            isValid={isVatValid}
            value=""
          />
        </div>
        <div>
          <label className="account-editable-fields acc-new relative">
            <span>
              Cancelled Cheque <span className="ml-1 text-[#EB4747]">*</span>
            </span>
            <UploadFile
              file={file}
              name="file_upload"
              label="Only .jpg and .png files. 5MB max file size."
              isFileUploaded={isFileUploaded}
              onFileChange={onFileChange}
              onUpload={onUploadFile}
              clearUpload={clearUpload}
              forQuote={true}
            />
          </label>
        </div>
        <div style={{ display: 'flex', marginTop: '25px' }}>
          <button className="btn btn-outline" style={{ flex: 6, marginRight: '6px' }} onClick={() => onClose(null)}>
            Cancel
          </button>
          <button className="btn" style={{ flex: 6, marginLeft: '6px' }} onClick={handleAddAccount}>
            Add Account
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddAccount;
