import { Account } from '@Types/account/Account';
import { Cart } from '@Types/cart/Cart';
import { COMPANY_ROLE_ICON, MAIL_ICON, PHONE_ICON } from 'components/commercetools-ui/account/details/icons';
import React from 'react';

export interface Props {
  accountData: Account;
  orderData: any;
}

function ContactDetails({ accountData, orderData }: Props) {
  const formatDate = (unformattedDate) => {
    const date = new Date(unformattedDate);
    return (
      (date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)) +
      '/' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
      '/' +
      date.getFullYear()
    );
  };
  return (
    <div className="order-preview-contact-details-wrapper">
      <div className="flex flex-col">
        <p className="order-preview-contact-details-heading">Contact Details</p>
        <p className="order-preview-contact-details-text">
          <span className="flex items-center">
            <span className="mr-2">{COMPANY_ROLE_ICON}</span>
            {accountData?.firstName} {accountData?.lastName}
          </span>
        </p>
        <p className="order-preview-contact-details-text">
          <span className="flex items-center">
            <span className="mr-2">{MAIL_ICON}</span>
            {accountData?.email || 'N/A'}
          </span>
        </p>
        <p className="order-preview-contact-details-text">
          {' '}
          <span className="flex items-center">
            <span className="mr-2">{PHONE_ICON}</span>
            {accountData?.phone_number || 'N/A'}
          </span>
        </p>
        
      </div>
      <span className="divider !m-0 md:hidden"></span>
      <div className="flex max-w-prose flex-col">
        <p className="order-preview-contact-details-heading">Order Date</p>
        <p className="order-preview-contact-details-text">{formatDate(orderData?.createdAt)}</p>
        <span className="divider !m-0 md:hidden"></span>
       {/* <p className="order-preview-contact-details-heading">Payment Method</p>
        <p className="order-preview-contact-details-text">NEFT</p>*/}
      </div>
      <span className="divider !m-0 md:hidden"></span>

      <div className="flex max-w-xs flex-col">
        <p className="order-preview-contact-details-heading">Billing Address</p>
        <p className="order-preview-contact-details-text">
          {orderData?.billingAddress && !(Object.keys(orderData?.billingAddress).length === 0) ? (
            <>
              {orderData?.billingAddress?.streetNumber +
                ' ' +
                orderData?.billingAddress?.streetName +
                ', ' +
                orderData?.billingAddress?.city +
                ' - ' +
                orderData?.billingAddress?.state +
                ' - ' +
                orderData?.billingAddress?.postalCode +
                ', ' +
                (orderData?.billingAddress?.country === 'IN' ? 'India' : orderData?.billingAddress?.country)}
            </>
          ) : (
            <p className="order-preview-contact-details-text">N/A</p>
          )}
        </p>
      </div>
      <span className="divider !m-0 md:hidden"></span>

      <div className="flex max-w-xs flex-col">
        <p className="order-preview-contact-details-heading">Shipping Address</p>
        <p className="order-preview-contact-details-text">
          {orderData?.shippingAddress && !(Object.keys(orderData?.shippingAddress).length === 0) ? (
            <>
              {orderData?.shippingAddress?.streetNumber +
                ' ' +
                orderData?.shippingAddress?.streetName +
                ', ' +
                orderData?.shippingAddress?.city +
                ' - ' +
                orderData?.billingAddress?.state +
                ' - ' +
                orderData?.shippingAddress?.postalCode +
                ', ' +
                (orderData?.shippingAddress?.country === 'IN' ? 'India' : orderData?.shippingAddress?.country)}
            </>
          ) : (
            <p className="order-preview-contact-details-text">N/A</p>
          )}
        </p>
      </div>
    </div>
  );
}

export default ContactDetails;
