import Price from 'components/commercetools-ui/price';
import React, { useState, useEffect, useMemo } from 'react';
import { LineItem } from '@Types/cart/LineItem';
import { useCart } from 'frontastic/provider';
import { ShippingMethod } from '@Types/cart/ShippingMethod';
import { useAccount } from 'frontastic';
import Loader from 'components/commercetools-ui/loader';

const PriceOrderSummary = ({ cart }) => {
  const { getShippingMethods } = useCart();
  const [shippingAmount, setShppingAmount] = useState<number>(0);
  const [currencyCode, setCurrencyCode] = useState<string>('INR');
  const { account } = useAccount();
  // console.log('cart', cart)
  const [loading, setLoading] = useState(true);
  const shippingCharges = 2000; 
  const vendorWiseItems = cart?.lineItems?.reduce((group, item) => {
    const vendorName = item.variant?.attributes['vendor-name'];
    group[vendorName] = group[vendorName] || [];
    group[vendorName].push(item);
    return group;
  }, {});
  const totalTaxes = cart?.taxed?.taxPortions?.reduce((a, b) => a + b.amount.centAmount, 0);
  useEffect(() => {
    if (cart) {
      setLoading(false);
    }
  }, [cart]);
  useMemo(() => {
    if (vendorWiseItems && account?.addresses.length > 0) {
      getShippingMethods().then((data: any) => {
        setShppingAmount(data[0]?.rates[0]?.price?.centAmount * Object.keys(vendorWiseItems).length);
        setCurrencyCode(data[0]?.rates[0]?.price?.currencyCode);
      });
    }
  }, [vendorWiseItems]);
  const productPrice = cart?.lineItems?.reduce((a, b: LineItem) => {
    if (b.discountedPrice) {
      return a + b.discountedPrice.centAmount * b.count;
    } else {
      return a + b.prices?.centAmount * b.count;
    }
  }, 0);
  const discountPrice = cart?.lineItems?.reduce((a, b) => {
    return (
      a +
      b.count *
        b.discounts.reduce((x, y) => {
          return x + y.discountedAmount.centAmount;
        }, 0)
    );
  }, 0);
  const priceData = [
    {
      id: 1,
      title: 'Subtotal',
      component: (
        <Price
          price={{
            centAmount: productPrice,
            currencyCode: cart?.sum?.currencyCode,
          }}
          className="price_checkout text-[14px] lg:text-[18px] 2xl:text-[20px]"
        />
      ),
    },
    {
      id: 2,
      title: 'Shipping Charges',
      component: (
        <Price
          price={{
            centAmount: shippingAmount,
            currencyCode: cart?.sum?.currencyCode,
          }}
          className="price_checkout text-[14px] lg:text-[18px] 2xl:text-[20px]"
        />
      ),
    },
    {
      id: 3,
      title: 'Discount',
      component: (
        <Price
          price={
            {
              fractionDigits: 0,
              centAmount: discountPrice == 0 ? 0 : -discountPrice || 0,
              currencyCode: cart?.sum?.currencyCode,
            } || {}
          }
          className="price_checkout text-[14px] lg:text-[18px] 2xl:text-[20px]"
        />
      ),
    },
    {
      id: 4,
      title: 'SGST',
      component: (
        <Price
          price={
            {
              fractionDigits: 2,
              centAmount: cart?.taxed?.taxPortions[0]?.amount.centAmount,
              currencyCode: cart?.sum?.currencyCode,
            } || {}
          }
          className="price_checkout text-[14px] lg:text-[18px] 2xl:text-[20px]"
        />
      ),
    },
    {
      id: 5,
      title: 'CGST',
      component: (
        <Price
          price={
            {
              fractionDigits: 2,
              centAmount: cart?.taxed?.taxPortions[1]?.amount.centAmount,
              currencyCode: cart?.sum?.currencyCode,
            } || {}
          }
          className="price_checkout text-[14px] lg:text-[18px] 2xl:text-[20px]"
        />
      ),
    },
  ];
  return (
    <>
      {loading && <Loader />}
      <div>
        <div className="mx-[15px] lg:mx-[25px] 2xl:mx-[38px]">
          {priceData.map((item) => (
            <div key={item?.id} className="mt-[10px] flex justify-between lg:mt-[15px]">
              <p className=" price_checkout_heading text-[18px] lg:text-[18px] 2xl:text-[20px]">{item?.title}</p>
              <span>{item?.component}</span>
            </div>
          ))}
        </div>
        <div className="grand-total-order-summary mx-[15px] mt-[10px] pt-[15px] lg:mx-[25px] lg:mt-[20px] lg:pt-[25px] 2xl:mx-[38px] 2xl:pt-[30px]">
          <p className="price_checkout_heading flex justify-between text-[18px] lg:text-[25px]  2xl:text-[30px]">
            Grand Total{' '}
            <span>
              {' '}
              <Price
                price={
                  {
                    fractionDigits: 2,
                    centAmount:
                      cart?.sum?.centAmount + (totalTaxes ? totalTaxes : 0) + (totalTaxes ? shippingCharges : 0),
                    currencyCode: cart?.sum?.currencyCode,
                  } || {}
                }
                className="price_checkout text-[14px] lg:text-[18px] 2xl:text-[20px]"
              />
            </span>
          </p>
        </div>
      </div>
    </>
  );
};
export default PriceOrderSummary;
