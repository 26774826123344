import { useState, Fragment, useEffect } from 'react';
import { Dialog, Transition } from "@headlessui/react";
import { useDarkMode } from 'frontastic';

interface Props {
    response: (boolean) => void
}

const RemoveItemPopup = ({ response }: Props) => {
    const { mode, theme } = useDarkMode();

    return (<Transition.Root show={true} as={Fragment}>
        <Dialog className={`${mode} ${theme} default fixed inset-0 z-10 overflow-y-auto dialog-background remove-dialog-background`} onClose={() => { }}>
            <>
                <div className="remove-dialog-content">
                    <div className='remove-dialog-icon-container'>
                        <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="4.5" y="4" width="48" height="48" rx="24" fill="#F9E0E0" />
                            <path d="M28.5 24V28M28.5 32H28.51M38.5 28C38.5 33.5228 34.0228 38 28.5 38C22.9772 38 18.5 33.5228 18.5 28C18.5 22.4772 22.9772 18 28.5 18C34.0228 18 38.5 22.4772 38.5 28Z" stroke="#EB4747" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <rect x="4.5" y="4" width="48" height="48" rx="24" stroke="#F9E0E0" strokeWidth="8" />
                        </svg>

                    </div>
                    <div className='remove-dialog-text primary-remove-dialog-text'>Remove from Cart</div>
                    <div className='remove-dialog-text secondary-remove-dialog-text'>Are you sure you want to delete this item from your cart?</div>
                    <div className='remove-dialog-button-container'>
                        <button className='remove-dialog-secondary-button' onClick={() => response(false)}>Cancel</button>
                        <button className='remove-dialog-primary-button' onClick={() => response(true)}>Delete</button>
                    </div>
                </div>
            </>
        </Dialog>
    </Transition.Root>)
}

export default RemoveItemPopup;