import React, { useState } from 'react';

const IFSCCodes = ['1111', '2222', '3333'];

function Cheque() {
  const [chequeNumber, setChequeNumber] = useState('');
  const [IFSCCode, setIFSCCode] = useState('');
  const [referenceNumber, setReferenceNumber] = useState('');

  return (
    <form className="payment-cheque-wrapper">
      <div className="flex flex-col gap-5 md:flex-row">
        <label className="flex-1" htmlFor="">
          <span className="payment-cheque-label flex">
            Cheque Number
            <span className="text-[#EB4747]">*</span>
          </span>
          <input
            placeholder="Add Cheque Number"
            className="cheque-input block w-full appearance-none rounded border border-[#d9d9d9] focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
            type="number"
            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
            onChange={(e) => setChequeNumber(e.target.value)}
          />
        </label>
        <label className="flex-1" htmlFor="">
          <span className="payment-cheque-label flex">
            IFSC Code
            <span className="text-[#EB4747]">*</span>
          </span>
          <select
            className="cheque-input block w-full appearance-none rounded border border-[#d9d9d9] focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
            placeholder="Add IFSC Code"
          >
            <option className="hidden" value="">
              Add IFSC Code
            </option>
            {IFSCCodes.map((bank, index) => (
              <option key={index} value={bank}>
                {bank}
              </option>
            ))}
          </select>
        </label>
      </div>
      <div className="mt-4">
        <label htmlFor="">
          <span className="payment-cheque-label flex">
            Reference Number
            <span className="text-[#EB4747]">*</span>
          </span>
          <input
            placeholder="Add Reference Number"
            className="cheque-input block w-full appearance-none rounded border border-[#d9d9d9] focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
            type="number"
            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
            onChange={(e) => setReferenceNumber(e.target.value)}
          />
        </label>
      </div>
    </form>
  );
}

export default Cheque;
