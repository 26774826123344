import React, { useState } from 'react';
import CustomAccordion from 'components/commercetools-ui/accordion/customAccordion';

function TargetMarket() {
  const [selected, setSelected] = useState([]);
  const targetCountry = [
    { id: 1, title: 'India' },
    { id: 2, title: 'Nepal' },
    { id: 3, title: 'Bangladesh' },
    { id: 4, title: 'Pakistan' },
  ];
  const handleChange = (e) => {
    if (!selected.includes(e.target.value)) {
      setSelected([...selected, e.target.value]);
    } else {
      setSelected(selected.filter((val) => val !== e.target.value));
    }
  };

  const removeSelectedCountry = (item) => {
    setSelected(selected.filter((val) => val !== item));
  };

  return (
    <div>
      <p className="targetTitle">Choose location you want to sell your product in?</p>
      <CustomAccordion
        openSectionTitle="select country"
        closedSectionTitle="select country"
        className="targetCountryMain"
      >
        {targetCountry?.map((item, index) => (
          <div key={index}>
            <label className="plp_country_filter_options">
              <input
                type="checkbox"
                value={item.title}
                checked={selected.includes(item.title)}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
              <p className="plp_country_filter_label">{item.title}</p>
            </label>
          </div>
        ))}
      </CustomAccordion>
      {selected?.map((item, index) => (
        <p className="selectedItemMarket" key={index}>
          {item}
          <span onClick={() => removeSelectedCountry(item)}>
            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                fill="#0280C7"
              />
            </svg>
          </span>
        </p>
      ))}
    </div>
  );
}

export default TargetMarket;
