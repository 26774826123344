const MostRecentAddressCard = ({ address, onClick, isFromModal = false, isBillingAddress = false }) => {
  return (
    <div
      className={`mt-3 cursor-pointer rounded-lg bg-white p-3 ${isFromModal && 'border-gray-5 0 border  border-solid'}`}
      onClick={() => onClick(address?.addressId)}
    >
      <div>
        <div className="flex" style={{ alignItems: 'center' }}>
          {!isBillingAddress && (
            <input type="radio" name="my-accordion-2" checked={address?.isDefaultShippingAddress} />
          )}{' '}
          <span className="ml-2 font-bold address-name">{address?.firstName}</span>
        </div>
        <div className={`${!isBillingAddress? 'ml-6':'ml-2'} address-full`}>
          {address?.streetNumber || ''}, {address?.streetName || ''}, {address?.city || ''}, {address?.state || ''},{' '}
          {address?.country || ''}, {address?.postalCode || ''}
        </div>
      </div>
    </div>
  );
};

export default MostRecentAddressCard;
