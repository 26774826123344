import ProductDocuments from './product-documents';
import ProductSpecifications from './product-specifications';
import { useState, useMemo } from 'react';

export default function ProductDescription({ product, dataToShow, attributes }) {
  const specifications = Object.entries(product?.variants[0].attributes);
  const [isFirstButtonActive, setIsFirstButtonActive] = useState(true);
  const [docsAvailable, setDocsAvailable] = useState(1);
  const activeButtonStyle = {
    color: '#003769',
    borderBottom: '2px solid #003769',
  };

  const inactiveButtonStyle = {
    color: '#676767',
    borderBottom: 'none',
  };
  const handleClick = (isFirstButton) => {
    setIsFirstButtonActive(isFirstButton);
  };
  useMemo(() => {
    {
      specifications.map(([key, value]) =>
        key === 'allergen-declaration' ||
        key === 'halal_declaration' ||
        key === 'gluten_declaration' ||
        key === 'MSDS' ||
        key === 'TSE-BSE' ||
        key === 'WHO-GMP' ||
        key === 'Manufacturing Licence'
          ? setDocsAvailable((current) => current + 1)
          : null,
      );
    }
  }, []);
  return (
    <div>
      <div className="pdp-desc-large pdp-desc">
        <p className="desc-pdp">Product Description</p>
        <div className="spec-docu">
          <button
            className="spec tracking-[1px]"
            style={{ ...activeButtonStyle, ...(isFirstButtonActive ? {} : inactiveButtonStyle) }}
            onClick={() => handleClick(true)}
          >
            Specifications
          </button>

          {docsAvailable > 1 && (
            <button
              className="docu tracking-[1px]"
              style={{ ...activeButtonStyle, ...(isFirstButtonActive ? inactiveButtonStyle : {}) }}
              onClick={() => handleClick(false)}
            >
              Documents
            </button>
          )}
        </div>
        {isFirstButtonActive ? (
          <ProductSpecifications dataToShow={dataToShow} product={product} attributes={attributes} />
        ) : (
          <ProductDocuments product={product} />
        )}
      </div>
    </div>
  );
}
