import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useDarkMode } from 'frontastic';
import React from 'react';

export default function SeeAllAddressModal({ setOpenModal, children }) {
  const { mode, theme } = useDarkMode();

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog
        className={`${mode} ${theme} default dialog-background remove-dialog-background fixed inset-0 z-10 overflow-y-auto`}
        onClose={() => {}}
      >
        <>
          <div className="remove-dialog-content">
            <div className="address-modal-container">
              <div className='address-modal-header-container'>
              <div className='address-modal-icon'>
                <svg width={`${window.innerWidth<400?'30':'56'}`} height="57" viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="4" y="4.5" width="48" height="48" rx="24" fill="#F7FCF5" />
                  <path
                    d="M27.9996 19C24.6948 19 21.9996 21.6952 21.9996 25C21.9996 26.4785 22.8764 28.1231 23.8707 29.7578C24.865 31.3925 26.0241 32.9482 26.7408 33.8721C27.367 34.6789 28.6322 34.6789 29.2584 33.8721C29.9751 32.9482 31.1342 31.3925 32.1285 29.7578C33.1228 28.1231 33.9996 26.4785 33.9996 25C33.9996 21.6952 31.3044 19 27.9996 19ZM27.9996 20.5C30.4938 20.5 32.4996 22.5058 32.4996 25C32.4996 25.775 31.7907 27.4257 30.8463 28.9785C29.9018 30.5313 28.7692 32.0555 28.0728 32.9531C28.023 33.0173 27.9762 33.0173 27.9264 32.9531C27.23 32.0555 26.0974 30.5313 25.1529 28.9785C24.2085 27.4257 23.4996 25.775 23.4996 25C23.4996 22.5058 25.5054 20.5 27.9996 20.5ZM27.9996 23.5C27.6018 23.5 27.2203 23.658 26.9389 23.9393C26.6576 24.2206 26.4996 24.6022 26.4996 25C26.4996 25.3978 26.6576 25.7794 26.9389 26.0607C27.2203 26.342 27.6018 26.5 27.9996 26.5C28.3974 26.5 28.779 26.342 29.0603 26.0607C29.3416 25.7794 29.4996 25.3978 29.4996 25C29.4996 24.6022 29.3416 24.2206 29.0603 23.9393C28.779 23.658 28.3974 23.5 27.9996 23.5ZM21.8619 31.5C21.1709 31.5 20.5423 31.9081 20.2623 32.5391L19.152 35.0391C18.9105 35.5826 18.9588 36.2046 19.2828 36.7031C19.6073 37.2021 20.1561 37.5 20.7506 37.5H35.2477C35.8422 37.5 36.3914 37.2021 36.7154 36.7031C37.0399 36.2046 37.0888 35.5831 36.8473 35.0391L35.7369 32.5391C35.4564 31.9081 34.8288 31.5 34.1373 31.5H32.5094C32.1909 31.9825 31.8453 32.482 31.4723 33H34.1373C34.2358 33 34.3258 33.0579 34.3658 33.1484L35.4762 35.6484C35.5242 35.7564 35.4856 35.8442 35.4576 35.8867C35.4296 35.9292 35.3666 36 35.2486 36H20.7506C20.6326 36 20.5681 35.9282 20.5406 35.8857C20.5131 35.8432 20.4746 35.7564 20.5221 35.6484L21.6334 33.1484C21.6734 33.0584 21.7634 33 21.8619 33H24.526C24.1525 32.482 23.8078 31.9825 23.4898 31.5H21.8619Z"
                    fill="#0068A3"
                  />
                  <rect x="4" y="4.5" width="48" height="48" rx="24" stroke="#CCE6F4" strokeWidth="8" />
                </svg>
              </div>
              <div className="text-xl font-bold address-modal-text">Most Recent Address</div>
              </div>

              {children}
              <div className="mt-10 w-full gap-2 address-modal-button-container">
                <button
                  className="rounded-md border px-4 py-2 text-center text-sm font-medium tracking-wide text-accent-400 address-modal-button"
                  onClick={() => setOpenModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="address-modal-button rounded-md border border-transparent bg-accent-400 px-4 py-2 text-center text-sm font-medium tracking-wide text-white shadow-sm transition-colors duration-150 ease-out focus:outline-none focus:ring-2 focus:ring-accent-400 focus:ring-offset-2"
                  onClick={() => setOpenModal(false)}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </>
      </Dialog>
    </Transition.Root>
  );
}
