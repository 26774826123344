import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import Category from 'components/commercetools-ui/breadcrumb/Category';
import { LOAD_PDP_BREADCRUMB_DATA } from 'components/commercetools-ui/header/graphql-query/mega-menu-graphql';

function PDPCategory({ productSKU, productName }) {
  const { loading, error, data } = useQuery(LOAD_PDP_BREADCRUMB_DATA(productSKU));
  const [PDPCategorySlugArray, setPDPCategorySlugArray] = useState([]);
  const [PDPCategoryNameArray, setPDPCategoryNameArray] = useState([]);

  const getCategoryString = () => {
    const slugArr = [];
    const nameArr = [];
    let ancestorsx = [];
    if (data.product?.masterData.current.categories && data.product.masterData.current.categories.length > 0) {
      ancestorsx = data.product.masterData.current.categories[0].ancestors;
    }
    for (let i = ancestorsx.length - 1; i >= 0; i--) {
      slugArr.push(ancestorsx[i].slug);
      nameArr.push(ancestorsx[i].name);
    }

    // newArr.unshift('')
    // slugArr.push(productURL)
    nameArr.push(productName);
    setPDPCategorySlugArray(slugArr);
    setPDPCategoryNameArray(nameArr);
    // console.log('PDPCategorySlugArray', PDPCategorySlugArray);
  };
  useEffect(() => {
    if (!loading) {
      getCategoryString();
    }
  }, [data]);
  return <Category categorySlugs={PDPCategorySlugArray}>{PDPCategoryNameArray[1]}</Category>;
}

export default PDPCategory;
