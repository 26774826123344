import React, { useEffect, useMemo, useState } from 'react';
//Mock Data
// import { data } from './makeData';
import Checkbox from './checkbox';

export type Sellers = {
  id: number;
  name: string;
  ratings: string;
  location: string;
  badge: string;
  certificate: string;
};

const SellersDetail = ({ sellers, isMobile = false, getSelectedSellers }) => {
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);

  useEffect(() => {
    setList(sellers.map((x) => x.name));
  }, []);

  useEffect(() => getSelectedSellers(isCheck), [isCheck])

  const handleSelectAll = (e) => {
    if (isCheckAll) {
      setIsCheck([]);
    } else {
      setIsCheck(sellers.map((x) => x.name));
    }
    setIsCheckAll(!isCheckAll);
  };

  const handleClick = (e) => {
    const { name, checked } = e.target;
    let x = [...isCheck];

    if (checked) {
      setIsCheck([...isCheck, name]);
      x.push(name);
    } else {
      const found = isCheck.filter((x) => x !== name);
      x = [...found];
      setIsCheck([...found]);
    }
    setTimeout(() => {
      if (x.length === sellers.length) {
        setIsCheckAll(true);
      } else {
        setIsCheckAll(false);
      }
    });
  };

  const sellerData = sellers?.map(({ id, name, location, ratings, badge, certificate }) => {
    return (
      <>
        <tr>
          <th style={{ textAlign: 'center' }}>
            <label>
              {
                <Checkbox
                  key={id}
                  type="checkbox"
                  id={id}
                  name={name}
                  handleClick={handleClick}
                  isChecked={isCheck.indexOf(name) > -1}
                />
              }
            </label>
          </th>
          <td>
            <div className="flex items-center space-x-3">
              <div>
                <div style={{ fontSize: '14px', marginLeft: '15px' }}>{name}</div>
              </div>
            </div>
          </td>
        </tr>
      </>
    );
  });

  return (
    <>
      <div className="w-full overflow-x-auto">
        {!isMobile && <p className="quote-title-inner">Select Sellers</p>}
        <p style={{ fontSize: '20px', fontWeight: '600', color: '#303030', margin: '15px 0px' }}>
          Top Sellers ({sellers?.length})
        </p>
        <table className="sellr-table w-full" style={{ border: 'none' }}>
          <thead>
            <tr style={{ backgroundColor: '#DFDFDF' }}>
              <th style={{ width: '75px', textAlign: 'center', borderBottom: '1px solid #DFDFDF' }}>
                <label>
                  {
                    <Checkbox
                      type="checkbox"
                      name="selectAll"
                      id="selectAll"
                      handleClick={handleSelectAll}
                      isChecked={isCheckAll}
                    />
                  }
                </label>
              </th>
              <th style={{ marginLeft: '15px' }}>Name</th>
            </tr>
          </thead>
          <tbody>{sellerData}</tbody>
        </table>
      </div>
    </>
  );
};

export default SellersDetail;
