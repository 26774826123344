import React from 'react';
import Markdown from 'components/commercetools-ui/content/markdown';
import Typography from 'components/commercetools-ui/typography';

const ContentPLP = ({ data }) => {
  const plpBgImageMarketing = data.data?.dataSource?.attributes?.bannerImage.content.url;
  return (
    <>
      <img src={plpBgImageMarketing} alt="" className="plpBgImageMarketing" />
    </>
  );
};

export default ContentPLP;
