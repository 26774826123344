import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Loader from 'components/commercetools-ui/loader';
import { STATIC_POPULAR_PRODUCTS_URL } from 'helpers/constants/statisURLs';
import { backendAPIClient } from 'helpers/utils/axiosRequestsUtil';
import APISlider from './api-slider';

function MostPopularSlider() {
  const [openTab, setOpenTab] = useState(0);
  const [productDataBasedOnMegaMenu, setProductDataBasedOnMegaMenu] = useState([]);
  const [megaMenuList, setMegaMenuList] = useState([]);
  const [loading, setLoading] = useState(false);
  const router = useRouter();

  const getProductData = async (searchString) => {
    setLoading(true);
    try {
      const res = await backendAPIClient.get(STATIC_POPULAR_PRODUCTS_URL + searchString);
      setProductDataBasedOnMegaMenu([...productDataBasedOnMegaMenu, { name: searchString, data: res }]);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const megaMenuListData = JSON.parse(sessionStorage.getItem('megamenu-data'))?.categories?.results;
    setMegaMenuList(megaMenuListData);
    megaMenuListData?.map((megaMenu) => {
      getProductData(megaMenu.name);
    });
  }, []);

  return (
    <>
      {loading && <Loader />}
      <div className="mostPopularContainer  bg-[#F3F5FD] ">
        <div className="container relative mx-auto">
          <div className="flex flex-wrap lg:mb-[24px]">
            <div className="w-full">
              <h1 className="most-popular-title">Most popular</h1>
              <ul className="sliderTabsWrap mb-0 flex list-none flex-row flex-wrap justify-start" role="tablist">
                {megaMenuList?.map((megaMenu, index) =>
                  productDataBasedOnMegaMenu?.find((e) => e.name === megaMenu.name)?.data?.data.length ? (
                    <li className="text-left">
                      <a
                        className={'tab-title  block' + (openTab === index ? ' active-api-tab' : '')}
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab(index);
                        }}
                        data-toggle="tab"
                        href="#link1"
                        role="tablist"
                      >
                        {megaMenu.name}
                      </a>
                    </li>
                  ) : (
                    <></>
                  ),
                )}
                <li className="sliderTabsWrapBtn" onClick={() => router.push(`/${megaMenuList[openTab]?.name}`)}>
                  <button className="see_all_button">See All</button>
                </li>
              </ul>
              <div className="mb-6 flex w-full min-w-0 flex-col ">
                <div className="flex-auto">
                  <div className="tab-content tab-space">
                    {megaMenuList?.map((megaMenu, index) => (
                      <div className={openTab === index ? 'block' : 'hidden'} id="link1">
                        <APISlider
                          popularProducts={
                            productDataBasedOnMegaMenu?.find((e) => e.name === megaMenu.name)?.data?.data
                          }
                          mostPopularTile={openTab === index}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MostPopularSlider;
