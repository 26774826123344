export const COMPANY_DETAIL_NUMBER_ICON = (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M2.5 2.5V17.5H17.5V2.5H2.5ZM15.8333 15.8333H4.16667V4.16667H15.8333V15.8333ZM9.16667 5.83333H10.8333V7.5H9.16667V5.83333ZM5.83333 5.83333H7.5V7.5H5.83333V5.83333ZM12.5 5.83333H14.1667V7.5H12.5V5.83333ZM5.83333 9.16667H7.5V10.8333H5.83333V9.16667ZM9.16667 9.16667H10.8333V10.8333H9.16667V9.16667ZM12.5 9.16667H14.1667V10.8333H12.5V9.16667Z"
      fill="#676767"
    />
  </svg>
);
export const ORG_TYPE_ICON = (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M18.3327 2.5L16.941 3.89167L15.5577 2.5L14.166 3.89167L12.7743 2.5L11.391 3.89167L9.99935 2.5L8.60768 3.89167L7.22435 2.5L5.83268 3.89167L4.44102 2.5L3.05768 3.89167L1.66602 2.5V15.8333C1.66602 16.75 2.41602 17.5 3.33268 17.5H16.666C17.5827 17.5 18.3327 16.75 18.3327 15.8333V2.5ZM9.16602 15.8333H3.33268V10.8333H9.16602V15.8333ZM16.666 15.8333H10.8327V14.1667H16.666V15.8333ZM16.666 12.5H10.8327V10.8333H16.666V12.5ZM16.666 9.16667H3.33268V6.66667H16.666V9.16667Z"
      fill="#676767"
    />
  </svg>
);
export const COMPANY_NAME_ICON = (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M11.666 5.41732V3.75065H8.33268V5.41732H11.666ZM3.33268 7.08398V16.2507H16.666V7.08398H3.33268ZM16.666 5.41732C17.591 5.41732 18.3327 6.15898 18.3327 7.08398V16.2507C18.3327 17.1757 17.591 17.9173 16.666 17.9173H3.33268C2.40768 17.9173 1.66602 17.1757 1.66602 16.2507L1.67435 7.08398C1.67435 6.15898 2.40768 5.41732 3.33268 5.41732H6.66602V3.75065C6.66602 2.82565 7.40768 2.08398 8.33268 2.08398H11.666C12.591 2.08398 13.3327 2.82565 13.3327 3.75065V5.41732H16.666Z"
      fill="#676767"
    />
  </svg>
);
export const COMPANY_ADDRESS_ICON = (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M5.99935 0.666016C2.77435 0.666016 0.166016 3.27435 0.166016 6.49935C0.166016 10.8743 5.99935 17.3327 5.99935 17.3327C5.99935 17.3327 11.8327 10.8743 11.8327 6.49935C11.8327 3.27435 9.22435 0.666016 5.99935 0.666016ZM1.83268 6.49935C1.83268 4.19935 3.69935 2.33268 5.99935 2.33268C8.29935 2.33268 10.166 4.19935 10.166 6.49935C10.166 8.89935 7.76602 12.491 5.99935 14.7327C4.26602 12.5077 1.83268 8.87435 1.83268 6.49935Z"
      fill="#676767"
    />
    <path
      d="M5.99935 8.58268C7.14994 8.58268 8.08268 7.64994 8.08268 6.49935C8.08268 5.34876 7.14994 4.41602 5.99935 4.41602C4.84876 4.41602 3.91602 5.34876 3.91602 6.49935C3.91602 7.64994 4.84876 8.58268 5.99935 8.58268Z"
      fill="#676767"
    />
  </svg>
);
export const COMPANY_ROLE_ICON = (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M14.6152 15.6667V14.0926C14.6152 13.2577 14.4078 12.1251 13.7852 11.5347C13.1625 10.9443 12.1755 10.9444 11.2949 10.9444H4.6543C3.7737 10.9444 2.57922 10.9443 1.95654 11.5347C1.33386 12.1251 1.33398 13.2577 1.33398 14.0926V15.6667M11.2949 4.64815C11.2949 6.38682 9.80837 7.7963 7.97461 7.7963C6.14085 7.7963 4.6543 6.38682 4.6543 4.64815C4.6543 2.90947 6.14085 1.5 7.97461 1.5C9.80837 1.5 11.2949 2.90947 11.2949 4.64815Z"
      stroke="#676767"
      strokeWidth="1.5"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);
export const BANK_NAME_ICON = (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M14.9993 11.666V4.99935H10.8327V11.666L12.916 10.416L14.9993 11.666Z" fill="#676767" />
    <path
      d="M16.666 1.66602H3.33268C2.41602 1.66602 1.66602 2.41602 1.66602 3.33268V18.3327L4.99935 14.9993H16.666C17.5827 14.9993 18.3327 14.2493 18.3327 13.3327V3.33268C18.3327 2.41602 17.5827 1.66602 16.666 1.66602ZM16.666 13.3327H4.99935L3.33268 14.9993V3.33268H16.666V13.3327Z"
      fill="#676767"
    />
  </svg>
);
export const AC_NUMBER_ICON = (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M14.9993 11.666V4.99935H10.8327V11.666L12.916 10.416L14.9993 11.666Z" fill="#676767" />
    <path
      d="M16.666 1.66602H3.33268C2.41602 1.66602 1.66602 2.41602 1.66602 3.33268V18.3327L4.99935 14.9993H16.666C17.5827 14.9993 18.3327 14.2493 18.3327 13.3327V3.33268C18.3327 2.41602 17.5827 1.66602 16.666 1.66602ZM16.666 13.3327H4.99935L3.33268 14.9993V3.33268H16.666V13.3327Z"
      fill="#676767"
    />
  </svg>
);
export const REPLY_ICON = (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M8.33333 7.08333V3.75L2.5 9.58333L8.33333 15.4167V12C12.5 12 15.4167 13.3333 17.5 16.25C16.6667 12.0833 14.1667 7.91667 8.33333 7.08333Z"
      fill="#676767"
    />
  </svg>
);

export const MAIL_ICON = (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="2 0 20 20" fill="none">
    <path
      d="M18.7253 5.36589C18.7253 4.44922 17.9753 3.69922 17.0586 3.69922H3.72526C2.80859 3.69922 2.05859 4.44922 2.05859 5.36589V15.3659C2.05859 16.2826 2.80859 17.0326 3.72526 17.0326H17.0586C17.9753 17.0326 18.7253 16.2826 18.7253 15.3659V5.36589ZM17.0586 5.36589L10.3919 9.52422L3.72526 5.36589H17.0586ZM17.0586 15.3659H3.72526V7.03255L10.3919 11.1992L17.0586 7.03255V15.3659Z"
      fill="#676767"
    />
  </svg>
);

export const PWD_ICON = (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="19" viewBox="0 0 15 19" fill="none">
    <path
      d="M12.3932 6.44857H11.5599V4.7819C11.5599 2.4819 9.69323 0.615234 7.39323 0.615234C5.09323 0.615234 3.22656 2.4819 3.22656 4.7819V6.44857H2.39323C1.47656 6.44857 0.726562 7.19857 0.726562 8.11523V16.4486C0.726562 17.3652 1.47656 18.1152 2.39323 18.1152H12.3932C13.3099 18.1152 14.0599 17.3652 14.0599 16.4486V8.11523C14.0599 7.19857 13.3099 6.44857 12.3932 6.44857ZM4.89323 4.7819C4.89323 3.39857 6.0099 2.2819 7.39323 2.2819C8.77656 2.2819 9.89323 3.39857 9.89323 4.7819V6.44857H4.89323V4.7819ZM12.3932 16.4486H2.39323V8.11523H12.3932V16.4486ZM7.39323 13.9486C8.3099 13.9486 9.0599 13.1986 9.0599 12.2819C9.0599 11.3652 8.3099 10.6152 7.39323 10.6152C6.47656 10.6152 5.72656 11.3652 5.72656 12.2819C5.72656 13.1986 6.47656 13.9486 7.39323 13.9486Z"
      fill="#676767"
    />
  </svg>
);

export const PLUS_ICON = (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 14 14" fill="none">
    <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill="#0068A3" />
  </svg>
);

export const EMAIL_VERIFIED_ICON = (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.392578 9.36523C0.392578 4.39467 4.42202 0.365234 9.39258 0.365234C14.3631 0.365234 18.3926 4.39467 18.3926 9.36523C18.3926 14.3358 14.3631 18.3652 9.39258 18.3652C4.42202 18.3652 0.392578 14.3358 0.392578 9.36523Z"
      fill="#30AB30"
    />
    <rect width="11.25" height="11.25" transform="translate(3.76758 3.74023)" fill="#30AB30" />
    <path
      d="M8.68945 11.9902L5.83008 9.17773L6.86133 8.14648L8.68945 10.0215L12.1582 6.50586L13.1895 7.53711L8.68945 11.9902Z"
      fill="white"
    />
  </svg>
);

export const PHONE_ICON = (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="-1 0 20 22" fill="none">
    <path
      d="M11.5703 0H3.57031C1.91031 0 0.570312 1.34 0.570312 3V19C0.570312 20.66 1.91031 22 3.57031 22H11.5703C13.2303 22 14.5703 20.66 14.5703 19V3C14.5703 1.34 13.2303 0 11.5703 0ZM12.5703 17H2.57031V3H12.5703V17ZM9.57031 20H5.57031V19H9.57031V20Z"
      fill="#676767"
    />
  </svg>
);
