import React,{useState} from 'react';


const SellerImage = ({image}) => {

  return (
    <div className='mt-[20px]'>
     <img className="vendor-info-image" src={image} />
    </div>
  );
};

export default SellerImage;