import React, { useState, useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useFormat } from 'helpers/hooks/useFormat';
import { getReferenceTarget, Reference } from 'helpers/reference';
import { ReferenceLink } from 'helpers/reference';
import { useAccount } from 'frontastic';
import Image, { NextFrontasticImage } from 'frontastic/lib/image';
import { EyeIcon, EyeOffIcon, LockClosedIcon } from '@heroicons/react/outline';
import ResetPasswordModal from './reset-password-popup';
export interface ResetPasswordProps {
  logo?: NextFrontasticImage;
  token?: string | string[];
  accountLink?: Reference;
  registerLink?: Reference;
}

const ResetPassword: React.FC<ResetPasswordProps> = ({ logo, token, accountLink, registerLink }) => {
  //i18n messages
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const [validPassword, setValidPassword] = useState('');
  const [validConfirmPassword, setValidConfirmPassword] = useState('');
  //next/router
  const router = useRouter();

  const passwordValidation = (password: string): void => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    const valid = regex.test(password);
    if (valid) {
      setValidPassword(null);
    } else {
      setValidPassword(
        'Password must have a minimum of 8 characters and include one capital letter, a number and one special character, e.g. @*!',
      );
    }
  };

  const confirmPasswordValidation = (password: string): void => {
    setValidConfirmPassword(data.password === password ? null : "Passwords don't match");
  };
  //account actions
  const { resetPassword } = useAccount();

  //reset password data data
  const [data, setData] = useState({ password: '', confirmPassword: '' });
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
  const [passwordValidationMsg, setPasswordValidationMsg] = useState(false);
  //error
  const [error, setError] = useState('');

  //processing...
  const [loading, setLoading] = useState(false);

  //handle text input change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fName = e.target.name;
    const fValue = e.target.value;
    if (fName === 'password') {
      passwordValidation(fValue);
    } else if (fName === 'confirmPassword') {
      confirmPasswordValidation(fValue);
    }
    setData({ ...data, [e.target.name]: e.target.value });
  };
  //Toggles password visibility
  const togglePasswordVisibility = useCallback(() => setIsPasswordVisible(!isPasswordVisible), [isPasswordVisible]);
  const toggleConfirmPasswordVisibility = useCallback(
    () => setIsConfirmPasswordVisible(!isConfirmPasswordVisible),
    [isConfirmPasswordVisible],
  );
  //data validation
  const validate = () => {
    //validation schema
    const passwordsMatch = data.password === data.confirmPassword;

    //UI error messages
    if (!passwordsMatch)
      // setError(formatErrorMessage({ id: 'password.noMatch', defaultMessage: "Passwords don't match" }));
      setPasswordValidationMsg(false);
    //return a boolean representing the data validity
    return passwordsMatch;
  };

  //form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    //validate data
    if (!validate()) return;
    //processing starts
    setLoading(true);
    //try registering the user with given credentials
    try {
      const response = await resetPassword(token as string, data.password);
      if (!response.accountId) {
        setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
      } else if (data.password == '') {
        setError('Password Should not be empty');
      } else {
        setError('');
        setPasswordValidationMsg(true);
        router?.push(getReferenceTarget(accountLink));
      }
    } catch (err) {
      setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
    }
    //processing ends
    setLoading(false);
  };
  useEffect(() => {
    const body = document.querySelector('body') as HTMLElement;
    const headerMenu = document.getElementsByClassName('wrapperMain')[0] as HTMLElement;
    if (body && document.querySelector('.blockBodyScroll')) {
      body.style.overflow = 'hidden';
      headerMenu.style.zIndex = '10';
    }
    return () => {
      if (body) {
        body.style.overflow = 'visible';
        headerMenu.style.zIndex = '5000';
      }
    };
  }, []);
  const handleResetPasswordModal = () => {
    if (data.password === data.confirmPassword && data.password !== '' && data.confirmPassword !== '') {
      setShowResetPasswordModal(true);
    } else {
      setShowResetPasswordModal(false);
    }
  };
  const backToLogin = () => {
    router.push('/login');
  };
  return (
    // <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">

    <div className="sign_in_container blockBodyScroll flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      {/* <div className="mt-8"> */}
      <div className="sign-in  flex  lg:mr-auto">
        {/* <div className="mx-auto w-full max-w-[500px] rounded-sm bg-white px-6 pb-32 pt-16 shadow-2xl dark:bg-primary-200 lg:px-12">
         */}
        <div className="sign-in-content  reset-password-content w-full   bg-white px-6 pb-32 pt-16  dark:bg-primary-200 lg:px-12">
          <div className="flex">
            <p onClick={backToLogin}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14.8627 3.225L13.3794 1.75L5.1377 10L13.3877 18.25L14.8627 16.775L8.0877 10L14.8627 3.225Z"
                  fill="#0068A3"
                />
              </svg>
            </p>
            <button className="return_sign_in" onClick={backToLogin}>
              Return to Sign In
            </button>
          </div>
          <form className="space-y-7" onSubmit={handleSubmit}>
            <div className="py-6 text-center">
              {/* <h2 className="text-3xl font-extrabold text-neutral-700"> */}
              <h2 className="signin_heading">
                {formatAccountMessage({ id: 'password.reset', defaultMessage: 'Reset Password' })}
              </h2>
              <h3 className="signin_tooltip">Please enter your new password</h3>
              {/* <h3 className="text-md mt-6 text-neutral-600"> */}
              {/* <h3 className=" signin_tooltip">
                {formatAccountMessage({
                  id: 'password.reset.desc',
                  defaultMessage: 'To reset your password, please enter the email address associated with your account',
                })}
              </h3> */}
            </div>
            {error && <p className="error_login">{error}</p>}
            <div>
              <label htmlFor="password" className="password_label">
                {formatAccountMessage({ id: 'password', defaultMessage: 'Password' })}
              </label>
              <div className="password_login relative mt-2">
                <input
                  id="password"
                  name="password"
                  type={isPasswordVisible ? 'text' : 'password'}
                  autoComplete="new-password"
                  placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                  required
                  className=" block w-full appearance-none rounded-sm border border-gray-300 py-2 px-3 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                  onChange={handleChange}
                />
                <span className="absolute left-3 top-1/2 block h-4 w-4 -translate-y-1/2 text-neutral-600">
                  <LockClosedIcon className="lock_closed_icon" />
                </span>
                <span
                  className="absolute right-3 top-1/2 block h-4 w-4 -translate-y-1/2 text-neutral-600"
                  onClick={togglePasswordVisibility}
                >
                  {!isPasswordVisible ? <EyeOffIcon /> : <EyeIcon />}
                </span>
              </div>
              {validPassword && <p className="errorMessage text-[#e15a5a]">{validPassword}</p>}
              {/* {validPassword && <p className="errorMessage">{validPassword}</p>} */}
            </div>

            <div>
              <label htmlFor="password" className="password_label">
                {formatAccountMessage({ id: 'password.confirm', defaultMessage: 'Confirm Password' })}
              </label>
              <div className="password_login relative mt-2">
                <input
                  id="confirm-password"
                  name="confirmPassword"
                  type={isConfirmPasswordVisible ? 'text' : 'password'}
                  autoComplete="new-password"
                  placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                  required
                  className=" block w-full appearance-none rounded-sm border border-gray-300 py-2 px-3 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                  onChange={handleChange}
                />
                <span className="absolute left-3 top-1/2 block h-4 w-4 -translate-y-1/2 text-neutral-600">
                  <LockClosedIcon className="lock_closed_icon" />
                </span>
                <span
                  className="absolute right-3 top-1/2 block h-4 w-4 -translate-y-1/2 text-neutral-600"
                  onClick={toggleConfirmPasswordVisibility}
                >
                  {!isConfirmPasswordVisible ? <EyeOffIcon /> : <EyeIcon />}
                </span>
              </div>
            </div>
            {validConfirmPassword && <p className="errorMessage text-[#e15a5a]">{validConfirmPassword}</p>}
            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-sm border border-transparent bg-accent-400 py-2 px-4 text-[14px] font-medium tracking-[1px] text-white shadow-sm transition-colors duration-200 ease-out disabled:bg-gray-200"
                disabled={loading}
                onClick={handleResetPasswordModal}
                // onClick={() => {
                //   // forgotPassword()
                //    setShowResetPasswordModal(true);
                // }}
              >
                {formatAccountMessage({ id: 'password.reset', defaultMessage: 'Reset password' })}
              </button>
              {showResetPasswordModal && <ResetPasswordModal setOpenModal={setShowResetPasswordModal} />}
            </div>
          </form>
          <p className="new_account mt-[32px] flex justify-center  lg:mt-[47px] ">
            {formatAccountMessage({ id: 'account.doNotHave', defaultMessage: 'New to Allverz?' })}{' '}
            <ReferenceLink
              target={registerLink}
              //  className="font-medium text-accent-400 underline hover:text-accent-500 sign_up_btn"
              className="sign_up_btn"
            >
              {formatAccountMessage({ id: 'account.register.here', defaultMessage: 'Sign-up' })}
            </ReferenceLink>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
