// components/search-hits.js
import { useEffect, useState } from 'react';
import Link from 'next/link';
import router, { useRouter } from 'next/router';
import { connectStateResults } from 'react-instantsearch-dom';

function MobileSearchHits({
  searchState,
  searchResults,
  setSearchOutput,
  setSearchQuery,
  setRecentSearchIndex,
  hideSearchDropdown,
  setHideSearchDropdown,
}) {
  const { asPath } = useRouter();
  // checking if the query length is >= 3
  // (since 3 is the minimum Algolia query length)
  const [slicedHits, setSlicedHits] = useState([]);
  const [recentSearches, setRecentSearches] = useState([]);
  const [categoryMatches, setCategoryMatches] = useState([
    {
      name: '',
      slug: '',
    },
  ]);
  const getCategories = (searchResults, searchState) => {
    const allCategories = searchResults?.hits[0]?.productProjections.categories[0].obj.ancestors;
    const categoryNames = [];

    if (Object.keys(searchState).length > 0 && allCategories) {
      allCategories.forEach((element) => {
        // console.log(element.obj.slug["en-US"])
        categoryNames.push({
          name: element.obj.name['en-IN'].toLowerCase(),
          slug: element.obj.slug['en-IN'],
        });
      });
      categoryNames.splice(0, 1);
      setCategoryMatches(categoryNames);
    }
  };
  const getRecentSearches = () => {
    if (typeof window !== 'undefined') {
      const recentSearches = JSON.parse(localStorage.getItem('recentSearches') || '[]');
      setRecentSearches(recentSearches);
    }
  };

  const addRecentSearches = (query) => {
    if (typeof window !== 'undefined') {
      const recentSearches = JSON.parse(localStorage.getItem('recentSearches') || '[]');
      if (recentSearches.length > 5) {
        recentSearches.splice(-1);
      }

      recentSearches.unshift(query);
      localStorage.setItem('recentSearches', JSON.stringify(recentSearches));
    }
  };

  useEffect(() => {
    // console.log(categorySlugsMatches);
    getRecentSearches();
    getCategories(searchResults, searchState);
    if (searchResults) {
      setSlicedHits(searchResults?.hits.slice(0, 5));
    }
    setSearchOutput(searchResults);
    setSearchQuery(searchState.query);
  }, [searchResults, searchState, setRecentSearchIndex]);

  useEffect(() => {
    searchState.query = '';
  }, [asPath]);

  const handleRecentSearchClick = (query) => {
    setSearchQuery(query);
    addRecentSearches(query);
    setHideSearchDropdown(true);
    router.push('/search?q=' + query);
  };

  const validQuery = searchState.query?.trim().length >= 3;
  return searchState.query && !hideSearchDropdown && validQuery ? (
    <div className="mobile-searchmodal-wrapper">
      <div className="mobile-searchmodal-scroller">
        {/* <p className="mobile-searchmodal-heading">Product Suggestions</p> */}
        <div className="mobile-searchmodal-items-wrapper">
          {searchResults?.hits.length === 0 && (
            <div className="mobile-searchmodal-item-heading-category">No results found!</div>
          )}
          {searchResults?.hits.length > 0 &&
            slicedHits.map((hit, index) => (
              <>
                <div key={hit.objectID} className="mobile-searchmodal-items">
                  {/* <Link href={`/${hit.productProjections.categories[0].obj.slug['en-IN']}`}> */}
                  <span>
                    <p
                      className="mobile-searchmodal-item-heading"
                      onClick={() => handleRecentSearchClick(hit.productProjections.name['en-IN'])}
                    >
                      {hit.productProjections.name['en-IN']}
                    </p>
                    {
                      hit.productProjections.masterVariant.attributes[0].value['en-IN'] && 
                        <p className="mobile-searchmodal-casno">
                          CAS No. :
                          {` ${hit.productProjections.masterVariant.attributes[0].value['en-IN']}`}
                        </p>
                    }
                  </span>

                  {/* </Link> */}
                </div>
                {index < slicedHits.length - 1 && <div className="mobile-searchmodal-divider"></div>}
              </>
            ))}
        </div>

        <div className="mobile-searchmodal-recents-wrapper">
          <p className="mobile-searchmodal-heading">Recent Searches</p>
          <div className="mobile-searchmodal-divider"></div>

          {recentSearches.length === 0 && (
            <div className="mobile-searchmodal-item-heading-category pt-2">No Product searched recently!</div>
          )}
          {recentSearches.length > 0 &&
            recentSearches.map((recentSearch, index) => (
              <div key={index} className="search-items-recent">
                <span onClick={() => handleRecentSearchClick(recentSearch)}>
                  <p className="mobile-searchmodal-item-heading-category">{recentSearch}</p>
                </span>
              </div>
            ))}
        </div>

        <div className="mobile-searchmodal-recents-wrapper">
          {searchResults?.hits.length > 0 && (
            <>
              <div className="mobile-searchmodal-divider"></div>
              <p className="mobile-searchmodal-heading">Product Category</p>
            </>
          )}

          {searchResults?.hits.length > 0 &&
            categoryMatches.map((item) => (
              <div key={item.name}>
                <Link href={`/${item.slug}`}>
                  <a className="mobile-searchmodal-item-heading-category capitalize hover:cursor-pointer hover:underline">
                    {item.name}
                  </a>
                </Link>
              </div>
            ))}
        </div>
      </div>
    </div>
  ) : (
    <div className="mobile-searchmodal-wrapper-empty">
      <p className="mobile-searchmodal-heading">Recent Searches</p>
      <div className="mobile-searchmodal-divider"></div>
      {recentSearches.length === 0 && (
        <div className="mobile-searchmodal-item-heading-category pt-2">No Product searched recently!</div>
      )}
      {recentSearches.length > 0 &&
        recentSearches.map((recentSearch, index) => (
          <div key={index} className="search-items-recent">
            <span onClick={() => handleRecentSearchClick(recentSearch)}>
              <p className="mobile-searchmodal-item-heading-category">{recentSearch}</p>
            </span>
          </div>
        ))}
    </div>
  );
}
export default connectStateResults(MobileSearchHits);
