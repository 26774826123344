import { useEffect, useState } from 'react';

const Certificates = ({ certificates, isFromProductListPage = true }) => {
  const [certificatesArray, setCertificatesArray] = useState([]);

  useEffect(() => {
    setCertificatesArray(certificates.split(','));
  }, [certificates]);

  if (isFromProductListPage) {
    return (
      <div style={{ display: 'flex' }}>
        {certificatesArray?.slice(0, 2)?.map((element: string, index: number) => (
          <button key={index} className="btn-fda" style={{ cursor: 'none' }}>
            {element}
          </button>
        ))}
        {certificatesArray.length > 2 && (
          <div
            className="tooltip tooltip-bottom"
            style={{ display: 'flex', cursor: 'pointer' }}
            data-tip={certificatesArray.slice(2).join(', ')}
          >
            <span className="certificate-count">+ {certificatesArray.length - 2}</span>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div>
        {certificatesArray?.map((element: string, index: number) => (
          <button key={index} className="btn-fda">
            {element}
          </button>
        ))}
      </div>
    );
  }
};

export default Certificates;
