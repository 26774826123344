import React from 'react';
import Markdown from 'components/commercetools-ui/content/markdown';

const BlogCarousel = ({ data }) => {
  //   const title = data.data?.dataSource?.fields?.title;
  //   const body = data.data?.dataSource?.fields?.body;
  const model = data.data.dataSource.attributes;
  return (
    <>
      
      hello 
      {model?.carousalText?.content}
      <pre className="mt-2 text-lg">{JSON.stringify(data.data?.dataSource, null, 2)}</pre>
      {/*<p className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white lg:text-4xl">
        {data.data.dataSource.title}
      </p>
      
       <div>title is &quot;{title}&quot;</div>
      <div>body is &quot;{body}&quot;</div>
       <pre className="mt-2 text-lg">{JSON.stringify(data.data?.dataSource, null, 2)}</pre> */}
    </>
  );
};

export default BlogCarousel;