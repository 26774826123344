import React, { useEffect, useState } from 'react';
import Footer from 'components/commercetools-ui/footer';
import Link from 'next/link';
import { useRouter } from 'next/router';
import MobileSearchModal from 'components/commercetools-ui/products/product-list/algolia-search/mobile-search-modal';

// import Highlights from 'components/commercetools-ui/footer/highlights';

const FooterTastic = ({ data }) => {
  // console.log('test>>>', data);
  const router = useRouter();
  const [showSearchModal, setShowSearchModal] = useState(false);
  const columns = [
    {
      header: data.headerCol1,
      links: data.linksCol1,
      icon: data.iconCol1,
    },
    {
      header: data.headerCol2,
      links: data.linksCol2,
      icon: data.icoReferenceLinknCol2,
    },
    {
      header: data.headerCol3,
      links: data.linksCol3,
      icon: data.iconCol3,
    },
    {
      header: data.headerCol4,
      links: data.linksCol4,
      icon: data.iconCol4,
    },
  ];

  const handleCategoriesClick = () => {
    window.localStorage.setItem('showSideMenu', 'true');
    window.dispatchEvent(new Event('storage'));
  };
  const { asPath } = useRouter();
  useEffect(() => {
    setShowSearchModal(false);
  }, [asPath]);

  return (
    <>
      <div className="fixed-screen-width lg:relative-width">
        {showSearchModal && <MobileSearchModal setOpenModal={setShowSearchModal} />}
        {/* <Highlights /> */}
        <Footer
          columns={columns}
          copyright={data.copyright}
          copyrightLinks={data.copyrightLinks}
          logo={data.logo}
          logoLink={data.logoLink}
        />
      </div>
      <div className="headerBottomDockMain">
        <ul className="headerDockwrap">
          <li className="headerDockItems">
            <Link href={'/'}>
              <span className="headerDockIcon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M3 9L12 2L21 9V20C21 20.5304 20.7893 21.0391 20.4142 21.4142C20.0391 21.7893 19.5304 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V9Z"
                    stroke="#676767"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9 22V12H15V22"
                    stroke="#676767"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </Link>
            Home
          </li>
          <li className="headerDockItems" onClick={() => handleCategoriesClick()}>
            <span className="headerDockIcon">
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.6727 3H19.7228C20.9848 3 22.0085 4.01634 22.0085 5.27014V8.29731C22.0085 9.55016 20.9848 10.5674 19.7228 10.5674H16.6727C15.4098 10.5674 14.3861 9.55016 14.3861 8.29731V5.27014C14.3861 4.01634 15.4098 3 16.6727 3Z"
                  stroke="#676767"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.61998 3H8.66914C9.93206 3 10.9558 4.01634 10.9558 5.27014V8.29731C10.9558 9.55016 9.93206 10.5674 8.66914 10.5674H5.61998C4.35707 10.5674 3.33334 9.55016 3.33334 8.29731V5.27014C3.33334 4.01634 4.35707 3 5.61998 3Z"
                  stroke="#676767"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.61998 13.813H8.66914C9.93206 13.813 10.9558 14.8293 10.9558 16.0841V19.1103C10.9558 20.3641 9.93206 21.3804 8.66914 21.3804H5.61998C4.35707 21.3804 3.33334 20.3641 3.33334 19.1103V16.0841C3.33334 14.8293 4.35707 13.813 5.61998 13.813Z"
                  stroke="#676767"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.6727 13.813H19.7228C20.9848 13.813 22.0085 14.8293 22.0085 16.0841V19.1103C22.0085 20.3641 20.9848 21.3804 19.7228 21.3804H16.6727C15.4098 21.3804 14.3861 20.3641 14.3861 19.1103V16.0841C14.3861 14.8293 15.4098 13.813 16.6727 13.813Z"
                  stroke="#676767"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            Categories
          </li>
          <li
            className="headerDockItems"
            onClick={() => {
              setShowSearchModal(true);
            }}
          >
            <span className="headerDockIcon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                  stroke="#676767"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21.0004 21L16.6504 16.65"
                  stroke="#676767"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            Search
          </li>
          <li className="headerDockItems" onClick={() => router.push('/faq')}>
            <span className="headerDockIcon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.4 1.01999C6.62 1.32999 3 5.51999 3 10.31V17C3 18.66 4.34 20 6 20H7C8.1 20 9 19.1 9 18V14C9 12.9 8.1 12 7 12H5V10.29C5 6.44999 7.96 3.10999 11.79 2.99999C15.76 2.88999 19 6.05999 19 9.99999V12H17C15.9 12 15 12.9 15 14V18C15 19.1 15.9 20 17 20H19V21H13C12.45 21 12 21.45 12 22C12 22.55 12.45 23 13 23H18C19.66 23 21 21.66 21 20V9.99999C21 4.82999 16.64 0.67999 11.4 1.01999Z"
                  fill="#676767"
                />
              </svg>
            </span>
            Need Help
          </li>
        </ul>
      </div>
    </>
  );
};

export default FooterTastic;
