// components/search-hits.js
import { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import router, { useRouter } from 'next/router';
import { connectStateResults } from 'react-instantsearch-dom';
function useOutsideAlerter(ref, setHideSearchDropdown) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setHideSearchDropdown(true);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}
// ...

const SecondarySearchHits = ({
  searchState,
  searchResults,
  setSearchOutput,
  setSearchQuery,
  setRecentSearchIndex,
  hideSearchDropdown,
  setHideSearchDropdown,
  categoryValue,
}) => {
  const { asPath } = useRouter();
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setHideSearchDropdown);
  const [slicedHits, setSlicedHits] = useState([]);
  const [recentSearches, setRecentSearches] = useState([]);
  const [categoryMatches, setCategoryMatches] = useState([]);

  const getCategories = (searchResults, searchState) => {
    const selectedCategorySlug = categoryValue;

    const allCategories = searchResults?.hits[0]?.productProjections.categories[0]?.obj?.ancestors;
    const categoryNames = [];

    if (Object.keys(searchState).length > 0 && allCategories) {
      allCategories.forEach((element) => {
        categoryNames.push({
          name: element.obj.name['en-IN'].toLowerCase(),
          slug: element.obj.slug['en-IN'],
        });
      });
      categoryNames.splice(0, 1);
      setCategoryMatches(categoryNames);

      const filteredHits = searchResults.hits.filter((hit) =>
        hit.productProjections.categories.some((category) => category.obj.slug['en-IN'] === selectedCategorySlug),
      );
      setSlicedHits(filteredHits.slice(0, 5));
    }
  };

  const getRecentSearches = () => {
    if (typeof window !== 'undefined') {
      const recentSearches = JSON.parse(localStorage.getItem('recentSearches') || '[]');
      setRecentSearches(recentSearches);
    }
  };

  useEffect(() => {
    getRecentSearches();
    getCategories(searchResults, searchState);
    setSearchOutput(searchResults);
    setSearchQuery(searchState.query);
  }, [searchResults, searchState, setRecentSearchIndex]);

  useEffect(() => {
    searchState.query = '';
  }, [asPath]);

  const handleRecentSearchClick = (query) => {
    setSearchQuery(query);
    setHideSearchDropdown(true);
    // router.push(`/search?category=${categoryValue}&q=${query}`);
    router.push(`search?facetFilters=productProjections.categorySlugs:${categoryValue}&q=${query}`);
  };

  const validQuery = searchState.query?.trim().length >= 3;

  return searchState.query && !hideSearchDropdown && validQuery ? (
    <div className="search_popup" ref={wrapperRef}>
      <div className="search-popup-left">
        <p className="search-heading">Product Suggestions</p>
        {searchResults?.hits.length === 0 && <div className="search-items-heading pt-2">No results found!</div>}
        {searchResults?.hits.length > 0 &&
          slicedHits.map((hit) => (
            <div key={hit.objectID} className="search-items">
              <span>
                <p
                  className="search-items-heading hover:cursor-pointer hover:underline"
                  onClick={() => handleRecentSearchClick(hit.productProjections.name['en-IN'])}
                >
                  {hit.productProjections.name['en-IN']}
                </p>
                { hit?.productProjections?.masterVariant?.attributes[0]?.value['en-IN'] &&
                  <p className="search-items-cas-no">
                    CAS No. : {hit.productProjections.masterVariant.attributes[0].value['en-IN']}
                  </p>
                }
              </span>
            </div>
          ))}
      </div>
    </div>
  ) : null;
};

export default connectStateResults(SecondarySearchHits);
