import React, { Fragment, useEffect, useState } from 'react';
import SubCategoryModal from './SubCategoryModal';
import { useRouter } from 'next/router';
import { backendAPIClient } from 'helpers/utils/axiosRequestsUtil';
import { STATIC_GET_MEGA_MENU_DETAIL } from 'helpers/constants/statisURLs';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function MegaMenuMobile({megaMenuData}) {
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [megaMenuItems, setMegaMenuItems] = useState([]);
  const router = useRouter()
  const[show,setShow]  = useState(false)
  const handleMegaMenuItems = async () => {
    try {
      const res = await backendAPIClient.get(STATIC_GET_MEGA_MENU_DETAIL);
      setMegaMenuItems(res.data);
    } catch (err) {
      console.log(err);
    }
  }
    useEffect(() => {
      handleMegaMenuItems();
    },[])
  const [showL2Category, setShowL2Category] = useState(0);
  const { asPath } = useRouter();
  useEffect(() => {
    setShowCategoryModal(false);
  }, [asPath]);
const handleNoResultsPage = (name,index) => {
   if(name == 'API' || name == 'All'){
       setShowL2Category(index);
       setShowCategoryModal(true);
   }else{
    router.push('/l1-products')
   }
}
  return (
    <div className="relative mt-[25px] mb-[20px]">
      <div className="ml-[44px] mr-auto text-[14px] font-medium tracking-[1px] text-[#0280C7]">Categories</div>
      <hr className="categories-header-border mt-[5px] mb-[0px]" />
      {megaMenuItems &&
         megaMenuItems?.map((category, index) => (
          <ul key={category.id}>
            <li
              className={
                showL2Category === index
                  ? 'active_category mt-[14px] mr-auto bg-[#F2F8FC] pl-[28px] text-[14px] font-semibold leading-[31px] tracking-[1px] text-[#003769]'
                  : 'mt-[14px] mr-auto ml-[28px] text-[14px] font-medium leading-[20px] tracking-[1px] text-[#003769]'
              }
            >
              <button
                className="mr-auto ml-[14px] tracking-[1px]"
                onClick={
                 () => handleNoResultsPage(category?.name,index)
              }
              >
                {category.name}
              </button>
            </li>
          </ul>
        ))}
      {showCategoryModal && (
        <SubCategoryModal
          setOpenModal={setShowCategoryModal}
          subCategories={megaMenuItems && megaMenuItems[showL2Category].children}
        />
      )}
    </div>
  );
}

export default MegaMenuMobile;
