import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { TermFilterParams } from 'components/commercetools-ui/term-filter';
import { useFormat } from 'helpers/hooks/useFormat';
import { updateURLParams } from 'helpers/utils/updateURLParams';
import { searchQueryText } from '../algolia-search/searchbox';
import Loader from 'components/commercetools-ui/loader';

function SortFilter({ selected, setSelected }) {
  const router = useRouter();
  const [params, setParams] = useState<Array<TermFilterParams>>([]);
  const [loading, setLoading] = useState(false);
  const { formatMessage } = useFormat({ name: 'product' });

  const handleCheckboxChange = (sortByValue) => {
    setLoading(true);
    const url = new URL(window.location.href);
    const existFacetValue = url.searchParams.get('facetFilters');
    const existQuery = url.searchParams.get('q');
    const params = [
      {
        key: 'cursor',
        value: 'offset:0',
      },
    ];
    if (existQuery !== null && existQuery !== undefined) {
      params.push({ key: 'q', value: existQuery });
    }
    if (existFacetValue !== null) {
      const existingFacetValueParam = [
        {
          key: 'facetFilters',
          value: existFacetValue,
        },
      ];
      if (existQuery !== null && existQuery !== undefined) {
        params.push({ key: 'q', value: existQuery });
      }
      params.push(...existingFacetValueParam);
    }
    if (sortByValue !== null) {
      const sortByValueParam = [
        {
          key: 'sortby',
          value: sortByValue,
        },
      ];
      if (existQuery !== null && existQuery !== undefined) {
        params.push({ key: 'q', value: existQuery });
      }
      params.push(...sortByValueParam);
    }

    const currentURL = updateURLParams(params);
    const newSelected = { [sortByValue]: !selected[sortByValue] };
    Object.keys(selected).forEach((key) => {
      if (key !== sortByValue) {
        newSelected[key] = false;
      }
    });

    router.push(currentURL);
    setSelected(newSelected);
    // setSelected({ ...selected, [sortByValue]: !selected[sortByValue] });
  };

  useEffect(() => {
    setLoading(false);
  }, [router.query]);

  const nameOptions: { label: string; value: string }[] = [
    {
      label: formatMessage({ id: 'nameAsc', defaultMessage: 'Alphabetical: A to Z' }),
      value: 'name_asc',
    },
    { label: formatMessage({ id: 'nameDesc', defaultMessage: 'Alphabetical: Z to A' }), value: 'name_desc' },
  ];

  const priceOptions: { label: string; value: string }[] = [
    {
      label: formatMessage({ id: 'priceAsc', defaultMessage: 'Price Low to High' }),
      value: 'price_asc',
    },
    { label: formatMessage({ id: 'priceDesc', defaultMessage: 'Price High to Low' }), value: 'price_desc' },
  ];

  return (
    <>
      {loading && <Loader />}

      <div className="plpLeftFilterItem plp_country_filter flex items-center justify-between">
        
        {nameOptions.map(({ label, value }, index) => (
          <label className="plp_country_filter_options" key={index}>
            <input
              id={value}
              name="alph"
              type="checkbox"
              className='ml-1'
              onChange={(e) => handleCheckboxChange(value)}
              checked={selected[value]}
            />

            <p className="plp_country_filter_label">{label}</p>
          </label>
        ))}
        {priceOptions.map(({ label, value }, index) => (
          <label className="plp_country_filter_options" key={index}>
            <input
              id={value}
              name="price"
              type="checkbox"
              className='ml-1'
              onChange={(e) => handleCheckboxChange(value)}
              checked={selected[value]}
            />
            <p className="plp_country_filter_label">{label}</p>
          </label>
        ))}
      </div>
    </>
  );
}

export default SortFilter;
