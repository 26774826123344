import React, { useMemo, useState,useEffect} from "react";
import PropTypes from "prop-types";
import {StarIcon} from '@heroicons/react/outline';


const Rate = ({ count, rating, color, onRating,reviewCallBack}) => {
  const [hoverRating, setHoverRating] = useState(0);
 

  const getColor = (index) => {
    if (hoverRating >= index) {
      return color.filled;
    } else if (!hoverRating && rating >= index) {
      return color.filled;
    }

    return color.unfilled;
  };

  const starRating = useMemo(() => {
    return Array(count)
      .fill(0)
      .map((_, i) => i + 1)
      .map((idx) => (
        <StarIcon 
          key={idx}
          className="cursor-pointer pdp-ratings"
         onClick={() => onRating(idx)}
          style={{ color: getColor(idx) ,fill: getColor(idx)}}
          onMouseEnter={() => setHoverRating(idx)}
          onMouseLeave={() => setHoverRating(0)}
        />
      ));
  }, [count, rating, hoverRating]);
  useEffect(() => {
    reviewCallBack(rating)
 },[rating])
  return <div className="flex">{starRating}</div>;
};

Rate.propTypes = {
  count: PropTypes.number,
  rating: PropTypes.number,
  onChange: PropTypes.func,
  color: {
    filled: PropTypes.string,
    unfilled: PropTypes.string,
  },
};

Rate.defaultProps = {
  count: 5,
  rating: 0,
  color: {
    filled: "#FEB343",
    // unfilled: "#DCDCDC",
    //unfilled: '#FFFFFF'
  },
};

export default Rate;
