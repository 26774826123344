const AddressHeaderText = ({ text, onCollapseClick, isCollapsed }) => {
  return (
    <div className="flex align-middle text-lg font-bold">
      <span onClick={()=>{onCollapseClick(!isCollapsed)}} className="cursor-pointer">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7 10.675V12.6159H17V10.675H7ZM12 1.94092C6.48 1.94092 2 6.28855 2 11.6455C2 17.0024 6.48 21.35 12 21.35C17.52 21.35 22 17.0024 22 11.6455C22 6.28855 17.52 1.94092 12 1.94092ZM12 19.4091C7.59 19.4091 4 15.9252 4 11.6455C4 7.36576 7.59 3.88183 12 3.88183C16.41 3.88183 20 7.36576 20 11.6455C20 15.9252 16.41 19.4091 12 19.4091Z"
          fill="#0068A3"
        />
      </svg>
      </span>
      <span className="ml-1">{text}</span>
    </div>
  );
};

export default AddressHeaderText;