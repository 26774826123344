import React, { useState, useEffect } from 'react';
import SellerImage from './seller-image';
import SellerDetails from './seller-details';
import SellerAdditionalDetails from './seller-additional-details';
import vendorImage from '../../../../../frontend/public/images/vendor.jpg'
import ProductsSoldByVendor from '../products-soldBy-vendor';
import { backendAPIClient } from 'helpers/utils/axiosRequestsUtil';
import { STATIC_GET_VENDOR_DETAILS } from 'helpers/constants/statisURLs';
import { useRouter } from 'next/router';

const SellerInformation = () => {
  const [vendorInfo, setVendorInfo] = useState(null)
  const router = useRouter();
  const vendorCode = router?.query?.['vendor-code']
  const handleVendorInfo = async () => {
    try {
      const response = await backendAPIClient.get(`${STATIC_GET_VENDOR_DETAILS}/?vendorCode=${vendorCode}`);
      setVendorInfo(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleVendorInfo();
  }, []);
  return (
    <div className='mt-[50px] flex flex-col lg:mx-[0px]'>
      <div className='lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-2 2xl:gap-x-3 mx-[20px] lg:mx-[0px]'>
        <p className='lg:col-start-2 lg:col-span-5 vendor-info-name'>About {vendorInfo?.value?.vendorName}</p>
      </div>
      <div className='lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-2 2xl:gap-x-3 mx-[20px] lg:mx-[0px] vendor-information'>
        <div className="lg:col-start-2 lg:col-span-2">
          <SellerImage image={vendorInfo?.value?.logo} />
        </div>
        <div className="lg:col-span-5">
          <SellerDetails vendorInfo={vendorInfo} />
        </div>
        <div className="lg:col-span-4 lg:col-ends-12">
          <SellerAdditionalDetails
            address={vendorInfo?.value?.address}
            orgType={vendorInfo?.value?.buyerOrgType} />
        </div>
      </div>
      <div className='mt-[25px] lg:mt-[40px]'>
        <ProductsSoldByVendor vendorInfo={vendorInfo} />
      </div>
    </div>
  );
};

export default SellerInformation;