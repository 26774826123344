import React from 'react';


const ProductCertifications = ({ starRating }) => {
  return (
    <div className="product_certifications flex">
      <div className="flex">
        <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.9 14.1L12.6 8.39998L11.55 7.37498L6.975 11.95L4.45 9.42498L3.35 10.525L6.9 14.1ZM8 20.975C5.66667 20.3916 3.75 19.0375 2.25 16.9125C0.75 14.7875 0 12.4583 0 9.92498V3.97498L8 0.974976L16 3.97498V9.92498C16 12.4583 15.25 14.7875 13.75 16.9125C12.25 19.0375 10.3333 20.3916 8 20.975Z"
            fill="#32C902"
          />
        </svg>
        <p className="ml-1 mt-0.5 text-xs font-medium tracking-[1px] text-[#000000]">Verified</p>
      </div>
      {/* <div className="ml-4 flex ">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7 15.35H12.75C12.9833 15.35 13.1792 15.3 13.3375 15.2C13.4958 15.1 13.6333 14.95 13.75 14.75L15.7 10.2C15.7333 10.1167 15.7625 9.99167 15.7875 9.825C15.8125 9.65833 15.825 9.53333 15.825 9.45V8.85C15.825 8.61667 15.7708 8.44583 15.6625 8.3375C15.5542 8.22917 15.3833 8.175 15.15 8.175H9.8L10.525 4.725C10.5583 4.59167 10.5583 4.46667 10.525 4.35C10.4917 4.23333 10.4333 4.13333 10.35 4.05L9.825 3.5L5.8 7.85L5.6 8.25C5.53333 8.38333 5.5 8.525 5.5 8.675V13.85C5.5 14.2333 5.65 14.5792 5.95 14.8875C6.25 15.1958 6.6 15.35 7 15.35ZM10 20C8.63333 20 7.34167 19.7375 6.125 19.2125C4.90833 18.6875 3.84583 17.9708 2.9375 17.0625C2.02917 16.1542 1.3125 15.0917 0.7875 13.875C0.2625 12.6583 0 11.3667 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.02917 3.825 2.9375 2.925C3.84583 2.025 4.90833 1.3125 6.125 0.7875C7.34167 0.2625 8.63333 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3667 19.7375 12.6583 19.2125 13.875C18.6875 15.0917 17.975 16.1542 17.075 17.0625C16.175 17.9708 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z"
            fill="#0280C7"
          />
        </svg>
        <p className="ml-1 mt-0.5 text-xs font-medium tracking-[1px] text-[#000000]">Trusted</p>
      </div> */}
    </div >
  );
};

export default ProductCertifications;