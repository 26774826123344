import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import CustomAccordion from 'components/commercetools-ui/accordion/customAccordion';
import { updateURLParams, URLParam } from 'helpers/utils/updateURLParams';
import { searchQueryText } from '../algolia-search/searchbox';
import SortFilter from './sort-filter';
function SortByFilters() {
  const [selected, setSelected] = useState<Record<string, boolean>>({});
  return (
    <CustomAccordion
      openSectionTitle="Sort By"
      closedSectionTitle="Sort By"
      className="secondary_search_filter_sortby accordion_search_item"
    >
      <SortFilter selected={selected} setSelected={setSelected} />
    </CustomAccordion>
  );
}

export default SortByFilters;
