import { FC, ReactNode } from 'react';

export interface Props {
  readonly children: ReactNode;
  /*
   * function called to close modal
   */
  readonly setOpenModal: (boolean) => void;
}

const AppModal: FC<Props> = ({ setOpenModal, children }: Props) => {
  return (
    <>
      <div className="app_modal fixed right-0 top-0 z-50 h-full w-full overflow-scroll bg-gray-900 bg-opacity-75">
        <div className="bg-black fixed inset-0 h-full w-full opacity-100" onClick={() => setOpenModal(false)}></div>
        <div className=" add_Review_Popup flex min-h-screen items-center px-4 py-8">
          <div className=" app_modal_height relative mx-auto my-auto mt-[30%] max-h-fit w-full max-w-fit rounded-md bg-white shadow-lg md:mt-[20%] lg:mt-[10%]  lg:max-w-[756px]">
            <div className="plpMobileFilterTextWrap">
              <div className="plpMobileFilterText ml-auto mr-auto lg:mt-[20px]">
                <p className="review_modal_closebtn absolute" onClick={() => setOpenModal(false)}>
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                      fill="#0068A3"
                    />
                  </svg>
                </p>
              </div>
            </div>
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default AppModal;
