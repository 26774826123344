import React from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
import { Reference, ReferenceLink } from 'helpers/reference';
import { useAccount } from './../../../frontastic';
import { useRouter } from 'next/router';
interface CartButtonProps {
  cartItemCount?: number;
  cartLink?: Reference;
}

const CartButton: React.FC<CartButtonProps> = ({ cartItemCount, cartLink }) => {
  //i18n messages
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const { account } = useAccount();
  if (!account) {
    cartLink['target'] = '/login';
    cartItemCount = 0;
  }
  let cartCountClassNames = 'absolute -top-2 -right-3 rounded-full bg-accent-400 hover:bg-accent-500';
  const router = useRouter();
  if (cartItemCount?.toString().split('').length > 2) {
    cartCountClassNames += ' h-6 w-6';
  } else {
    cartCountClassNames += ' h-4 w-4';
  }
const navigateTOCart = () => {
  if (!account) {
    router.push({pathname:'/login',query: router.asPath});
  } else{
     router.push('/cart')
  }
}
  return (
    <div className="flow-root">
      {/* <ReferenceLink target={cartLink} className="headerCartIconMain relative -m-2 flex items-center"> */}
      <div onClick={navigateTOCart} className="headerCartIconMain relative -m-2 flex items-center cursor-pointer p-2"> 
        <svg
          className="headerCartIcon"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 22C9.55228 22 10 21.5523 10 21C10 20.4477 9.55228 20 9 20C8.44772 20 8 20.4477 8 21C8 21.5523 8.44772 22 9 22Z"
            stroke="#003769"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20 22C20.5523 22 21 21.5523 21 21C21 20.4477 20.5523 20 20 20C19.4477 20 19 20.4477 19 21C19 21.5523 19.4477 22 20 22Z"
            stroke="#003769"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1 1H5L7.68 14.39C7.77144 14.8504 8.02191 15.264 8.38755 15.5583C8.75318 15.8526 9.2107 16.009 9.68 16H19.4C19.8693 16.009 20.3268 15.8526 20.6925 15.5583C21.0581 15.264 21.3086 14.8504 21.4 14.39L23 6H6"
            stroke="#003769"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        {cartItemCount > 0 && (
          <>
            <span className={cartCountClassNames}>
              <span className="flex h-full w-full items-center justify-center text-xs text-white group-hover:text-white">
                {cartItemCount}
              </span>
            </span>
            <span className="sr-only">
              {formatCartMessage({
                id: 'cart.items.in.view',
                defaultMessage: 'items in cart, view cart',
              })}
            </span>
          </>
        )}
        </div>
      {/* </ReferenceLink> */}
    </div>
  );
};

export default CartButton;
