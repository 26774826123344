import { Cart } from '@Types/cart/Cart';
import OrderSummaryItem from './order-summary-item';
import PriceOrderSummary from './price-order-summary';

interface Props {
  readonly cart: Cart;
}

const OrderSummaryCheckout = ({ cart }: Props) => {
  return (
    <div className="bg-[#F3F5FD] pb-[25px] ">
      <p className="order-summary-heading">
        Order Summary <span>({cart?.lineItems?.length})</span>
      </p>
      {cart?.lineItems?.map((item) => (
        <div key={item?.lineItemId}>
          <OrderSummaryItem item={item} />
        </div>
      ))}
      <PriceOrderSummary  cart={cart}/>
    </div>
  );
};
export default OrderSummaryCheckout;
