import Loader from 'components/commercetools-ui/loader';
import { DR_VENDOR_NAME } from 'helpers/constants/constant';
import { checkIfUserIsSSOVerified } from 'helpers/helper';
import { useAccount } from 'frontastic/provider';
import ProductList from '../../../../components/commercetools-ui/products/product-list';
import NoResultsFound from '../../../../components/commercetools-ui/products/product-list/algolia-search/no-results-found';
import { useEffect } from 'react';
import { getRelatedProducts } from 'frontastic/actions/search';

function ProductListTastic({ data }) {
  const { isSSOLoggedIn, account } = useAccount();
  if (!data?.data?.dataSource) return <></>;

  const { items, facets, category, previousCursor, nextCursor, total, totalItems } =
    data.data.dataSource;

  if (facets.length) {
    const vendorNameList = facets.find((e) => e.key === 'productProjections.variants.filterableAttributes.vendor-name');
    const facetsListForVendorName =
      vendorNameList && vendorNameList.terms && vendorNameList.terms.filter((e) => e.label === DR_VENDOR_NAME);
    const vendorNameFacetNo = facets.findIndex(
      (e) => e.key === 'productProjections.variants.filterableAttributes.vendor-name',
    );
    if (facets[vendorNameFacetNo] && account && !checkIfUserIsSSOVerified(account.email)) {
      facets[vendorNameFacetNo].terms = facetsListForVendorName;
    }
  }

  return items ? (
    items.length > 0 ? (
      <>
        <ProductList
          products={items}
          totalProducts={total}
          facets={facets}
          category={items[0]?.categories[0]?._url}
          previousCursor={previousCursor}
          nextCursor={nextCursor}
          searching={false}
          facetKey={''}
        />
      </>
    ) : (
      <NoResultsFound />
    )
  ) : (
    <Loader />
  );
}

export default ProductListTastic;
