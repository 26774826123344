import React from 'react'
const InitialsCircle = ({ firstName, lastName }) => {

  const getFirstChar = (str) => {
    if (str?.length === 0) {
      return null
    }
    return str?.charAt(0) || ''
  }

  const firstInitial = getFirstChar(firstName || '')?.toUpperCase() || ''
  const lastInitial = getFirstChar(lastName || '')?.toUpperCase() || ''
  return (
    <div className="flex items-center justify-center w-12 h-12 bg-blue-300 rounded-full">
      <span className="text-2xl font-semibold theme-circle-text">
        {firstInitial}
        {lastInitial}
      </span>
    </div>
  )
}
export default InitialsCircle