import VendorListing from 'components/commercetools-ui/vendor-list';
import VendorListFilters from 'components/commercetools-ui/vendor-list/vendor-list-filters';
import Link from 'next/link';
import React, { useCallback, useEffect, useState } from 'react';

function VendorList() {
  const [y, setY] = useState(window.scrollY);
  const [scrollUp, setScrollUp] = useState(false);
  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      if (y > window.scrollY && window.scrollY != 0) {
        setScrollUp(true);
      } else {
        setScrollUp(false);
      }
      setY(window.scrollY);
    },
    [y],
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener('scroll', handleNavigation);

    return () => {
      window.removeEventListener('scroll', handleNavigation);
    };
  }, [handleNavigation]);

  return (
    <div className="vendor-list-wrapper">
      <h1 className="cart-breadcrumb">
        <Link href="/">
          <a className="pdpBreadcrumbItem text-[#0280C7] hover:text-gray-500">
            <span className="mr-3 cursor-pointer font-medium capitalize hover:text-gray-600">Home Page</span>
          </a>
        </Link>
        /{' '}
        <span className="pdpBreadcrumbItem" style={{ marginLeft: '6px' }}>
          Vendor List
        </span>
      </h1>
      <div className="z-10 flex flex-col justify-between bg-[#fafafa] md:flex-row md:pt-5">
        <h1 className="vendor-list-heading">Vendor Listing</h1>
        <div>
          <VendorListFilters />
        </div>
      </div>
      <VendorListing scrollUp={scrollUp} />
    </div>
  );
}

export default VendorList;
