import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import 'react-datepicker/dist/react-datepicker.css';
import DocUpload from './docUpload';
import AccordionBtn from '../../../../components/commercetools-ui/accordion';

function PurchaseOrder({ onPorefNumberChange, onDocumentUpload, onDateChange }) {
  const [startDate, setStartDate] = useState(null);
  const [file, setFile] = useState<File>();
  const [poRefNumber, setPoRefNumber] = useState('');
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const { formatMessage } = useFormat({ name: 'common' });
  const accept = '.pdf';

  const onFileChange = (file: File) => {
    setIsFileUploaded(false);
    setFile(file);
    onDocumentUpload(file);
  };

  const onUploadFile = async (file: File) => {
    onDocumentUpload(file);
  };

  const clearUpload = () => {
    setFile(null);
    setIsFileUploaded(false);
  };

  const buttonElement = (
    <button className="btn ml-2 !w-[30%]" onClick={() => {}} disabled={false}>
      Upload File
    </button>
  );
  const handlePoRefNumberChange = (e) => {
    const value = e.target.value;
    const regex = /^[0-9a-zA-Z]+$/; //this will admit letters, numbers and dashes
    if (value.match(regex) || value === ""){
      setPoRefNumber(value );
    }
   // setPoRefNumber(value);
    onPorefNumberChange(value);
  };
  const handleDateChange = (date) => {
    setStartDate(date);
    onDateChange(date);
  };

  return (
    <AccordionBtn
      openSectionTitle="3. Purchase Order Details"
      closedSectionTitle="3. Purchase Order Details"
      className="collapse-title"
    >
      <div className="lg:mt-[20px]">
        <label htmlFor="date" className="email_label ">
          {formatMessage({ id: 'PO Reference Number', defaultMessage: 'PO Reference Number' })}
        </label>
        <div className="div">
          <div className="purchase-order relative mt-0">
            <input
              id="email2"
              name="email"
              type="text"
              autoComplete="email"
              placeholder={formatMessage({
                id: 'Enter PO Refrence number',
                defaultMessage: 'Enter PO Refrence number',
              })}
              className="email_input focus:border-accent-400 focus:ring-accent-400 block w-full appearance-none rounded-sm border border-gray-300 px-3 py-2 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm"
              value={poRefNumber}
              onChange={handlePoRefNumberChange}
              // onChange={(e) => setPoRefNumber(e.target.value)}
            />
          </div>
          <div className="">
            <label htmlFor="date" className="email_label block lg:mt-[20px]">
              {formatMessage({ id: 'PO Document', defaultMessage: 'PO Document' })}
            </label>
            <label htmlFor="date" className="email_label lg:mt-[20px] ">
              {formatMessage({
                id: 'Only PDF, Word, .xls files. 5 MB max file size.',
                defaultMessage: 'Only PDF, Word, .xls files. 5 MB max file size.',
              })}
            </label>
            <div className="podetails-container">
              <DocUpload
                file={file}
                name="file_upload"
                label=""
                accept={accept}
                isFileUploaded={isFileUploaded}
                onFileChange={onFileChange}
                onUpload={onUploadFile}
                clearUpload={clearUpload}
              />
            </div>
          </div>
          <div className="lg:mt-[10px]">
            <label htmlFor="date" className="email_label lg:mt-[20px]">
              {formatMessage({ id: 'PO Date', defaultMessage: 'PO Date' })}
            </label>

            <div className="purchase-order relative">
              <DatePicker
                showIcon
                selected={startDate}
                onChange={handleDateChange}
                placeholderText="(DD/MM/YYYY)"
                dateFormat="dd-MM-yyyy"
              />
            </div>
          </div>
        </div>
      </div>
    </AccordionBtn>
  );
}

export default PurchaseOrder;
