import { HOW_ALLVERZ_WORKS_PAGE_VIEWED } from 'helpers/constants/events';
import React, { useCallback, useEffect } from 'react';

function AllverzWorks() {
  const handleAllverzWorksAnalytics = useCallback(async () => {
    gtag('event', HOW_ALLVERZ_WORKS_PAGE_VIEWED, {});
  }, []);
  useEffect(() => {
    handleAllverzWorksAnalytics();
  }, [handleAllverzWorksAnalytics]);
  return (
    <div className="allverzWorksBreadcrumbs">
      <div className="flex pt-20">
        <div className="card-breadcrumbs">
          <a href="/" style={{ color: '#0280C7' }}>
            Home Page
          </a>
          <a>/</a>
          <a>Allverz</a>
        </div>
      </div>
      <div className="blog-header flex">
        <h2 className="heading">How Allverz works?</h2>
      </div>
    </div>
  );
}

export default AllverzWorks;
