import { useCallback, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import {
  PDP_PRODUCT_ADDED_TO_CART,
  PDP_VIEWED,
  PDP_VIEWED_AFTER_SEARCH,
  PDP_PRODUCT_ADDED_TO_CART_AFTER_SEARCH,
} from 'helpers/constants/events';

const useTrack = ({ product }) => {
  const { query } = useRouter();
  const lastProductId = useRef<string>();

  const trackView = useCallback(async () => {
    if (product && product.productId !== lastProductId.current) {
      gtag('event', query.sr ? PDP_VIEWED_AFTER_SEARCH : PDP_VIEWED, { product });
      lastProductId.current = product.productId;
    }
  }, [product, query]);

  const trackAddToCart = useCallback(async () => {
    const eventName = query.sr ? PDP_PRODUCT_ADDED_TO_CART_AFTER_SEARCH : PDP_PRODUCT_ADDED_TO_CART;

    gtag('event', eventName, product);
  }, [product, query]);

  return { trackAddToCart, trackView };
};

export default useTrack;
