import React, { useEffect, useState } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { EyeIcon, ChevronLeftIcon, ChevronRightIcon, DotsHorizontalIcon } from '@heroicons/react/outline';
import Loader from 'components/commercetools-ui/loader';
import Price from 'components/commercetools-ui/price';
import { commercetoolsAPIClient } from 'helpers/utils/axiosRequestsUtil';
import { useAccount } from 'frontastic';

const OrdersList = () => {
  const { account } = useAccount();
  const [orders, setOrders] = useState([]);
  const [limit, setLimit] = useState(12);
  const [offset, seetOffset] = useState(0);
  const [totalProducts, setTotlaProducts] = useState(0);
  const [numberOfPages, setnumberOfPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const router = useRouter();
  const shippingCharges = 2000;

  const reqHeaders = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.localStorage.getItem('token')}`,
    },
  };

  useEffect(() => {
    if (account)
      commercetoolsAPIClient
        .get(`orders?limit=${limit}&offset=${offset}&where=customerId%3D%22${account.accountId}%22`, reqHeaders)
        .then((res) => {
          console.log('res?.data?.results - ', res?.data?.results);
          setOrders(res?.data?.results.reverse());
          setnumberOfPages(Math.ceil(res?.data?.total / limit));
          setTotlaProducts(res?.data?.total);
          setLoading(false);
        });
  }, [offset, limit, account]);

  const prepareDate = (dateValue) => {
    const x = new Date(dateValue.toString());
    return `${x.getDate()}/${x.getMonth() + 1}/${x.getFullYear()}`;
  };

  const handlePerPageSelect = (e) => {
    setLimit(e.target.value);
  };

  const handlePageButton = (pageNumber: number) => {
    seetOffset(pageNumber);
  };

  const getPageButton = (pageNumber: number) => {
    const buttonClasses = pageNumber === offset ? 'order-page-button order-page-button-active' : 'order-page-button';
    return (
      <button className={buttonClasses} onClick={() => handlePageButton(pageNumber)}>
        {pageNumber + 1}
      </button>
    );
  };

  const handleArrow = (v) => {
    seetOffset(offset + v);
  };

  const getLeftArrowButton = () => {
    return (
      <button onClick={() => handleArrow(-12)} disabled={offset === 0}>
        <ChevronLeftIcon
          key="1"
          width="24"
          height="24"
          color={offset === 0 ? 'gray' : '#32C902'}
          style={{ border: `1px solid ${offset === 0 ? 'gray' : '#32C902'}`, borderRadius: '4px' }}
        />
      </button>
    );
  };

  const getRightArrowButton = () => {
    return (
      <button onClick={() => handleArrow(12)} disabled={offset === numberOfPages - 1}>
        <ChevronRightIcon
          key={totalProducts + 1}
          width="24"
          height="24"
          color={offset === numberOfPages - 1 ? 'gray' : '#32C902'}
          style={{ border: `1px solid ${offset === numberOfPages - 1 ? 'gray' : '#32C902'}`, borderRadius: '4px' }}
        />
      </button>
    );
  };

  const getThreeDots = () => {
    return (
      <div className="order-page-button">
        <DotsHorizontalIcon width={24} height={24}></DotsHorizontalIcon>
      </div>
    );
  };

  const pushThreePageButtons = (pages, indexes) => {
    pages.push(getPageButton(indexes[0]));
    pages.push(getPageButton(indexes[1]));
    pages.push(getPageButton(indexes[2]));
  };

  const Pagination = () => {
    const pages = [];

    if (numberOfPages > 0) {
      pages.push(getLeftArrowButton());
    }
    if (numberOfPages <= 5) {
      for (let i = 0; i < numberOfPages; ++i) {
        pages.push(getPageButton(i));
      }
    } else {
      pages.push(getPageButton(0));
      if (offset <= 2) {
        pushThreePageButtons(pages, [1, 2, 3]);
        pages.push(getThreeDots());
      } else if (offset < numberOfPages - 3) {
        pages.push(getThreeDots());
        pushThreePageButtons(pages, [offset - 1, offset, offset + 1]);
        pages.push(getThreeDots());
      } else {
        pages.push(getThreeDots());
        for (let i = numberOfPages - 4; i < numberOfPages - 1; ++i) {
          pages.push(getPageButton(i));
        }
      }
      pages.push(getPageButton(numberOfPages - 1));
    }
    if (numberOfPages > 0) {
      pages.push(getRightArrowButton());
    }
    return <div style={{ display: 'flex' }}>{pages.map((p) => p)}</div>;
  };

  const handleViewOrderDetailsClick = (orderId) => {
    router.push(`order-history?id=${orderId}`);
  };

  return (
    <>
      {loading && <Loader />}
      <div>
        <h1 className="cart-breadcrumb">
          <NextLink href="/">
            <a className="pdpBreadcrumbItem text-[#0280C7] hover:text-gray-500">
              <span className="mr-3 cursor-pointer font-medium capitalize hover:text-gray-600">Home Page</span>
            </a>
          </NextLink>
          <span className="quote-breadcrumb-seperator">/</span>
          <span className="quote-breadcrumb-part">My Account</span>
          <span className="quote-breadcrumb-seperator">/</span>
          <span className="quote-breadcrumb-part">Order List</span>
        </h1>
        <div className="request-for-quote-top-banner">
          <div>
            <div className="quote-title">Order List ({totalProducts})</div>
            <p>The following are the list of your orders.</p>
          </div>
        </div>
        <div style={{ marginTop: '50px' }}>
          <div className="tableContainer">
            <table style={{ width: '100%' }}>
              <thead className="tablehead-order-list">
                <tr>
                  <th>Order Number</th>
                  <th>PO Reference No.</th>
                  <th>Order Date</th>
                  <th>Delivery Date</th>
                  <th>Total Price</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {orders && orders.length > 0 ? 
                  orders.map((order) => {
                  // order.taxedPrice.totalGross.centAmount + shippingCharges;
                    return (
                      <>
                        <tr className="desktop-order-list" key={order.orderNumber}>
                          <td className="cursor-pointer" onClick={() => handleViewOrderDetailsClick(order.id)}>
                            {order.orderNumber}
                          </td>
                          <td>{order.purchaseOrderNumber}</td>
                          <td>{prepareDate(order.createdAt.toString()).toString()}</td>
                          <td>{prepareDate(order.createdAt.toString()).toString()}</td>
                          <td>
                            <Price
                              price={order.taxedPrice.totalGross.centAmount + shippingCharges}
                              className="priceTd"
                            ></Price>
                          </td>
                          <td>
                            <span className={`order-status status-${order.orderState}`}>{order.orderState}</span>
                          </td>
                          <td className="action-eye">
                            <EyeIcon
                              width="24px"
                              height="24px"
                              color="#0068A3"
                              cursor="pointer"
                              onClick={() => handleViewOrderDetailsClick(order.id)}
                            />
                          </td>
                        </tr>
                      </>
                    );
                  }) :
                  <tr className="desktop-order-list">
                  {'No Records Found'}  
                  </tr>
                }
              </tbody>
            </table>
          </div>
          <div
            className="order-list-pagination"
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '150px' }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>Orders per page:</div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <select
                  className="select w-full max-w-xs"
                  onChange={handlePerPageSelect}
                  style={{
                    border: '1px solid',
                    width: '100px',
                    height: '44px',
                    minHeight: 'unset',
                    marginLeft: '15px',
                    textAlign: 'center',
                    backgroundColor: 'white',
                  }}
                >
                  <option value={12}>12</option>
                  <option value={24}>24</option>
                  <option value={36}>36</option>
                  <option value={48}>48</option>
                </select>
              </div>
            </div>
            {/* <div>{Pagination()}</div> */}
            <div>{totalProducts > 12 ? Pagination() : null}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrdersList;
