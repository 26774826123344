import React, { useState } from 'react';
import Markdown from 'components/commercetools-ui/content/markdown';
import Typography from 'components/commercetools-ui/typography';
import TestimonialSlider from './testimonial-slider';
import AddHomeReview from './HomeReviewModal';
import { useRouter } from 'next/router';
import { useAccount } from 'frontastic';

const ContentGalleryTestimonials = ({ data }) => {
  //   const title = data.data?.dataSource?.fields?.title;
  //   const body = data.data?.dataSource?.fields?.body;
  const [showHomeReviewModal, setShowHomeReviewModal] = useState(false);
  const { account } = useAccount();
  const router = useRouter();
  const testimonialData = data?.data?.dataSource?.attributes;
  const handleAddReview = () => {
    if (!account) {
      router.push({ pathname: '/login', query: router.asPath });
    } else {
      setShowHomeReviewModal(true);
    }
  };
  return (
    <>
      <div className="homepageTestimonialMain">
        <div className="container mx-auto px-5 md:px-0 lg:px-0">
          <div className="homepageTestimonialWrap md:grid lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2 2xl:gap-[97px]">
            <div className="col-span-1 text-center lg:text-left 2xl:text-left">
              <p className="testimonialLeftTitle">
                {testimonialData?.descriptionBlockWithButton?.content?.fields?.title}
              </p>
              <p className="testimonialLeftDescription">
                <Markdown
                  className=""
                  text={testimonialData?.descriptionBlockWithButton?.content?.fields?.titleDescription || ''}
                />
              </p>
              {/* <a href={testimonialData?.descriptionBlockWithButton?.content?.fields?.buttonLink} className="testimonialLeftLink"> */}
              {/* leave review button */}
              <button className="testimonialLeftButton leave_review_button" onClick={handleAddReview}>
                {testimonialData?.descriptionBlockWithButton?.content?.fields?.buttonText}
              </button>
              {showHomeReviewModal && <AddHomeReview setOpenModal={setShowHomeReviewModal} />}
              {/* </a> */}
            </div>
            <div className="col-span-1 xl:pr-3">
              <TestimonialSlider data={testimonialData} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContentGalleryTestimonials;
