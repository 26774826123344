import React, { useEffect, useState } from 'react';
import router, { useRouter } from 'next/router';
import { Product } from '@Types/product/Product';
import { Facet } from '@Types/result/Facet';
import { commercetoolsAPIClient } from 'helpers/utils/axiosRequestsUtil';
import { useAccount } from 'frontastic';
import Breadcrumb from '../../../../components/commercetools-ui/breadcrumb';
import AlgoliaSearch from './algolia-search/search-algolia';
import { searchQueryText } from './algolia-search/searchbox';
import SecondarySearchAlgolia from './algolia-search/secondarySearchAlgolia';
import FilterDropdown from './filter-selections/filters';
import Modal from './modal';
import Pagination from './pagination';
import ProductTiles from './product-tiles';
import SecondaryFilters from './secondary-search-filters/secondary-search-filters';
import SortByModal from './secondary-search-filters/sortbyModal';
import SortByFilters from './sort-selections';

export interface Props {
  products: Product[];
  previousCursor: string;
  nextCursor: string;
  category: string;
  facets: Facet[];
  totalProducts: number;
  searching: boolean;
  facetKey: string;
}
export default function ProductList({
  products,
  totalProducts,
  previousCursor,
  nextCursor,
  category,
  facets,
  facetKey,
  searching,
}: Props) {
  const categoryValue = products[0]?.categories[0]?.slug;
  const productsPerPage = 12;
  const [showModal, setShowModal] = useState(false);
  const [showSortByModal, setShowSortByModal] = useState(false);
  const [numberOfPages, setNumberOfPages] = useState(Math.ceil(totalProducts / productsPerPage));
  const [breadcrumbValue, setBreadcrumbValue] = useState([]);
  const [breadcrumbSlug, setBreadcrumbSlug] = useState([]);
  const [currentCategoryValue, setCurrentCategoryValue] = useState<string>('');
  const [triggerSearch, setTriggerSearch] = useState(false);
  const { asPath } = useRouter();
  const slug = asPath.slice(1).split('?')[0];
  const urlParams = new URLSearchParams(window.location.search);
  const query = urlParams.get('q');
  const handleSearchClick = () => {
    setTriggerSearch(true);
  };
  const Breadcrumbs = async (slug) => {
    try {
      const response = await commercetoolsAPIClient.get(
        `categories?expand=ancestors[*]&where=slug(en-IN%3D%22${slug}%22)`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
          },
        },
      );
      if (!searching && response.data.total) {
        const slugArr = [];
        const nameArr = [];
        const data = response.data;
        data?.results[0].ancestors.map((item) => {
          slugArr.push(item.obj.slug['en-IN']);
          nameArr.push(item.obj.name['en-IN']);
        });
        slugArr.shift();
        nameArr.shift();
        slugArr.push(data?.results[0].slug['en-IN']);
        nameArr.push(data?.results[0].name['en-IN']);
        setBreadcrumbSlug(slugArr);
        setBreadcrumbValue(nameArr);
      } else {
        const breadcrumbValue = asPath?.slice(1).split('?')[0];
        setBreadcrumbValue([breadcrumbValue]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    Breadcrumbs(slug);
    setNumberOfPages(Math.ceil(totalProducts / productsPerPage));
  }, [slug, totalProducts]);

  return (
    <div className="">
      <div className={query && 'plpMarketingBanner'}>
        <img src="" alt="" />
      </div>
      <span className="plpMobileContentTop">
        <div className="plpBreadcrumbWrap">
          {breadcrumbValue && (
            <Breadcrumb Separator="/" categorySlugs={breadcrumbSlug}>
              {breadcrumbValue}
            </Breadcrumb>
          )}
        </div>

        {query !== '*' && (
          <div className="plpCategoryTitle">
            <p className="plpCategoryHead capitalize">
              {query ? (
                <>
                  <span className="text-xl">Result found for </span>
                  <span>&quot;{query}&quot;</span>
                </>
              ) : (
                <span>{currentCategoryValue}</span>
              )}
            </p>
          </div>
        )}

        <div className="search_results px-5 py-[15px]">
          <svg
            onClick={() => handleSearchClick()}
            className="plp_search plp_search_Icon ml-2"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
              stroke="#003769"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.0004 21L16.6504 16.65"
              stroke="#003769"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="plp-search-bar flex">
            <SecondarySearchAlgolia
              searchInputStyle={'search_Input-mob-plp search_Input'}
              placeHolder={'Search Chemical name & CAS No.'}
              triggerSearch={triggerSearch}
              categoryValue={categoryValue}
            />
            <button onClick={() => handleSearchClick()} className="btn-search-mob-plp">
              Search
            </button>
          </div>
        </div>
      </span>
      {/* Mobile modal filter */}
      <span className="lg:hidden">
        <div className="filter_sortby">
          <button
            className="plpFilterModalBtn"
            type="button"
            onClick={() => {
              setShowModal(true);
            }}
          >
            Filters
          </button>
          <button
            className="plpFilterModalBtn"
            type="button"
            onClick={() => {
              setShowSortByModal(true);
            }}
          >
            Sort By
          </button>
        </div>
        {showModal && (
          <Modal setOpenModal={setShowModal}>
            <FilterDropdown facets={facets} facetKey={facetKey} />
          </Modal>
        )}
        {showSortByModal && (
          <SortByModal setOpenModal={setShowSortByModal}>
            <SortByFilters />
          </SortByModal>
        )}
      </span>
      <div className="plpUpperContainer container mx-auto px-5 sm:px-0 lg:px-0">
        <div className="md:grid lg:grid-cols-6 2xl:grid-cols-6 2xl:gap-[20px]">
          <span className="col-span-1 hidden lg:block">
            <FilterDropdown facets={facets} facetKey={facetKey} />
          </span>

          <div className="plpRightWrap col-span-5">
            {/* <div className="plpRightWrap col-span-2 col-start-2 col-end-7"> */}
            <div className="plpFixedContainer">
              <span className="plpDesktopContentTop">
                <div className="plpBreadcrumbWrap">
                  {breadcrumbValue && (
                    <Breadcrumb Separator="/" categorySlugs={breadcrumbSlug}>
                      {breadcrumbValue}
                    </Breadcrumb>
                  )}
                </div>

                {query !== '*' && (
                  <div className="plpCategoryTitle">
                    <p className="plpCategoryHead capitalize">
                      {query ? (
                        <>
                          <span className="text-xl">Result found for </span>
                          <span>&quot;{query}&quot;</span>
                        </>
                      ) : (
                        <span>{currentCategoryValue}</span>
                      )}
                    </p>
                  </div>
                )}

                <div className="plpCategoryPara">{!searching && <p className="plpCategoryParaText"></p>}</div>
              </span>
              <div className="filter_items">
                <SecondaryFilters facets={facets} facetKey={facetKey} />
                <div className="search_results">
                  <svg
                    onClick={() => handleSearchClick()}
                    className="plp_search_Icon mx-2"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                      stroke="#003769"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M21.0004 21L16.6504 16.65"
                      stroke="#003769"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div className="flex">
                    <SecondarySearchAlgolia
                      searchInputStyle={'search_Input'}
                      placeHolder={'Search by Chemical name, CAS No or Supplier Part Number'}
                      triggerSearch={triggerSearch}
                      categoryValue={categoryValue}
                      // showbutton={true}
                    />
                    <button onClick={() => handleSearchClick()} className="plp-btn-search">
                      Search
                    </button>
                  </div>
                </div>
                <div className="secondary_sortby_filter">
                  <SortByFilters />
                </div>
              </div>
            </div>
            <ProductTiles products={products} totalProducts={totalProducts} category={category} />
          </div>
        </div>
      </div>
      <Pagination
        numberOfPages={numberOfPages}
        previousCursor={previousCursor}
        nextCursor={nextCursor}
        searchQueryText={searchQueryText}
        products={products}
      />
    </div>
  );
}
