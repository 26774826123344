import React, { useState, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
export default function QuoteCreateDropdown({ product, variant, quantityCallBack, productQuantity,pdpWeight}) {
  const[minimumQuantity, setMinimumQuantity] = useState(1)
  const [productQuantityValue, setProductQuantityValue] = useState(productQuantity);
  const[packageValue,setPackageValue] = useState(pdpWeight)
  const [weightChanged, setWeightChanged] = useState(1);
  const { MOQ, "packaging-size": packageSize } = variant?.attributes
  useEffect(() =>{
    const moqValue = parseInt(MOQ.split(' ')[0], 10)
    const packageSizeValue = parseInt(packageValue.split(' ')[0], 10)
    if((MOQ.includes('kg') && packageValue.includes('gms'))){
      setMinimumQuantity(Math.round((moqValue*1000)/packageSizeValue))
    }
  },[])
 {
    quantityCallBack(productQuantityValue);
  }
  const handleProductQuantity = (e) => {
    if(parseInt(e.target.value) >= minimumQuantity){
      setProductQuantityValue(e.target.value);
    }
    else if(parseInt(e.target.value) <= minimumQuantity){
      return 0;
    }
    else if(isNaN(e.target.value)) {
      return 0;
    } else if (e.target.value == '') {
      setProductQuantityValue(minimumQuantity);
    } else {
      setProductQuantityValue(parseInt(e.target.value));
    }
  };
  const router = useRouter();
  ``;
  const handleProductWeightChange = (e) => {
    router.push({ pathname: `/${product.productId}/p/${product.variants[e.target.selectedIndex].sku}` }, undefined, {
      scroll: false,
    });
    setPackageValue(e.target.value)
    setWeightChanged(current => current + 1);
    };

  useMemo(() => {
    if (weightChanged >= 2) {
      setTimeout(() => {
        const { MOQ, "packaging-size": packageSize } = variant?.attributes
        const moqValue = parseInt(MOQ.split(' ')[0], 10)
        //const packageSizeValue = parseInt(packageSize.split(' ')[0], 10)
        const packageSizeValue = parseInt(packageValue.split(' ')[0], 10)
        const minQty1 = Math.round(moqValue/(packageSizeValue*1000))
        const minQty2 = Math.round(moqValue/packageSizeValue)
        const minQty3 =  Math.round((moqValue*1000)/packageSizeValue)
        const MOQINLit = MOQ.includes('L')
        const MOQINML = MOQ.includes('ml')
        const MOQINKG = MOQ.includes('kg') 
        const MOQINGMS = MOQ.includes('gms') 
        const PACKINLit = packageValue.includes('L')
        const PACKINML = packageValue.includes('ml')
        const PACKINKG = packageValue.includes('Kg') 
        const PACKINGMS = packageValue.includes('gms') 
        if((MOQINLit && PACKINLit) || (MOQINKG && PACKINKG)){
        setProductQuantityValue(minQty2);
          setMinimumQuantity(minQty2);
        }else if((MOQINML && PACKINGMS) || (MOQINGMS && PACKINGMS)){
        setProductQuantityValue(minQty2);
          setMinimumQuantity(minQty2);
        }else if((MOQINML && PACKINLit) || (MOQINGMS && PACKINKG)){
        setProductQuantityValue(minQty1);
          setMinimumQuantity(minQty1);
        }else if((MOQINLit && PACKINML) || (MOQINKG && PACKINGMS)){
        setProductQuantityValue(minQty3);
          setMinimumQuantity(minQty3);
        }
      }, 1000)
    }
  }, [weightChanged]);
  useEffect(() => {
    if (productQuantityValue == 0) {
      setProductQuantityValue(1);
    }
  }, [productQuantityValue]);
  return (
    <div className="quote_filters">
      <div className="pdp_filters flex-col sm:mt-[5px]  lg:-ml-[5px] lg:mt-[5px] lg:w-auto 2xl:flex-row">
        {variant?.attributes?.dosage && (
          <div>
            <select
              id="selected-tab"
              name="selected-tab"
              className="pdp_search_filter w-[50%] 2xl:w-auto"
              // onChange={(e) => setProductDosage(e.target.value)}
            >
              {variant?.attributes?.dosage &&
                variant?.attributes?.dosage.split(',').map((item, index) => (
                  <option
                    className="values"
                    key={index}
                    value={item}
                    // onClick={() => setProductDosage(item)}
                  >
                    {item}
                  </option>
                ))}
            </select>
          </div>
        )}
        <div className="pdp-tile-weight flex" id="scroll-to-product-description">
          <select
            id="selected-tab"
            name="selected-tab"
            className="pdp_search_filter  cursor-pointer 2xl:w-auto"
            onChange={(e) => {
              handleProductWeightChange(e);
             }}
            value={packageValue}
          >
            {product.variants.map((item, index) => (
              <option
                className="values text-left"
                key={index}
                value={item?.attributes['packaging-size']}
                onChange={() => {}}
              >
                {item?.attributes['packaging-size']}
              </option>
            ))}
          </select>
        </div>

        <div className="qty qty-width rfq-qty-width">
          <div className="grms-qty grms-qty-pdp mt-[14px] cursor-pointer bg-[white]">
            <p className="qty-p text-center">Quantity:</p>
            <button
              className="qtyminus bg-[white] "
              aria-hidden="true"
              onClick={() => productQuantityValue > minimumQuantity && setProductQuantityValue(productQuantityValue - 1)}
            >
              &minus;
            </button>
            <input
              className="plp-weight quote-rfq-qty"
              type="number"
              name="qty"
              id="qty"
              value={productQuantityValue}
              onChange={handleProductQuantity}
            />
            <button
              className="qtyplus quote-qty-plus bg-[white]"
              aria-hidden="true"
              onClick={() => setProductQuantityValue(productQuantityValue + 1)}
            >
              +
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
