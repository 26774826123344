import { Fragment } from 'react';
import { Dialog, Transition } from "@headlessui/react";
import { useDarkMode } from 'frontastic';
import { FiAlertCircle, FiX } from 'react-icons/fi';
import { useRouter } from 'next/router';

interface Props {
  handleClose: (boolean) => void
}

const EnquireMoreProduct = ({ handleClose }: Props) => {
  const { mode, theme } = useDarkMode();
  const router = useRouter()

  return (<Transition.Root show={true} as={Fragment}>
    <Dialog className={`${mode} ${theme} default fixed inset-0 overflow-y-auto dialog-background remove-dialog-background z-50`} onClose={() => handleClose}>
      <>
        <div className="remove-dialog-content">
          <div className='flex'>
            <FiAlertCircle size={26} />
            <div className='text-lg font-bold ml-2'>Enquire About The Quantity</div>
            <FiX size={26} className='text-blue-600 ml-auto cursor-pointer' onClick={handleClose} />
          </div>
          <div className='remove-dialog-text secondary-remove-dialog-text'>We do not have the information about this quantity, you can enquire about it.</div>
          <div className='remove-dialog-button-container flex items-center justify-center'>
            <button className='remove-dialog-secondary-button !bg-blue-500 !text-white !w-[70%] cursor-pointer' onClick={() => router.push('/contact-us')}>Enquire</button>
          </div>
        </div>
      </>
    </Dialog>
  </Transition.Root>)
}

export default EnquireMoreProduct;