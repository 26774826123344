import React, { useState } from 'react';

function PaymentDropdown({ label, component }) {
  const [currentStep, setCurrentStep] = useState(false);
  return (
    <div className="payment-dropdown-wrapper">
      <ul>
        {/* Render the steps navigation */}

        <li className="payment-dropdown-list">
          <div>
            <div className="payment-dropdown-label" onClick={() => setCurrentStep(!currentStep)}>
              <span>
                {currentStep ? (
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="2 2 20 20" fill="none">
                    <path
                      d="M7 11.1797V13.1797H17V11.1797H7ZM12 2.17975C6.48 2.17975 2 6.65975 2 12.1797C2 17.6997 6.48 22.1797 12 22.1797C17.52 22.1797 22 17.6997 22 12.1797C22 6.65975 17.52 2.17975 12 2.17975ZM12 20.1797C7.59 20.1797 4 16.5897 4 12.1797C4 7.76975 7.59 4.17975 12 4.17975C16.41 4.17975 20 7.76975 20 12.1797C20 16.5897 16.41 20.1797 12 20.1797Z"
                      fill="#0068A3"
                    />
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none">
                    <path
                      d="M11 5.00012H9V9.00012H5V11.0001H9V15.0001H11V11.0001H15V9.00012H11V5.00012ZM10 0.00012207C4.48 0.00012207 0 4.48012 0 10.0001C0 15.5201 4.48 20.0001 10 20.0001C15.52 20.0001 20 15.5201 20 10.0001C20 4.48012 15.52 0.00012207 10 0.00012207ZM10 18.0001C5.59 18.0001 2 14.4101 2 10.0001C2 5.59012 5.59 2.00012 10 2.00012C14.41 2.00012 18 5.59012 18 10.0001C18 14.4101 14.41 18.0001 10 18.0001Z"
                      fill="#0068A3"
                    />
                  </svg>
                )}
              </span>
              <p className="payment-dropdown-label-heading">{label}</p>
            </div>
            <div>{currentStep && component}</div>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default PaymentDropdown;
