import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { addItem } from 'frontastic/actions/cart';
import EnquireMoreProduct from '../product-details/product-details-components/enquire-more-product';
import { FiAlertCircle } from 'react-icons/fi';
import Loader from 'components/commercetools-ui/loader';
import router from 'next/router';
import { CURRENCY_SYMBOL, DEFAULT_PRODUCT_MAX_QTY } from 'helpers/constants/constant';
import { useAccount } from 'frontastic';

export default function Modal({ setOpenModal, product }) {
  const [selectedProduct, setSelectedProduct] = useState({...product});
  const [loading, setLoading]= useState(false);
  const { account } = useAccount();
  const [selectedLocationValues, setSelectedLocationValues]= useState({
    selectedQty: 1,
    requestedDateOfDelivery: null,
    notes: ''
  });
  const [enquireMoreProductPopupVisible, setEnquireMoreProductPopupVisible] = useState(false);
  
  const openEnquireMoreProductPopup = () => setEnquireMoreProductPopupVisible(true);
  const closeEnquireMoreProductPopup = () => setEnquireMoreProductPopupVisible(false);

  const handleAddToCart = async () => {
    setLoading(true);
    if (!account) {
      router.push({ pathname: '/login', query: router.asPath });
    } else {
      addItem(
        { "sku": selectedProduct.skuId},
        selectedLocationValues.selectedQty,
        selectedLocationValues.notes,
        selectedLocationValues.requestedDateOfDelivery?.toLocaleDateString('en-GB') || '',
        selectedProduct.data[selectedProduct.selectedLoc].expectedDateOfDelivery,
        selectedProduct.data[selectedProduct.selectedLoc].location).then((response) => {
        setLoading(false);
        router.push('/cart');
      });
    }
    setOpenModal(false)
  };

  const handleProductQuantityChange = (data) => {
    const { val, index, key, maxVal = 0, qty, minus = false } = data;
    if (key === 'selectedQty') {
      if ((val * 1) > maxVal && !minus) {
        return;
      }
      setSelectedLocationValues({...selectedLocationValues, selectedQty: val});
    } else if (key === 'requestedDateOfDelivery') {
      setSelectedLocationValues({...selectedLocationValues, requestedDateOfDelivery: val});
    }
  };

  return (
    <>
      {loading && <Loader />}
      {enquireMoreProductPopupVisible && <EnquireMoreProduct handleClose={closeEnquireMoreProductPopup} />}
      <div className="fixed right-0 top-0 z-50 h-full w-full bg-gray-900 bg-opacity-75">
        <div className="plpMobileWrap flex min-h-screen items-center px-4 py-8">
          <div className="plpMobileMain relative mx-auto h-[34rem] w-[50rem] rounded-md bg-white p-10 shadow-lg mt-[10rem]">
            <div className="plpMobileFilterTextWrap">
              <svg
                className="ml-auto cursor-pointer"
                onClick={() => setOpenModal(false)}
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                  fill="#0068A3"
                />
              </svg>
            </div>
            <div className="api-tile-title cursor-pointer">
              <p className="quick_rfq_heading text-[24px] font-bold text-[#1E1E1E] lg:text-[24px]">{`Buy ${selectedProduct?.productName?.['en-IN']}`}</p>
            </div>
            <div className='flex'>
              <div className="cas-no w-[18rem]">
                <p className="category-title">Seller :</p>
                <span className='compare-seller-name tooltip'>
                  {` ${((selectedProduct?.productAttributes[0]?.find(e => e.name === "vendor-name")).value['en-IN']).substring(0, 20)} ...`}
                  <span className="seller-modal-tooltiptext">{` ${(selectedProduct?.productAttributes[0]?.find(e => e.name === "vendor-name")).value['en-IN']}`}</span>
                </span>
              </div>
              <div className="cas-no ml-10">
                <p className="category-title">Variants :</p>
                {` ${typeof (product.productAttributes[0].find(e => e.name === 'packaging-size'))?.value === 'object' ?
                    ((product.productAttributes[0].find(e => e.name === 'packaging-size'))?.value['en-IN'] || '-'):
                    (((product.productAttributes[0].find(e => e.name === 'packaging-size'))?.value) || '-')}`}
              </div>
              { (selectedProduct.productAttributes[0].find(e => e.name === "cas-no"))?.value?.['en-IN'] && <div className="cas-no ml-10">
                <p className="category-title">CAS No. :</p>
                {` ${(selectedProduct.productAttributes[0].find(e => e.name === "cas-no"))?.value?.['en-IN']}`}
              </div>
}
            </div>
           {(selectedProduct.data.length > 0) &&
            <table className="min-w-full border border-gray-300 mt-8">
              <thead>
                <tr className="bg-gray-100 text-left" style={{ fontSize: '14px', marginLeft: '15px' }}>
                  {/* <th className="border-b py-2 px-4"></th> */}
                  <th className="border-b py-2 px-4">Location</th>
                  <th className="border-b py-2 px-4">Qty</th>
                  <th className="border-b py-2 px-4">Price/Unit</th>
                  <th className="border-b py-2 px-4">Stock</th>
                  <th className="border-b py-2 px-4">EDD</th>
                  <th className="border-b py-2 px-4">RDD</th>
                </tr>
              </thead>
              <tbody>
                {selectedProduct.data[selectedProduct.selectedLoc] &&
                  [selectedProduct.data[selectedProduct.selectedLoc]].map((row, index) => 
                    {
                      const quantityAvailable = selectedProduct?.cbpCatalogue ? DEFAULT_PRODUCT_MAX_QTY : row.quantityAvailable;
                    return <>
                      <tr key={index} style={{ fontSize: '14px', marginLeft: '15px' }}>
                        {/* <td className="border-b py-2 px-4 text-center">
                          <input
                            type="radio"
                            id="html"
                            name="fav_language"
                            value={row?.isEnable}
                            checked={row?.isEnable || false}
                            onClick={() => handleProductQuantityChange({ val: !row?.isEnable, index, key: 'isEnable', qty: row?.selectedQty })}
                             />

                        </td> */}
                        <td className="border-b py-2 px-4">{row?.location}</td>
                        <td className="border-b ">
                          <div className="pdp_filters">
                            <div className="qty w-36">
                              <div className="cursor-pointer pl-2">
                                <button
                                  className={`qtyplus ${(selectedLocationValues?.selectedQty <= 1) && '!bg-gray-400'} `}
                                  aria-hidden="true"
                                  onClick={() => selectedLocationValues?.selectedQty > 1 && handleProductQuantityChange({ val: selectedLocationValues?.selectedQty - 1, index, key: 'selectedQty', maxVal: quantityAvailable, minus: true })}
                                >
                                  <span className={!selectedLocationValues?.selectedQty && '!text-gray-100'}>&minus;</span>
                                </button>
                                <span className="pl-5 pr-5"> {selectedLocationValues?.selectedQty || 0} </span>

                                {
                                  selectedLocationValues?.selectedQty < quantityAvailable
                                    ? <button
                                      className="qtyplus"
                                      aria-hidden="true"
                                      onClick={() => handleProductQuantityChange({ val: selectedLocationValues?.selectedQty + 1 || 1, index, key: 'selectedQty', maxVal: quantityAvailable })}
                                    >
                                      <span>+</span>
                                    </button>
                                    : <button
                                      className="qtyplus"
                                      aria-hidden="true"
                                      onClick={openEnquireMoreProductPopup}
                                    >
                                      <span >
                                        <FiAlertCircle size={13} />
                                      </span>
                                    </button>}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="border-b py-2 px-4">{row?.discountedPrice > 0 ? `${CURRENCY_SYMBOL[row?.currencyCode]} ${row?.discountedPrice}` : 'NA'}</td>
                        <td className={`border-b py-2 px-4 text-${quantityAvailable > 0 ? 'green-500' : 'red-500'}`}>{quantityAvailable > 0 ? `In Stock (${quantityAvailable})` : "Out Of Stock"}</td>
                        <td className="border-b py-2 px-4">{(quantityAvailable > 0 && row?.expectedDateOfDelivery) ? row?.expectedDateOfDelivery : 'NA'}</td>
                        <td className="border-b py-2 px-4">
                          {
                            <DatePicker
                              showIcon
                              selected={selectedLocationValues?.requestedDateOfDelivery}
                              value
                              onChange={(e) => handleProductQuantityChange({ val: e, index, key: 'requestedDateOfDelivery' })}
                              dateFormat="dd-MM-yyyy"
                              disabled={quantityAvailable == 0}
                            />
                          }
                        </td>
                      </tr>
                    </>
                })
                }
              </tbody>
            </table> 
            }
              <div className='mt-4'>
                <div className="category-title mb-2">Notes :</div>
                <div className="relative mt-1">
                  <textarea
                    required
                    name="description"
                    id="Description"
                    placeholder="Enter a Description"
                    autoComplete="given-Description"
                    className="review_description_box quote_description_box mt-[6px]  border-2 border-blue-200 text-[12px]"
                    onChange={(e) => setSelectedLocationValues({...selectedLocationValues, notes: e.target.value})}
                  />
                </div>
              </div>

              <div>
              <button
                className={`compare-add-cart-modal mt-4`}
                onClick={handleAddToCart}
                disabled={selectedLocationValues.selectedQty === 0}
              >
                Add to Cart
              </button>
              </div>
          </div>
        </div>
      </div>
    </>
  );
}
