import React, { Fragment, useCallback, useEffect } from 'react';
import NextLink from 'next/link';
import { Dialog, Tab, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import Typography from 'components/commercetools-ui/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import { isLiveReference, Reference, ReferenceLink } from 'helpers/reference';
import { useDarkMode } from 'frontastic';
import { Link } from './index';
// import  { Link as RouterLink }  from 'next/link';
import AccountButton from './account-button';
import SearchButton from './search-button';
import WishListButton from './wishlist-button';
import CartButton from './cart-button';
import { Account } from '@Types/account/Account';
import HeaderMobile from './header-mobile';
import { useRouter } from 'next/router';

interface HeaderMenuProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  navigation: any;
  links: Link[];
  previewId?: string;
  account: Account;
  accountLink: Reference;
  wishlistLink?: Reference;
  // cartLink: Reference;
  wishlistItemCount?: number;
  megaMenuData: any;
}

const HeaderMenu: React.FC<HeaderMenuProps> = ({
  open,
  setOpen,
  navigation,
  links,
  previewId,
  account,
  accountLink,
  wishlistLink,
  wishlistItemCount,
  megaMenuData,
}) => {
  //Darkmode
  const { mode, theme } = useDarkMode();
  const requestForQuoteUrl = account ? 'quotes/request-for-quote' : 'login';

  //i18n messages
  const { formatMessage } = useFormat({ name: 'common' });

  const closeMenu = () => setOpen(false);

  //Generates tab class name
  const tabClassName = useCallback((selected: boolean) => {
    return classNames(
      selected ? 'border-accent-500 text-accent-500' : 'border-transparent text-gray-900 dark:text-light-100 ',
      'flex-1 whitespace-nowrap border-b-2 py-4 px-1 text-base font-medium',
    );
  }, []);
  const { asPath } = useRouter();
  const router = useRouter();
  const goToAccount = () => {
    router.push('/account');
  };

  useEffect(() => {
    closeMenu();
  }, [asPath]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog className={`${mode} ${theme} fixed inset-0 z-[9999] flex lg:hidden`} onClose={closeMenu}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="bg-black fixed inset-0 opacity-25" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex w-full max-w-[258px] flex-col overflow-y-auto bg-white pb-12 shadow-xl md:max-w-xs">
            <div className="flex px-4 pt-5 pb-2">
              <button
                type="button"
                className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400 dark:text-light-100"
                onClick={() => setOpen(false)}
              >
                <span className="sr-only">{formatMessage({ id: 'menu.close', defaultMessage: 'Close menu' })}</span>
                <XIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>

            {/* Links */}
            {/* <Tab.Group>
              <div className="mt-2 border-b border-gray-200">
                <Tab.List className="-mb-px flex space-x-8 px-4" onClick={closeMenu}>
                  {navigation.categories.map((category) => (
                    <Tab key={category.name} className={({ selected }) => tabClassName(selected)}>
                      <Typography>{category.name}</Typography>
                    </Tab>
                  ))}
                </Tab.List>
              </div>
              <Tab.Panels as={Fragment}>
                {navigation.categories.map((category, categoryIdx) => (
                  <Tab.Panel key={category.name} className="space-y-12 px-4 pt-10 pb-6">
                    <div className="grid grid-cols-1 items-start gap-x-6 gap-y-10">
                      <div className="grid grid-cols-1 gap-x-6 gap-y-10">
                        <div>
                          <p
                            id={`mobile-featured-heading-${categoryIdx}`}
                            className="font-medium text-gray-900 dark:text-light-100"
                          >
                            {formatMessage({ id: 'featured', defaultMessage: 'Featured' })}
                          </p>
                          <ul
                            role="list"
                            aria-labelledby={`mobile-featured-heading-${categoryIdx}`}
                            className="mt-6 space-y-6"
                          >
                            {category.featured.map((item) => (
                              <li key={item.name} className="flex">
                                <NextLink href={item.href}>
                                  <a className="text-gray-500 dark:text-light-100" onClick={closeMenu}>
                                    <Typography>{item.name}</Typography>
                                  </a>
                                </NextLink>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div>
                          <p id="mobile-categories-heading" className="font-medium text-gray-900 dark:text-light-100">
                            {formatMessage({ id: 'categories', defaultMessage: 'Categories' })}
                          </p>
                          <ul role="list" aria-labelledby="mobile-categories-heading" className="mt-6 space-y-6">
                            {category.categories.map((item) => (
                              <li key={item.name} className="flex">
                                <NextLink href={item.href}>
                                  <a className="text-gray-500 dark:text-light-100" onClick={closeMenu}>
                                    <Typography>{item.name}</Typography>
                                  </a>
                                </NextLink>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      <div className="grid grid-cols-1 gap-x-6 gap-y-10">
                        <div>
                          <p id="mobile-collection-heading" className="font-medium text-gray-900 dark:text-light-100">
                            {formatMessage({ id: 'collection', defaultMessage: 'Collection' })}
                          </p>
                          <ul role="list" aria-labelledby="mobile-collection-heading" className="mt-6 space-y-6">
                            {category.collection.map((item) => (
                              <li key={item.name} className="flex">
                                <NextLink href={item.href}>
                                  <a className="text-gray-500 dark:text-light-100" onClick={closeMenu}>
                                    <Typography>{item.name}</Typography>
                                  </a>
                                </NextLink>
                              </li>
                            ))}
                          </ul>
                        </div>

                        <div>
                          <p id="mobile-brand-heading" className="font-medium text-gray-900 dark:text-light-100">
                            {formatMessage({ id: 'brands', defaultMessage: 'Brands' })}
                          </p>
                          <ul role="list" aria-labelledby="mobile-brand-heading" className="mt-6 space-y-6">
                            {category.brands.map((item) => (
                              <li key={item.name} className="flex">
                                <NextLink href={item.href}>
                                  <a className="text-gray-500 dark:text-light-100" onClick={closeMenu}>
                                    <Typography>{item.name}</Typography>
                                  </a>
                                </NextLink>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </Tab.Group> */}

            {/* <div className="space-y-6 border-t border-gray-200 py-6 px-4">
              {links
                .filter((link) => previewId || isLiveReference(link.reference))
                .map((link) => (
                  <div key={link.name} className="flow-root" onClick={closeMenu}>
                    <ReferenceLink
                      target={link.reference}
                      className="-m-2 block p-2 font-medium text-gray-900 dark:text-light-100"
                    >
                      <Typography>{link.name}</Typography>
                    </ReferenceLink>
                  </div>
                ))}
            </div> */}

            <div className="flex w-full items-center justify-center">
              <div className="headerBtnGroupHB flex w-fit flex-col items-center gap-[11px]">
                <div className="accountDetails">
                  <span className="accountUserIcon" onClick={goToAccount}>
                    <svg
                      className="accountUserIcon-image"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.8375 20V21.1H21.0375V20H18.8375ZM15.9531 14.3333V13.2333V14.3333ZM7.98438 14.3333V13.2333V14.3333ZM4 18.1111H2.9H4ZM2.9 20C2.9 20.6075 3.39249 21.1 4 21.1C4.60751 21.1 5.1 20.6075 5.1 20H2.9ZM21.0375 20V18.1111H18.8375V20H21.0375ZM21.0375 18.1111C21.0375 17.5286 20.9662 16.8359 20.7786 16.1686C20.5949 15.515 20.2717 14.7872 19.6983 14.2434L18.1846 15.8399C18.3583 16.0047 18.5332 16.3106 18.6607 16.764C18.7844 17.2039 18.8375 17.6917 18.8375 18.1111H21.0375ZM19.6983 14.2434C19.1072 13.6831 18.3866 13.4458 17.7474 13.3373C17.1183 13.2306 16.463 13.2333 15.9531 13.2333V15.4333C16.5 15.4333 16.9652 15.436 17.3793 15.5063C17.7833 15.5749 18.0284 15.6918 18.1846 15.8399L19.6983 14.2434ZM15.9531 13.2333H7.98438V15.4333H15.9531V13.2333ZM7.98438 13.2333C7.47018 13.2333 6.76429 13.2312 6.08475 13.3343C5.4223 13.4347 4.6076 13.6581 3.99022 14.2434L5.50392 15.8399C5.63376 15.7168 5.90935 15.586 6.41456 15.5094C6.90269 15.4354 7.44184 15.4333 7.98438 15.4333V13.2333ZM3.99022 14.2434C3.36373 14.8374 3.11835 15.633 3.00865 16.2834C2.89741 16.9429 2.9 17.6258 2.9 18.1111H5.1C5.1 17.5945 5.10252 17.0969 5.17801 16.6493C5.25504 16.1926 5.3832 15.9544 5.50392 15.8399L3.99022 14.2434ZM2.9 18.1111V20H5.1V18.1111H2.9ZM14.8531 6.77778C14.8531 8.20222 13.6177 9.45556 11.9688 9.45556V11.6556C14.7209 11.6556 17.0531 9.52615 17.0531 6.77778H14.8531ZM11.9688 9.45556C10.3198 9.45556 9.08437 8.20222 9.08437 6.77778H6.88438C6.88438 9.52615 9.21665 11.6556 11.9688 11.6556V9.45556ZM9.08437 6.77778C9.08437 5.35333 10.3198 4.1 11.9688 4.1V1.9C9.21665 1.9 6.88438 4.0294 6.88438 6.77778H9.08437ZM11.9688 4.1C13.6177 4.1 14.8531 5.35333 14.8531 6.77778H17.0531C17.0531 4.0294 14.7209 1.9 11.9688 1.9V4.1Z"
                        fill="#003769"
                      ></path>
                    </svg>
                  </span>
                  <ul className="accountUserDetail">
                    {account ? (
                      <>
                        <li className="accountUserName">
                          Hi, {(account.firstName || ' ') + ' ' + (account.lastName || ' ')}
                        </li>
                        <li className="accountUserdesignation">Designation</li>
                      </>
                    ) : (
                      <>
                        <li className="accountUserName">Hi, there!</li>
                        <li className="accountUserdesignation">To access account and manage order</li>
                      </>
                    )}
                  </ul>
                </div>

                <AccountButton showIcon={false} account={account} accountLink={accountLink} />

                <NextLink href={requestForQuoteUrl}>
                  <button className="headerEnquirybtn">Product Enquiry</button>
                </NextLink>
                {/* <NextLink href="/contact-us">
                  <button className="headerSellbtn">Sell on Allverz</button>
                </NextLink> */}
                <HeaderMobile account={account} megaMenuData={megaMenuData} />
                {/* <SearchButton /> */}
                {/* <WishListButton wishlistItemCount={wishlistItemCount} wishlistLink={wishlistLink} /> */}
                <span className="hidden lg:block">
                  {!account && <AccountButton showIcon={false} account={account} accountLink={accountLink} />}
                </span>
                {/* <CartButton cartItemCount={cartItemCount} cartLink={cartLink} /> */}
              </div>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
};

export default HeaderMenu;
