import { useState, useEffect } from 'react';
import countryList from './countries';
import { useAccount } from 'frontastic';
import state from './states';
import cities from './cities';
import axios from 'axios';
import { add } from 'date-fns';

const AddAddressForm = ({ addAddress }) => {
  const { account } = useAccount();
  const [addressName, setAddressName] = useState('');
  const [unit, setUnit] = useState('');
  const [street, setStreet] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [pincode, setPincode] = useState('');
  const [addressState, setAddressState] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    setAddressName('');
    setUnit('');
    setStreet('');
    setCountry('Select');
    setCity('Select');
    setPincode('');
    setAddressState('Select');
    setError('');
  }, [account]);

  const getAddressFromPinCode = async () => {
    const adddress: any = await axios.get(`https://api.postalpincode.in/pincode/${pincode}`);
    if (adddress?.data[0]?.Status !== 'Error') {
      setCity(adddress?.data[0]?.PostOffice[0]?.Region);
      setCountry(adddress?.data[0]?.PostOffice[0]?.Country);
      setAddressState(adddress?.data[0]?.PostOffice[0]?.Circle);
    }
  };

  useEffect(() => {
    if (pincode.length === 6) {
      getAddressFromPinCode();
    }
  }, [pincode]);

  const handleAddAddressClick = () => {
    console.log(
      'addressName && unit && street && country && city && pincode && addressState',
      addressName,
      unit,
      street,
      country,
      city,
      pincode,
      addressState,
    );
    if (
      addressName &&
      unit &&
      street &&
      country &&
      city &&
      pincode &&
      addressState &&
      addressState !== 'Select' &&
      city !== 'Select' &&
      country !== 'Select'
    ) {
      setError('');
      const addressPayload = {
        addressName,
        unit,
        street,
        country,
        city,
        pincode,
        addressState,
      };
      addAddress(addressPayload);
    } else {
      setError('All the fields are mandatory');
    }
  };

  return (
    <div>
      <div className="mt-5">
        <div className="rfqInput mt-2">
          <label>
            Address Name <span className="mb-2 text-red-600">*</span>
          </label>
          <input
            className="w-full"
            style={{ width: '100%' }}
            type="text"
            value={addressName}
            placeholder="Add Address Name"
            onChange={(e) => setAddressName(e.target.value)}
          />
        </div>
        <div className="rfqInput mt-2">
          <label>
            Unit/Building level <span className="mb-2 text-red-600">*</span>
          </label>
          <input
            className="w-full"
            style={{ width: '100%' }}
            value={unit}
            type="text"
            placeholder="Add Detail"
            onChange={(e) => setUnit(e.target.value)}
          />
        </div>
        <div className="rfqInput mt-2">
          <label>
            Street/Road <span className="mb-2 text-red-600">*</span>
          </label>
          <input
            className="w-full"
            style={{ width: '100%' }}
            type="text"
            placeholder="Add Detail"
            value={street}
            onChange={(e) => setStreet(e.target.value)}
          />
        </div>
        <div className="mt-2 flex" style={{ gap: '10px' }}>
          <div className="rfqInput w-6/12">
            <label>
              Pincode <span className="mb-2 text-red-600">*</span>
            </label>
            <input
              className="w-full"
              style={{ width: '100%', padding: '8px 8px 8px 12px' }}
              type="number"
              placeholder="Enter Pincode"
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
            />
          </div>
          <div className="rfqInput w-6/12">
            <label>
              Suburb/City <span className="mb-2 text-red-600">*</span>
            </label>
            <input
              className="w-full"
              style={{ width: '100%' }}
              type="text"
              placeholder="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
            {/* <select
              id="selected-tab"
              name="selected-tab"
              value={city}
              className="mt-1 block w-full rounded-sm border-gray-300 stroke-accent-400 py-2 pr-10 pl-3 text-base text-accent-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
              onChange={(e) => setCity(e.target.value)}
            >
              <option className="values" key={0}>
                Select
              </option>
              {cities.cities?.map((data, index) => (
                <option className="values" key={index} defaultValue={data.city}>
                  {data.city}
                </option>
              ))}
            </select> */}
          </div>
        </div>
        <div className="mt-2 flex" style={{ gap: '10px' }}>
          <div className="w-6/12">
            <label className="quote-form-element-label">
              State <span className="mb-2 text-red-600">*</span>
            </label>
            <select
              id="selected-tab"
              name="selected-tab"
              value={addressState}
              className="mt-1 block w-full rounded-sm border-gray-300 stroke-accent-400 py-2 pr-10 pl-3 text-base text-accent-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
              onChange={(e) => setAddressState(e.target.value)}
            >
              <option className="values" key={0}>
                Select
              </option>
              {state.state?.map((data, index) => (
                <option className="values" key={index}>
                  {data}
                </option>
              ))}
            </select>
          </div>
          <div className="w-6/12">
            <label className="quote-form-element-label">
              Country <span className="mb-2 text-red-600">*</span>
            </label>
            <select
              id="selected-tab"
              name="selected-tab"
              value={country}
              className="mt-1 block w-full rounded-sm border-gray-300 stroke-accent-400 py-2 pr-10 pl-3 text-base text-accent-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
              onChange={(e) => setCountry(e.target.value)}
            >
              <option className="values" key={0}>
                Select
              </option>
              {countryList.countryList?.map((data, index) => (
                <option className="values" key={index}>
                  {data}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="mt-3">
          {error && <div className="mb-2 text-sm text-red-600">{error}</div>}
          <button
            className="rounded-md border border-transparent bg-accent-400 px-4 py-2 text-center text-sm font-medium tracking-wide text-white shadow-sm transition-colors duration-150 ease-out focus:outline-none focus:ring-2 focus:ring-accent-400 focus:ring-offset-2"
            onClick={handleAddAddressClick}
          >
            Add Address
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddAddressForm;
