import ReviewCarousel from "../reviewCarousel";
import ReviewsAndRatings from "../ReviewsAndRatings";
import AddReview from "../addRatingModal";
import { useRouter } from 'next/router';
import { useAccount } from 'frontastic';
import { useState} from 'react';


export default function  ProductReviews({review,product,handleReviewCallBack}){
  
    const specifications = product?.variants[0].attributes;
    const [showReviewModal, setShowReviewModal] = useState(false);
    const [seeMorecounter, setSeeMoreCounter] = useState(4);
    const [showSeeLessButton, setShowLessButton] = useState(false);
    const [showSeeMoreButton, setShowMoreButton] = useState(true);
    const { account } = useAccount();
    const router = useRouter();
    const handleAddReview = () => {
        if (!account) {
          router.push({pathname:'/login',query: router.asPath});
        } else {
          setShowReviewModal(true);
        }
      };
      const viewMoreReviews = () => {
        setSeeMoreCounter((current) => current + 4);
        setShowLessButton(true);
        setShowMoreButton(false);
      };
      const viewLessReviews = () => {
        setSeeMoreCounter((current) => current - 4);
        setShowMoreButton(true);
      };
       return(
        <div>
         <div className="pdp_review_rating bg-[#F3F5FD]  lg:mt-[60px]">
        <div className="flex justify-around lg:ml-auto lg:pt-[43px]">
         {review?.length > 0 && ( <p className="reviews_heading text-[20px] font-semibold text-[#003769] lg:mr-auto lg:ml-[70px] lg:text-[32px]">
            Reviews and Ratings
          </p>) }
          {review?.length > 2 && <button className="see_all_button lg:mr-auto lg:ml-[100px]">See All</button>}
        </div>
        <div className="flex flex-col lg:mx-[20px] lg:mt-[0px] lg:flex-row">
          <div className="pdp_reviews">
            <ReviewCarousel review={review} />
          </div>

          <div className="pdp_reviews_mobile lg:hidden">
            {review?.slice(0, seeMorecounter).map((item) => (
              <ReviewsAndRatings productVariants={item} key={item.id} />
            ))}
          </div>
          <div className="flex justify-evenly">
            {showSeeMoreButton && review?.length > 4 && (
              <button className="see_more_reviews lg:hidden" onClick={viewMoreReviews}>
                See More
              </button>
            )}
            {showSeeLessButton && seeMorecounter > 5 && review?.length > 0 && (
              <button className="see_more_reviews lg:hidden" onClick={viewLessReviews}>
                See Less
              </button>
            )}
          </div>
       
            <div
              className={review?.length > 0 ? "add_review flex-col bg-[#FFFFFF] sm:h-[138px]  lg:ml-[12px] lg:h-[200px]" : 'add_review_first flex-col bg-[#FFFFFF] sm:h-[138px]  lg:ml-[12px] lg:h-[200px]'}
         
            >
               {review?.length > 0 ? (<p className="review_product text-center lg:mt-[32px]">Review this Product</p>)
                : (<p className="review_product text-center text-[#003769] lg:mt-[32px]">Be the first one to Review</p>)}
              <p className="share_review text-center lg:mt-[15px]">Share your thoughts with other customers</p>
              <button className="add_review_button align-center lg:mt-[20px]" onClick={handleAddReview}>
                Add Review
              </button>

              {showReviewModal && (
                <AddReview
                  setOpenModal={setShowReviewModal}
                  sellerName={specifications?.['vendor-name']}
                  productId={product?.id}
                  handleReviewCallBack={handleReviewCallBack}
                  vendorCode={specifications?.['vendor-code']}
                />
              )}
            </div>
         
        </div>
      </div>
        </div>
    )

}