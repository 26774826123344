import React from 'react';
import Markdown from 'components/commercetools-ui/content/markdown';

const ContentAllverz = ({ data }) => {
  //   const title = data.data?.dataSource?.fields?.title;
  //   const body = data.data?.dataSource?.fields?.body;
  const model1 = data.data.dataSource.attributes;
  return (
    <>
      {/* <pre className="mt-2 text-lg">{JSON.stringify(data.data?.dataSource, null, 2)}</pre> */}
      {/* <Typography>{model1?.descriptionTextBlock?.content}</Typography> */}
      <div className="whyAllverzMain">
        <div className="container mx-auto px-5 md:px-0 lg:px-0">
          <div className="md:grid-cols-1 lg:grid lg:grid-cols-1 xl:grid xl:grid-cols-3 xl:gap-[97px]">
            <div className="whyAllverzGridDesktop col-span-2">
              <div className="whyAllverzGrid">
                <div className="allverzColOne col-1">
                  <div className="whyAllverzGridItems allverzGridOne">
                    <img
                      className="whyAllverzGridImg"
                      src={model1?.cardContentBox1?.content?.fields?.cardImage?.fields.file.url}
                      alt=""
                    />
                    <p className="whyAllverzGridTitle">{model1?.cardContentBox1?.content?.fields?.cardTitle}</p>
                    <p className="whyAllverzGridSubHead">
                      {model1?.cardContentBox1?.content?.fields?.cardDescriptionBox}
                    </p>
                  </div>
                  {/* <div className="whyAllverzGridItems allverzGridFour">
                    <img
                      className="whyAllverzGridImg"
                      src={model1?.cardContentBox4?.content?.fields?.cardImage?.fields.file.url}
                      alt=""
                    />
                    <p className="whyAllverzGridTitle">{model1?.cardContentBox4?.content?.fields?.cardTitle}</p>
                    <p className="whyAllverzGridSubHead">
                      {model1?.cardContentBox4?.content?.fields?.cardDescriptionBox}
                    </p>
                  </div> */}
                </div>
                <div className="allverzColOne col-2">
                  <div className="whyAllverzGridItems allverzGridTwo">
                    <img
                      className="whyAllverzGridImg"
                      src={model1?.cardContentBox2?.content?.fields?.cardImage?.fields.file.url}
                      alt=""
                    />
                    <p className="whyAllverzGridTitle">{model1?.cardContentBox2?.content?.fields?.cardTitle}</p>
                    <p className="whyAllverzGridSubHead">
                      {model1?.cardContentBox2?.content?.fields?.cardDescriptionBox}
                    </p>
                  </div>
                  {/* <div className="whyAllverzGridItems allverzGridFive">
                    <img
                      className="whyAllverzGridImg"
                      src={model1?.cardContentBox5?.content?.fields?.cardImage?.fields.file.url}
                      alt=""
                    />
                    <p className="whyAllverzGridTitle">{model1?.cardContentBox5?.content?.fields?.cardTitle}</p>
                    <p className="whyAllverzGridSubHead">
                      {model1?.cardContentBox5?.content?.fields?.cardDescriptionBox}
                    </p>
                  </div> */}
                </div>
                <div className="allverzColOne col-3">
                  <div className="whyAllverzGridItems allverzGridThree">
                    <img
                      className="whyAllverzGridImg"
                      src={model1?.cardContentBox3?.content?.fields?.cardImage?.fields.file.url}
                      alt=""
                    />
                    <p className="whyAllverzGridTitle">{model1?.cardContentBox3?.content?.fields?.cardTitle}</p>
                    <p className="whyAllverzGridSubHead">
                      {model1?.cardContentBox3?.content?.fields?.cardDescriptionBox}
                    </p>
                  </div>

                  {/* <div className="whyAllverzGridItems allverzGridSix">
                    <img
                      className="whyAllverzGridImg"
                      src={model1?.cardContentBox6?.content?.fields?.cardImage?.fields.file.url}
                      alt=""
                    />
                    <p className="whyAllverzGridTitle">{model1?.cardContentBox6?.content?.fields?.cardTitle}</p>
                    <p className="whyAllverzGridSubHead">
                      {model1?.cardContentBox6?.content?.fields?.cardDescriptionBox}
                    </p>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="whyAllverzRight md:mx-auto">
              <div>
                <p className="whyAllverzRightTitle">{model1?.descriptionBlockWithButton?.content?.fields?.title}</p>
                <p className="whyAllverzRightSub">
                  <Markdown
                    className=""
                    text={model1?.descriptionBlockWithButton?.content?.fields?.titleDescription || ''}
                  />
                </p>

                <a href="/about-us">
                  <button className="whyAllverzRightBTN">
                    {model1?.descriptionBlockWithButton?.content?.fields?.buttonText}
                  </button>
                </a>
              </div>
            </div>
            <div className="whyAllverzGridMobile col-span-2 my-4">
              <div className="whyAllverzGrid">
                <div className="whyAllverzGridItems">
                  <img
                    className="whyAllverzGridImg"
                    src={model1?.cardContentBox1?.content?.fields?.cardImage?.fields.file.url}
                    alt=""
                  />
                  <p className="whyAllverzGridTitle">{model1?.cardContentBox1?.content?.fields?.cardTitle}</p>
                  <p className="whyAllverzGridSubHead">
                    {model1?.cardContentBox1?.content?.fields?.cardDescriptionBox}
                  </p>
                </div>

                <div className="whyAllverzGridItems">
                  <img
                    className="whyAllverzGridImg"
                    src={model1?.cardContentBox2?.content?.fields?.cardImage?.fields.file.url}
                    alt=""
                  />
                  <p className="whyAllverzGridTitle">{model1?.cardContentBox2?.content?.fields?.cardTitle}</p>
                  <p className="whyAllverzGridSubHead">
                    {model1?.cardContentBox2?.content?.fields?.cardDescriptionBox}
                  </p>
                </div>

                <div className="whyAllverzGridItems">
                  <img
                    className="whyAllverzGridImg"
                    src={model1?.cardContentBox3?.content?.fields?.cardImage?.fields.file.url}
                    alt=""
                  />
                  <p className="whyAllverzGridTitle">{model1?.cardContentBox3?.content?.fields?.cardTitle}</p>
                  <p className="whyAllverzGridSubHead">
                    {model1?.cardContentBox3?.content?.fields?.cardDescriptionBox}
                  </p>
                </div>

                {/* <div className="whyAllverzGridItems allverzGridFour">
                  <img
                    className="whyAllverzGridImg"
                    src={model1?.cardContentBox4?.content?.fields?.cardImage?.fields.file.url}
                    alt=""
                  />
                  <p className="whyAllverzGridTitle">{model1?.cardContentBox4?.content?.fields?.cardTitle}</p>
                  <p className="whyAllverzGridSubHead">
                    {model1?.cardContentBox4?.content?.fields?.cardDescriptionBox}
                  </p>
                </div> */}

                {/* <div className="whyAllverzGridItems allverzGridFive">
                  <img
                    className="whyAllverzGridImg"
                    src={model1?.cardContentBox5?.content?.fields?.cardImage?.fields.file.url}
                    alt=""
                  />
                  <p className="whyAllverzGridTitle">{model1?.cardContentBox5?.content?.fields?.cardTitle}</p>
                  <p className="whyAllverzGridSubHead">
                    {model1?.cardContentBox5?.content?.fields?.cardDescriptionBox}
                  </p>
                </div> */}

                {/* <div className="whyAllverzGridItems allverzGridSix">
                  <img
                    className="whyAllverzGridImg"
                    src={model1?.cardContentBox6?.content?.fields?.cardImage?.fields.file.url}
                    alt=""
                  />
                  <p className="whyAllverzGridTitle">{model1?.cardContentBox6?.content?.fields?.cardTitle}</p>
                  <p className="whyAllverzGridSubHead">
                    {model1?.cardContentBox6?.content?.fields?.cardDescriptionBox}
                  </p>
                </div> */}

                {/* <div className="allverzColOne col-1">
                </div>
                <div className="allverzColOne col-2">
                </div>
                <div className="allverzColOne col-3">
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContentAllverz;
