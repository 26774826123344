import React from 'react';
import CustomAccordion from '../accordion/customAccordion';
const AllverzVerifiedFilter = [
  {
    label: 'Allverz Verfied',
    value: 'verified',
  },
  {
    label: 'Not Verfied',
    value: 'not-verified',
  },
];
const ProductCategoryFilter = [
  {
    label: 'API',
    value: 'api',
  },
  {
    label: 'Excipients',
    value: 'excipients',
  },
  {
    label: 'Solvents',
    value: 'solvents',
  },
  {
    label: 'Intermediates',
    value: 'intermediates',
  },
  {
    label: 'All',
    value: 'all',
  },
];
function VendorListFilters() {
  return (
    <div className="flex hidden gap-2">
      <CustomAccordion
        openSectionTitle="Allverz Verified"
        closedSectionTitle="Allverz Verified"
        className="vendor-list-accordian-item !m-0 hidden capitalize md:block"
      >
        <div className="plpLeftFilterItem plp_country_filter flex items-center justify-between">
          {AllverzVerifiedFilter.map(({ label, value }, index) => (
            <label className="plp_country_filter_options" key={index}>
              <input id={value} name="price" type="radio" />
              <p className="plp_country_filter_label">{label}</p>
            </label>
          ))}
        </div>
      </CustomAccordion>
      <CustomAccordion
        openSectionTitle="Product Category"
        closedSectionTitle="Product Category"
        className="vendor-list-accordian-item !m-0 hidden capitalize md:block"
      >
        <div className="plpLeftFilterItem plp_country_filter flex items-center justify-between">
          {ProductCategoryFilter.map(({ label, value }, index) => (
            <label className="plp_country_filter_options" key={index}>
              <input id={value} name="price" type="checkbox" />
              <p className="plp_country_filter_label">{label}</p>
            </label>
          ))}
        </div>
      </CustomAccordion>

      <div className="search_resultsp">
        <svg
          className="plp_search_Icon mx-2"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
            stroke="#003769"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.0004 21L16.6504 16.65"
            stroke="#003769"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <div className="flex w-full px-5 py-4 md:px-0 md:pt-0">
          <input type="text" className="vendor-list-search" placeholder="Search by Seller Name" />
          <button className="plp-btn-search  hidden md:block">Search</button>
        </div>
      </div>
    </div>
  );
}

export default VendorListFilters;
