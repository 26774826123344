import { useEffect, useState } from 'react';
import moment from 'moment'

import { STATIC_STAGEQUOTE_COMMENT } from 'helpers/constants/statisURLs';
import { backendAPIClient } from 'helpers/utils/axiosRequestsUtil';
import { useFormat } from 'helpers/hooks/useFormat';
import { FileUpload } from 'components/commercetools-ui/form';
import InitialsCircle from './initialCircle';
import axios from 'axios';
import { useAccount } from 'frontastic/provider';
import { BACKEND_BASE_URL } from 'helpers/constants/envVariables';
import { STATUS } from 'helpers/constants/constant';

export default function TalkToSeller({ fileUploadCallback, existingComments, quoteRequestId, status }) {
  const [talkToSellerMessage, setTalkToSellerMessage] = useState('');
  const [talkToSellerDocument, setTalkToSellerDocument] = useState();
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [comments, setComments] = useState([]);
  const [file, setFile] = useState<File>();
  const [documentDownloadLoader, setDocumentDownloadLoader] = useState([])

  const shouldChatEnable = ![STATUS.REJECTED, STATUS.DECLINED].includes(status)

  const { formatMessage } = useFormat({ name: 'common' });
  const { account } = useAccount();

  useEffect(() => {
    setComments([...existingComments]);
  }, [existingComments])

  const accept = '.doc, .docx, .xls, .xlsx, .pdf';

  const clearUpload = () => {
    setTalkToSellerDocument(null);
    setIsFileUploaded(false);
  };

  const onFileChange = (file: any) => {
    setIsFileUploaded(false);
    setTalkToSellerDocument(file[0]);
  };

  const clearInputs = () => {
    setTalkToSellerMessage('');
    clearUpload();
  }

  const updateLoaderState = (ind, state = false) => {
    const tempDoumentDownloadLoader = [...documentDownloadLoader]
    tempDoumentDownloadLoader[ind] = state
    setDocumentDownloadLoader(tempDoumentDownloadLoader)
  }

  const downloadDocument = async (s3Link, ind) => {
    updateLoaderState(ind, true)
    await downloadFileFromUrl(
      `${BACKEND_BASE_URL}/cart/document?documentKey=${s3Link}`,
      'documentS3Link'
    )
    setTimeout(() => updateLoaderState(ind), 1000)
  }

  const downloadFileFromUrl = async (url, keyInResponse) => {
    const response = await axios.get(url)

    if (response?.data[keyInResponse]) {
      // Create a link element to download the image
      const link = document.createElement('a')
      link.href = response.data[keyInResponse]
      link.target = '_blank'
      link.download = 'downloaded_image.jpg'
      document.body.appendChild(link)

      // Click the link to trigger the download
      link.click()

      // Cleanup
      document.body.removeChild(link)
    } else {
      console.error('Invalid Image!')
    }
  }

  const handleTalkToSellerSave = async () => {
    try {
      const formData = new FormData();
      formData.append('stagedQuoteId', quoteRequestId);
      const name = `${account?.firstName || ''} ${account?.lastName || ''}`
      formData.append('name', name.trim());
      formData.append('postDate', `${new Date()}`);
      formData.append('companyName', 'Royal Cyber Company');
      formData.append('message', talkToSellerMessage);
      formData.append('source', 'SYSTEM');
      if (talkToSellerDocument) {
        formData.append('quoteDocument', talkToSellerDocument);
      }
      await backendAPIClient.put(STATIC_STAGEQUOTE_COMMENT, formData).then((res) => {
        fileUploadCallback && fileUploadCallback(res);
        if (res?.data?.comments) {
          setComments(res.data.comments)
        }
        setIsFileUploaded(true);
        clearInputs();
      });
    } catch (err) { }
  }

  const getAvatar = (name) => {
    const [firstName, lastName] = name.split(' ')
    return (
      <InitialsCircle
        firstName={firstName || ''}
        lastName={lastName || ''}
      />
    )
  }

  const getFormattedDate = (format, date = new Date()) =>
    moment(date).format(format)

  return (
    <>
      <div className="request-for-quote-top-banner">
        <div>
          <div className="quote-title">Talk To Seller</div>
          {
            shouldChatEnable &&
            <p>Please add your comments here</p>
          }
        </div>
      </div>
      <div className={`rfq-container ${!shouldChatEnable && "-mt-8"}`}>
        {
          shouldChatEnable &&
          <>
            <div className="talk-to-seller-details-description">
              <div style={{ width: '100%' }}>
                <label className="quote-form-element-label text-gray-700">Type here to response back to the seller for the data/documantation</label>
                <textarea
                  value={talkToSellerMessage}
                  required
                  name="Description"
                  id="Description"
                  maxLength={500}
                  placeholder="Enter a Description"
                  autoComplete="given-Description"
                  className="review_description_box quote_description_box mt-[5px] text-[12px]"
                  onChange={(e) => {
                    setTalkToSellerMessage(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="quote-details-file-upload mt-6">
              <label className="quote-form-element-label text-gray-700">Product Specification Document</label>
              <div className="upload_file_pdp ml-[-5px] mt-[0.5rem]" style={{ width: '100%' }}>
                {file ? (
                  <div className="mt-5 flex gap-2" style={{ alignItems: 'center' }}>
                    <div className="multi-file-wrapper">
                      {Object.values(file).map((element, index) => (
                        <div key={index}>
                          <div className="multi-file-wrapper">
                            <span
                              className="fileclearbutton text-[#0280c7]"
                              style={{ marginRight: '5px' }}
                              onClick={clearUpload}
                            >
                              X
                            </span>
                            {element.name}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div>
                      {/* <button className="btn" disabled={isFileUploaded} onClick={() => onUploadFile(file)}>
                              {isFileUploaded ? 'Done' : 'Upload'}
                            </button> */}
                    </div>
                  </div>
                ) : (
                  <FileUpload
                    file={talkToSellerDocument}
                    name="file_upload"
                    label="Only PDF, Word, .xls files (5MB max file size)"
                    accept={accept}
                    isFileUploaded={isFileUploaded}
                    onFileChange={onFileChange}
                    onUpload={() => { }}
                    clearUpload={clearUpload}
                    forQuote={false}
                    isMultiple={true}
                    displayAccept={false}
                    showButtonElement={false}
                  />
                )}
              </div>
              <div className="mt-8 flex gap-4 sm:col-span-2 sm:gap-8">
                <button
                  type="submit"
                  disabled={!talkToSellerMessage.trim()}
                  onClick={() => handleTalkToSellerSave()}
                  className={`inline-flex w-40 h-10 items-center justify-center rounded-sm border ${talkToSellerMessage.trim() ? 'bg-accent-400 text-white  border-transparent' : 'bg-gray-300'} py-3 px-6 text-base font-medium  shadow-sm transition-colors duration-200 ease-out hover:bg-accent-500 focus:outline-none focus:ring-2 focus:ring-accent-400 focus:ring-offset-2`}
                >
                  {formatMessage({ id: 'save', defaultMessage: 'Save' })}
                </button>
                <button className="headerEnquirybtn" onClick={() => clearInputs()}>Cancel</button>
              </div>

            </div>
          </>
        }
        {(Array.isArray(comments) && comments.length > 0) ? (
          <div className=" bg-scroll gap-4 mt-4">
            {comments.map((comment, ind) => (
              <>
                <div className="semi-bold flex items-center px-5 pb-4 mb-4 " style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 8px 2px" }}>
                  <div className="semi-bold flex items-center mt-2">
                    {getAvatar(comment.name)}
                    <div className="ml-4 mt-3">
                      <p className="text-primary-company-name-color text-sm">
                        {comment.name}
                      </p>
                      <p className="text-xs text-primary-date-color">
                        {getFormattedDate(
                          'MMM, DD, YYYY, hh:mm A',
                          comment.postDate
                        )}
                      </p>
                      <div className="semi-bold text-gray-500">
                        <p style={{ whiteSpace: 'pre-wrap' }}>{comment.message}</p>
                      </div>
                      {comment.documentKey &&
                        (documentDownloadLoader[
                          ind
                        ] ? (
                          <p>Loading...</p>
                        ) : (
                          <a
                            className="text-blue-500 underline cursor-pointer text-sm"
                            onClick={() =>
                              downloadDocument(
                                comment.documentKey,
                                ind
                              )
                            }
                          >
                            Download
                            Document
                          </a>
                        ))}
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        )
          : <p className='-ml-5 mt-2'>No commnuication as of now.</p>}
      </div>
    </>
  )
}
