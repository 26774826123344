import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Image from 'next/image';
import router from 'next/router';
import Loader from 'components/commercetools-ui/loader';
import PlpFilterAccordion from '../../../../../components/commercetools-ui/accordion/plpAccordion';
import { updateURLParams, URLParam } from '../../../../../helpers/utils/updateURLParams';
import CrossIcon from '../../../../../public/images/cross-icon.png';

function SecondarySearchFilters({ facets, facetKey }) {
  const [selected, setSelected] = useState<Record<string, boolean>>({});
  const [loading, setLoading] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const query = urlParams.get('q');
  const handleClearFilters = () => {
    setLoading(true);
    const params = [
      {
        key: 'cursor',
        value: 'offset:0',
      },
    ];

    const urlParams = new URLSearchParams(window.location.search);
    const facetFilters = urlParams.get('facetFilters');
    const query = urlParams.get('q');
    const preservedFacets = [
      'productProjections.variants.filterableAttributes.purity',
      'productProjections.variants.filterableAttributes.MOQ',
      'productProjections.variants.filterableAttributes.country-of-origin',
    ];

    if (facetFilters) {
      facetFilters.split(',').forEach((facet) => {
        const facetKey = facet.split(':')[0];
        if (!preservedFacets.includes(facetKey)) {
          params.push({ key: 'facetFilters', value: facet });
        }
      });
    }

    if (query !== null && query !== undefined) {
      params.push({ key: 'q', value: query });
    }

    const currentURL = updateURLParams(params);
    router.push(currentURL);
    setSelected({});
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const facetFilters = urlParams.get('facetFilters');

    if (facetFilters) {
      const preservedFacets = [
        'productProjections.variants.filterableAttributes.purity',
        'productProjections.variants.filterableAttributes.MOQ',
        'productProjections.variants.filterableAttributes.country-of-origin',
      ];

      const facetsArray = facetFilters.split(',');
      const selectedFacets = {};

      facetsArray.forEach((facet) => {
        const facetKey = facet.split(':')[0];
        if (preservedFacets.includes(facetKey)) {
          selectedFacets[facet] = true;
        }
      });

      setSelected(selectedFacets);
    }
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [router.query]);

  const handleCheckboxChange = (facetValue) => {
    setLoading(true);
    const urlParams = new URLSearchParams(window.location.search);
    const existingFacetFilters = urlParams.get('facetFilters');
    const query = urlParams.get('q');
    const existingSort = urlParams.get('sortby');
    const isFacetSelected = existingFacetFilters?.split(',').includes(facetValue);

    if (isFacetSelected) {
      const updatedFacetFilters = existingFacetFilters
        .split(',')
        .filter((value) => value !== facetValue)
        .join(',');

      const params = [
        { key: 'cursor', value: 'offset:0' },
        { key: 'facetFilters', value: updatedFacetFilters },
      ];
      if (query) {
        params.push({ key: 'q', value: query });
      }

      if (existingSort) {
        params.push({ key: 'sortby', value: existingSort });
      }

      const currentURL = updateURLParams(params);
      router.push(currentURL);
      setSelected((prevState) => ({ ...prevState, [facetValue]: false }));

      const newSelected = { ...selected };
      delete newSelected[facetValue];
      if (Object.keys(newSelected).length < 2) {
        setSelected(newSelected);
      }
    } else {
      const newFacetFilters = existingFacetFilters ? existingFacetFilters + ',' + facetValue : facetValue;

      const params = [
        { key: 'cursor', value: 'offset:0' },
        { key: 'facetFilters', value: newFacetFilters },
      ];
      if (query) {
        params.push({ key: 'q', value: query });
      }

      if (existingSort) {
        params.push({ key: 'sortby', value: existingSort });
      }

      const currentURL = updateURLParams(params);
      router.push(currentURL);
      setSelected((prevState) => ({ ...prevState, [facetValue]: true }));
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div>
        <div className="secondary_search_filters">
          <div className="secondary-filters">
            {facets.map((accordionData, i) =>
              accordionData.label == 'productProjections.variants.filterableAttributes.MOQ' ||
              accordionData.label == 'productProjections.variants.filterableAttributes.purity' ||
              accordionData.label == 'productProjections.variants.filterableAttributes.country-of-origin' ? (
                <PlpFilterAccordion
                  openSectionTitle={accordionData.label.split('.').pop().replace(/-/g, ' ')}
                  closedSectionTitle={accordionData.label.split('.').pop().replace(/-/g, ' ')}
                  className="secondary_search_filter_weight accordion_search_item capitalize"
                >
                  <div className="plpLeftFilterItem plp_country_filter flex items-center justify-between">
                    {accordionData?.terms?.map((filter, i) => {
                      return (
                        <div key={i}>
                          <label className="plp_country_filter_options">
                            <input
                              type="checkbox"
                              value={accordionData.key + ':' + filter.key}
                              checked={selected[accordionData.key + ':' + filter.key]}
                              onChange={(e) => handleCheckboxChange(accordionData.key + ':' + filter.key)}
                            />
                            {/* {console.log('filter: ' + filter.key)} */}

                            <p className="plp_country_filter_label">{filter.key}</p>
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </PlpFilterAccordion>
              ) : null,
            )}
          </div>
        </div>
        <div className="secondary-selected_filters">
          {Object.entries(selected).map(([facetValue, isSelected]) => {
            // console.log('Fact Value: ', facetValue);

            if (isSelected) {
              return (
                <div key={facetValue} className="selected_filter_item">
                  <span className="selected-filter capitalize">
                    {facetValue.slice(49).replace('country-of-origin', ' ').replace(':', '  ')}
                    <button className="cross-icon" onClick={() => handleCheckboxChange(facetValue)}>
                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                          fill="#0280C7"
                        />
                      </svg>
                    </button>
                  </span>
                </div>
              );
            }
          })}
          {Object.keys(selected).length > 1 && (
            <button className="clear-all" onClick={handleClearFilters}>
              Clear All
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default SecondarySearchFilters;
