import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import APISlider from './api-slider';
import { commercetoolsAPIClient } from 'helpers/utils/axiosRequestsUtil';


function ProductsSoldByVendor({ vendorInfo }) {
  const [openTab, setOpenTab] = useState(1);
  const [productsByVendor, setProductsByVendor] = useState([]);
  const [productsLength, setProductsLength] = useState(null)
  const [limit, setLimit] = useState(12);
  const [offset, seetOffset] = useState(0);
  const router = useRouter();
  const vendorCode = router?.query?.['vendor-code']
  const setProductsByVendorHandler = async () => {
    try {
      const response = await commercetoolsAPIClient.get(
        `products?limit=12&offset=0&where=masterData%28current%28masterVariant%28attributes%28%28name%3D%22vendor-id%22%20and%20value%3D%22${vendorCode}%22%29%20or%20%28name%3D%22vendor-code%22%20and%20value%28en-IN%3D%22${vendorCode}%22%29%29%29%29%29%29`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
          },
        },
      );
      setProductsByVendor(response.data.results);
      setProductsLength(response?.data?.total)
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    setProductsByVendorHandler();
  }, []);
  const selectVendor = () => {
    router.push(`/search?q=*&facetFilters=productProjections.variants.filterableAttributes.vendor-name:${vendorInfo?.value?.vendorName}`);
  };
  if (productsByVendor?.length == 0) {
    return null;
  }

  return (
    <div className="mostPopularContainer  bg-[#F3F5FD] ">
      <div className="container relative mx-auto">
        <div className="flex flex-wrap lg:mb-[24px]">
          <div className="w-full">
            <div className='sm:flex flex-row lg:grid lg:grid-cols-12 lg:items-start lg:items-start'>
              <div className="lg:col-start-0 2xl:col-start-1 lg:col-span-5">
                <h1 className='vendor-info-name lg:mt-[28px] products-sold-heading prod-sold-desk'>Products Sold by {vendorInfo?.value?.vendorName} ({productsLength})</h1>
                <h1 className="vendor-info-name lg:mt-[28px] products-sold-heading lg:hidden">Products ({productsLength})</h1>
              </div>
              <ul className="sliderTabsWrap vendor-see-all vendor-products-see-all mb-0 flex list-none flex-row flex-wrap justify-start lg:col-start-10 2xl-col-start-11 lg:col-span-3 " role="tablist">
                <li className="sliderTabsWrapBtn">
                  <button className="see_all_button sm:-mt-[20px] vendor-see-all-btn lg:mt-[28px]" onClick={() => selectVendor()}>See All</button>
                </li>
              </ul>
            </div>
            <div className="mb-6 flex w-full min-w-0 flex-col ">
              <div className="flex-auto">
                <div className="tab-content tab-space">
                  <div className={openTab === 1 ? 'block' : 'hidden'} id="link1">
                    <APISlider popularProducts={productsByVendor} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductsSoldByVendor;
