import Link from 'next/link';
import router from 'next/router';

interface Props {
  vendor?: string;
  showVendorLink?: boolean;
}

const TermsConditions = ({ vendor, showVendorLink = true }: Props) => {
  const continueMsg = `Continue Shopping with ${vendor}`;
  const vendorName = vendor;
  const selectVendor = () => {
    router.push(`/search?q=*&facetFilters=productProjections.variants.filterableAttributes.vendor-name:${vendorName}`);
  };
  return (
    <section aria-labelledby="cart-heading" className="pl-15 mb-2 md:mb-0 lg:col-span-4">
      <div style={{ backgroundColor: '#F3F5FD' }}>
        <p
          style={{ fontSize: '14px' }}
          className="flex justify-between pr-5 text-base font-bold text-gray-700 hover:text-gray-800 md:pr-0"
        >
          Terms and conditions
        </p>
        <ul className="terms-list" style={{ marginLeft: '17px' }}>
          <li>All Prices shown exclude gst</li>
          <li>All information is subject to change without notice</li>
        </ul>
        {showVendorLink && (
          <p className="continue-btn" style={{ margin: '5px 0px 0px 5px' }} onClick={() => selectVendor()}>
            {continueMsg}
          </p>
        )}
      </div>
    </section>
  );
};

export default TermsConditions;
