import React from 'react';
import Link from 'next/link';
import { Account } from '@Types/account/Account';
import Markdown from 'components/commercetools-ui/content/markdown';
import Typography from 'components/commercetools-ui/typography';
import { useAccount } from 'frontastic/provider';

const ContentLoginBanner = ({ data }) => {
  const { account } = useAccount();
  const loginBannerData = data?.data?.dataSource?.attributes;
  const bgImageDesktop = loginBannerData?.bannerBackgroundDesktop?.content?.url;
  return (
    <>
      {!account && (
        <div className="loginBannerMain">
          <div
            className="container mx-auto px-5 md:px-0 lg:px-0"
            // style={{ backgroundImage: "url(" + bgImageDesktop + ")"}}
          >
            <div className="loginBannerWrap md:grid lg:grid-cols-2 2xl:grid-cols-2 2xl:gap-[90px]">
              <div className="col-span-1">
                <div className="loginBannerLeft loginBannerDesk col-span-1">
                  <p className="loginBannerTitle">{loginBannerData?.bannerTitle?.content}</p>
                  <p className="loginBannerPara">{loginBannerData?.bannerSubTitle?.content}</p>
                  <Link href={'/login'}>
                    <button className="loginBannerBTN">
                      {loginBannerData?.bannerCta1Text?.content || 'Btn Text not found'}
                    </button>
                  </Link>
                </div>
                <div className="loginBannerLeft loginBannerMob col-span-1">
                  <p className="loginBannerTitle">{loginBannerData?.bannerTitle?.content}</p>
                  <Link href={'/login'}>
                    <button className="loginBannerBTN">
                      {loginBannerData?.bannerCta1Text?.content || 'Btn Text not found'}
                    </button>
                  </Link>
                  <p className="loginBannerPara">{loginBannerData?.bannerSubTitle?.content}</p>
                </div>
              </div>
              <div className="loginBannerRight col-span-1">
                <img src={loginBannerData?.bannerImage?.content?.url} alt="" className="loginBannerImg" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ContentLoginBanner;
