import React, { useEffect, useState } from 'react';

import OrdersList from 'components/commercetools-ui/orders/orders-list';
import MobileOrderList from 'components/commercetools-ui/orders/orders-list/mobile-order-list';

function OrderListTastic() {
  const [width, setWidth] = useState<number>(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  return (
    <div className="container mobile-container-OL mx-auto pb-24 lg:mr-[100px] order-list-wrapper">
      <div className="order-container-main flex">
        <div className="quote-container">
          <div className="quote-part-two">
            <div className="lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-7">
              <div className="lg:col-span-11">{isMobile ? <MobileOrderList /> : <OrdersList />}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderListTastic;
