import React, { useState, useEffect } from 'react';

import { useRouter } from 'next/router';

import {
  STATIC_GET_SELLER_DETAILS,
  STATIC_QUOTE_DETAILS,
  STATIC_VIEW_QUOTE,
  STATIC_QUOTE_ACTION,
  STATIC_CREATE_QUOTE_REQUEST,
} from 'helpers/constants/statisURLs';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { backendAPIClient, commercetoolsAPIClient } from 'helpers/utils/axiosRequestsUtil';
import toast from 'react-hot-toast';
// import { addItem } from 'frontastic/actions/cart';
import QuoteProductDetail from './quoteProductDetail';
import TalkToSeller from 'frontastic/tastics/request-for-quote/talkToSeller';
import Loader from 'components/commercetools-ui/loader';
import { STATUS, SUCCESS_API_CODES } from 'helpers/constants/constant';
import { onlyNums } from 'helpers/helper';
import { useCart } from 'frontastic/provider';
import axios from 'axios';
import { COMMERCETOOLS_BASE_URL } from 'helpers/constants/envVariables';

interface Props {
  selectedQuote: any;
  onReject: () => void;
}

export function QuoteDetails({ selectedQuote, setShowDetails, onReject }) {
  const [isAccepted, setIsAccepted] = useState(false);
  const [quoteDetails, setQuoteDetails] = useState<any>();
  const [newPrice, setNewPrice] = useState(0);
  const { data: cartList } = useCart();

  const [quoteID, setQuoteID] = useState<any>();
  const [sellerDetails, setSellerDetails] = useState<any>();
  const [deactivteRejectBtn, setDeactivteRejectBtn] = useState(false);
  const [deactivteAcceptBtn, setDeactivteAcceptBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showCheckout, setShowCheckout] = useState(false);
  const router = useRouter();

  useEffect(()=> console.log("deactivteAcceptBtn: ", deactivteAcceptBtn), [deactivteAcceptBtn])

  const onAccept = async () => {
    if (isAccepted) {
      // let apiCallCount = 0;
      // for (let i = 0; i < quoteDetails?.lineItems?.length; i++) {
      //   const variantData = {
      //     sku: quoteDetails?.lineItems[i]?.variant?.sku,
      //     count: quoteDetails?.lineItems[i]?.quantity,
      //   };
      //   await addItem(variantData, quoteDetails?.lineItems[i]?.quantity, '', '', '', '').then((response) => {
      //     apiCallCount++;
      //   });
      // }
      // router.push('/checkout');
      const tokenCategory = localStorage.getItem('token');
      await axios.post(`${COMMERCETOOLS_BASE_URL}/orders/quotes`, {quote: {id: quoteDetails.id, typeId: "quote"}, version : 1} , {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${tokenCategory}`,
        },
      }).then((res) => {
        console.log("res", res);
        setShowDetails(false)
        toast.success('Order placed successfully!');
      }).catch((err) => console.log("error", err));
      return;
    }
    toast.success('Quote Accepted successfully!');
    if (!quoteDetails) {
      return;
    }
    const reqBody = {
      quoteId: quoteID,

      quoteState: 'Pending',
    };

    try {
      const acceptQuote = await backendAPIClient.post(STATIC_QUOTE_ACTION, reqBody);
      if (SUCCESS_API_CODES.includes(acceptQuote.status)) {
        if (acceptQuote.status === 200) {
          const _quoteDetails = acceptQuote?.data;
          setQuoteDetails(_quoteDetails);
          setIsLoading(false);
          setShowCheckout(true)
        }
        setQuoteDetails(quoteDetails => ({ ...quoteDetails, quoteState: STATUS.ACCEPTED }))
        setIsAccepted(true);
      }
    } catch (err) { }
  };
  useEffect(() => {
    quoteDetailsHandler();
  }, []);

  useEffect(() => {
    qouteView();
  }, []);

  const reqBody1 = {
    quoteRequestId: selectedQuote?.id,
  };

  const reqBody = {
    quoteId: selectedQuote?.id,
    quoteNumber: selectedQuote?.version,
  };
  const quoteDetailsHandler = async () => {
    setIsLoading(true);
    try {
      const response = await backendAPIClient.post(STATIC_VIEW_QUOTE, reqBody1);
      if(response?.data) {
        const {quote: _quoteDetails} = response.data;
        setQuoteDetails(_quoteDetails);
        setIsLoading(false);

        const sert = _quoteDetails.id;

        setQuoteID(sert);

        //get seller details.
        const sellerCode: string = _quoteDetails?.lineItems[0]?.variant?.attributes.find((el) => el.name == 'vendor-code')
          ?.value?.['en-IN'];

        if (sellerCode) {
          const sellerDetailRes = await backendAPIClient.get(`${STATIC_GET_SELLER_DETAILS}/?vendorCode=${sellerCode}`);
          setSellerDetails(sellerDetailRes?.data);
        }
        const isCancelled = _quoteDetails?.quoteRequestState === 'Cancelled';
        const isPriceUdatedByBuyer = _quoteDetails?.custom?.fields.priceUpdatedBy === 'Buyer';
        setDeactivteRejectBtn(isCancelled);
        setDeactivteAcceptBtn(isCancelled || isPriceUdatedByBuyer);
        setIsAccepted(_quoteDetails?.quoteRequestState === 'Accepted');
      }
    } catch (error) { setIsLoading(false); }
  };

  const qouteView = async () => {
    try {
      const response1 = await backendAPIClient.post(STATIC_QUOTE_DETAILS, reqBody);
      const _quoteDetails1 = response1?.data?.quote;
      const sellerCode1: string = _quoteDetails1?.lineItems[0]?.variant?.attributes.find(
        (el) => el.name == 'vendor-code',
      )?.value?.['en-IN'];
    } catch (error) { }
  };

  const getFormattedDate = (dateValue) => {
    const x = new Date(dateValue * 1000);
    return `${x.getDate()}/${x.getMonth() + 1}/${x.getFullYear()}`;
  };

  const onClickReject = async () => {
    if (!quoteDetails) {
      return;
    }
    setIsLoading(true);
    const reqBody = {
      quoteId: quoteID,

      quoteState: 'Declined',
    };

    try {
      const rejectQuote = await backendAPIClient.post(STATIC_QUOTE_ACTION, reqBody);
      setNewPrice(0);

      if (rejectQuote.status.toString() === '200' || '201') {
        const _quoteDetails = rejectQuote?.data;
        toast.success('Quote Rejected successfully!');
        setQuoteDetails(_quoteDetails);
        setIsLoading(false);
        setDeactivteRejectBtn(true);
        setDeactivteAcceptBtn(true);
        router.push('/quotes/quotes-list');

        onReject();
      }
    } catch (err) { setIsLoading(false); }
  };

  const onRespondBack = async () => {
    if (newPrice) {
      const quoteTax = quoteDetails?.custom?.fields?.quoteTax || 0;
      const newTotalNet = (newPrice || 0) * (quoteDetails?.lineItems[0]?.quantity || 1)
      const newTotalNetWithTax = newTotalNet + ((newTotalNet * (quoteTax || 0)) / 100)
      const payload = {
        cartId: cartList?.cartId,
        quoteRequestId: selectedQuote?.id,
        cartVersion: 1,
        customFields: {
          RFQTitle: quoteDetails?.custom?.fields?.rfqtitle,
          isQuote: true,
          quoteNumber: quoteDetails?.custom?.fields?.quoteNumber,
          quoteLineItemPrice: {
            ...quoteDetails.custom.fields.quoteLineItemPrice,
            centAmount: isNaN(newPrice) ? 0 : newPrice,
          },
          quoteTotalPrice: {
            ...quoteDetails.custom.fields.quoteTotalPrice,
            centAmount: isNaN(newTotalNetWithTax) ? 0 : newTotalNetWithTax,
          },
          quoteTax,
        },
      }

      const res: any = await backendAPIClient.post(STATIC_CREATE_QUOTE_REQUEST, payload);
      const quote = res?.data?.response?.quote;
      if (quote) {
        toast.success('Price sent back to Seller');
        const tempQuoteDetails = { ...quoteDetails };
        tempQuoteDetails.custom.fields.quoteLineItemPrice = quote.custom.fields.quoteLineItemPrice
        tempQuoteDetails.custom.fields.quoteTotalPrice = quote.custom.fields.quoteTotalPrice
        tempQuoteDetails.custom.fields.priceUpdatedBy = 'Buyer';
        setQuoteDetails({ ...tempQuoteDetails })
        setDeactivteAcceptBtn(true);
      }
      setNewPrice(0);
    }
  };

  const handleMessageChange = (e) => {
    const { target: { value } } = e
    if (onlyNums(value)) {
      setNewPrice(value);
    }
  };

  return (
    <div className="">
      <div className="quote-details-section">
        <div className="quote-title">
          <p className="quote-details-heading text-[20px] font-semibold tracking-[1px] text-[#003769] lg:text-[24px]">
            Quote Details
          </p>
        </div>
        {isLoading && <Loader />}
        <div className="quote-details-section-body">
          <div className="quote-details-row">
            <div className="quote-details-col">
              <div className="quote-details-col-key">RFQ Number :</div>
              <div className="quote-details-col-val">{quoteDetails?.custom?.fields?.quoteNumber}</div>
            </div>
            <div className="quote-details-col">
              <div className="quote-details-col-key">RFQ Name :</div>
              <div className="quote-details-col-val">{quoteDetails?.custom?.fields?.rfqtitle}</div>
            </div>
            <div className="quote-details-col">
              <div className="quote-details-col-key">Quote Status :</div>
              <div className="quote-details-col-val quote-details-col-val-status">{quoteDetails?.quoteState}</div>
            </div>
            {/* <div className="quote-details-col">
              <div className="quote-details-col-key">Quote Version :</div>
              <div className="quote-details-col-val">{quoteDetails?.version}</div>
            </div> */}
          </div>
          <div className="quote-details-row">
            <div className="quote-details-col">
              <div className="quote-details-col-key">Date Created :</div>
              <div className="quote-details-col-val">{!isLoading && getFormattedDate(quoteDetails?.createdAt)}</div>
            </div>
            <div className="quote-details-col">
              <div className="quote-details-col-key">Date Updated :</div>
              <div className="quote-details-col-val">{!isLoading && getFormattedDate(quoteDetails?.lastModifiedAt)}</div>
            </div>
            <div className="quote-details-col">
              <div className="quote-details-col-key">Quote Expiry :</div>
              <div className="quote-details-col-val">{!isLoading && getFormattedDate(quoteDetails?.validTo)}</div>
            </div>
          </div>
          <div className="quote-details-row">
            <div className="quote-details-col">
              <div className="quote-details-col-key flex-3">Seller Name :</div>
              <div className="quote-details-col-val">
                <span className="quote-details-col-val-seller">
                  {quoteDetails?.lineItems?.[0]?.variant?.attributes?.find((i) => i.name === 'vendor-name')?.value?.[
                    'en-IN'
                  ] || 'N/A'}
                </span>
              </div>
            </div>
            {/* <div className="quote-details-col">
              <div className="quote-details-col-key">Quote Expiry :</div>
              <div className="quote-details-col-val">{getFormattedDate(quoteDetails?.validTo)}</div>
            </div> */}
            <div className="quote-details-col">
              <div className="quote-details-col-key flex-3">RFQ Description :</div>
              <div className="quote-details-col-val">
                <div className="quote-details-col-val quote-details-comment">{quoteDetails?.comment || quoteDetails?.sellerComment}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {quoteDetails &&
        quoteDetails?.lineItems?.map((product) => <QuoteProductDetail key={product.id} product={product} />)}
      <hr />
      <div className="xs:flex-none mx-[18px] mt-[40px] justify-around text-[14px] md:flex">
        {/* <div className="xs:flex-none pt-[10px] text-left md:flex-[75%]">
          <h1 className="font-bold">Terms and Conditions</h1>
          <ul>
            <li>* All prices shown exclude GST</li>
            <li>* All information is subject to change without notice</li>
          </ul>
        </div>
        <br /> */}
        <div className="xs:flex-none md:flex-[25%]">
          <div className="flex">
            <div className="flex-1 text-left">
              <ul className="xs:text-[12px] md:text-[18px]">
                <li>Price (per qty)</li>
                <li>Tax</li>
                <li className="xs:text-[14px] mt-5  md:text-[18px]">Grand Total</li>
              </ul>
            </div>
            <div className="xs:text-left flex-1 md:text-right">
              <ul className="xs:text-[12px] font-bold md:text-[18px]">
                <li>{CurrencyHelpers.formatForCurrency(quoteDetails?.custom?.fields?.quoteLineItemPrice)}</li>
                <li>{quoteDetails?.custom?.fields?.quoteTax}%</li>
                <li className="xs:text-[14px] mt-5 md:text-[18px]">
                  <li>{CurrencyHelpers.formatForCurrency(quoteDetails?.custom?.fields?.quoteTotalPrice)}</li>
                  { 
                    (quoteDetails?.custom?.fields.priceUpdatedBy && !isAccepted) && 
                    <li className="text-sm text-cyan-600">{`Price Updated by ${quoteDetails.custom.fields.priceUpdatedBy}`}</li>
                  }
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="my-10 mx-[20px] flex flex-wrap-reverse justify-end gap-5 text-center md:flex-nowrap">
          {(quoteDetails?.quoteState === 'Pending' && !isAccepted) && (
            <div className="flex justify-end">
              <input type="text" placeholder='New price' value={newPrice} onChange={handleMessageChange} />
              <a className={`link ${newPrice ? 'link-primary' : 'text-gray-400 cursor-not-allowed'} w-[100%] self-center px-1 md:w-auto`} onClick={onRespondBack}>
                Respond back
              </a>
            </div>
          )}
          {
            (quoteDetails?.quoteState === 'Pending' && !isAccepted) &&
            (
              <div>
                <button
                  className="btn btn-outline w-[100%] md:w-auto"
                  onClick={onClickReject}
                  disabled={deactivteRejectBtn}
                >
                  Reject
                </button>
              </div>
            )
          }

          {
            (quoteDetails?.quoteState === 'Pending' || quoteDetails?.quoteState === 'Accepted' || isAccepted) &&
            (
              <button className="btn-blue btn w-[100%] md:w-auto" onClick={onAccept} disabled={deactivteAcceptBtn}>
                {(isAccepted || quoteDetails?.quoteState === 'Accepted') ? 'Continue to Checkout' : 'Accept'}
              </button>
            )}
        </div>
      </div>
      <TalkToSeller fileUploadCallback={null} existingComments={quoteDetails?.custom?.fields?.quoteComments || quoteDetails?.fields?.quoteComments || []} quoteRequestId={quoteDetails?.stagedQuote?.id} status={quoteDetails?.quoteState} />
    </div>
  );
}
