/* eslint-disable tailwindcss/no-arbitrary-value */
import { useEffect, useRef, useState } from 'react';
import { FileUpload } from '../form';
import { backendAPIClient } from 'helpers/utils/axiosRequestsUtil';
import { STATIC_QUOTE_DOCUMENTS_UPLOAD } from 'helpers/constants/statisURLs';

const RequestQuoteProductDetails = ({
  selectedProduct,
  quantityCallBack,
  packageSizeCallback,
  descriptionCallBack,
  fileUploadCallback,
  isMobile = false,
}) => {
  const [quantity, setQuantity] = useState(1);
  const [purity, setPurity] = useState('');
  const [country, setCountry] = useState('');
  const [packSize, setPackSize] = useState('');
  const [selectedSupplierPartNumber, setSelectedSupplierPartNumber] = useState('');
  const [file, setFile] = useState<File>();
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const purityArray = [];
  const countryArray = [];
  const packSizeArray = [];
  const standardsArray = [];
  const dosageArray = [];
  const [selectedAttributes, setSelectedAttributes] = useState({ purity: '', country: '', packSize: '', supplierPartNumber: '' })
  const [produtAttributes, setProdutAttributes] = useState({ purities: [], countryOfOrigin: [], packagingSize: [], pharmacopoeaStandard: [], dosage: [], supplierPartNumber: [] })
  const { purities, countryOfOrigin, packagingSize, pharmacopoeaStandard, dosage, supplierPartNumber } = produtAttributes;

  useEffect(() => {
    const [purities, countryOfOrigin, packagingSize, pharmacopoeaStandard, dosage, supplierPartNumber] = [[], [], [], [], [], []]
    selectedProduct?.variants.forEach((v, ind) => {
      v?.attributes.forEach((att, ind) => {
        // if (ind != 0) {
        switch (att.name) {
          case 'purity': {
            if (!purities.includes(att.value)) {
              purities.push(att.value);
            }
            break;
          }
          case 'country-of-origin': {
            if (att?.value?.label && !countryOfOrigin.includes(att.value.label)) {
              countryOfOrigin.push(att.value.label);
            }
            break;
          }
          case 'packaging-size': {
            if (!packagingSize.includes(att.value)) {
              packagingSize.push(att.value);
            }
            break;
          }
          case 'pharmacopoea-standard': {
            if (!pharmacopoeaStandard.includes(att.value)) {
              pharmacopoeaStandard.push(att.value);
            }
            break;
          }
          case 'dosage': {
            if (att?.value['en-IN'] && dosage.includes(att.value['en-IN'])) {
              dosage.push(att?.value['en-IN']);
            }
            break;
          }
          default: null
        }
        // }
      })
    });
    setProdutAttributes({ purities, countryOfOrigin, packagingSize, pharmacopoeaStandard, dosage, supplierPartNumber })
    setDefaultValToAttributes({ purities, countryOfOrigin, packagingSize, pharmacopoeaStandard, dosage, supplierPartNumber })
  }, [selectedProduct?.variants])

  const setDefaultValToAttributes = (attributes) => {
    const { purities, countryOfOrigin, packagingSize, pharmacopoeaStandard, dosage, supplierPartNumber } = attributes;
    const tempAttributes = { purity: '', country: '', packSize: '', supplierPartNumber: '' }
    if (purities.length > 0) { tempAttributes.purity = purities[0]; }
    if (countryOfOrigin.length > 0) { tempAttributes.country = countryOfOrigin[0]; }
    if (packagingSize.length > 0) { tempAttributes.packSize = packagingSize[0]; }
    if (pharmacopoeaStandard.length > 0) { tempAttributes.packSize = pharmacopoeaStandard[0]; }
    if (dosage.length > 0) { tempAttributes.packSize = dosage[0]['en-IN']; }
    setSelectedAttributes(attr => ({ ...attr, ...tempAttributes }))
  }

  const inputRef = useRef<HTMLInputElement | null>(null);
  const accept = '.doc, .docx, .xls, .xlsx, .pdf';
  const updateQuantity = (qty) => {
    setQuantity(qty);
    quantityCallBack(qty);
  };

  const handleDescription = (value) => {
    descriptionCallBack(value);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return e.preventDefault();
  };

  const onFileChange = (file: File) => {
    setIsFileUploaded(false);
    setFile(file);
  };

  const onUploadFile = async (file: any) => {
    try {
      const formData = new FormData();
      for (const item of file) {
        formData.append('file', item);
      }
      formData.append('cartId', 'ec56f1ae-182c-4ddf-8083-bcca5d704f7b');

      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      await backendAPIClient.post(STATIC_QUOTE_DOCUMENTS_UPLOAD, formData, { headers }).then((res) => {
        fileUploadCallback(res);
        setIsFileUploaded(true);
      });
    } catch (err) { }
  };

  const clearUpload = () => {
    setFile(null);
    setIsFileUploaded(false);
  };

  const inputElement = (
    <input
      className="file_upload_button  h-[44px]"
      id="fileUpload"
      type="file"
      ref={inputRef}
      onChange={() => { }}
      accept={accept}
      style={{ display: 'none' }}
    />
  );

  const buttonElement = (
    <button className="btn ml-2 !w-[30%]" onClick={() => { }} disabled={false}>
      Upload File
    </button>
  );

  function formatMessage(arg0: { id: string; defaultMessage: string }): import('react').ReactNode {
    throw new Error('Function not implemented.');
  }

  useEffect(() => getSupplierPartNumberOptions(), [selectedAttributes.packSize])

  useEffect(() => packageSizeCallback(selectedAttributes), [selectedAttributes])

  const getSupplierPartNumberOptions = () => {
    const tempSupplierPartNumberOptions = [];
    if (selectedAttributes.packSize) {
      selectedProduct?.variants.forEach((v, ind) => {
        if (v?.attributes.find(att => att.name === 'packaging-size')?.value === selectedAttributes.packSize) {
          const supplierPartNumber = v?.attributes.find(att => att.name === 'supplier-part-number')?.value;
          if (supplierPartNumber) {
            tempSupplierPartNumberOptions.push(supplierPartNumber)
          }
        }
      });
      setProdutAttributes({ ...produtAttributes, supplierPartNumber: tempSupplierPartNumberOptions })
      setSelectedAttributes(attr => ({ ...attr, ...{ 'supplierPartNumber': tempSupplierPartNumberOptions[0] } }))
    }
  }

  const renderDropwown = (label, options, changeHandler, key, isElementValueEN = false) => (
    options.length > 0
      ?
      <div className="quote-form-element">
        <label className="quote-form-element-label">
          {label}<span className="ml-[3px] text-[#EB4747]">*</span>
        </label>
        {
          // options.length === 1
          //   ? <div className="rfqInput">
          //     <input type="text" className='rfq-input' style={{ background: '#FAFAFA' }} placeholder="" required value={options[0]} readOnly />
          //   </div>
          //   : 
          <select
            id="selected-tab"
            name="selected-tab"
            className="mt-1 block w-full rounded-sm border-gray-300 stroke-accent-400 py-2 pr-10 pl-3 text-base text-accent-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
            value={selectedAttributes[key]}
            onChange={(e) => {
              const value = e.target.value
              changeHandler(isElementValueEN ? value['en-IN'] : value, isElementValueEN);
              console.log([key], value)
              const updatePayload = { [key]: value }
              if (key === 'packSize') {
                updatePayload['supplierPartNumber'] = ''
              }
              setSelectedAttributes(attr => ({ ...attr, ...updatePayload }))
            }}
          >
            {/* {options.length > 1 && <option value=''>Please select</option>} */}
            {
              options.map((value, index) => (
                <option
                  className="values"
                  key={index}
                  defaultValue={value}
                >
                  {value}
                </option>
              ))
            }
          </select>
        }
      </div>
      : <></>
  )

  return (
    <div className="quote-product-details-container">
      {!isMobile && <div className="quote-product-details-title">Product Details</div>}
      <div className="quote-flex-container">
        {renderDropwown('Purity', purities, setPurity, 'purity')}
        {renderDropwown('Country of Origin', countryOfOrigin, setCountry, 'country')}
        {renderDropwown('Pack Size', packagingSize, setPackSize, 'packSize')}
        {renderDropwown('Supplier Part Number', supplierPartNumber, setSelectedSupplierPartNumber, 'supplierPartNumber')}
        <div className="quote-form-element">
          <label className="quote-form-element-label mb-1">Quantity</label>
          <div className="gms flex gap-[10px]">
            <div className="qty plpQTY" style={{ width: '100%' }}>
              <div
                className="grms-qty bg-[white]"
                style={{ width: '100%', justifyContent: 'space-between', borderColor: '#ABABAB' }}
              >
                <button
                  className={`qtyminus bg-[white] ${quantity <= 1 && 'disable'}`}
                  style={quantity <= 1 ? { backgroundColor: 'lightgray', cursor: 'not-allowed' } : {}}
                  onClick={() => {
                    quantity > 1 && updateQuantity(quantity - 1);
                  }}
                >
                  <svg style={{ marginLeft: '6px' }} color='red' width="14" height="2" viewBox="0 0 14 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.2079 2H0.992188V0H13.2079V2Z" fill={quantity <= 1 ? '#000000' : '#0068A3'} />
                  </svg>
                </button>
                <input className="plp-weight" type="text" value={quantity} />
                <button
                  className="qtyplus bg-[white]"
                  onClick={() => {
                    updateQuantity(quantity + 1);
                  }}
                >
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13.1649 8H7.92961V14H6.18451V8H0.949219V6H6.18451V0H7.92961V6H13.1649V8Z"
                      fill="#0068A3"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="quote-flex-container">
        <div className="quote-flex-container-one">
          <div className="quote-flex-container-one-one">
            {renderDropwown('Standards', pharmacopoeaStandard, setPackSize, 'packSize')}
            {/* <div className="quote-form-element-50">
              <label className="quote-form-element-label">Standards</label>
              <select id="selected-tab" name="selected-tab" className="pdp_search_filter w-[50%] 2xl:w-auto">
                {selectedProduct?.variants
                  .filter((_, index) => index !== 0)
                  .map((item, index) =>
                    item.attributes
                      .filter((data) => data.name === 'pharmacopoea-standard')
                      .map((element) => {
                        if (!standardsArray.includes(element?.value)) {
                          standardsArray.push(element?.value);
                          return (
                            <option
                              className="values"
                              key={index}
                              defaultValue={element?.value}
                              onClick={() => {
                                setPackSize(element?.value);
                              }}
                            >
                              {element?.value}
                            </option>
                          );
                        }
                      }),
                  )}
              </select>
            </div> */}
            {
              <div className="quote-form-element-50">
                {selectedProduct?.variants[0].attributes.filter((data) => data.name === 'dosage').length !== 0 && (
                  renderDropwown('Dosage Forms', dosage, setPackSize, 'packSize', true)
                  // <>
                  //   <label className="quote-form-element-label">
                  //     Dosage Forms <span className="ml-[3px] text-[#EB4747]">*</span>
                  //   </label>

                  //   <select id="selected-tab" name="selected-tab" className="pdp_search_filter w-[50%] 2xl:w-auto">
                  //     {selectedProduct?.variants
                  //       .filter((_, index) => index !== 0)
                  //       .map((item, index) =>
                  //         item.attributes
                  //           .filter((data) => data.name === 'dosage')
                  //           .map((element) => {
                  //             if (!dosageArray.includes(element?.value['en-IN'])) {
                  //               dosageArray.push(element?.value['en-IN']);
                  //               return (
                  //                 <option
                  //                   className="values"
                  //                   key={index}
                  //                   defaultValue={element?.value['en-IN']}
                  //                   onClick={() => {
                  //                     setPackSize(element?.value['en-IN']);
                  //                   }}
                  //                 >
                  //                   {element?.value['en-IN']}
                  //                 </option>
                  //               );
                  //             }
                  //           }),
                  //       )}
                  //   </select>
                  // </>
                )}
              </div>
            }
          </div>
        </div>
      </div>
      <div className="flex pt-4">
        <div className="quote-details-file-upload w-1/2">
          <label className="quote-form-element-label">Product Specification Document</label>
          <div className="upload_file_pdp ml-[-5px]" style={{ width: '100%' }}>
            {file ? (
              <div className="mt-5 flex gap-2" style={{ alignItems: 'center' }}>
                <div className="multi-file-wrapper">
                  {Object.values(file).map((element, index) => (
                    <div key={index}>
                      <div className="multi-file-wrapper">
                        <span
                          className="fileclearbutton text-[#0280c7]"
                          style={{ marginRight: '5px' }}
                          onClick={clearUpload}
                        >
                          X
                        </span>
                        {element.name}
                      </div>
                    </div>
                  ))}
                </div>
                <div>
                  <button className="btn" disabled={isFileUploaded} onClick={() => onUploadFile(file)}>
                    {isFileUploaded ? 'Done' : 'Upload'}
                  </button>
                </div>
              </div>
            ) : (
              <FileUpload
                file={file}
                name="file_upload"
                label="Add Document"
                accept={accept}
                isFileUploaded={isFileUploaded}
                onFileChange={onFileChange}
                onUpload={onUploadFile}
                clearUpload={clearUpload}
                forQuote={true}
                isMultiple={true}
              />
            )}
          </div>
        </div>
        <div className="quote-details-description w-1/2">
          <div style={{ width: '100%' }}>
            <label className="quote-form-element-label">Description</label>
            <textarea
              required
              name="Description"
              id="Description"
              maxLength={500}
              placeholder="Enter a Description"
              autoComplete="given-Description"
              className="review_description_box quote_description_box mt-[5px] text-[12px]"
              onChange={(e) => {
                handleDescription(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestQuoteProductDetails;
