/* eslint-disable tailwindcss/no-custom-classname */
import React, { useEffect, useState } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { EyeIcon, ChevronLeftIcon, ChevronRightIcon, DotsHorizontalIcon } from '@heroicons/react/outline';
import Loader from 'components/commercetools-ui/loader';
import Price from 'components/commercetools-ui/price';
import { commercetoolsAPIClient } from 'helpers/utils/axiosRequestsUtil';
import { useAccount } from 'frontastic';

const MobileOrderList = () => {
  const { account } = useAccount();
  const [orders, setOrders] = useState([]);
  const [limit, setLimit] = useState(10);
  const [offset, seetOffset] = useState(0);
  const [totalProducts, setTotlaProducts] = useState(0);
  const [numberOfPages, setnumberOfPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const router = useRouter();
  const shippingCharges = 2000;
  const [visibleOrders, setVisibleOrders] = useState(10);
  const [showButton, setShowButton] = useState(true);

  const handleSeeMoreClick = () => {
    setVisibleOrders((prevVisibleOrders) => prevVisibleOrders + 10);
    setLimit((prevLimit) => prevLimit + 10);
  };

  useEffect(() => {
    if (visibleOrders > orders.length) {
      //   setShowButton(false);
    }
  }, [visibleOrders, orders.length]);

  const reqHeaders = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.localStorage.getItem('token')}`,
    },
  };

  useEffect(() => {
    if (account)
      commercetoolsAPIClient
        .get(`orders?limit=${limit}&offset=${offset}&where=customerId%3D%22${account.accountId}%22`, reqHeaders)
        .then((res) => {
          console.log('res?.data?.results - ', res?.data?.results);
          setOrders(res?.data?.results);
          setnumberOfPages(Math.ceil(res?.data?.total / limit));
          setShowButton(res?.data?.limit < res?.data?.total);
          setTotlaProducts(res?.data?.total);
          setLoading(false);
        });
  }, [offset, limit, account]);

  const prepareDate = (dateValue) => {
    const x = new Date(dateValue.toString());
    return `${x.getDate()}/${x.getMonth() + 1}/${x.getFullYear()}`;
  };

  const handleViewOrderDetailsClick = (orderId) => {
    router.push(`order-history?id=${orderId}`);
  };

  return (
    <>
      {loading && <Loader />}
      <div>
        <div className="order-list-main">
          <div className="quote-title">Order List ({totalProducts})</div>
          <p>The following are the list of your orders.</p>
        </div>
        <div>
          <div className="">
            {orders.slice(0, visibleOrders).map((order) => {
              return (
                <>
                  <div className="mobile-order-list">
                    <div className="cards-grid-orders">
                      <ul className="orderList-top-status">
                        <li>
                          <span className={`order-status status-${order.orderState}`}>{order.orderState}</span>
                        </li>
                        <li className="action-eye">
                          <EyeIcon
                            width="24px"
                            height="24px"
                            color="#0068A3"
                            cursor="pointer"
                            onClick={() => handleViewOrderDetailsClick(order.id)}
                          />
                        </li>
                      </ul>
                      <ul>
                        <li className="order-list-title">Order Number :</li>
                        <li className="order-detail-info">{order.orderNumber}</li>
                      </ul>
                      <ul>
                        <li className="order-list-title">PO Reference Number :</li>
                        <li className="order-detail-info">{order.purchaseOrderNumber}</li>
                      </ul>
                      <ul>
                        <li className="order-list-title">Order Date :</li>
                        <li className="order-detail-info">{prepareDate(order.createdAt.toString()).toString()}</li>
                      </ul>
                      <ul>
                        <li className="order-list-title">Delivery Date :</li>
                        <li className="order-detail-info">{prepareDate(order.createdAt.toString()).toString()}</li>
                      </ul>
                      <ul>
                        <li className="order-list-title">Total Order Price :</li>
                        <li className="order-detail-info">
                          <Price
                            price={order.taxedPrice.totalGross.centAmount + shippingCharges}
                            className="priceTd"
                          ></Price>
                        </li>
                      </ul>
                    </div>
                  </div>
                </>
              );
            })}
            <div className="showMoreBtnMain">
              {showButton && (
                <button className="orderListMoreBTN" onClick={handleSeeMoreClick}>
                  See more
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <p>{showButton.toString}</p> */}
    </>
  );
};

export default MobileOrderList;
