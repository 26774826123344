import { useRouter } from 'next/router';
import { useAccount } from 'frontastic';

export default function ProductDocuments({product}){
    const specifications = product?.variants[0].attributes;
    const { account } = useAccount();
    const router = useRouter();
    const handleDownloadButton = (url) => {
       if (!account) {
        router.push({pathname:'/login',query: router.asPath});
        } else {
          fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
              const blobURL = window.URL.createObjectURL(new Blob([blob]));
              const fileName = url.split('/').pop();
              const aTag = document.createElement('a');
              aTag.href = blobURL;
              aTag.setAttribute('download', fileName);
              document.body.appendChild(aTag);
              aTag.click();
              aTag.remove();
            });
        }
      };
       return(
        <div>
           <div className="documents-table">
                    <p className="spec-descrip">
                      Here you will find the list of product certification for {product?.name}
                    </p>
                    <div className="documents-tab">
                     <table className="table-auto">
                        <thead>
                          <tr>
                            <th className="doc-title">Document Name</th>
                            <th className="file-title">File Size</th>
                            <th className="act-title">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                         {specifications?.['allergen-declaration'] && (
                           <tr>
                              <td className="doc-name">
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 21"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M18 0.162888H6C4.9 0.162888 4 1.0701 4 2.17891V14.275C4 15.3838 4.9 16.2911 6 16.2911H18C19.1 16.2911 20 15.3838 20 14.275V2.17891C20 1.0701 19.1 0.162888 18 0.162888ZM18 14.275H6V2.17891H18V14.275ZM2 4.19493H0V18.3071C0 19.4159 0.9 20.3231 2 20.3231H16V18.3071H2V4.19493ZM14 10.243V7.21896C14 6.66456 13.55 6.21095 13 6.21095H11V11.251H13C13.55 11.251 14 10.7974 14 10.243ZM12 7.21896H13V10.243H12V7.21896ZM16 9.23498H17V8.22697H16V7.21896H17V6.21095H15V11.251H16V9.23498ZM8 9.23498H9C9.55 9.23498 10 8.78138 10 8.22697V7.21896C10 6.66456 9.55 6.21095 9 6.21095H7V11.251H8V9.23498ZM8 7.21896H9V8.22697H8V7.21896Z"
                                    fill="#676767"
                                  />
                                </svg>
                                Allergen
                              </td>

                              <td className="file-size">{specifications?.['allergen-declaration'].split('||').pop()}</td>

                              <td
                                className="actions-download"
                                onClick={() => {
                                  handleDownloadButton(specifications?.['allergen-declaration'].split('||').shift());
                                }}
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M14 11V14H2V11H0V14C0 15.1 0.9 16 2 16H14C15.1 16 16 15.1 16 14V11H14ZM13 7L11.59 5.59L9 8.17V0H7V8.17L4.41 5.59L3 7L8 12L13 7Z"
                                    fill="#0068A3"
                                  />
                                </svg>
                                Download
                              </td>

                              <td className="download-img test" 
                              // onClick={handleDownloadButton}
                              onClick={() => {
                                handleDownloadButton(specifications?.['allergen-declaration'].split('||').shift());
                              }}
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M14 11V14H2V11H0V14C0 15.1 0.9 16 2 16H14C15.1 16 16 15.1 16 14V11H14ZM13 7L11.59 5.59L9 8.17V0H7V8.17L4.41 5.59L3 7L8 12L13 7Z"
                                    fill="#0068A3"
                                  />
                                </svg>
                              </td>
                            </tr>
                          )}
                          {specifications?.['halal_declaration'] && (
                            <tr>
                              <td className="doc-name">
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 21"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M18 0.162888H6C4.9 0.162888 4 1.0701 4 2.17891V14.275C4 15.3838 4.9 16.2911 6 16.2911H18C19.1 16.2911 20 15.3838 20 14.275V2.17891C20 1.0701 19.1 0.162888 18 0.162888ZM18 14.275H6V2.17891H18V14.275ZM2 4.19493H0V18.3071C0 19.4159 0.9 20.3231 2 20.3231H16V18.3071H2V4.19493ZM14 10.243V7.21896C14 6.66456 13.55 6.21095 13 6.21095H11V11.251H13C13.55 11.251 14 10.7974 14 10.243ZM12 7.21896H13V10.243H12V7.21896ZM16 9.23498H17V8.22697H16V7.21896H17V6.21095H15V11.251H16V9.23498ZM8 9.23498H9C9.55 9.23498 10 8.78138 10 8.22697V7.21896C10 6.66456 9.55 6.21095 9 6.21095H7V11.251H8V9.23498ZM8 7.21896H9V8.22697H8V7.21896Z"
                                    fill="#676767"
                                  />
                                </svg>
                                Halal
                              </td>

                              <td className="file-size">{specifications?.['halal_declaration'].split('||').pop()}</td>

                              <td
                                className="actions-download"
                                onClick={() => {
                                  handleDownloadButton(specifications?.['halal_declaration'].split('||').shift());
                                }}
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M14 11V14H2V11H0V14C0 15.1 0.9 16 2 16H14C15.1 16 16 15.1 16 14V11H14ZM13 7L11.59 5.59L9 8.17V0H7V8.17L4.41 5.59L3 7L8 12L13 7Z"
                                    fill="#0068A3"
                                  />
                                </svg>
                                Download
                              </td>

                              <td className="download-img" 
                              // onClick={handleDownloadButton}
                              onClick={() => {
                                handleDownloadButton(specifications?.['halal_declaration'].split('||').shift());
                              }}
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M14 11V14H2V11H0V14C0 15.1 0.9 16 2 16H14C15.1 16 16 15.1 16 14V11H14ZM13 7L11.59 5.59L9 8.17V0H7V8.17L4.41 5.59L3 7L8 12L13 7Z"
                                    fill="#0068A3"
                                  />
                                </svg>
                              </td>
                            </tr>
                          )}
                          {specifications?.['gluten_declaration'] && (
                            <tr>
                              <td className="doc-name">
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 21"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M18 0.162888H6C4.9 0.162888 4 1.0701 4 2.17891V14.275C4 15.3838 4.9 16.2911 6 16.2911H18C19.1 16.2911 20 15.3838 20 14.275V2.17891C20 1.0701 19.1 0.162888 18 0.162888ZM18 14.275H6V2.17891H18V14.275ZM2 4.19493H0V18.3071C0 19.4159 0.9 20.3231 2 20.3231H16V18.3071H2V4.19493ZM14 10.243V7.21896C14 6.66456 13.55 6.21095 13 6.21095H11V11.251H13C13.55 11.251 14 10.7974 14 10.243ZM12 7.21896H13V10.243H12V7.21896ZM16 9.23498H17V8.22697H16V7.21896H17V6.21095H15V11.251H16V9.23498ZM8 9.23498H9C9.55 9.23498 10 8.78138 10 8.22697V7.21896C10 6.66456 9.55 6.21095 9 6.21095H7V11.251H8V9.23498ZM8 7.21896H9V8.22697H8V7.21896Z"
                                    fill="#676767"
                                  />
                                </svg>
                                Gluten_declaration
                              </td>

                              <td className="file-size">{specifications?.['gluten_declaration'].split('||').pop()}</td>

                              <td
                                className="actions-download"
                                onClick={() => {
                                  handleDownloadButton(specifications?.['gluten_declaration'].split('||').shift());
                                }}
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M14 11V14H2V11H0V14C0 15.1 0.9 16 2 16H14C15.1 16 16 15.1 16 14V11H14ZM13 7L11.59 5.59L9 8.17V0H7V8.17L4.41 5.59L3 7L8 12L13 7Z"
                                    fill="#0068A3"
                                  />
                                </svg>
                                Download
                              </td>

                              <td className="download-img" 
                              // onClick={handleDownloadButton}
                              onClick={() => {
                                handleDownloadButton(specifications?.['gluten_declaration'].split('||').shift());
                              }}
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M14 11V14H2V11H0V14C0 15.1 0.9 16 2 16H14C15.1 16 16 15.1 16 14V11H14ZM13 7L11.59 5.59L9 8.17V0H7V8.17L4.41 5.59L3 7L8 12L13 7Z"
                                    fill="#0068A3"
                                  />
                                </svg>
                              </td>
                            </tr>
                          )}
                          {specifications?.['MSDS'] && (
                            <tr>
                              <td className="doc-name">
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 21"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M18 0.162888H6C4.9 0.162888 4 1.0701 4 2.17891V14.275C4 15.3838 4.9 16.2911 6 16.2911H18C19.1 16.2911 20 15.3838 20 14.275V2.17891C20 1.0701 19.1 0.162888 18 0.162888ZM18 14.275H6V2.17891H18V14.275ZM2 4.19493H0V18.3071C0 19.4159 0.9 20.3231 2 20.3231H16V18.3071H2V4.19493ZM14 10.243V7.21896C14 6.66456 13.55 6.21095 13 6.21095H11V11.251H13C13.55 11.251 14 10.7974 14 10.243ZM12 7.21896H13V10.243H12V7.21896ZM16 9.23498H17V8.22697H16V7.21896H17V6.21095H15V11.251H16V9.23498ZM8 9.23498H9C9.55 9.23498 10 8.78138 10 8.22697V7.21896C10 6.66456 9.55 6.21095 9 6.21095H7V11.251H8V9.23498ZM8 7.21896H9V8.22697H8V7.21896Z"
                                    fill="#676767"
                                  />
                                </svg>
                                MSDS
                              </td>

                              <td className="file-size">{specifications?.['MSDS'].split('||').pop()}</td>

                              <td
                                className="actions-download"
                                onClick={() => {
                                  handleDownloadButton(specifications?.['MSDS'].split('||').shift());
                                }}
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M14 11V14H2V11H0V14C0 15.1 0.9 16 2 16H14C15.1 16 16 15.1 16 14V11H14ZM13 7L11.59 5.59L9 8.17V0H7V8.17L4.41 5.59L3 7L8 12L13 7Z"
                                    fill="#0068A3"
                                  />
                                </svg>
                                Download
                              </td>

                              <td className="download-img" 
                              // onClick={handleDownloadButton}
                              onClick={() => {
                                handleDownloadButton(specifications?.['MSDS'].split('||').shift());
                              }}
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M14 11V14H2V11H0V14C0 15.1 0.9 16 2 16H14C15.1 16 16 15.1 16 14V11H14ZM13 7L11.59 5.59L9 8.17V0H7V8.17L4.41 5.59L3 7L8 12L13 7Z"
                                    fill="#0068A3"
                                  />
                                </svg>
                              </td>
                            </tr>
                          )}
                          {specifications?.['TSE-BSE'] && (
                            <tr>
                              <td className="doc-name">
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 21"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M18 0.162888H6C4.9 0.162888 4 1.0701 4 2.17891V14.275C4 15.3838 4.9 16.2911 6 16.2911H18C19.1 16.2911 20 15.3838 20 14.275V2.17891C20 1.0701 19.1 0.162888 18 0.162888ZM18 14.275H6V2.17891H18V14.275ZM2 4.19493H0V18.3071C0 19.4159 0.9 20.3231 2 20.3231H16V18.3071H2V4.19493ZM14 10.243V7.21896C14 6.66456 13.55 6.21095 13 6.21095H11V11.251H13C13.55 11.251 14 10.7974 14 10.243ZM12 7.21896H13V10.243H12V7.21896ZM16 9.23498H17V8.22697H16V7.21896H17V6.21095H15V11.251H16V9.23498ZM8 9.23498H9C9.55 9.23498 10 8.78138 10 8.22697V7.21896C10 6.66456 9.55 6.21095 9 6.21095H7V11.251H8V9.23498ZM8 7.21896H9V8.22697H8V7.21896Z"
                                    fill="#676767"
                                  />
                                </svg>
                                TSE-BSE
                              </td>

                              <td className="file-size">{specifications?.['TSE-BSE'].split('||').pop()}</td>

                              <td
                                className="actions-download"
                                onClick={() => {
                                  handleDownloadButton(specifications?.['TSE-BSE'].split('||').shift());
                                }}
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M14 11V14H2V11H0V14C0 15.1 0.9 16 2 16H14C15.1 16 16 15.1 16 14V11H14ZM13 7L11.59 5.59L9 8.17V0H7V8.17L4.41 5.59L3 7L8 12L13 7Z"
                                    fill="#0068A3"
                                  />
                                </svg>
                                Download
                              </td>

                              <td className="download-img" 
                              // onClick={handleDownloadButton}
                              onClick={() => {
                                handleDownloadButton(specifications?.['TSE-BSE'].split('||').shift());
                              }}
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M14 11V14H2V11H0V14C0 15.1 0.9 16 2 16H14C15.1 16 16 15.1 16 14V11H14ZM13 7L11.59 5.59L9 8.17V0H7V8.17L4.41 5.59L3 7L8 12L13 7Z"
                                    fill="#0068A3"
                                  />
                                </svg>
                              </td>
                            </tr>
                          )}
                          {specifications?.['WHO-GMP'] && (
                            <tr>
                              <td className="doc-name">
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 21"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M18 0.162888H6C4.9 0.162888 4 1.0701 4 2.17891V14.275C4 15.3838 4.9 16.2911 6 16.2911H18C19.1 16.2911 20 15.3838 20 14.275V2.17891C20 1.0701 19.1 0.162888 18 0.162888ZM18 14.275H6V2.17891H18V14.275ZM2 4.19493H0V18.3071C0 19.4159 0.9 20.3231 2 20.3231H16V18.3071H2V4.19493ZM14 10.243V7.21896C14 6.66456 13.55 6.21095 13 6.21095H11V11.251H13C13.55 11.251 14 10.7974 14 10.243ZM12 7.21896H13V10.243H12V7.21896ZM16 9.23498H17V8.22697H16V7.21896H17V6.21095H15V11.251H16V9.23498ZM8 9.23498H9C9.55 9.23498 10 8.78138 10 8.22697V7.21896C10 6.66456 9.55 6.21095 9 6.21095H7V11.251H8V9.23498ZM8 7.21896H9V8.22697H8V7.21896Z"
                                    fill="#676767"
                                  />
                                </svg>
                                WHO GMP
                              </td>

                              <td className="file-size">{specifications?.['WHO-GMP'].split('||').pop()}</td>

                              <td
                                className="actions-download"
                                onClick={() => {
                                  handleDownloadButton(specifications?.['WHO-GMP'].split('||').shift());
                                }}
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M14 11V14H2V11H0V14C0 15.1 0.9 16 2 16H14C15.1 16 16 15.1 16 14V11H14ZM13 7L11.59 5.59L9 8.17V0H7V8.17L4.41 5.59L3 7L8 12L13 7Z"
                                    fill="#0068A3"
                                  />
                                </svg>
                                Download
                              </td>

                              <td className="download-img" 
                              // onClick={handleDownloadButton}
                              onClick={() => {
                                handleDownloadButton(specifications?.['WHO-GMP'].split('||').shift());
                              }}
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M14 11V14H2V11H0V14C0 15.1 0.9 16 2 16H14C15.1 16 16 15.1 16 14V11H14ZM13 7L11.59 5.59L9 8.17V0H7V8.17L4.41 5.59L3 7L8 12L13 7Z"
                                    fill="#0068A3"
                                  />
                                </svg>
                              </td>
                            </tr>
                          )}
                         </tbody>
                      </table>
                    </div>
                   
                  </div>
            </div>
    )
}