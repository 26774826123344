import React, { useEffect, useState } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import Markdown from 'components/commercetools-ui/content/markdown';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { callingCodes } from 'helpers/constants/callingCodes';
import { STATIC_GET_CONTACT_QUERY_TYPE, STATIC_SEND_QUERY } from 'helpers/constants/statisURLs';
import { useFormat } from 'helpers/hooks/useFormat';
import { backendAPIClient } from 'helpers/utils/axiosRequestsUtil';
import toast from 'react-hot-toast';
import { useAccount } from 'frontastic/provider';
import CustomerCarePopup from './customerCarePopup';

const ContentContactUs = ({ data }) => {
  const contactUsAttributes = data.data?.dataSource?.attributes;
  const [contactData, setContactData] = useState({
    roleType: '',
    firstName: '',
    lastName: '',
    workEmail: '',
    countryCode: '91',
    phoneNumber: '',
    queryType: '',
    description: '',
  });
  const [contactQueryType, setContactQueryType] = useState({});
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const [validEmail, setValidEmail] = useState('');
  const [validPhoneNumber, setValidPhoneNumber] = useState('');
  const [success, setSuccess] = useState(false);
  const [customerCarePopup, setCustomerCarePopup] = useState(false);
  const [countactUsPhoneNumber, setCountactUsPhoneNumber] = useState('');
  const { loggedIn } = useAccount();
  const router = useRouter();

  const validatePhoneNumber = (phoneNumber) => {
    /*
    Phone number validation using google-libphonenumber
    */
    let valid = false;
    try {
      const phoneUtil = PhoneNumberUtil.getInstance();
      valid = phoneUtil.isValidNumber(phoneUtil.parse('+' + contactData.countryCode + phoneNumber));
    } catch (e) {
      valid = false;
    }
    if (valid) {
      setValidPhoneNumber(null);
    } else {
      setValidPhoneNumber('Must enter valid number');
    }
  };
  useEffect(() => {
    getContactQueryType();
  }, []);

  const getContactQueryType = async () => {
    backendAPIClient
      .get(STATIC_GET_CONTACT_QUERY_TYPE)
      .then(async (res) => {
        if (res && (res.status === 201 || res.status === 200)) {
          setContactQueryType(res.data);
        } else {
          toast.error('Something went wrong. Please try again.');
        }
      })
      .catch((err) => {
        toast.error('Something went wrong. Please try again.');
      });
  };

  const validate = () => {
    if (!contactData.roleType) toast.error('Please choose Role Type!');
    else if (!contactData.firstName) toast.error('Please enter First Name!');
    else if (!contactData.lastName) toast.error('Please enter Last Name!');
    else if (!contactData.workEmail) toast.error('Please enter Work Email!');
    else if (validEmail) toast.error('Please enter valid Email!'); //Email will be null if valid
    else if (!contactData.phoneNumber) toast.error('Please enter Phone Number!');
    else if (validPhoneNumber) toast.error('Please enter valid Phone Number!'); //Phone No will be null if valid
    else if (!contactData.description) toast.error('Please enter Description!');
    else return true;
    return false;
  };

  const handleSubmitClick = e => {
    if (!loggedIn) {
      setCustomerCarePopup(false); router.push('/register')
    } else {
      // alert(e)
    }
    setCustomerCarePopup(false);
  }

  const onPhoneClick = (e, phone) => {
    e.preventDefault();
    setCountactUsPhoneNumber(phone)
    setCustomerCarePopup(true);
  }

  const renderTimeSection = () => {
    const { description } = contactUsAttributes?.component?.content[1]?.data?.target?.fields || {};
    const descArr = description?.split('\n\n') || '';
    const email = descArr[1]?.split('Email:')[1] || '';
    const phone = descArr[2]?.split('Phone:')[1] || '';
    return (<>
      <Markdown
        text={descArr[0] || 'Text not found'}
      ></Markdown>
      <p className='mb-5'>Email: <Markdown isMarkdownClassApply={false} className='inline-flex text-primary-theme-color underline' text={email}></Markdown></p>
      <p onClick={(e) => onPhoneClick(e, phone)}>Support: <Markdown isMarkdownClassApply={false} className='inline-flex text-primary-theme-color underline cursor-pointer' text={'Customer Care'}></Markdown></p>
    </>)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const fName = e.target.name;
    const fValue = e.target.value;
    if (fName === 'phoneNumber') {
      validatePhoneNumber(fValue);
    } else if (fName === 'workEmail') {
      emailValidation(fValue);
    }
    setContactData({ ...contactData, [fName]: fValue });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validate()) return;
    const payload = { ...contactData };
    payload.phoneNumber = data.countryCode + data.phoneNumber;

    try {
      const { data } = await backendAPIClient.post(STATIC_SEND_QUERY, payload);
      if (data?.statusCode >= 200 && data?.statusCode <= 203) {
        setSuccess(true);
        window.scrollTo(0, 0);
      } else toast.error('Something went wrong!');
    } catch (err) {
      console.log(err?.message);
    }
  };

  const emailValidation = (email: string): void => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const valid = regex.test(email);
    if (valid) {
      setValidEmail(null);
    } else {
      setValidEmail('Enter valid email');
    }
  };
  const { account } = useAccount();
  const requestForQuoteUrl = () => {
    if (!account) {
      router.push({ pathname: '/login', query: router.asPath });
    } else {
      router.push('quotes/request-for-quote');
    }
  };
  const sideBarSection = (
    <span className="plpLeftFiltersWrap sidebar col-span-3 hidden px-[40px] lg:block">
      {/* <div className="contactUsLeftHeading text-center">SELECT YOUR QUERY TYPE</div> */}
      {/* <hr className="Hr" />
      <ul>
        <li>
          <a href="#" className="contactUsLeftLink">
            Order Related Queries
          </a>
        </li>
        <li>
          <a href="#" className="contactUsLeftLink">
            Non-Order Related Queries
          </a>
        </li>
        <li>
          <a href="#" className="contactUsLeftLink">
            Recent Issues
          </a>
        </li>
      </ul>
      <hr /> */}
      <div className="contactUsLeftFAQLink">
        <NextLink href="/faq">Frequently Asked Question</NextLink>
      </div>

      <div className="contactUsAdditionalInfo">
        <p className="contactUsAdditonalTitle">
          {contactUsAttributes?.component?.content[0]?.data?.target?.fields?.title || 'Text not found'}
        </p>
        <p className="contactUsAdditionalPara">
          {contactUsAttributes?.component?.content[0]?.data?.target?.fields?.description || 'Text not found'}
        </p>
        <p className="contactUsAdditonalTitle">
          {contactUsAttributes?.component?.content[1]?.data?.target?.fields?.title || 'Text not found'}
        </p>
        <p className="contactUsAdditionalPara">
          {' '}
          {renderTimeSection()}
          {/* <Markdown
            text={contactUsAttributes?.component?.content[1]?.data?.target?.fields?.description || 'Text not found'}
          ></Markdown> */}
        </p>
      </div>
    </span>
  );

  const formSection = (
    <div className="col-span-6 px-5 pb-10">
      {customerCarePopup && <CustomerCarePopup isAnonymousUser={!loggedIn} phoneNumber={countactUsPhoneNumber} onClose={() => setCustomerCarePopup(false)} onSubmit={handleSubmitClick} />}
      <div className="card-breadcrumbs">
        <a href="/" style={{ color: '#0280C7' }}>
          Home Page
        </a>
        <a>/</a>
        <a>Contact Us</a>
      </div>
      <h1 className="contactUsHeading">Write to us</h1>
      <p className="contactUsContent">Please complete the information below to ensure we can reach out to you.</p>

      <form onSubmit={handleSubmit}>
        <div className="sidesp container m-auto grid grid-cols-4 gap-4">
          {/* Are you a */}
          <div className="tile col-span-full">
            <label htmlFor="buyerseller" className="flex text-sm font-medium text-neutral-700">
              Are you a<span className="d-block pl-[5px] text-[21px] text-[#dc2626]">*</span>
            </label>
            <div className="relative">
              <div className="focus:border-accent-400 focus:ring-accent-400 flex w-full appearance-none py-2 placeholder:text-gray-400 focus:outline-none sm:text-sm">
                <div className="form-check">
                  <input
                    className="form-check-input radioBtn"
                    type="radio"
                    name="roleType"
                    id="areYouBuyer"
                    value="Buyer"
                    onChange={handleChange}
                    checked={contactData.roleType === 'Buyer'}
                  />
                  <label className="form-check-label pl-[16px] pr-[50px]" htmlFor="areYouBuyer">
                    Buyer
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input radioBtn "
                    type="radio"
                    name="roleType"
                    id="areYouSeller"
                    value="Seller"
                    checked={contactData.roleType === 'Seller'}
                    onChange={handleChange}
                  />
                  <label className="form-check-label pl-[16px] pr-[50px]" htmlFor="areYouSeller">
                    Seller
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input radioBtn"
                    type="radio"
                    name="roleType"
                    id="areYouBoth"
                    value="Both"
                    checked={contactData.roleType === 'Both'}
                    onChange={handleChange}
                  />
                  <label className="form-check-label pl-[16px]" htmlFor="areYouBoth">
                    Both
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="tile col-span-full">
            <label htmlFor="lastName" className="flex text-sm font-medium text-neutral-700">
              {formatAccountMessage({ id: 'lastName', defaultMessage: 'Queries' })}
            </label>
            <div className="focus:border-accent-400 focus:ring-accent-400 relative mt-1 flex border border-gray-300 focus:outline-none">
              <select
                name="queryType"
                onChange={handleChange}
                className="w-[100%] appearance-none border-0 bg-transparent pr-0 focus:outline-none focus:ring-0"
              >
                {contactQueryType &&
                  Object.keys(contactQueryType).map((key, i) => {
                    return (
                      <option key={i} value={contactQueryType[key]}>
                        {contactQueryType[key]}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          {/* Firstname */}
          <div className="tile col-span-2">
            <label htmlFor="firstName" className="flex text-sm font-medium text-neutral-700">
              {formatMessage({ id: 'firstName', defaultMessage: 'FirstName' })}
              <span className="d-block pl-[5px] text-[21px] text-[#dc2626]">*</span>
            </label>
            <div className="mt-1">
              <input
                name="firstName"
                type="text"
                autoComplete="firstName"
                placeholder={formatAccountMessage({ id: 'firstName.enter', defaultMessage: 'First Name' })}
                required
                className="firstNameImg focus:border-accent-400 focus:ring-accent-400 block w-full appearance-none rounded-sm border border-gray-300 py-2 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm"
                onChange={handleChange}
              />
            </div>
          </div>
          {/* Lastname */}
          <div className="tile col-span-2">
            <label htmlFor="lastName" className="flex text-sm font-medium text-neutral-700">
              {formatAccountMessage({ id: 'lastName', defaultMessage: 'Last Name' })}
              <span className="d-block pl-[5px] text-[21px] text-[#dc2626]">*</span>
            </label>
            <div className="relative mt-1">
              <input
                name="lastName"
                type="text"
                autoComplete="lastName"
                placeholder={formatAccountMessage({ id: 'lastName.enter', defaultMessage: 'Last Name' })}
                required
                className="firstNameImg focus:border-accent-400 focus:ring-accent-400 block w-full appearance-none rounded-sm border border-gray-300 py-2 px-3 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm"
                onChange={handleChange}
              />
            </div>
          </div>
          {/* Email */}
          <div className="tile col-span-full">
            <label htmlFor="email" className="flex text-sm font-medium text-neutral-700">
              {formatAccountMessage({ id: 'email', defaultMessage: 'Email Address' })}
              <span className="d-block pl-[5px] text-[21px] text-[#dc2626]">*</span>
            </label>
            <div className="relative mt-1">
              <input
                // id="email"
                name="workEmail"
                type="email"
                autoComplete="email"
                placeholder={formatAccountMessage({ id: 'email', defaultMessage: 'Enter Email' })}
                required
                className="emailImg focus:border-accent-400 focus:ring-accent-400 block w-full appearance-none rounded-sm border border-gray-300 py-2 px-3 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm"
                onChange={handleChange}
              />
            </div>
            {validEmail && <p className="errorMessage">{validEmail}</p>}
          </div>
          {/* country Code */}
          <div className="tile col-span-1">
            <label htmlFor="phoneNumber" className="flex text-sm font-medium text-neutral-700">
              {formatAccountMessage({ id: 'phoneNumber', defaultMessage: 'Country Code' })}
              <span className="d-block pl-[5px] text-[21px] text-[#dc2626]">*</span>
            </label>
            <div className="focus:border-accent-400 focus:ring-accent-400 relative mt-1 flex items-center rounded-sm border border-gray-300 focus:outline-none">
              <select
                name="countryCode"
                onChange={handleChange}
                className="sizep w-12 appearance-none border-0 bg-transparent pr-0 focus:outline-none focus:ring-0"
              >
                {callingCodes.map((item, index) => (
                  <option value={item.value} key={index}>
                    {item.code}
                  </option>
                ))}
                {/* <option value="+44">UK</option>
                <option value="+92">PK</option>
                <option value="+86">CHN</option>
                <option value="+91" selected>
                  IND
                </option>
                <option value="+61">AUS</option>
                <option value="+33">FR</option>
                <option value="+49">DE</option>
                <option value="+81">JPN</option> */}
              </select>
              <span className="text-xs">+{contactData.countryCode}</span>
            </div>
          </div>
          {/* Mobile Number */}
          <div className="tile col-span-3">
            <label htmlFor="lastName" className="mobh flex text-sm font-medium text-neutral-700">
              {formatAccountMessage({ id: 'lastName', defaultMessage: 'Mobile Number' })}
            </label>
            <div className="relative mt-1">
              <input
                name="phoneNumber"
                type="text"
                autoComplete="phoneNumber"
                placeholder={formatAccountMessage({
                  id: 'lastName.enter',
                  defaultMessage: 'Enter your 10 digit mobile number',
                })}
                required
                className="phoneImg focus:border-accent-400 focus:ring-accent-400 block w-full appearance-none rounded-sm border border-gray-300 py-2 px-3 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm"
                onChange={handleChange}
              />
            </div>
            {validPhoneNumber && <p className="errorMessage">{validPhoneNumber}</p>}
          </div>
          {/* Queries */}

          {/* Description */}
          <div className="tile col-span-full">
            <label htmlFor="lastName" className="flex text-sm font-medium text-neutral-700">
              {formatAccountMessage({ id: 'lastName', defaultMessage: 'Description' })}
              <span className="d-block pl-[5px] text-[21px] text-[#dc2626]">*</span>
            </label>
            <div className="relative mt-1">
              <textarea
                required
                name="description"
                id="Description"
                placeholder="Enter a Description"
                autoComplete="given-Description"
                className="review_description_box quote_description_box mt-[6px]  text-[12px]"
                onChange={handleChange}
              />
            </div>
          </div>
          {/* Captcha */}

          <div className="tile col-span-full flex justify-between">
            <button
              className="btn btn-outline"
            // onClick={(e) => {
            //   e.preventDefault();
            //   router.push('/');
            // }}
            >
              Cancel
            </button>
            <button type="submit" className="btn">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );

  const advSection = (
    <div className="col-span-3 hidden justify-self-end lg:block mt-20">
      {localStorage.getItem('punchoutUrl') && <div className="contactUsProdEnquiry">
        <div className="contactUsProdTitle">Are you looking for a specific product?</div>
        <button className="contactUsProdButton" onClick={requestForQuoteUrl}>
          Product Enquiry
        </button>
      </div>}
      <div className="contactUsProdEnquiry">
        <div className="contactUsProdTitle">Allverz Chemical Platform </div>
        {!loggedIn && (
          <button
            className="btn mt-[10px]"
            onClick={(e) => {
              e.preventDefault();
              router.push('/login');
            }}
          >
            Login/Sign-up
          </button>
        )}
        <p className="contactUsProdContent">for better experience!</p>
        <img className="px-2" src="/images/allverz_home.png" />
      </div>
    </div>
  );

  const successSection = (
    <div className="col-span-9 ">
      <div className="card-breadcrumbs pl-[0px]">
        <a href="/" style={{ color: '#0280C7' }}>
          Home Page
        </a>
        <a>/</a>
        <a>Contact Us</a>
      </div>
      <h1 className="contactUsHeading">Write to us</h1>
      <div className="success flex h-[500px] flex-col items-center ">
        <div className="verified_tick contactUsSuccessTick">
          <svg width="80" height="80" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="check_circle_outline">
              <path
                id="Vector"
                d="M45 7.5C24.3 7.5 7.5 24.3 7.5 45C7.5 65.7 24.3 82.5 45 82.5C65.7 82.5 82.5 65.7 82.5 45C82.5 24.3 65.7 7.5 45 7.5ZM45 75C28.4625 75 15 61.5375 15 45C15 28.4625 28.4625 15 45 15C61.5375 15 75 28.4625 75 45C75 61.5375 61.5375 75 45 75ZM62.2125 28.425L37.5 53.1375L27.7875 43.4625L22.5 48.75L37.5 63.75L67.5 33.75L62.2125 28.425Z"
                fill="#30AB30"
              />
            </g>
          </svg>
        </div>
        <h1 className="contactUsSuccessHeading">Successfully Sent!</h1>
        <p className="contactUsSuccessContent">Your concern is successfully shared with us. </p>
        <button
          className="contactUsSuccessBtn btn"
          onClick={() => {
            router.push('/');
          }}
        >
          Continue Browsing
        </button>
      </div>
    </div>
  );

  return (
    <>
      <div className="mt-[160px]">
        <div className="md:grid lg:grid-cols-12 2xl:grid-cols-12 2xl:gap-[20px]">
          {sideBarSection}
          {success ? (
            <>{successSection}</>
          ) : (
            <>
              {formSection}
              {advSection}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ContentContactUs;
