import OrderSummaryCheckout from 'components/commercetools-ui/adyen-checkout/order-summary';
import Payment from 'components/commercetools-ui/payment';
import Stepper from 'components/commercetools-ui/stepper';
import { useCart } from 'frontastic';
import Link from 'next/link';
import React from 'react';

function PaymentTastic() {
  const { data: cartList } = useCart();
  const stepperData = [{ label: 'Product Summary' }, { label: 'Checkout' }, { label: 'Payment' }];

  return (
    <div className="payment-wrapper">
      <div className="cartWrapper">
        <h1 className="cart-breadcrumb">
          <Link href="/">
            <a className="pdpBreadcrumbItem text-[#0280C7] hover:text-gray-500">
              <span className="mr-3 cursor-pointer font-medium capitalize hover:text-gray-600">Home Page</span>
            </a>
          </Link>
          /{' '}
          <span className="pdpBreadcrumbItem" style={{ marginLeft: '6px' }}>
            Payment
          </span>
        </h1>
        <div className="flex w-full justify-center">
          <Stepper
            stepperData={stepperData}
            isHorizontal={true}
            activeStep={2}
            showCurrentStepOnly={false}
            isClickable={false}
          />
        </div>
      </div>
      <div className="flex items-center justify-between">
        <span>
          <h1 className="payment-title">Payment</h1>
          <p className="payment-subtitle">If you need to make changes to the order, please return to the cart</p>
        </span>
        <Link href="/cart">
          <button className="payment-cart-btn hidden lg:block">Back to Cart</button>
        </Link>
      </div>
      <div className="flex flex-col gap-7 md:flex-row mt-[26px]">
        <div className="flex-1">
          <Payment />
        </div>
        <div className="flex-1">
          <OrderSummaryCheckout cart={cartList} />
        </div>
      </div>
    </div>
  );
}

export default PaymentTastic;
