// components/search-hits.js
import { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import router, { useRouter } from 'next/router';
import { connectStateResults } from 'react-instantsearch-dom';
import { object } from 'yup';
import { DR_VENDOR_NAME } from 'helpers/constants/constant';
import { useAccount } from 'frontastic';
function useOutsideAlerter(ref, setHideSearchDropdown) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setHideSearchDropdown(true);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}
function SearchHits({
  searchState,
  searchResults,
  setSearchOutput,
  setSearchQuery,
  setRecentSearchIndex,
  hideSearchDropdown,
  setHideSearchDropdown,
}) {
  const { asPath } = useRouter();
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setHideSearchDropdown);
  // checking if the query length is >= 3
  // (since 3 is the minimum Algolia query length)
  const { isSSOLoggedIn} = useAccount();
  const [slicedHits, setSlicedHits] = useState([]);
  const [recentSearches, setRecentSearches] = useState([]);
  const [categoryMatches, setCategoryMatches] = useState([
    {
      name: '',
      slug: '',
    },
  ]);
  const getCategories = (searchResults, searchState) => {
    const allCategories = searchResults?.hits[0]?.productProjections.categories[0].obj.ancestors;
    const categoryNames = [];

    if (Object.keys(searchState).length > 0 && allCategories) {
      allCategories.forEach((element) => {
        // console.log(element.obj.slug["en-US"])
        categoryNames.push({
          name: element.obj.name['en-IN'].toLowerCase(),
          slug: element.obj.slug['en-IN'],
        });
      });
      categoryNames.splice(0, 1);
      setCategoryMatches(categoryNames);
    }
  };
  const getRecentSearches = () => {
    if (typeof window !== 'undefined') {
      const recentSearches = JSON.parse(localStorage.getItem('recentSearches') || '[]');
      setRecentSearches(recentSearches);
    }
  };

  const addRecentSearches = (query) => {
    if (typeof window !== 'undefined') {
      const recentSearches = JSON.parse(localStorage.getItem('recentSearches') || '[]');
      if (recentSearches.length > 5) {
        recentSearches.splice(-1);
      }

      recentSearches.unshift(query);
      localStorage.setItem('recentSearches', JSON.stringify(recentSearches));
    }
  };

  useEffect(() => {
    // console.log(categorySlugsMatches);
    getRecentSearches();
    getCategories(searchResults, searchState);
    if (searchResults) {
      setSlicedHits(searchResults?.hits.slice(0, 5));
    }
    setSearchOutput(searchResults);
    setSearchQuery(searchState.query);
  }, [searchResults, searchState, setRecentSearchIndex]);

  useEffect(() => {
    searchState.query = '';
  }, [asPath]);

  const handleRecentSearchClick = (query) => {
    setSearchQuery(query);
    addRecentSearches(query);
    setHideSearchDropdown(true);
    router.push(`/search?q=${query}${!isSSOLoggedIn ? `&facetFilters=productProjections.variants.filterableAttributes.vendor-name:${DR_VENDOR_NAME}` : ''}`);
  };

  const validQuery = searchState.query?.trim().length >= 3;
  return searchState.query && !hideSearchDropdown && validQuery ? (
    <div className="search_popup" ref={wrapperRef}>
      <div className="search-popup-left">
        <p className="search-heading">Product Suggestions</p>
        {searchResults?.hits.length === 0 && <div className="search-items-heading pt-2">No results found!</div>}
        {searchResults?.hits?.length > 0 &&
          slicedHits?.map((hit) => (
            <div key={hit?.objectID} className="search-items">
              {/* <Link href={`/${hit.productProjections.categories[0].obj.slug['en-IN']}`}> */}
              <span>
                <p
                  className="search-items-heading hover:cursor-pointer hover:underline"
                  onClick={() => handleRecentSearchClick(hit.productProjections.name['en-IN'])}
                >
                  {hit.productProjections.name['en-IN']}
                </p>
                { hit?.productProjections?.masterVariant?.attributes.find((attr) => attr.name === 'cas-no')?.value['en-IN'] &&
                  <p className="search-items-cas-no">
                    CAS No. : 
                    {
                      ` ${hit?.productProjections?.masterVariant?.attributes.find((attr) => attr.name === 'cas-no')?.value[
                        'en-IN'
                      ]}`
                    }
                  </p>
                }
              </span>
              {/* </Link> */}
            </div>
          ))}
        <p className="search-heading">Recently Searched</p>
        {recentSearches.length === 0 && <div className="search-items-heading pt-2">No Product searched recently!</div>}
        {recentSearches.length > 0 &&
          recentSearches.map((recentSearch, index) => (
            <div key={index} className="search-items-recent">
              <span onClick={() => handleRecentSearchClick(recentSearch)}>
                <p className="search-items-heading hover:cursor-pointer hover:underline">{recentSearch}</p>
              </span>
            </div>
          ))}
        {searchResults?.hits.length > 0 && <p className="search-heading">Category Suggestions</p>}

        {searchResults?.hits.length > 0 &&
          categoryMatches.map((item) => (
            <div key={item.name} className="search-items-category">
              <Link href={`/${item.slug}`}>
                <a className="search-items-heading capitalize hover:cursor-pointer hover:underline">{item.name}</a>
              </Link>
            </div>
          ))}
      </div>
      {/* <div className="search-popup-right"></div> */}
    </div>
  ) : null;
}
export default connectStateResults(SearchHits);
