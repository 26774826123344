import React, { useState } from 'react';

function UPI() {
  const [upiID, setupiID] = useState('');
  return (
    <form className="payment-cheque-wrapper">
      <div>
        <label htmlFor="">
          <span className="payment-cheque-label flex">
            Pay Directly from your Bank Account
            <span className="text-[#EB4747]">*</span>
          </span>
          <input
            placeholder="Enter your UPI ID"
            className="cheque-input block w-full appearance-none rounded border border-[#d9d9d9] focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
            type="number"
            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
            onChange={(e) => setupiID(e.target.value)}
          />
        </label>
      </div>
      <div className="payment-upi-subheading">
        <h1>We support the following UPI ID</h1>
        <div className="mt-2 flex items-center gap-1">
          <span className="upi-digits"></span>
          <span className="upi-digits"></span>
          <span className="upi-digits"></span>
          <span className="upi-digits"></span>
        </div>
      </div>
    </form>
  );
}

export default UPI;
