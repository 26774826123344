import React, { ChangeEvent, FC, MouseEvent, useRef, useState } from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
import toast from 'react-hot-toast';

export interface Props {
  /*
   * File
   */
  readonly file?: File;
  /*
   * input name, must be unique on page
   */
  readonly name: string;
  /*
   * the label text displayed to the user
   */
  readonly label: string;
  /*
   * File extenstions Accepted. Ex: '.png, .jpg, .jpeg' | '.xls, .xlsx, .csv'
   */
  readonly accept?: string;
  /*
   * Maximum file size allowed.
   */
  readonly maxFileSizeInMB?: number;
  /*
   * Flag to identified the file upload status.
   */
  readonly isFileUploaded?: boolean;
  /*
   * replaces the label's className field if provided
   */
  readonly labelClassNames?: string;
  /*
   * function called when file is changed
   */
  onFileChange?: (file: File) => void;
  /*
   * function called when file is upload
   */
  onUpload: (file: File) => void;
  /*
   * function called when file is removed
   */
  clearUpload: () => void;
  forQuote?: boolean;
}

const DocUpload: FC<Props> = ({
  file,
  name,
  label,
  isFileUploaded,
  accept = '.pdf',
  maxFileSizeInMB = 5,
  labelClassNames,
  onFileChange = () => {},
  onUpload,
  clearUpload = () => {},
  forQuote = false,
}: Props) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];
    if (!file) return e.preventDefault();
    const acceptedFileExt = accept.replaceAll(' ', '').split(',');
    if (!acceptedFileExt.includes('.' + file?.name?.split('.').pop())) {
      toast.error(
        formatErrorMessage({
          id: 'fileUpload.invalidFile',
          defaultMessage: 'Please upload the correct format of the file',
        }),
      );
      return e.preventDefault();
    } else if (file.size > maxFileSizeInMB * 1000000) {
      toast.error(
        formatErrorMessage({
          id: 'fileUpload.maxFileSize',
          defaultMessage: `The file size should not exceed ${maxFileSizeInMB}MB.`,
          values: { maxSize: '5' },
        }),
      );
      return e.preventDefault();
    } else {
      onFileChange(file);
    }
  };

  const handleUploadClick = () => {
    if (!file) return toast.error('Please attach the file');
    onUpload(file);
  };

  const onClearUpload = (e: MouseEvent) => {
    inputRef.current.value = '';
    e.preventDefault();
    clearUpload();
  };

  const labelElement = (
    <label
      htmlFor={name}
      className={labelClassNames ? labelClassNames : 'block text-sm font-medium text-gray-700 dark:text-light-100'}
    >
      {label}
    </label>
  );

  const inputElement = (
    <input
      className="file_upload_button  h-[44px]"
      id="fileUpload"
      type="file"
      ref={inputRef}
      onChange={handleFileChange}
      accept={accept}
      style={{ display: 'none' }}
    />
  );

  const mainClasses = forQuote ? 'upload_file_pdp ml-[-5px] !w-[65%]' : 'upload_file_pdp ml-[-5px] !w-[80%]';
  const buttonClasses = forQuote ? 'btn ml-2 !w-[35%]' : 'btn ml-2 !w-[20%]';

  const buttonElement = (
    <button className={buttonClasses} onClick={handleUploadClick} disabled={isFileUploaded}>
      {formatErrorMessage({
        id: isFileUploaded ? 'file.uploaded' : 'file.upload',
        defaultMessage: isFileUploaded ? 'Done' : 'Upload File',
      })}
    </button>
  );

  return (
    <>
      {labelElement}
      <div className="flex gap-1">
        <div className={mainClasses}>
          {inputElement}
          <label
            htmlFor="fileUpload"
            className="add-file-label border-1 !ml-0 !mt-[-7px] flex items-center !rounded-[6px] border-solid !border-[#ABABAB] !py-[0px] !pr-[0px] !pb-[1px] !pl-[2px] text-[12px] font-semibold !text-[#ABABAB]"
          >
            {file ? (
              <>
                <span className="py-[12px] px-[8px]">{file.name}</span>
              </>
            ) : (
              <>
                <span className="browse-file px-[8px] py-[10px]">
                  {formatMessage({ id: 'file.browse', defaultMessage: 'Browse' })}
                </span>
                <span className="ml-1">{formatMessage({ id: 'file.add', defaultMessage: 'Add File' })}</span>
              </>
            )}
          </label>
        </div>
        {buttonElement}
      </div>
      {file ? (
        <p className="lg:mt-[10px]">
          <span className="purchase-document">
            {file.name}
            <span className="pl-[10px] text-[#0280c7]" onClick={(e) => onClearUpload(e)}>
              X
            </span>
          </span>
        </p>
      ) : null}
    </>
  );
};

export default DocUpload;
