import PDPCategory from "../../pdp-category";

export default function ProductDetailsQuote({productDetails,variant,starRating,review}){
    
    return(
        <div className="lg:ml-[14px]">
            <p className="text-[#0280c7] text-[14px] font-medium mt-[14px] lg:mt-[0px]">{productDetails?.variants[0]?.attributes?.['cas-no']}</p>
           <p className="text-[#003769] text-[20px] lg:text-[24px] font-semibold">{productDetails?.name}</p>
           <PDPCategory productSKU={variant?.sku} productName={productDetails?.name} />
           <div className="flex mt-[12px]">
                        <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M6.9 14.1L12.6 8.39998L11.55 7.37498L6.975 11.95L4.45 9.42498L3.35 10.525L6.9 14.1ZM8 20.975C5.66667 20.3916 3.75 19.0375 2.25 16.9125C0.75 14.7875 0 12.4583 0 9.92498V3.97498L8 0.974976L16 3.97498V9.92498C16 12.4583 15.25 14.7875 13.75 16.9125C12.25 19.0375 10.3333 20.3916 8 20.975Z"
                            fill="#32C902"
                          />
                        </svg>
                        <p className="ml-1 mt-0.5 text-xs font-medium tracking-[1px] text-[#000000]">Verified</p>
                        <div className="product_rating flex -mt-[8px]">{review?.length > 0 ? starRating : ''}</div>
                      </div>
                     
                      {variant?.attributes?.['product-certificate'] && (
                    <div className="pdp_tags lg:mt-[15px] flex justify-start">
                      <p className="pdp_product_Tag ml-0 mr-1">{variant?.attributes?.['product-certificate']}</p>
                     
                    </div>
                    
                  )}
                  <p className="text-[#000000] text-[12px] font-normal mt-[14px] lg:mt-[18px] ">Seller:<span className="text-[#1E1E1E] text-[12px] font-bold pl-[3px] lg:text-[#0280c7] lg:underline">{productDetails?.variants[0]?.attributes?.['vendor-name']}</span></p>
        </div>
    )
}