import React, { useEffect, useState } from 'react';
import { backendAPIClient } from 'helpers/utils/axiosRequestsUtil';
import OtpInput from 'react-otp-input';
import { loginWithOTP } from 'frontastic/actions/account';
import { STATIC_VERIFY_OTP_URL } from 'helpers/constants/statisURLs';

function OTPVerfiy({ OTP, setOTP, userEmail, getOTPCode }) {
  const [loading, setLoading] = useState(false);
  const [showResendButton, setShowResendButton] = useState(false);
  const [counter, setCounter] = useState(60);
  const [errorMessage, setErrorMessage] = useState('');

  const handleOTPChange = (OTP) => {
    setOTP(OTP);
    setLoading(false);
    setErrorMessage('');
  };

  const verifyOTPCode = async (e) => {
    e.preventDefault();
    setLoading(true);
    const reqBody = {
      email: userEmail,
      otp: OTP,
      method: 'login',
    };
    try {
      const res = await backendAPIClient.post(STATIC_VERIFY_OTP_URL, reqBody);

      if (res?.data?.verified) {
        const isEmailVerified = res.data?.customer.isEmailVerified;
        loginWithOTP(res.data.customer, isEmailVerified);
      } else {
        setErrorMessage('Wrong OTP Code, try again!');
      }
      // if (res?.data?.verified) {
      //   loginWithOTP(res.data.customer);
      // } else {
      //   setErrorMessage('Wrong OTP Code, try again!');
      // }
    } catch (error) {
      setErrorMessage('Wrong OTP Code, try again!');
      console.log(error);
    }
  };

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    if (counter <= 0) {
      setShowResendButton(true);
    }
    return () => clearInterval(timer);
  }, [counter]);

  return (
    <form onSubmit={verifyOTPCode}>
      <div className="my-10 flex flex-col items-center justify-center rounded-lg ">
        <p className="otp-text">
          One Time Password<span className="ml-[3px] text-[#EB4747]">*</span>
        </p>
        <OtpInput
          value={OTP}
          numInputs={4}
          onChange={(OTP) => handleOTPChange(OTP)}
          containerStyle={'otp-inputs-container'}
          inputStyle={'otp-inputs'}
          isInputNum={true}
          shouldAutoFocus={true}
        />
        <div className="mt-2">{errorMessage && <span className="email_label text-[#EB4747]">{errorMessage}</span>}</div>
        {showResendButton ? (
          <button
            className="resend-otp-btn"
            onClick={(e) => {
              setCounter(60);
              setShowResendButton(false);
              getOTPCode(e);
            }}
          >
            Resend OTP
          </button>
        ) : (
          <p className="otp-text">
            Resend OTP in <b>00:{counter > 10 ? counter : counter.toString().padStart(2, '0')}</b>
          </p>
        )}
      </div>
      <button
        type="submit"
        // className="flex w-full justify-center rounded-sm border border-transparent bg-accent-400 py-2 px-4 text-sm font-medium text-white shadow-sm transition-colors duration-200 ease-out hover:bg-accent-500 focus:outline-none focus:ring-2 focus:ring-accent-400 focus:ring-offset-2 disabled:bg-gray-200"
        className="flex w-full justify-center rounded-sm border border-transparent bg-accent-400 py-2 px-4 text-[14px] font-medium tracking-[1px] text-white shadow-sm transition-colors duration-200 ease-out disabled:bg-gray-200"
        disabled={loading}
      >
        Sign-in
      </button>
    </form>
  );
}

export default OTPVerfiy;
