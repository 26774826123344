import React, { useCallback, useEffect, useState } from 'react';
import { EyeIcon, EyeOffIcon, LockClosedIcon } from '@heroicons/react/outline';
import Modal from 'components/commercetools-ui/products/product-list/modal';
import { STATIC_GET_OTP_URL, STATIC_VERIFY_OTP_URL } from 'helpers/constants/statisURLs';
import { useFormat } from 'helpers/hooks/useFormat';
import { backendAPIClient } from 'helpers/utils/axiosRequestsUtil';
import OtpInput from 'react-otp-input';
import { useAccount } from 'frontastic';
import ResetPasswordModal from '../../reset-password/reset-password-popup';

const ChangePassword = ({ showModal, setShowModal }) => {
  //account data
  const { account, changePassword } = useAccount();
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  //new passwords
  const initialData = { oldPassword: '', password: '', confirmPassword: '' };
  const [data, setData] = useState(initialData);

  //error
  const [error, setError] = useState('');

  //success
  const [success, setSuccess] = useState('');

  //loading..
  const [processing, setProcessing] = useState(false);

  //input change handler
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  //Show close modal
  const [loading, setLoading] = useState(false);
  const [userVerified, setUserVerified] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [OTP, setOTP] = useState('');
  const [counter, setCounter] = useState(60);
  const [showResendButton, setShowResendButton] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const togglePasswordVisibility = useCallback(() => setIsPasswordVisible(!isPasswordVisible), [isPasswordVisible]);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    if (counter <= 0) {
      setShowResendButton(true);
    }
    return () => clearInterval(timer);
  }, [counter]);

  //submission handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    //loading starts
    setProcessing(true);
    //try updating user's password
    try {
      const response = await changePassword(data.oldPassword, data.password);
      if (response.accountId) {
        setSuccess(
          formatAccountMessage({ id: 'password.change.success', defaultMessage: 'Password successfully changed!' }),
        );
        setShowResetPasswordModal(true);
        setShowModal(false);
        setError('');
      } else {
        setError(
          formatAccountMessage({
            id: 'password.change.exception',
            defaultMessage: "Sorry, we couldn't fulfill your request",
          }),
        );
        setSuccess('');
      }
    } catch (err) {
      setError(formatAccountMessage({ id: 'password.change.wrong', defaultMessage: 'Wrong password entered' }));
      setSuccess('');
    } finally {
      setProcessing(false);
      setData(initialData);
    }
  };

  //save button disabled
  const submitDisabled =
    !(data.password && data.confirmPassword && data.oldPassword) || data.password !== data.confirmPassword;
  const getOTPCode = async (e) => {
    e.preventDefault();
    setLoading(true);
    const reqBody = {
      email: account?.email,
      action: 'change-password',
    };

    try {
      await backendAPIClient.post(STATIC_GET_OTP_URL, reqBody);
      setLoading(false);
      //   setUserVerified(true);
    } catch (error) {
      if (error?.response?.data) {
        setErrorMessage(error?.response?.data?.Details);
      } else {
        setErrorMessage('There was a problem, please try again!');
      }
    }
  };
  const handleOTPChange = (OTP) => {
    setOTP(OTP);
    setLoading(false);
    setErrorMessage('');
  };
  const handleOTPModalShow = (e) => {
    setShowModal(true);
    getOTPCode(e);
  };
  const handleOTPModalClose = () => {
    setShowModal(false);
    setOTP('');
    setErrorMessage('');
    setLoading(false);
    setCounter(60);
    setUserVerified(false);
    setShowResendButton(false);
  };
  const verifyOTPCode = async (e) => {
    e.preventDefault();
    setLoading(true);
    const reqBody = {
      email: account?.email,
      action: 'change-password',
      otp: OTP,
    };
    try {
      const res = await backendAPIClient.post(STATIC_VERIFY_OTP_URL, reqBody);
      if (res?.data?.verified) {
        setUserVerified(true);
      } else {
        setErrorMessage('Wrong OTP Code, try again!');
      }
    } catch (error) {
      setErrorMessage('Wrong OTP Code, try again!');
      console.log(error);
    }
  };

  return (
    <>
      <span className="change-passwrd-wrap">
        <button
          className="account-change-pwd-btn"
          type="button"
          onClick={(e) => {
            handleOTPModalShow(e);
          }}
        >
          Change Password
        </button>
        {showResetPasswordModal && <ResetPasswordModal setOpenModal={setShowResetPasswordModal} />}

        {showModal && (
          <div className="customPassMain">
            {userVerified ? (
              <Modal setOpenModal={setShowModal}>
                <div className="emailIconPassword resetPassModal flex justify-start">
                  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="4" y="4" width="48" height="48" rx="24" fill="#F7FCF5" />
                    <path
                      d="M34 25H33V23C33 20.24 30.76 18 28 18C25.24 18 23 20.24 23 23V25H22C20.9 25 20 25.9 20 27V37C20 38.1 20.9 39 22 39H34C35.1 39 36 38.1 36 37V27C36 25.9 35.1 25 34 25ZM25 23C25 21.34 26.34 20 28 20C29.66 20 31 21.34 31 23V25H25V23ZM34 37H22V27H34V37ZM28 34C29.1 34 30 33.1 30 32C30 30.9 29.1 30 28 30C26.9 30 26 30.9 26 32C26 33.1 26.9 34 28 34Z"
                      fill="#0280C7"
                    />
                    <rect x="4" y="4" width="48" height="48" rx="24" stroke="#CCE6F4" strokeWidth="8" />
                  </svg>
                </div>
                <p className="otpPasswordTitle resetPasswordTitle">Change Password</p>
                {error}
                <div className="flex flex-col">
                  <div className="">
                    <label htmlFor="password" className="password_label">
                      {formatAccountMessage({ id: 'old-password', defaultMessage: 'Old Password' })}
                    </label>
                    <span className="ml-[3px] text-[#EB4747]">*</span>
                  </div>
                  <div className="password_login relative mt-0">
                    <input
                      aria-label="old password"
                      id="old-password"
                      name="oldPassword"
                      type={isPasswordVisible ? 'text' : 'password'}
                      autoComplete="current-password"
                      placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                      required
                      className=" block w-full appearance-none rounded-sm border border-gray-300 py-2 px-3 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                      onChange={handleChange}
                      value={data.oldPassword}
                    />
                    <span className="absolute left-3 top-1/2 block h-4 w-4 -translate-y-1/2 text-neutral-600">
                      <LockClosedIcon className="lock_closed_icon" />
                    </span>
                    <span
                      className="absolute right-3 top-1/2 block h-4 w-4 -translate-y-1/2 text-neutral-600"
                      onClick={togglePasswordVisibility}
                    >
                      {!isPasswordVisible ? <EyeOffIcon /> : <EyeIcon />}
                    </span>
                  </div>
                  <div className="">
                    <label htmlFor="password" className="password_label">
                      {formatAccountMessage({ id: 'new-password', defaultMessage: 'New Password' })}
                    </label>
                    <span className="ml-[3px] text-[#EB4747]">*</span>
                  </div>
                  <div className="password_login relative mt-0">
                    <input
                      aria-label="new password"
                      id="password"
                      name="password"
                      type={isPasswordVisible ? 'text' : 'password'}
                      autoComplete="current-password"
                      placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                      required
                      className=" block w-full appearance-none rounded-sm border border-gray-300 py-2 px-3 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                      onChange={handleChange}
                      value={data.password}
                    />
                    <span className="absolute left-3 top-1/2 block h-4 w-4 -translate-y-1/2 text-neutral-600">
                      <LockClosedIcon className="lock_closed_icon" />
                    </span>
                    <span
                      className="absolute right-3 top-1/2 block h-4 w-4 -translate-y-1/2 text-neutral-600"
                      onClick={togglePasswordVisibility}
                    >
                      {!isPasswordVisible ? <EyeOffIcon /> : <EyeIcon />}
                    </span>
                  </div>
                  <p className="newPasswordMessage">
                    Password must have a minimum of 8 characters and include one capital letter, a number and one
                    special character, e.g. @*!
                  </p>
                  <div className="">
                    <label htmlFor="password" className="password_label">
                      {formatAccountMessage({ id: 'confirm-new-password', defaultMessage: 'Confirm New Password' })}
                    </label>
                    <span className="ml-[3px] text-[#EB4747]">*</span>
                  </div>
                  <div className="password_login relative mt-0">
                    <input
                      aria-label="confirm new password"
                      id="confirm-password"
                      name="confirmPassword"
                      type={isPasswordVisible ? 'text' : 'password'}
                      autoComplete="current-password"
                      placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                      required
                      className="block w-full appearance-none rounded-sm border border-gray-300 py-2 px-3 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                      onChange={handleChange}
                      value={data.confirmPassword}
                    />
                    <span className="absolute left-3 top-1/2 block h-4 w-4 -translate-y-1/2 text-neutral-600">
                      <LockClosedIcon className="lock_closed_icon" />
                    </span>
                    <span
                      className="absolute right-3 top-1/2 block h-4 w-4 -translate-y-1/2 text-neutral-600"
                      onClick={togglePasswordVisibility}
                    >
                      {!isPasswordVisible ? <EyeOffIcon /> : <EyeIcon />}
                    </span>
                  </div>
                </div>
                <div className="otpCancelConfirmBtn">
                  <button className="otpCancelBtn" onClick={() => handleOTPModalClose()}>
                    Cancel
                  </button>
                  <button
                    className="otpConfirmBtn"
                    disabled={submitDisabled || processing}
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    Change
                  </button>
                </div>
              </Modal>
            ) : (
              <Modal setOpenModal={setShowModal}>
                <div className="emailIconPassword">
                  <svg width={56} height={56} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x={4} y={4} width={48} height={48} rx={24} fill="#F7FCF5" />
                    <path
                      d="M38 22C38 20.9 37.1 20 36 20H20C18.9 20 18 20.9 18 22M38 22V34C38 35.1 37.1 36 36 36H20C18.9 36 18 35.1 18 34V22M38 22L28 29L18 22"
                      stroke="#0280C7"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <rect x={4} y={4} width={48} height={48} rx={24} stroke="#CCE6F4" strokeWidth={8} />
                  </svg>
                </div>
                <p className="otpPasswordTitle">Please check your email.</p>
                <p className="otpPasswordPara">
                  We have sent a code to {account.email}. Please authenticate with the OTP to change the password.
                </p>
                <OtpInput
                  value={OTP}
                  numInputs={4}
                  onChange={(OTP) => handleOTPChange(OTP)}
                  containerStyle={'otp-inputs-container'}
                  inputStyle={'otp-inputs'}
                  isInputNum={true}
                  shouldAutoFocus={true}
                />
                <div className="my-1">
                  {errorMessage && <span className="otpPasswordChangeTimer !text-[#EB4747]">{errorMessage}</span>}
                </div>

                {showResendButton ? (
                  <p className="otpCodeResend">
                    Didn’t get a code?
                    <span
                      className="ml-1"
                      onClick={(e) => {
                        getOTPCode(e);
                      }}
                    >
                      Resend OTP
                    </span>
                  </p>
                ) : (
                  <p className="otpPasswordChangeTimer">
                    Resend OTP in
                    <b className="ml-1">00:{counter > 10 ? counter : counter.toString().padStart(2, '0')}</b>
                  </p>
                )}
                <div className="otpCancelConfirmBtn">
                  <button className="otpCancelBtn" onClick={() => handleOTPModalClose()}>
                    Cancel
                  </button>
                  <button className="otpConfirmBtn" disabled={loading} onClick={(e) => verifyOTPCode(e)}>
                    Confirm
                  </button>
                </div>
              </Modal>
            )}
          </div>
        )}
      </span>
    </>
  );
};

export default ChangePassword;
