import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Carousel from 'react-elastic-carousel';
import ReviewsAndRatings from './ReviewsAndRatings';
const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 2, itemsToScroll: 2  },
    { width: 1200, itemsToShow: 2 , itemsToScroll: 2 }
  ];
 
function ReviewCarousel({review}) {
   return (
         <div>
          <Carousel 
           isRTL={false}  
           breakPoints={breakPoints}
            showEmptySlots={false}
            itemPadding={[0, 40]} 
            easing={'ease'}
            transitionMs={500}
            showArrows={review?.length> 2 ? true : false}
           > 
            {review?.map((item) => (
               <ReviewsAndRatings productVariants={item} key={item.id} />
              ))}
        </Carousel>
    </div>
  );
}

export default ReviewCarousel;
