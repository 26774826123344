import React, { useEffect, useState } from 'react';

export default function CancelSuccess({ setOpenModal }) {
  const [closeSuccessModal, setCloseSuccessModal] = useState(false);
  useEffect(() => {
    const body = document.querySelector('body') as HTMLElement;
    const header = document.getElementsByClassName('wrapperMain')[0] as HTMLElement;
    if (body && document.querySelector('.blockBodyScroll')) {
      body.style.overflow = 'hidden';
      header.style.zIndex = '10';
    }
    return () => {
      if (body) {
        body.style.overflow = 'visible';
        header.style.zIndex = '5000';
      }
    };
  }, []);

  return (
    <>
      {/* {handleCallBack(closeSuccessModal)} */}
      <div className="blockBodyScroll fixed right-0 top-0 z-50 h-full w-full bg-gray-900 bg-opacity-75">
        <div className="bg-black fixed inset-0 h-full w-full opacity-100" onClick={() => setOpenModal(false)}></div>
        <div className="plpMobileWrap flex min-h-screen items-center px-4 py-8">
          <div className="plpMobileMain rating_modal_height email_verify_modal relative  mx-auto max-h-fit w-full max-w-fit max-w-lg rounded-md  bg-white shadow-lg lg:mt-[60px]">
            {/* <div className="relative w-full lg:min-w-[756px] max-w-lg mx-auto bg-white rounded-md shadow-lg h-[400px] lg:h-[644px] plpMobileMain"> */}
            <div className="plpMobileFilterTextWrap">
              <div className="plpMobileFilterText mx-auto lg:mt-[20px]">
                <p className="review_modal_closebtn absolute" onClick={() => setOpenModal(false)}>
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                      fill="#0068A3"
                    />
                  </svg>
                </p>
                <div className="verified_tick">
                  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="4" y="4" width="48" height="48" rx="24" fill="#E0F9E2" />
                    <path
                      d="M38 27.0799V27.9999C37.9988 30.1563 37.3005 32.2545 36.0093 33.9817C34.7182 35.7088 32.9033 36.9723 30.8354 37.5838C28.7674 38.1952 26.5573 38.1218 24.5345 37.3744C22.5117 36.6271 20.7847 35.246 19.611 33.4369C18.4373 31.6279 17.8798 29.4879 18.0217 27.3362C18.1636 25.1844 18.9972 23.1362 20.3983 21.4969C21.7994 19.8577 23.6928 18.7152 25.7962 18.24C27.8996 17.7648 30.1003 17.9822 32.07 18.8599M38 19.9999L28 30.0099L25 27.0099"
                      stroke="#30AB30"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <rect x="4" y="4" width="48" height="48" rx="24" stroke="#F7FCF5" strokeWidth="8" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="review_modal_content mx-[30px] lg:mx-[47px] lg:mt-[0px]">
              <p className="text-[22px] font-semibold tracking-[1px] text-[#303030] lg:text-[22px]">Order Cancel</p>
              {/* <p className="text-[14px] lg:text-[14px] text-[#1E1E1E]  font-normal mt-[27px] lg:mt-[27px]">Add your valuable feedback based on your experience with us</p>
                           <div className="flex text-[12px] text-[#1E1E1E]   font-normal mt-[15px]">Seller : <p className="font-bold">Cipla Pharmaceuticals</p></div> */}

              <div className="mt-[8px] flex">
                <p className="text-center text-[14px] font-medium tracking-[1px] text-[#ABABAB]">
                  your Order Cancelled Successfully
                </p>
              </div>
              <button
                className="review_adding_button h-[40px] lg:mt-[25px]"
                onClick={() => {
                  setOpenModal(false);
                  setCloseSuccessModal(false);

                  //   message = closeSuccessModal
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
