export const COUNTRY_CODE = { INR: 'INR', USD: 'USD' }
export const CURRENCY_SYMBOL =
  { INR: '₹', USD: '$' }
export const COUNTRIES = { INDIA: 'India' }
export const DEFAULT_PRODUCT_MAX_QTY = 100;
export const DR_VENDOR_KEY = "seller-700002";
export const DR_VENDOR_NAME = "Dr. Reddy's Laboratories Limited";
export const [HOME, PLP, PDP] = ['HOME', 'PLP', 'PDP'];
export const LOCAL_STORAGE_KEYS = {
  redirectTo: 'redirectTo',
  redirectRoute: 'redirectRoute'
};
export const LOADING_STATUS = {
  LOADING: 'Loading',
  SUCCESS: 'Success',
  FAILED: 'Failed',
  SUCCESS_WITH_NO_RESULT: 'No Records Found',
  SUCCESS_WITH_RESULT: 'Records Found'
}

export const STATUS = {
  REJECTED: 'Rejected',
  DECLINED: 'Declined',
  APPROVED: 'Approved',
  ACCEPTED: 'Accepted',
  CANCELLED: 'Cancelled'
}

export const SUCCESS_API_CODES = [200, 201]
