import React from 'react';
import Link from 'next/link';
import Markdown from 'components/commercetools-ui/content/markdown';
import Typography from 'components/commercetools-ui/typography';
import { Carousel } from 'react-responsive-carousel';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

const ContentRegistrationSlider = ({ data }) => {
  //   const title = data?.data??.dataSource??.fields??.title;
  //   const body = data?.data??.dataSource??.fields??.body;
  //   const loginBannerData = data?.data?.dataSource?.attributes;
  //   const bgImageDesktop = loginBannerData?.bannerBackgroundDesktop?.content?.url
  const loginSliderData = JSON.stringify(data.data?.dataSource, null, 2);
  const loginSliderContent = data.data?.dataSource?.attributes?.component.attributeId;
  const router = useRouter();
  const getHelp = (e) => {
    e.stopPropagation();
   router.push('/contact-us')
  }
  return (
    <>
      <div className="login_slider_content registration_slider_content sign_up lg:mt-[100px]  lg:ml-auto  ">
        {/* <pre className="mt-2 text-lg">{loginSliderContent}</pre> */}
        <Carousel
          interval={4000}
          infiniteLoop
          showStatus={false}
          stopOnHover={true}
          swipeable={true}
          showArrows={false}
          autoPlay={true}
        >
          {data.data?.dataSource?.attributes?.component?.content?.slice(0, 4).map((banner, index) => (
            <div className="registration_slider login_slider" key={index}>
              <img
                src={banner?.data?.target?.fields?.cardImage?.fields?.file?.url}
                alt="bannerImage"
                className="login_banner_images"
              />
              <div>
                <p className="login_slider_title signInPageTitleAEP">{banner?.data?.target?.fields?.cardTitle}</p>
                <p className="login_slider_description signInPageDescAEP"> {banner?.data?.target?.fields?.cardDescriptionBox}</p>
              </div>
              <div className="trouble_help">
                Have Trouble Logging in?<NextLink href="/contact-us"><button className="get_banner_help  cursor-pointer" onClick={getHelp}>Get Help</button></NextLink>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </>
  );
};

export default ContentRegistrationSlider;
