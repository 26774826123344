import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { UserIcon } from '@heroicons/react/outline';
import { Account } from '@Types/account/Account';
import { useFormat } from 'helpers/hooks/useFormat';
import { Reference, ReferenceLink } from 'helpers/reference';
import { logout } from 'frontastic/actions/account';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useAccount } from 'frontastic';
interface AccountButtonProps {
  accountLink: Reference;
  account: Account;
  showIcon: boolean;
}

const AccountButton: React.FC<AccountButtonProps> = ({ accountLink, account, showIcon }) => {
  const router = useRouter();
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();
  const { isSSOLoggedIn } = useAccount();
  const handleLogout = () => {
    logout();
    if (inProgress === InteractionStatus.None && isAuthenticated) {
      instance.logoutPopup();
      // logout();
    }
    router.push('/login');
  };
  const goTOAccount = () => {
    if (!account) {
      router.push({ pathname: '/login', query: router.asPath });
    } else {
      router.push('/');
    }
  };

  return (
    <div className="relative flex items-center ">
      {account ? (
        <Menu>
          <Menu.Button className="hidden lg:block">
            <svg
              className="HeaderAccIcon "
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.8375 20V21.1H21.0375V20H18.8375ZM15.9531 14.3333V13.2333V14.3333ZM7.98438 14.3333V13.2333V14.3333ZM4 18.1111H2.9H4ZM2.9 20C2.9 20.6075 3.39249 21.1 4 21.1C4.60751 21.1 5.1 20.6075 5.1 20H2.9ZM21.0375 20V18.1111H18.8375V20H21.0375ZM21.0375 18.1111C21.0375 17.5286 20.9662 16.8359 20.7786 16.1686C20.5949 15.515 20.2717 14.7872 19.6983 14.2434L18.1846 15.8399C18.3583 16.0047 18.5332 16.3106 18.6607 16.764C18.7844 17.2039 18.8375 17.6917 18.8375 18.1111H21.0375ZM19.6983 14.2434C19.1072 13.6831 18.3866 13.4458 17.7474 13.3373C17.1183 13.2306 16.463 13.2333 15.9531 13.2333V15.4333C16.5 15.4333 16.9652 15.436 17.3793 15.5063C17.7833 15.5749 18.0284 15.6918 18.1846 15.8399L19.6983 14.2434ZM15.9531 13.2333H7.98438V15.4333H15.9531V13.2333ZM7.98438 13.2333C7.47018 13.2333 6.76429 13.2312 6.08475 13.3343C5.4223 13.4347 4.6076 13.6581 3.99022 14.2434L5.50392 15.8399C5.63376 15.7168 5.90935 15.586 6.41456 15.5094C6.90269 15.4354 7.44184 15.4333 7.98438 15.4333V13.2333ZM3.99022 14.2434C3.36373 14.8374 3.11835 15.633 3.00865 16.2834C2.89741 16.9429 2.9 17.6258 2.9 18.1111H5.1C5.1 17.5945 5.10252 17.0969 5.17801 16.6493C5.25504 16.1926 5.3832 15.9544 5.50392 15.8399L3.99022 14.2434ZM2.9 18.1111V20H5.1V18.1111H2.9ZM14.8531 6.77778C14.8531 8.20222 13.6177 9.45556 11.9688 9.45556V11.6556C14.7209 11.6556 17.0531 9.52615 17.0531 6.77778H14.8531ZM11.9688 9.45556C10.3198 9.45556 9.08437 8.20222 9.08437 6.77778H6.88438C6.88438 9.52615 9.21665 11.6556 11.9688 11.6556V9.45556ZM9.08437 6.77778C9.08437 5.35333 10.3198 4.1 11.9688 4.1V1.9C9.21665 1.9 6.88438 4.0294 6.88438 6.77778H9.08437ZM11.9688 4.1C13.6177 4.1 14.8531 5.35333 14.8531 6.77778H17.0531C17.0531 4.0294 14.7209 1.9 11.9688 1.9V4.1Z"
                fill="#003769"
              />
            </svg>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="ring-black/5 headerAccountDDWrap absolute right-[-102px] top-[2.4rem] z-50 mt-2 w-[241px] origin-top-right rounded-md bg-white focus:outline-none">
              <div className="headerAccountDD py-1">
                {/* <Menu.Item>
                  <ReferenceLink
                    target={accountLink}
                    className={`block w-36 cursor-pointer py-2 px-4 ${account ? 'text-left' : 'text-center'
                      }  text-sm text-primary-400 hover:bg-gray-100 dark:bg-primary-400  dark:text-light-100`}
                  >
                    {account.firstName
                      ? formatAccountMessage({ id: 'hello', defaultMessage: 'Hi, ' }) + account.firstName
                      : account.lastName
                        ? formatAccountMessage({ id: 'hello', defaultMessage: 'Hi, ' }) + account.lastName
                        : formatAccountMessage({ id: 'hello', defaultMessage: 'Hi, ' }) +
                        formatAccountMessage({ id: 'user', defaultMessage: 'User ' })}
                  </ReferenceLink>
                </Menu.Item> */}
                {/* {account && (
                  <Menu.Item>
                    <button
                      onClick={handleLogout}
                      className="block w-36 cursor-pointer py-2 px-4 text-left text-sm hover:bg-gray-100 dark:bg-primary-400 dark:text-light-100"
                    >
                      {formatAccountMessage({ id: 'signout', defaultMessage: 'Logout' })}
                    </button>
                  </Menu.Item>
                )} */}
                <Menu.Item>
                  <li className="myAccountItemsHB">
                    <span className="myAccountIconHB">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M13.291 13.3333V12.0741C13.291 11.4061 13.1251 10.5001 12.627 10.0278C12.1288 9.55546 11.3392 9.55556 10.6348 9.55556H5.32227C4.61778 9.55556 3.66221 9.55546 3.16406 10.0278C2.66592 10.5001 2.66602 11.4061 2.66602 12.0741V13.3333M10.6348 4.51852C10.6348 5.90946 9.44552 7.03704 7.97852 7.03704C6.51151 7.03704 5.32227 5.90946 5.32227 4.51852C5.32227 3.12758 6.51151 2 7.97852 2C9.44552 2 10.6348 3.12758 10.6348 4.51852Z"
                          stroke="#344054"
                          strokeWidth="1.5"
                          strokeLinecap="square"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    <NextLink href="/account">My Profile</NextLink>{' '}
                  </li>
                </Menu.Item>
                {/* <Menu.Item>
                  <li className="myAccountItemsHB">
                    <span className="myAccountIconHB">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M7.33398 4.66671H8.66732V6.00004H7.33398V4.66671ZM7.33398 7.33337H8.66732V11.3334H7.33398V7.33337ZM8.00065 1.33337C4.32065 1.33337 1.33398 4.32004 1.33398 8.00004C1.33398 11.68 4.32065 14.6667 8.00065 14.6667C11.6807 14.6667 14.6673 11.68 14.6673 8.00004C14.6673 4.32004 11.6807 1.33337 8.00065 1.33337ZM8.00065 13.3334C5.06065 13.3334 2.66732 10.94 2.66732 8.00004C2.66732 5.06004 5.06065 2.66671 8.00065 2.66671C10.9407 2.66671 13.334 5.06004 13.334 8.00004C13.334 10.94 10.9407 13.3334 8.00065 13.3334Z"
                          fill="#303030"
                        />
                      </svg>
                    </span>
                    <NextLink href="/company-info">Company Information</NextLink>{' '}
                  </li>
                </Menu.Item> */}
                {!isSSOLoggedIn && <Menu.Item>
                  {account && (
                    <li className="myAccountItemsHB">
                      <span className="myAccountIconHB">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M8.00065 1.33337C4.32065 1.33337 1.33398 4.32004 1.33398 8.00004C1.33398 11.68 4.32065 14.6667 8.00065 14.6667C11.6807 14.6667 14.6673 11.68 14.6673 8.00004C14.6673 4.32004 11.6807 1.33337 8.00065 1.33337ZM13.2873 7.33337H8.66732V2.71337C11.074 3.01337 12.9873 4.92671 13.2873 7.33337ZM2.66732 8.00004C2.66732 5.28671 4.70732 3.04004 7.33398 2.71337V13.2867C4.70732 12.96 2.66732 10.7134 2.66732 8.00004ZM8.66732 13.2867V8.66671H13.2873C12.9873 11.0734 11.074 12.9867 8.66732 13.2867Z"
                            fill="#303030"
                          />
                        </svg>
                      </span>
                      <NextLink href="/admin-dashboard">Admin Dashboard</NextLink>{' '}
                    </li>
                  )}
                </Menu.Item>}
                <Menu.Item>
                  <li className="myAccountItemsHB">
                    <span className="myAccountIconHB">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M9 2C5.68667 2 3 4.68667 3 8H1L3.59333 10.5933L3.64 10.6867L6.33333 8H4.33333C4.33333 5.42 6.42 3.33333 9 3.33333C11.58 3.33333 13.6667 5.42 13.6667 8C13.6667 10.58 11.58 12.6667 9 12.6667C7.71333 12.6667 6.54667 12.14 5.70667 11.2933L4.76 12.24C5.84667 13.3267 7.34 14 9 14C12.3133 14 15 11.3133 15 8C15 4.68667 12.3133 2 9 2ZM8.33333 5.33333V8.66667L11.1667 10.3467L11.68 9.49333L9.33333 8.1V5.33333H8.33333Z"
                          fill="#303030"
                        />
                      </svg>
                    </span>
                    <NextLink href="orders/order-list">Order History</NextLink>{' '}
                  </li>
                </Menu.Item>
                <Menu.Item>
                  {account && (
                    <li className="myAccountItemsHB">
                      <span className="myAccountIconHB">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M8.00065 1.33331C5.42065 1.33331 3.33398 3.41998 3.33398 5.99998C3.33398 9.49998 8.00065 14.6666 8.00065 14.6666C8.00065 14.6666 12.6673 9.49998 12.6673 5.99998C12.6673 3.41998 10.5807 1.33331 8.00065 1.33331ZM4.66732 5.99998C4.66732 4.15998 6.16065 2.66665 8.00065 2.66665C9.84065 2.66665 11.334 4.15998 11.334 5.99998C11.334 7.91998 9.41398 10.7933 8.00065 12.5866C6.61398 10.8066 4.66732 7.89998 4.66732 5.99998Z"
                            fill="#303030"
                          />
                          <path
                            d="M8.00065 7.66665C8.92113 7.66665 9.66732 6.92045 9.66732 5.99998C9.66732 5.07951 8.92113 4.33331 8.00065 4.33331C7.08018 4.33331 6.33398 5.07951 6.33398 5.99998C6.33398 6.92045 7.08018 7.66665 8.00065 7.66665Z"
                            fill="#303030"
                          />
                        </svg>
                      </span>
                      Addresses{' '}
                    </li>
                  )}
                </Menu.Item>
                <Menu.Item>
                  <li className="myAccountItemsHB">
                    <span className="myAccountIconHB">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M9 2C5.68667 2 3 4.68667 3 8H1L3.59333 10.5933L3.64 10.6867L6.33333 8H4.33333C4.33333 5.42 6.42 3.33333 9 3.33333C11.58 3.33333 13.6667 5.42 13.6667 8C13.6667 10.58 11.58 12.6667 9 12.6667C7.71333 12.6667 6.54667 12.14 5.70667 11.2933L4.76 12.24C5.84667 13.3267 7.34 14 9 14C12.3133 14 15 11.3133 15 8C15 4.68667 12.3133 2 9 2ZM8.33333 5.33333V8.66667L11.1667 10.3467L11.68 9.49333L9.33333 8.1V5.33333H8.33333Z"
                          fill="#303030"
                        />
                      </svg>
                    </span>
                    <NextLink href="quotes/quotes-list">My RFQ</NextLink>{' '}
                  </li>
                </Menu.Item>
                <Menu.Item>
                  {account && (
                    <li className="myAccountItemsHB logoutBtnDD" onClick={handleLogout}>
                      <span className="myAccountIconHB">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M6 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V3.33333C2 2.97971 2.14048 2.64057 2.39052 2.39052C2.64057 2.14048 2.97971 2 3.33333 2H6M10.6667 11.3333L14 8M14 8L10.6667 4.66667M14 8H6"
                            stroke="#344054"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                      Logout{' '}
                    </li>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      ) : (
        <>
          <div className="flex space-x-8">
            <div className="flex">
              {/* <ReferenceLink
                target={accountLink}
                className="-m-2 p-2 text-primary-400 hover:text-primary-500 dark:text-light-100"
              > */}
              {showIcon ? (
                //  <NextLink href="/login">
                <button className="hidden lg:block" onClick={goTOAccount}>
                  <svg
                    className="HeaderAccIcon "
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.8375 20V21.1H21.0375V20H18.8375ZM15.9531 14.3333V13.2333V14.3333ZM7.98438 14.3333V13.2333V14.3333ZM4 18.1111H2.9H4ZM2.9 20C2.9 20.6075 3.39249 21.1 4 21.1C4.60751 21.1 5.1 20.6075 5.1 20H2.9ZM21.0375 20V18.1111H18.8375V20H21.0375ZM21.0375 18.1111C21.0375 17.5286 20.9662 16.8359 20.7786 16.1686C20.5949 15.515 20.2717 14.7872 19.6983 14.2434L18.1846 15.8399C18.3583 16.0047 18.5332 16.3106 18.6607 16.764C18.7844 17.2039 18.8375 17.6917 18.8375 18.1111H21.0375ZM19.6983 14.2434C19.1072 13.6831 18.3866 13.4458 17.7474 13.3373C17.1183 13.2306 16.463 13.2333 15.9531 13.2333V15.4333C16.5 15.4333 16.9652 15.436 17.3793 15.5063C17.7833 15.5749 18.0284 15.6918 18.1846 15.8399L19.6983 14.2434ZM15.9531 13.2333H7.98438V15.4333H15.9531V13.2333ZM7.98438 13.2333C7.47018 13.2333 6.76429 13.2312 6.08475 13.3343C5.4223 13.4347 4.6076 13.6581 3.99022 14.2434L5.50392 15.8399C5.63376 15.7168 5.90935 15.586 6.41456 15.5094C6.90269 15.4354 7.44184 15.4333 7.98438 15.4333V13.2333ZM3.99022 14.2434C3.36373 14.8374 3.11835 15.633 3.00865 16.2834C2.89741 16.9429 2.9 17.6258 2.9 18.1111H5.1C5.1 17.5945 5.10252 17.0969 5.17801 16.6493C5.25504 16.1926 5.3832 15.9544 5.50392 15.8399L3.99022 14.2434ZM2.9 18.1111V20H5.1V18.1111H2.9ZM14.8531 6.77778C14.8531 8.20222 13.6177 9.45556 11.9688 9.45556V11.6556C14.7209 11.6556 17.0531 9.52615 17.0531 6.77778H14.8531ZM11.9688 9.45556C10.3198 9.45556 9.08437 8.20222 9.08437 6.77778H6.88438C6.88438 9.52615 9.21665 11.6556 11.9688 11.6556V9.45556ZM9.08437 6.77778C9.08437 5.35333 10.3198 4.1 11.9688 4.1V1.9C9.21665 1.9 6.88438 4.0294 6.88438 6.77778H9.08437ZM11.9688 4.1C13.6177 4.1 14.8531 5.35333 14.8531 6.77778H17.0531C17.0531 4.0294 14.7209 1.9 11.9688 1.9V4.1Z"
                      fill="#003769"
                    />
                  </svg>
                </button>
              ) : (
                // </NextLink>
                <div className="test relative flex space-x-8">
                  <button className="flex" onClick={goTOAccount}>
                    <span className="headerAccBtn loginAccBtnHeader">Login/Sign-up</span>
                  </button>
                </div>
              )}
              {/* <UserIcon className="h-6 w-6" aria-hidden="true" /> */}
              {/* </ReferenceLink> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AccountButton;
