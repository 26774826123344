import Link from 'next/link';
import React, { useState, useEffect } from 'react';

const topBannerTastic = () => {
  const [isVisible, setIsVisible] = useState(true);
  let lastScrollTop = 0;
  const heightToHideFrom = 100;
  useEffect(() => {
    window.addEventListener(
      'scroll',
      function () {
        if (window.location.href.includes('/vendor-list')) {
          setIsVisible(true);
          return;
        }
        const st = window.pageYOffset || document.documentElement.scrollTop;
        if (st > lastScrollTop && st > heightToHideFrom) {
          setIsVisible(false);
        } else if (st < lastScrollTop) {
          setIsVisible(true);
        }
        lastScrollTop = st <= 0 ? 0 : st;
      },
      false,
    );
  }, []);
  return (
    <div>
      {isVisible && (
        <div className={'fixed-screen-width lg:relative-width transition-all'}>
          <div className="topBannerMain">
            <div className="flex w-full  justify-end lg:mx-[100px]">
              <ul className="topBannerlist pr-5 lg:pr-0">
                <li className="topBannerItem hidden lg:block">
                  {' '}
                  <Link href="/about-us">About Allverz</Link>{' '}
                </li>
                <li className="topBannerItem hidden lg:block">
                  <Link href="/contact-us">Contact Us</Link>
                </li>
                <Link href={'/faq'}>
                  <li className="topBannerItem hidden cursor-pointer lg:block	">Need Help?</li>
                </Link>
                <li className="topBannerItem">
                  Currency
                  <span>
                    ₹ INR
                    <select className="currencySelector">
                      <option value="INR">INR</option>
                      <option value="EUR">EUR</option>
                    </select>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default topBannerTastic;
