import { STATIC_QUOTE_NOTIFICATIONS } from 'helpers/constants/statisURLs';
import { backendAPIClient } from 'helpers/utils/axiosRequestsUtil';

interface Props {
  toEmail: string;
  subject: string;
  body: string;
  toName: string;
}

const notifyQuoteActions = async ({ toName, subject, body, toEmail }: Props) => {
  if (!toEmail || !subject) return;
  try {
    const reqBody = {
      toEmail: toEmail || 'alwinjose.v@royalcyber.com',
      subject: subject || 'Quote has been created!',
      body: body || 'Quote has been created. Please wait for the seller to respond.',
      toName: toName || 'KRKA LAB',
      fromName: 'DRL',
    };
    const response = await backendAPIClient.post(STATIC_QUOTE_NOTIFICATIONS, reqBody);
    console.log('Send notification response:', response);
  } catch (err) {
    console.error('Send notification failed:', err.message);
  }
};

export { notifyQuoteActions };
