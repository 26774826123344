import React, { useState, useEffect } from 'react';
import Markdown from 'components/commercetools-ui/content/markdown';
import Typography from 'components/commercetools-ui/typography';
import Search from './search';
import Link from 'next/link';
import { useAccount } from 'frontastic';
import { useRouter } from 'next/router';

const Blog = ({ data }) => {
  const model = data.data.dataSource.attributes;
  const { account, loggedIn } = useAccount();
  const router = useRouter();
  const requestForQuoteUrl = account ? 'quotes/request-for-quote' : 'login';

  // BAckground Image change code
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsSmallScreen(window.innerWidth < 376);
    }

    window.addEventListener('resize', handleResize);

    handleResize(); // check size on initial mount

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const bgImageDesktop = 'url(' + model?.bannerImage?.content.url + ')';
  const bannerBgImageMob = 'url(' + model?.bannerBackgroundMobile.content.url + ')';

  const styles = {
    backgroundImage: isSmallScreen ? bannerBgImageMob : bgImageDesktop,
    backgroundSize: 'cover',
  };

  return (
    <>
      <div
        className=""
        // style={{ backgroundImage: "url(" + bannerBgImageMob + ")"}}
        style={styles}
      >
        <div className="">
          <div className="">
            <div className="min-h-[17rem] w-full">
              {/* <img className="banner-img" src={model?.bannerImage?.content.url} alt="logo" /> */}
              <Search data={data} />
              <div className="banner-main container mx-auto px-5 md:px-0 lg:px-3">
                <div className="banner-inner">
                  {/* <div className="banner-title col-span-4 col-start-2 ">
                    <Typography>{model?.bannerTitle?.content}</Typography>
                  </div> */}

                  <div className=" ">
                    <h2 className="banner-title belowTitleBanner">
                      <Typography>{model?.bannerSubTitle?.content}</Typography>
                    </h2>
                  </div>
                  {/* <div className=" ">
                    <p className="banner-content">
                      <Typography>{model?.description?.content}</Typography>
                    </p>
                  </div> */}
                  <div className="banner-button">
                    <div className="BannerBtnWrap">
                      {/* TODO: Need to discuss with CMS team, whether the link is coming from CMS or need to hard code */}
                      {!localStorage.getItem('punchoutUrl') && <Link href={requestForQuoteUrl}>
                        <button className="product-enquiry">{model?.bannerCta1Text?.content}</button>
                      </Link>}
                      <a href="/contact-us" target="_self" rel="noreferrer">
                        <button className="banner-demo">{model?.bannerCta2Text?.content}</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
