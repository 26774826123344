import React, { MouseEvent, useEffect, useState } from 'react';
import NextLink from 'next/link';
import { STATIC_QUOTE_LIST } from 'helpers/constants/statisURLs';
import { backendAPIClient, commercetoolsAPIClient } from 'helpers/utils/axiosRequestsUtil';
import toast from 'react-hot-toast';
import { useAccount } from 'frontastic';
import { QuoteDetails } from 'frontastic/tastics/quotes-detail/Quote-details-component/quote-details';
import { notifyQuoteActions } from '../notification';
import { LOADING_STATUS } from 'helpers/constants/constant';

const RFQList = () => {
  const [rfqList, setRFQlist] = useState([]);
  const [rfqListLoadingStatus, setRFQlistLoadingStatus] = useState(LOADING_STATUS.LOADING);
  const { account } = useAccount();
  const [showDetails, setShowDetails] = useState(false);
  const [selectedQuote, setSelectedQuote] = useState({});

  useEffect(() => {
    setShowDetails(false);
    getRFQList();
  }, []);

  const getRFQList = async () => {
    const reqBody = {
      offSet: '0',
      limit: '20',
      customerId: account?.accountId,
    };

    backendAPIClient
      .post(STATIC_QUOTE_LIST, reqBody)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          console.log('API Response:', res.data);
          setRFQlist(res.data['results']);
          if (res.data['results'].length < 1) {
            setRFQlistLoadingStatus(LOADING_STATUS.SUCCESS_WITH_NO_RESULT)
          } else {
            [
              setRFQlistLoadingStatus(LOADING_STATUS.SUCCESS_WITH_RESULT)
            ]
          }
        } else {
          setRFQlistLoadingStatus(LOADING_STATUS.SUCCESS_WITH_NO_RESULT)
          console.error('Error on getting RFQ List');
        }
      })
      .catch((ex) => {
        setRFQlistLoadingStatus(LOADING_STATUS.SUCCESS_WITH_NO_RESULT)
        console.error(ex.message)
      });
  };

  const onClickViewQuoteLink = (e: MouseEvent, row: any) => {
    setShowDetails(true);
    setSelectedQuote(row.quoteRequest);
    e.preventDefault();
  };

  const onRejectQuote = async (quote: any) => {
    const reqBody = {
      version: quote.version,
      actions: [
        {
          action: 'changeQuoteRequestState',
          quoteRequestState: 'Cancelled',
        },
      ],
    };

    try {
      const response = await commercetoolsAPIClient.post(`quote-requests/${quote.id}`, reqBody, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
      });
      if (response.status.toString() === '200') {
        toast.success('Quote Rejected successfully!');
        getRFQList();
        //Notify to Buyer
        notifyQuoteActions({
          toName: account?.firstName + ' ' + account?.lastName,
          toEmail: account?.['email'],
          subject: 'Quote has been rejected!',
          body: 'Quote has been rejected by the seller.',
        });
      }
    } catch (err) { }
  };

  // remove the function once quote-details displayed in seperate page.
  const onReject = () => {
    getRFQList();
    notifyQuoteActions({
      toName: account?.firstName + ' ' + account?.lastName,
      toEmail: account?.['email'],
      subject: 'Quote has been rejected!',
      body: 'Quote has been rejected by the seller.',
    });
    setShowDetails(false)
  };

  const getStatusText = (status) => {
    const res = status?.replace(/([a-z])([A-Z])/g, '$1 $2') || '';
    return res
  }

  const showList = () => (
    <div className="cart-heading-count">
      <div className=" mt-10">
        <h1 className="my-cart-text text-2xl font-bold">RFQ List</h1>
      </div>
      <div className="mt-5">
        <table className="min-w-full border border-gray-300">
          <thead>
            <tr className="bg-gray-100" style={{ fontSize: '14px', marginLeft: '15px' }}>
              <th className="border-b py-2 px-4">Quote Number</th>
              <th className="border-b py-2 px-4">Quote Name</th>
              {/* <th className="border-b py-2 px-4">Status</th> */}
              <th className="border-b py-2 px-4">Actions</th>
              <th className="border-b py-2 px-4">View Details</th>
            </tr>
          </thead>
          <tbody className='text-center'>
            {
              rfqListLoadingStatus !== LOADING_STATUS.SUCCESS_WITH_RESULT &&
              <tr>
                <td colSpan={4} className="text-center">
                  {rfqListLoadingStatus}
                </td>
              </tr>}
            {rfqList && rfqList.length > 0 &&
              rfqList.map((row) => (
                <tr key={row.id} style={{ fontSize: '14px', marginLeft: '15px' }}>
                  {row?.quoteRequestState == 'Submitted' ? (
                    <td className="border-b py-2 px-4 text-center">{row?.quoteRequest?.custom?.fields?.quoteNumber}</td>
                  ) : (
                    <td className="border-b py-2 px-4 text-center">{row?.quoteRequest?.custom?.fields?.quoteNumber}</td>
                  )}
                  <td className="border-b py-2 px-4 text-center">
                    {row?.quoteRequest?.custom?.fields?.RFQTitle || row.comment}
                  </td>
                  {/* <td className="border-b py-2 px-4 text-center">{row.quoteState}</td> */}
                  <td className="border-b py-2 px-4 text-center">{getStatusText(row.quoteState)}</td>
                  {/* <td className="border-b py-2 px-4 text-center">{row?.quoteState?.replace(/([a-z])([A-Z])/g, '$1 $2') || ''}</td> */}

                  {/* <td className="border-b py-2 px-4 text-center">
                    {row.quoteRequestState !== 'Cancelled' && (
                      <a href="#" onClick={() => onRejectQuote(row)} className="font-bold text-red-700">
                        X
                      </a>
                    )}
                    hello
                  </td> */}

                  <td className="border-b py-2 px-4 text-center">
                    <button
                      disabled={false}
                      className="link link-primary whitespace-nowrap"
                      onClick={(e) => onClickViewQuoteLink(e, row)}
                    >
                      View Quote
                    </button>
                    {/* {row?.quoteState === 'Pending' ||
                      row?.quoteState === 'DeclinedForRenegotiation' ||
                      row?.quoteState === 'Accepted' ||
                      row?.quoteState === 'RenegotiationAddressed' ? (
                      <button
                        disabled={false}
                        className="link link-primary whitespace-nowrap"
                        onClick={(e) => onClickViewQuoteLink(e, row)}
                      >
                        View Quote
                      </button>
                    ) : (
                      row?.quoteState === 'Submitted' ||
                      (row?.quoteState !== 'Rejected' && (
                        <button disabled={true} className="whitespace-nowrap">
                          View Quote
                        </button>
                      ))
                    )} */}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  const displayPart = showDetails ? <QuoteDetails selectedQuote={selectedQuote} setShowDetails={setShowDetails} onReject={onReject} /> : showList();

  return (
    <div className="cart-heading-count mt-4">
      <h1 className="cart-breadcrumb">
        <NextLink href="/">
          <a className="pdpBreadcrumbItem text-[#0280C7] hover:text-gray-500">
            <span className="cursor-pointer font-medium capitalize hover:text-gray-600">Home Page</span>
          </a>
        </NextLink>
        {showDetails && (
          <>
            <span>
              <span className="quote-breadcrumb-seperator">/</span>
              <span className="quote-breadcrumb-part">
                <span style={{ cursor: 'pointer', color: '#0280C7' }} onClick={() => setShowDetails(false)}>
                  Quote List
                </span>
              </span>
            </span>
            <span className="quote-breadcrumb-seperator">/</span>
            <span className="quote-breadcrumb-part">Quote Details</span>
          </>
        )}
        {!showDetails && (
          <>
            <span className="quote-breadcrumb-seperator">/</span>
            <span className="quote-breadcrumb-part">Quote List</span>
          </>
        )}
      </h1>
      {displayPart}
    </div>
  );
};

export default RFQList;
