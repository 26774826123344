import React, { useEffect, useState } from 'react';
import NextLink from 'next/link';
import { updateURLParams } from '../../../../helpers/utils/updateURLParams';
import { Product } from '@Types/product/Product';

export interface PaginationProps {
  numberOfPages: number;
  previousCursor: string | null;
  nextCursor: string | null;
  searchQueryText: string;
  products?: Product[];
}

export default function Pagination({ numberOfPages, previousCursor, nextCursor, searchQueryText, products }: PaginationProps) {
  const productsPerPage = 12;
  const [previousPageURL, setPreviousPageURL] = useState<string>('/');
  const [clickedItem, setClickedItem] = useState(0);
  const [nextPageURL, setNextPageURL] = useState<string>('/');
  const [isPagintionChanged, setIsPagintionChanged] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const query = urlParams.get('q');
  const existingFacetFilters = urlParams.get('facetFilters');
  const existingSort = urlParams.get('sortby');

  useEffect(()=>{
    if(!isPagintionChanged){
      setClickedItem(0);
    }
    setIsPagintionChanged(false);
  },[products])

  useEffect(()=>{
    setIsPagintionChanged(true);
  },[clickedItem])

  useEffect(() => {
    setPreviousPageURL(
      previousCursor
        ? updateURLParams([
            ...(query ? [{ key: 'q', value: query }] : []),
            { key: 'cursor', value: previousCursor },
            ...(existingFacetFilters ? [{ key: 'facetFilters', value: existingFacetFilters }] : []),
            ...(existingSort ? [{ key: 'sortby', value: existingSort }] : []),
          ])
        : '/',
    );

    setNextPageURL(
      nextCursor
        ? updateURLParams([
            ...(query ? [{ key: 'q', value: query }] : []),
            { key: 'cursor', value: nextCursor },
            ...(existingFacetFilters ? [{ key: 'facetFilters', value: existingFacetFilters }] : []),
            ...(existingSort ? [{ key: 'sortby', value: existingSort }] : []),
          ])
        : '/',
    );
  }, [previousCursor, nextCursor, existingFacetFilters, query, existingSort]);

  const getPageURLParams = (index) => {
    if (typeof window !== 'undefined') {
      const urlParams = [{ key: 'cursor', value: `offset:${index * productsPerPage}` }];
      if (query !== null && query !== undefined) {
        urlParams.push({ key: 'q', value: query });
      }

      if (existingFacetFilters !== null && existingFacetFilters !== undefined) {
        urlParams.push({ key: 'facetFilters', value: existingFacetFilters });
      }
      if (existingSort !== null && existingSort !== undefined) {
        urlParams.push({ key: 'sortby', value: existingSort });
      }

      return updateURLParams(urlParams);
    }

    return '/';
  };

  return (
    <nav
      className="flex items-center justify-between border-t border-gray-200 py-[40px] px-4 sm:px-6"
      aria-label="Pagination"
    >
      <ul className="plpPaginationWrap flex flex-1 items-center justify-between gap-x-1.5 sm:justify-end">
        {numberOfPages > 1 && (
          <>
            <NextLink href={previousCursor ? previousPageURL : '#'}>
              <button
                className={`plpPaginationitemsLeft ${!previousCursor ? 'disabled' : ''}`}
                // className="plpPaginationitemsLeft"
                disabled={!previousCursor}
                onClick={() => setClickedItem(clickedItem - 1)}
              >
                <span className="sr-only">Previous</span>
                <svg
                  aria-hidden="true"
                  className="h-5 w-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </NextLink>

            {[...Array(numberOfPages)].map((_, index) => {
              if (
                index === 0 ||
                index === numberOfPages - 1 ||
                (index >= clickedItem - 2 && index <= clickedItem + 2)
              ) {
                return (
                  <NextLink key={index} href={getPageURLParams(index)}>
                    <li
                      className={index === clickedItem ? 'plpPaginationPageItem activePage' : 'plpPaginationPageItem'}
                      onClick={() => setClickedItem(index)}
                    >
                      {index + 1}
                    </li>
                  </NextLink>
                );
              } else if (index === clickedItem - 3 || index === clickedItem + 3) {
                return (
                  <li key={index} className="plpPaginationPageItem" onClick={() => setClickedItem(index)}>
                    ...
                  </li>
                );
              } else {
                return null;
              }
            })}

            <NextLink href={nextPageURL}>
              <button
                className={`plpPaginationitemsRight ${!nextCursor ? 'disabled' : ''}`}
                // className="plpPaginationitemsRight"
                disabled={!nextCursor}
                onClick={() => setClickedItem(clickedItem + 1)}
              >
                <span className="sr-only">Next</span>
                <svg
                  aria-hidden="true"
                  className="h-5 w-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </NextLink>
          </>
        )}
      </ul>
    </nav>
  );
}
