import { useEffect, useRef, useState } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { ShippingMethod } from '@Types/cart/ShippingMethod';
import Address from 'components/commercetools-ui/adyen-checkout/panels/address';
import Checkout from 'components/commercetools-ui/adyen-checkout/panels/checkout';
import Overview from 'components/commercetools-ui/adyen-checkout/panels/overview';
import OrderSummary from 'components/commercetools-ui/cart/orderSummary';
import { STATIC_ORDERS_URL, STATIC_SELLER_PO, STATIC_ORDER_PUBLISH } from 'helpers/constants/statisURLs';
import { useFormat } from 'helpers/hooks/useFormat';
import { backendAPIClient, commercetoolsAPIClient } from 'helpers/utils/axiosRequestsUtil';
import { useCart, useAccount } from 'frontastic';
import AccordionBtn from '../accordion';
import Loader from '../loader';
import Stepper from '../stepper';
import CheckoutTabs from './checkoutTabs';
import BehalfDetails from './checkoutTabs/behalf';
import DeliveryDate from './checkoutTabs/deliveryDate';
import CheckoutInstructions from './checkoutTabs/instructions';
import PurchaseOrder from './checkoutTabs/purchaseOrder';
import ShippingAddress from './checkoutTabs/shippingAddress/index';
import TargetMarket from './checkoutTabs/targetMarket';
import { mapToCartStructure, mapToFormStructure } from './mapFormData';
import OrderSummaryCheckout from './order-summary';
import { requiredDataIsValid } from './requiredDataIsValid';
import Modal from './order-modal';
import { BACKEND_BASE_URL } from 'helpers/constants/envVariables';
import axios from 'axios';
import { FiArrowLeftCircle } from 'react-icons/fi';

export type FormData = {
  firstName: string;
  lastName: string;
  phone?: string;
  email: string;
  shippingStreetName: string;
  shippingCity: string;
  shippingPostalCode: string;
  shippingCountry: string;
  billingStreetName: string;
  billingCity: string;
  billingPostalCode: string;
  billingCountry: string;
};

const AdyenCheckout = ({ termsLink, cancellationLink, privacyLink }) => {
  const { data: cartList, updateCart, setShippingMethod } = useCart();
  const { formatMessage } = useFormat({ name: 'cart' });
  const { account } = useAccount();

  const containerRef = useRef();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [disableSubmitButton, setDisableSubmitButton] = useState<boolean>(true);
  const [billingIsSameAsShipping, setBillingIsSameAsShipping] = useState<boolean>(true);
  const [currentShippingMethod, setCurrentShippingMethod] = useState<ShippingMethod>();
  const [dataIsValid, setDataIsValid] = useState<boolean>(false);
  const [orderId, setOrderId] = useState('');
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [POReferenceResponse, setPOReferenceResponse] = useState('');
  const [errorAddress, setErrorAddress] = useState('');
  const [isDateValid, setIsDateValid] = useState<boolean>(false);

  const [data, setData] = useState<FormData>({
    firstName: '',
    lastName: '',
    email: '',
    shippingStreetName: '',
    shippingCity: '',
    shippingPostalCode: '',
    shippingCountry: '',
    billingStreetName: '',
    billingCity: '',
    billingPostalCode: '',
    billingCountry: '',
  });
  const changeStep = (stepIndex: number) => {
    if (currentStepIndex > stepIndex) {
      setCurrentStepIndex(stepIndex);
    }
  };

  const toggleBillingAddressOption = () => {
    setBillingIsSameAsShipping(!billingIsSameAsShipping);
  };

  const generateStepTag = (index: number) => (
    <div
      className={`mx-auto flex h-10 w-10 items-center rounded-full text-lg  text-white ${index == currentStepIndex ? `bg-green-500` : 'border-2 border-gray-200 bg-white'
        }`}
    >
      <span className={`w-full text-center ${index == currentStepIndex ? `text-white` : 'text-gray-600'}`}>
        {index + 1}
      </span>
    </div>
  );

  const goToTopOfPage = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const gotToNextStep = () => {
    if (currentStepIndex == 0) {
      updateCartData();
    }

    setCurrentStepIndex(currentStepIndex + 1);
    goToTopOfPage();
  };

  const updateData = (data: FormData) => {
    setData(data);
  };

  const updateCartData = () => {
    if (dataIsValid) {
      const updatedData = mapToCartStructure(data, billingIsSameAsShipping);

      setIsLoading(true);
      updateCart(updatedData).finally(() => {
        setIsLoading(false);
      });
    }
  };

  const updatecurrentShippingMethod = (shippingMethod: ShippingMethod) => {
    if (shippingMethod?.shippingMethodId) {
      setCurrentShippingMethod(shippingMethod);
      setShippingMethod(shippingMethod.shippingMethodId);
    }
  };

  const submitButtonLabel = [
    formatMessage({ id: 'goToOverview', defaultMessage: 'Go to overview' }),
    formatMessage({ id: 'ContinueAndPay', defaultMessage: 'Continue and pay' }),
  ];

  const steps = [
    {
      name: formatMessage({ id: 'address', defaultMessage: 'Address' }),
      component: (
        <Address
          data={data}
          updateData={updateData}
          billingIsSameAsShipping={billingIsSameAsShipping}
          toggleBillingAddressOption={toggleBillingAddressOption}
        />
      ),
    },
    {
      name: formatMessage({ id: 'overview', defaultMessage: 'Overview' }),
      component: (
        <Overview
          shippingMethods={cartList?.availableShippingMethods}
          currentShippingMethod={currentShippingMethod}
          onSelectShippingMethod={updatecurrentShippingMethod}
        />
      ),
    },
    { name: formatMessage({ id: 'payment', defaultMessage: 'Payment' }), component: <Checkout /> },
  ];

  useEffect(() => {
    setDataIsValid(requiredDataIsValid(data, billingIsSameAsShipping));
  }, [data, billingIsSameAsShipping]);

  useEffect(() => {
    setDisableSubmitButton(!dataIsValid);
  }, [dataIsValid]);

  //   useEffect(() => {
  //     if (data.shippingCountry !== '') {
  //       updateCartData();
  //     }
  //   }, [data.shippingCountry, dataIsValid]);

  useEffect(() => {
    const defaultData = mapToFormStructure(cartList);
    if (defaultData && requiredDataIsValid(defaultData, billingIsSameAsShipping)) {
      updateData(defaultData);
    }
  }, [cartList]);

  useEffect(() => {
    if (!currentShippingMethod && cartList?.availableShippingMethods) {
      if (cartList?.shippingInfo) {
        const currentShippingMethod = cartList.availableShippingMethods.find(
          ({ shippingMethodId }) => shippingMethodId == cartList.shippingInfo.shippingMethodId,
        );
        setCurrentShippingMethod(currentShippingMethod);
      } else {
        setCurrentShippingMethod(cartList?.availableShippingMethods?.[0]);
      }
    }
  }, [cartList?.availableShippingMethods]);
  const [porefNumber, setPorefNumber] = useState('');
  const handlePorefNumberChange = (value) => {
    console.log('PO Ref Number:', value);
    setPorefNumber(value);
  };
  const [document, setDocument] = useState('');

  const handleDocumentUpload = (file) => {
    setDocument(file);
  };
  const [selectedDate, setSelectedDate] = useState(null);
  const handleDateChange = (date) => {
    console.log('setSelectedDate:', date);
    setSelectedDate(date);
  };
  const validDateCallBack = (validDate) => {
    if (validDate) {
      setIsDateValid(validDate);
    }
  };
  const checkoutData = [
    {
      id: 1,
      component: (
        <AccordionBtn
          openSectionTitle="1. Select Delivery & Billing Address"
          closedSectionTitle="1. Select Delivery & Billing Address"
          className="collapse-title"
        >
          <ShippingAddress />
        </AccordionBtn>
      ),
    },
    {
      id: 2,
      component: <DeliveryDate cartList={cartList} validDate={validDateCallBack} />,
    },
    {
      id: 3,
      component: (
        <PurchaseOrder
          onPorefNumberChange={handlePorefNumberChange}
          onDocumentUpload={handleDocumentUpload}
          onDateChange={handleDateChange}
        />
      ),
    },
    // {
    //   id: 4,
    //   component: (
    //     <AccordionBtn
    //       openSectionTitle="4. On behalf of"
    //       closedSectionTitle="4. On behalf of"
    //       className="collapse-title"
    //     >
    //       <div className="behalfDetails">
    //         <BehalfDetails />
    //       </div>
    //     </AccordionBtn>
    //   ),
    // },
    {
      id: 5,
      component: (
        <AccordionBtn
          openSectionTitle="4. Target Market"
          closedSectionTitle="4. Target Market"
          className="collapse-title"
        >
          <div className="targetDetails">
            <TargetMarket />
          </div>
        </AccordionBtn>
      ),
    },
    {
      id: 6,
      component: (
        <AccordionBtn
          openSectionTitle="5. Instructions"
          closedSectionTitle="5. Instructions"
          className="collapse-title"
        >
          <div className="instructionDetails">
            <CheckoutInstructions />
          </div>
        </AccordionBtn>
      ),
    },
  ];

  const stepperData = [{ label: 'Product Summary' }, { label: 'Checkout' }, { label: 'Payment' }];
  const reqBody = {
    id: cartList?.cartId,
    version: cartList?.cartVersion && JSON.parse(cartList?.cartVersion),
    orderNumber: JSON.stringify(Math.ceil(Math.random() * 100000)),
    purchaseOrderNumber: JSON.stringify(Math.ceil(Math.random() * 100000)),
  };
  const handlePlaceOrderAPICall = async () => {
    setLoading(true);
    try {
      const response = await commercetoolsAPIClient.post(STATIC_ORDERS_URL, reqBody, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
      });
      setOrderId(response?.data?.id);
      handlePublishOrder(response?.data?.id);
      setOpenModal(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };
  const handlePublishOrder = async (orderID) => {
    const orderid = orderID;
    const orderPublishUrl = `${BACKEND_BASE_URL}/${STATIC_ORDER_PUBLISH}/${orderid}`;
    axios
      .post(orderPublishUrl, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
      })
      .then((response) => { })
      .catch((error) => {
        console.error('Error from transfer cart API:', error);
      });
    return;
  };
  const handlePlaceOrder = () => {
    if (account?.addresses?.length > 0) {
      handlePlaceOrderAPICall();
      setErrorAddress('');
    } else {
      setErrorAddress('Please fill the  Shipping Address');
    }
  };

  useEffect(() => {
    if (account?.addresses?.length > 0 && account?.addresses?.find((element) => element.isDefaultBillingAddress)) {
      setErrorAddress('');
    }
  }, [account?.addresses?.length]);

  const handlePoDetails = async () => {
    try {
      const formData = new FormData();
      formData.append('poReferenceNumber', porefNumber);
      formData.append('cartId', cartList?.cartId);
      formData.append('file', document);
      formData.append('poDate', selectedDate);

      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      await backendAPIClient.post(STATIC_SELLER_PO, formData, { headers }).then((res) => {
        setPOReferenceResponse(res.data.poNumber);
      });
    } catch (err) { }
  };

  const router = useRouter();
  const goTOPreview = () => {
    if (orderId) {
      router.push(`/preview-order?orderID=${orderId}`);
    } else {
      return false;
    }
  };
  useEffect(() => {
    const selectedShippingAddress = account?.addresses.find((address) => address.isDefaultShippingAddress === true);
    const selectedBillingAddress = account?.addresses.find((address) => address.isDefaultBillingAddress === true);
    if (selectedShippingAddress) {
      updateCart({ billing: selectedBillingAddress || selectedShippingAddress, shipping: selectedShippingAddress });
    }
  }, []);

  return (
    <>
      {loading && <Loader />}
      <div className="checkoutContainer cartWrapper">
        <div className='text-primary-theme-color-2 cursor-pointer w-20 mt-36 mb-4'>
          <FiArrowLeftCircle />
          <p onClick={() => history.back()} className='-mt-5 ml-5'>
            Back
          </p>
        </div>
        <h1 className="cart-breadcrumb">
          <NextLink href="/">
            <a className="pdpBreadcrumbItem text-[#0280C7] hover:text-gray-500">
              <span className="mr-3 cursor-pointer font-medium capitalize hover:text-gray-600">Home Page</span>
            </a>
          </NextLink>
          /{' '}
          <span className="pdpBreadcrumbItem" style={{ marginLeft: '6px' }}>
            Checkout
          </span>
        </h1>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Stepper
            stepperData={stepperData}
            isHorizontal={true}
            activeStep={1}
            showCurrentStepOnly={false}
            isClickable={false}
          />
        </div>
        <div className="checkoutTop">
          <div className="checkoutLeft">
            <h1 className="checkoutTitle">Checkout</h1>
            <p className="checkoutPara">If you need to make changes to the order, please return to the cart</p>
          </div>
          <div className="checkoutRight">
            <NextLink href="/cart">
              <button className="rightCheckoutBack">Back to Cart</button>
            </NextLink>
          </div>
        </div>
        <div className="lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-3 2xl:gap-x-7">
          <div className="lg:col-span-6">
            <div className="checkoutAccordionContainer ">
              {checkoutData?.map((item, index) => (
                <div key={item?.id}>{item?.component}</div>
              ))}
              <div className="lg:hidden">
                <AccordionBtn
                  openSectionTitle="7. Order Summary"
                  closedSectionTitle="7. Order Summary"
                  className="collapse-title"
                >
                  <OrderSummaryCheckout cart={cartList} />
                </AccordionBtn>
              </div>
            </div>
            <div className="checkoutAccButtonMain">
              <div className="checkoutAccBtnLeft">
                <NextLink href="/cart">
                  <button className="checkoutAccBtnBack">Back to Cart</button>
                </NextLink>
              </div>
              <div className="checkoutAccBtnRight flex flex-col lg:flex-row">
                {/* <button
                  disabled={orderId ? false : true}
                  className="text-[12px] font-semibold text-[#0280C7] underline lg:mr-[15px]"
                  style={{ color: !orderId ? 'gray' : '#0280c7' }}
                  onClick={goTOPreview}
                >
                  Preview
                </button> */}
                <button
                  disabled={orderId ? true : false}
                  style={{ backgroundColor: orderId ? 'gray' : '#0280c7' }}
                  className={`checkoutAccBtnNext mt-[10px] lg:mt-[0px] ${orderId && 'bg-gray-700'}`}
                  onClick={() => {
                    setLoading(true);
                    handlePlaceOrder();
                    handlePoDetails();
                  }}
                >
                  Place Order
                </button>
              </div>
            </div>
            {openModal && (
              <Modal setOpenModal={setOpenModal} goTOPreview={goTOPreview}>
                <p className="text-center text-[20px] font-semibold text-[#32CD32]">Order placed successfully!</p>
              </Modal>
            )}
            {errorAddress && (
              <p className="text-center text-[12px] font-semibold text-[#ff0000] lg:text-[14px]">{errorAddress}</p>
            )}
          </div>
          <div className="order-summary-desktop lg:col-span-6 lg:mt-[26px]">
            <OrderSummaryCheckout cart={cartList} />
          </div>
        </div>

        <div className="mx-auto hidden max-w-4xl md:mt-4">
          <div>
            <div className="mx-auto py-6">
              <div className="relative flex justify-between py-6 px-5 shadow-md md:px-12" id="ProgressStepper">
                <div className="absolute top-0 left-0 flex h-full w-full items-center justify-between py-6 px-12 ">
                  <div className="top-2/4 h-2 w-full bg-green-100"></div>
                </div>
                {steps.map(({ name }, index) => (
                  <button key={index} className="relative rounded bg-white p-2" onClick={() => changeStep(index)}>
                    {generateStepTag(index)}
                    <div className="text-center text-xs 2xl:text-base">{name}</div>
                  </button>
                ))}
              </div>
            </div>
          </div>
          <div className="lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-12 xl:gap-x-16" ref={containerRef}>
            {steps[currentStepIndex].component}
            <OrderSummary
              cart={cartList}
              submitButtonLabel={submitButtonLabel[currentStepIndex]}
              disableSubmitButton={isLoading || disableSubmitButton}
              showDiscountsForm={currentStepIndex < 2}
              showSubmitButton={currentStepIndex < 2}
              onSubmit={gotToNextStep}
              termsLink={termsLink}
              cancellationLink={cancellationLink}
              privacyLink={privacyLink}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdyenCheckout;
