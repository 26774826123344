import React, { useState } from 'react';

const OtherBank = ['XYZ', 'ABC', 'Test'];

function NetBanking() {
  const [selectedBank, setSelectedBank] = useState('');
  return (
    <form className="payment-cheque-wrapper">
      <div className="mb-5 flex flex-col gap-4">
        <label className="flex items-center gap-2" htmlFor="">
          <input
            className="payment-radio-button"
            type="radio"
            name="bank-selected"
            onChange={(e) => setSelectedBank(e.target.value)}
          />
          <span className="payment-cheque-label !mb-0 flex">Axis Bank</span>
        </label>
        <label className="flex items-center gap-2" htmlFor="">
          <input
            className="payment-radio-button"
            type="radio"
            name="bank-selected"
            onChange={(e) => setSelectedBank(e.target.value)}
          />
          <span className="payment-cheque-label !mb-0 flex">HDFC Bank</span>
        </label>
        <label className="flex items-center gap-2" htmlFor="">
          <input
            className="payment-radio-button"
            type="radio"
            name="bank-selected"
            onChange={(e) => setSelectedBank(e.target.value)}
          />
          <span className="payment-cheque-label !mb-0 flex">ICICI Bank</span>
        </label>
        <label className="flex items-center gap-2" htmlFor="">
          <input
            className="payment-radio-button"
            type="radio"
            name="bank-selected"
            onChange={(e) => setSelectedBank(e.target.value)}
          />
          <span className="payment-cheque-label !mb-0 flex">Kotak Bank</span>
        </label>
        <label className="flex items-center gap-2" htmlFor="">
          <input
            className="payment-radio-button"
            type="radio"
            name="bank-selected"
            onChange={(e) => setSelectedBank(e.target.value)}
          />
          <span className="payment-cheque-label !mb-0 flex">SBI Bank</span>
        </label>
      </div>
      <div className='mt-9'>
        <span className="payment-cheque-label flex">Other Bank</span>
        <select
          className="cheque-input block w-full appearance-none rounded border border-[#d9d9d9] focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
          placeholder="Select Bank"
        >
          <option className="hidden" value="">
            Select Bank
          </option>
          {OtherBank.map((bank, index) => (
            <option key={index} value={bank}>
              {bank}
            </option>
          ))}
        </select>
      </div>
    </form>
  );
}

export default NetBanking;
