// components/search.js
import { useState } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { ALGOLIA_APPLICATION_ID, ALGOLIA_APPLICATION_KEY, ALGOLIA_INDEX_NAME } from 'helpers/constants/envVariables';
import { InstantSearch } from 'react-instantsearch-dom';
import MobileSearchHits from './mobileSearchHits';
import SearchBox from './searchbox';
import SearchHits from './searchhits';
import SecondarySearchBox from './secondarySearchBox';
import SecondarySearchHits from './secondarySearchHits';
interface SearchProps {
  searchInputStyle?: string;
  placeHolder?: string;
  endSearch?: any;
  showbutton?: boolean;
  triggerSearch?: boolean;
  isMobileModal?: boolean;
  isRfq?: boolean;
  handleCasCallback?: any;
  isFromQuote?: boolean;
  categoryValue?: any;
}
const searchClient = algoliasearch(ALGOLIA_APPLICATION_ID, ALGOLIA_APPLICATION_KEY);
export default function SecondarySearchAlgolia({
  searchInputStyle,
  placeHolder,
  showbutton,
  triggerSearch,
  isMobileModal,
  isRfq,
  handleCasCallback,
  isFromQuote = false,
  categoryValue,
}: SearchProps) {
  const [searchOutput, setSearchOutput] = useState([]);
  const [searchQuery, setSearchQuery] = useState([]);
  const [recentSearchIndex, setRecentSearchIndex] = useState(-1);
  const [hideSearchDropdown, setHideSearchDropdown] = useState(false);

  return (
    <div className="plp-search-bar-input-container relative">
      <InstantSearch searchClient={searchClient} indexName={ALGOLIA_INDEX_NAME}>
        <SecondarySearchBox
          style={searchInputStyle}
          placeHolder={placeHolder}
          searchOutput={searchOutput}
          searchQuery={searchQuery}
          showbutton={showbutton}
          setHideSearchDropdown={setHideSearchDropdown}
          triggerSearch={triggerSearch}
          handleCasCallback={handleCasCallback}
          isFromQuote={isFromQuote}
          categoryValue={categoryValue}
        />

        <SecondarySearchHits
          setSearchOutput={setSearchOutput}
          setSearchQuery={setSearchQuery}
          setRecentSearchIndex={setRecentSearchIndex}
          hideSearchDropdown={hideSearchDropdown}
          setHideSearchDropdown={setHideSearchDropdown}
          categoryValue={categoryValue}
        />
      </InstantSearch>
    </div>
  );
}
