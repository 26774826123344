import React from 'react';
import RFQList from 'components/commercetools-ui/cart/rfq/RFQList';

function QuotesList() {
  return (
    <div className="container mx-auto pb-24 lg:mr-[100px]">
      <div className="quote-container-main flex">
        <div className="quote-container">
          <div className="quote-part-two">
            <div className="lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-7">
              <div className="lg:col-span-11">
                <RFQList />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuotesList;
