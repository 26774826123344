import Markdown from 'components/commercetools-ui/content/markdown';
import { TERMS_AND_CONDITIONS_PAGE_VIEWED } from 'helpers/constants/events';
import React, { useCallback, useEffect, useState } from 'react';

const ContentTerms = ({ data }) => {
  const termsAttributes = data.data.dataSource.attributes;

  const handleTermsAnalytics = useCallback(async () => {
    gtag('event', TERMS_AND_CONDITIONS_PAGE_VIEWED, {});
  }, []);
  useEffect(() => {
    handleTermsAnalytics();
  }, [handleTermsAnalytics]);
  return (
    <>
      <div className="container mx-auto mt-[110px] px-5 md:px-0 lg:px-3">
        <div className="privacyPolicyContainer">
          <div className="plpBreadcrumbWrap">
            <span>
              <a href="/">Home Page</a>
            </span>
            / <span>Terms and Conditions</span>
          </div>
          <div className="plpCategoryTitle">
            <p className="plpCategoryHead capitalize">Terms and Conditions</p>
          </div>
          {termsAttributes.component.content.map((item) => (
            <>
              {item.nodeType != 'paragraph' && (
                <>
                  <Markdown className="privacyPolicyTitle" text={item?.data?.target?.fields?.title || ''} />

                  <Markdown className="privacyPolicyDesciption" text={item?.data?.target?.fields?.description} />
                </>
              )}
            </>
          ))}
        </div>
      </div>
    </>
  );
};

export default ContentTerms;
